import React from 'react';
import {Link} from "react-router-dom";
import TeamLogoSmall from "modules/Teams/TeamLogoSmall";
import {getTimezoneName} from "services/TimezoneService";
import {Trans} from "react-i18next";

export default function TeamSection({ team, onDeleteAccount }) {
    return (
        <div className="mb-default">
            <h2><Trans>Profile settings</Trans></h2>

            <dl className="dl-list mb-small">
                <dt><Trans>Team name</Trans></dt><dd>{team.name}</dd>
                <dt><Trans>Timezone</Trans></dt><dd>{getTimezoneName(team.timezone)}</dd>
            </dl>

            <div className="mb-small">
                <p><Trans>Team logo</Trans></p>
                <TeamLogoSmall team={team} />
            </div>

            <Link to={'/teams/'+team.id+'/edit'} className="btn-sm btn-default mr-small"><Trans>Edit</Trans></Link>
            <button onClick={() => onDeleteAccount()} className="btn-sm btn-default mr-small"><Trans>Delete account</Trans></button>
        </div>
    );
}
