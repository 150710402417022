import React from 'react';
import Select from "react-select";
import {withTranslation} from "react-i18next";

const SeasonSelect = ({ t, seasons, selectedValue, selectedSeason, onChange }) => {
    function formatSeasonName(season) {
        const seasonStatus = t(season.status);
        return t('Season') + ` #${season.id} (${seasonStatus})`;
    }

    const prepareSeasonsList = () => {
        let options = [];

        seasons.forEach((season) => {
            options.push({
                value: season.id,
                label: formatSeasonName(season)
            });
        });

        return options;
    };
    const options = prepareSeasonsList();

    let selectedOption = selectedSeason ? {
        value: selectedSeason.id,
        label: formatSeasonName(selectedSeason)
    } : null;

    if (!selectedOption) {
        selectedOption = options.find(({value}) => (value === selectedValue));
    }

    return (
        <Select
            className="language-switcher-select"
            options={options}
            value={selectedOption}
            onChange={(option) => onChange(option.value)}
        />
    );
};

export default withTranslation()(SeasonSelect);
