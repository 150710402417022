import React, { useState } from 'react';
import Logo from "modules/Common/Logo";
import NavBar from "modules/Common/NavBar";
import NavActions from "modules/Common/NavActions";
import {getUser} from "services/AuthService";
import SecureNavActions from "modules/Common/SecureNavActions";
import SecureNavBar from "modules/Common/SecureNavBar";
import {withRouter} from "react-router-dom";
import LangLinks from "modules/Common/LangLinks";

class Header extends React.Component {
    state = {
        isMenuOpen: false,
        path: this.props.location.pathname,
    };

    toggleMenu = () => {
        if (this.state.isMenuOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    };

    openMenu = () => {
        document.body.classList.add('noscroll');
        this.setState({isMenuOpen: true});
    };

    closeMenu = () => {
        document.body.classList.remove('noscroll');
        this.setState({isMenuOpen: false});
    };

    componentDidUpdate() {
        if (this.state.path !== this.props.location) {
            this.setState({path: this.props.location});
            this.closeMenu();
        }
    }

    render() {
        const {isMenuOpen} = this.state;
        const user = getUser();

        let notificationOptions = {};
        if (this.props.nbNotifications > 0) {
            notificationOptions['data-badge'] = this.props.nbNotifications;
        }

        return (
            <header className={"header" + (isMenuOpen ? ' menu-open' : '')}>
                <Logo/>

                <button {...notificationOptions} className="mobile-nav-toggle" onClick={() => {this.toggleMenu()}}>
                    <img src="/img/icons/bars.svg" style={{width: 30, marginTop: 10}} />
                </button>

                <div className="topnav">
                    <LangLinks />

                    {user
                        ? <SecureNavBar user={user}/>
                        : <NavBar/>
                    }
                    {user
                        ? <SecureNavActions user={user} nbNotifications={this.props.nbNotifications}/>
                        : <NavActions/>
                    }
                </div>
            </header>
        );
    }
}

export default withRouter(Header);