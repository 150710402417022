import React from 'react';
import ReactModal from 'react-modal';
import {Trans} from "react-i18next";
import Trophy from "modules/Trophies/Trophy";

export default function TrophyModal({
      handleCloseModal,
      content,
      trophy,
      onConfirm,
      onCancel,
      ...rest
    }) {

    const onRequestCancel = () => {
        if (onCancel===undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };

    const style = {
        content : {
            paddingBottom: 0,
        }
    };

    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            style={style}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">
                <Trans>You won a trophy!</Trans>
            </p>
            <div className="modal-content">
                <Trophy trophy={trophy} wide />
            </div>
        </ReactModal>
    );
}
