import React from 'react';
import CopyrightNotice_en from "./html/en/CopyrightNotice";
import CopyrightNotice_hu from "./html/hu/CopyrightNotice";
import {withTranslation} from "react-i18next";

function CopyrightNoticePage({ i18n }) {
    return (
        <main className="content-page">
            {i18n.language === 'en' ? <CopyrightNotice_en/> : <CopyrightNotice_hu/>}
        </main>
    );
}

export default withTranslation()(CopyrightNoticePage);
