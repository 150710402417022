import React from 'react';
import Select from "react-select";
import {withTranslation} from "react-i18next";

function GenderSelect({form, field, t, ...rest}) {
    const options = [
        {value: 'm', label: t('Male')},
        {value: 'f', label: t('Female')}
    ];

    const selectedOption = options.find((option) => option.value === field.value);
    return (
        <Select
            placeholder={t('select_placeholder')}
            defaultValue={selectedOption}
            options={options}
            isSearchable={false}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            {...rest}
        />
    );
}

export default withTranslation()(GenderSelect);
