import React, {Component} from 'react';
import ScoreConfigForm from "modules/Owner/ScoreConfig/ScoreConfigForm";
import {getScoreConfigs, updateScoreConfigs} from "services/ScoresConfigsService";
import Loader from "modules/Common/Loader";
import {addErrorAsNotification} from "services/ValidationService";
import {addSuccessNotification} from "services/NotificationService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {withTranslation} from "react-i18next";

class ScoreConfigPage extends Component {
    state = {
        scoreConfigs: null,
        isLoading: false,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        getScoreConfigs()
            .then((scoreConfigs) => this.setState({scoreConfigs, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    updateScoresConfig = (values) => {
        this.setState({isLoading: true});
        return updateScoreConfigs(values)
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Scores configuration was successfully updated'
                );

                this.refreshData();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {scoreConfigs, isLoading} = this.state;

        if (isLoading && !scoreConfigs) {
            return <main><Loader /></main>;
        }

        if (!scoreConfigs) {
            return null;
        }

        return (
            <main>
                <h1>Scores configuration</h1>
                <LoadingOverlay active={isLoading && scoreConfigs}>
                    <ScoreConfigForm values={scoreConfigs} handleSubmit={this.updateScoresConfig} />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(ScoreConfigPage);