import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function MoveToActiveConfirmationModal({player, onConfirm, t, ...rest}) {
    if (!player) {
        return null;
    }
    const positionTitle = t(player.position);

    return (
        <ConfirmationModal
            title={t("Move player")}
            content={t('Are you sure you want to move {{namePosition}} to starting lineup?', {namePosition: `${player.name} (${player.position})`})}
            buttons={{
                yes: t('Yes, move player'),
                no: t('Cancel')
            }}
            type="creative"
            onConfirm={() => onConfirm(player)}
            {...rest}
        />
    );
}

export default withTranslation()(MoveToActiveConfirmationModal);
