import React from 'react';
import Select from 'react-select';
import {withTranslation} from "react-i18next";

function TeamsNbSelect({t, anyOption, selectedValue, ...rest}) {
    let options = [
        {value: 4, label: '4'},
        {value: 6, label: '6'},
        {value: 8, label: '8'},
        {value: 10, label: '10'},
        {value: 12, label: '12'},
        {value: 14, label: '14'},
        {value: 16, label: '16'},
    ];

    if (anyOption) {
        options.unshift({value: null, label: t('Any')});
    }

    const selectedOption = options.find(({value}) => (value === selectedValue));

    return (
        <Select
            placeholder={t('select_placeholder')}
            value={selectedOption}
            options={options}
            {...rest}
        />
    );
}

export default withTranslation()(TeamsNbSelect);
