import React, {Component} from 'react';
import {NB_BENCH_PLAYERS} from "services/RosterService";
import {prepareActionsCell} from "modules/Roster/RosterTableHelper";
import BenchRosterTableRaw from "modules/Roster/BenchRosterTableRaw";
import BenchRosterTableRawFixed from "modules/Roster/BenchRosterTableRawFixed";

export default class BenchRosterTable extends Component {
    render() {
        const {
            players,
            playersWeeklyScores,
            scores,
            withEmptySlots,
        } = this.props;

        if (withEmptySlots) {
            const nbEmptySlots = Math.max(0, NB_BENCH_PLAYERS - players.length);
            for (let slotNumber = 1; slotNumber <= nbEmptySlots; ++slotNumber) {
                players.push({id: `slot_${slotNumber}`, emptySlot: true});
            }
        }

        let actionsCell = prepareActionsCell(this.props);

        return (
            <div>
                <div className="lg-up">
                    <BenchRosterTableRawFixed
                        actionsCell={actionsCell}
                        players={players}
                        scores={scores}
                        playersWeeklyScores={playersWeeklyScores}
                    />
                </div>

                <div className="lg-down">
                    <BenchRosterTableRaw
                        actionsCell={actionsCell}
                        players={players}
                        scores={scores}
                        playersWeeklyScores={playersWeeklyScores}
                    />
                </div>
            </div>
        );
    }
}
