import React, { useState, useEffect } from 'react';
import Select from "react-select";
import moment from "moment";
import {withTranslation} from "react-i18next";

const DropdownDatePicker = ({field, form, options, t, ...rest}) => {
    const initialDate = field.value ? moment(field.value) : null;

    let [dateParts, setDateParts] = useState({
        day: initialDate ? parseInt(initialDate.format('D')) : null,
        month: initialDate ? parseInt(initialDate.format('M')) : null,
        year: initialDate ? parseInt(initialDate.format('Y')) : null,
    });

    useEffect(() => {
        const {day, month, year} = dateParts;
        if (!day || !month || !year) {
            form.setFieldValue(null);
            return;
        }

        form.setFieldValue(field.name, moment.utc([year, month-1, day]));
    }, [dateParts]);

    const setDate = (newDateParts) => {
        setDateParts(newDateParts);
    };

    const setDay = (day) => {
        setDate({...dateParts, day});
    };

    const setMonth = (month) => {
        setDate({...dateParts, month});
    };

    const setYear = (year) => {
        setDate({...dateParts, year});
    };

    const onBlur = (e) => {
        const fromElement = e.target;
        const toElement = e.relatedTarget;

        if (fromElement && toElement) {
            if (toElement.closest('.dropdown-date-picker') === fromElement.closest('.dropdown-date-picker')) {
                return;
            }
        }

        form.setFieldTouched(field.name);
    };

    return (
        <div className="form-control">
            <div className="dropdown-date-picker" onBlur={onBlur}>
                <DayPicker value={dateParts.day} onChange={setDay} placeholder={t('Day')} />
                <MonthPicker value={dateParts.month} onChange={setMonth} placeholder={t('Month')} />
                <YearPicker  value={dateParts.year} onChange={setYear} placeholder={t('Year')} />
            </div>
        </div>
    );
};

const DayPicker = ({ value, onChange, ...rest }) => {
    let options = [];
    for (let day=1; day<=31; ++day) {
        options.push({value: day, label: day});
    }

    const selectedValue = value ? options.find((current) => current.value === value) : null;

    return (
        <Select
            className="day-picker"
            menuPortalTarget={document.body}
            value={selectedValue}
            options={options}
            onChange={({value}) => onChange(value)}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem',
                    height: '32rem',
                    overflow: 'scroll'
                }),
            }}
            {...rest}
        />
    )
};

const MonthPicker = ({ value, onChange, ...rest }) => {
    let options = [];
    for (let month=1; month<=12; ++month) {
        options.push({
            value: month,
            label: moment().month(month-1).format("MMMM")
        });
    }

    const selectedValue = value ? options.find((current) => current.value === value) : null;

    return (
        <Select
            className="month-picker"
            menuPortalTarget={document.body}
            value={selectedValue}
            options={options}
            onChange={({value}) => onChange(value)}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem',
                    height: '32rem',
                    overflow: 'scroll'
                }),
            }}
            {...rest}
        />
    )
};

const YearPicker = ({value, onChange, startYear = null, endYear = null, ...rest }) => {
    const now = new Date();
    const currentYear = now.getFullYear();

    if (!endYear) {
        endYear = currentYear;
    }

    if (!startYear) {
        startYear = endYear - 100;
    }

    let options = [];
    for (let year=startYear; year<=endYear; ++year) {
        options.push({value: year, label: year});
    }

    const selectedValue = value ? options.find((current) => current.value === value) : null;

    return (
        <Select
            className="year-picker"
            menuPortalTarget={document.body}
            value={selectedValue}
            options={options}
            onChange={({value}) => onChange(value)}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem',
                    height: '32rem',
                    overflow: 'scroll'
                }),
            }}
            {...rest}
        />
    )
};

export default withTranslation()(DropdownDatePicker);