import React from 'react';
import TimePicker from './TimePicker';

export default class TimePickerWidget extends React.Component {
    onChange = (value) => {
        this.props.form.setFieldValue(this.props.field.name, value);
    };

    render() {
      const {field, form, options, ...rest} = this.props;

      return (
          <TimePicker
              onChange={this.onChange}
              value={field.value}
              step={15}
              {...rest}
          />

      );
    }
}
