import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {normalizeErrors} from "services/ValidationService";
import TeamsNbSelect from "modules/Common/TeamsNbSelectWidget";
import {Trans, withTranslation} from "react-i18next";
import {SliderWidget} from "modules/Common/Form/SliderWidget";

const LeagueForm = function({handleSubmit, submitButtonName, submitButtonRef, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form owner-league-form" noValidate>
            {errors.general && <div className="error-message">{errors.general}</div>}

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>League name</Trans></label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="nbMaxTeams"><Trans>Number of teams</Trans></label>
                <Field className="form-control"
                       name="nbMaxTeams"
                       component={TeamsNbSelect}
                />
                <ErrorMessage name="nbMaxTeams" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="description"><Trans>Description</Trans></label>
                <Field component="textarea" rows={5} name="description" className="form-control" />
                <ErrorMessage name="description" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="images"><Trans>Images</Trans></label>
                <Field component={SliderWidget} name="images" className="form-control" />
                <ErrorMessage name="images" />
            </div>

            <div className="form-group mt-default" style={{display: submitButtonName ? 'block' : 'none'}}>
                <button ref={submitButtonRef} className="submit btn btn-primary mr-small" type="submit"><Trans>{submitButtonName ? submitButtonName : 'Create'}</Trans></button>
            </div>
        </form>
    );
};

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: ({ league }) => ({
            'name': league ? league.name : '',
            'description': league ? league.description : '',
            'nbMaxTeams': league ? league.nbMaxTeams : '',
            'images': league ? league.slides : [],
        }),
        validationSchema: ({ t }) => yup.object().shape({
            name: yup.string().required(t('Please input league name')),
            description: yup.string(),
            nbMaxTeams: yup.number().required(t('Please select number of teams')),
            images: yup.array().of(
                yup.object().shape({
                    title: yup.string().required('Title is required')
                    // image: yup
                    //     .mixed()
                    //     .required("Please select image")
                    //     .test(
                    //         "fileSize",
                    //         "File too large",
                    //         value => value && value.size <= FILE_SIZE
                    //     )
                    //     .test(
                    //         "fileFormat",
                    //         "Unsupported Format",
                    //         value => value && SUPPORTED_FORMATS.includes(value.type)
                    //     )
                })
            )
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(LeagueForm);
