import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {addErrorAsNotification, normalizeErrors, normalizeGeneralError} from "services/ValidationService";
import GenderSelect from "modules/Common/Form/GenderSelect";
import moment from "moment";
import {Trans, withTranslation} from "react-i18next";
import Checkbox from "modules/Common/Form/Checkbox";
import DropdownDatePicker from "modules/Common/Form/DropdownDatePicker";

const EditProfileForm = function({handleSubmit, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form center-form" noValidate>
            <h1><Trans>Edit profile details</Trans></h1>

            {errors.general && <div className="error-message">{errors.general}</div>}

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>First name</Trans></label>
                <Field type="text" name="firstName" className="form-control" />
                <ErrorMessage name="firstName" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Last name</Trans></label>
                <Field type="text" name="lastName" className="form-control" />
                <ErrorMessage name="lastName" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Gender</Trans></label>
                <Field component={GenderSelect} name="gender" className="form-control" />
                <ErrorMessage name="gender" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Location</Trans></label>
                <Field type="text" name="location" className="form-control" />
                <ErrorMessage name="location" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="birthDate"><Trans>DOB</Trans></label>
                <Field component={DropdownDatePicker} name="birthDate" />
                <ErrorMessage name="birthDate" />
            </div>

            <div className="form-group mt-default">
                <label className="form-label" htmlFor="newsletter" />
                <label className="form-label">
                    <Field component={Checkbox} className="mr-tiny" id="newsletter" name="newsletter"  />

                    <Trans>I want to receive a newsletter</Trans>
                </label>

                <ErrorMessage name="newsletter" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Save Changes</Trans></button>
                <Link to="/profile" className="btn btn-default"><Trans>Cancel</Trans></Link>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: ({ user }) => ({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'location': user.location,
            'gender': user.gender,
            'birthDate': moment(user.birthDate),
            'newsletter': user.newsletter,
        }),
        validationSchema: ({ t }) => yup.object().shape({
            firstName: yup.string().required(t('Please input first name')),
            lastName: yup.string().required(t('Please input last name')),
            location: yup.string()
                .required(t('Please input your location'))
                .max(180, t('Must be shorter than {{max}} characters', {max: 180})),
            birthDate: yup.date()
                .required(t('Please input your date of birth'))
                .nullable()
                .typeError(t('Invalid date'))
            ,
            gender: yup.string()
                .required(t('Please choose your gender'))
                .oneOf(['m', 'f']),

            newsletter: yup.bool()
        }),
        handleSubmit: (values, { props, setErrors }) => {
            const data = {...values};
            data.birthDate =  moment(values.birthDate, 'DD/MM/YYYY', true).format('YYYY-MM-DD');
            props.processData(data)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(EditProfileForm);
