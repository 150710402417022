import React from 'react';

import { compose } from "recompose";
import {Field} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {normalizeErrors} from "services/ValidationService";
import UploadThumbnail from "modules/Common/UploadThumbnail";
import PageSelect from "modules/Owner/Files/PageSelect";
import PlacementSelect from "modules/Owner/Files/PlacementSelect";
import {withFormikAndEffect} from "modules/Common/WithFormikAndEffect";

const FileForm = function({handleSubmit, values, setFieldValue, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form registration-form" noValidate>

            <div className="form-group">
                <label className="form-label" htmlFor="page">Page</label>
                <Field component={PageSelect} name="page" className="form-control" />
                <ErrorMessage name="page" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="placement">Placement</label>
                <Field component={PlacementSelect} name="placement" className="form-control" />
                <ErrorMessage name="placement" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="file">Picture</label>
                <input key={values.file} name="file" type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                }} className="form-control"/>

                <ErrorMessage name="file" />

                <div style={{marginLeft: '25rem'}} className='mt-default'>
                    <UploadThumbnail file={values.file}/>
                </div>
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="placement">URL</label>
                <Field name="url" className="form-control" />
                <ErrorMessage name="url" />
            </div>


            {<div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit">Upload Ad</button>
            </div>}
        </form>
    );
};


const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

export default compose(
    withFormikAndEffect({
        onChange: (props) => {
            props.onChange(props.values);
        },
        mapPropsToValues: ({ ad }) => {
            return {
                'page': ad ? ad.page : null,
                'placement': ad ? ad.placement : null,
                'file': null,
                'url': ad ? ad.url : null,
            };
        },
        validationSchema: () => {
            let validators = {
                page: yup.string().required('Please select page'),
                placement: yup.string().required('Please select ad placement'),
                file: yup
                    .mixed()
                    .test(
                        "fileSize",
                        "File too large",
                        value => !value || value.size <= FILE_SIZE
                    )
                    .test(
                        "fileFormat",
                        "Unsupported Format",
                        value => !value || SUPPORTED_FORMATS.includes(value.type)
                    ),
                url: yup.string().url()
            };

            //validators.file = validators.file.required("Please select ad file");

            return yup.object().shape(validators);
        },
        handleSubmit: (values, { props, setFieldValue, setTouched, setErrors, resetForm }) => {
            props.processData(values)
                .then(() => {
                    resetForm();
                    setFieldValue('page', values.page);
                    setFieldValue('placement', values.placement);
                })
                .catch(({response}) => {
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(FileForm);
