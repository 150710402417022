import {Component} from 'react';
import {withRouter} from "react-router-dom";
import {addWarningNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";

class Expired extends Component {
    componentDidMount() {
        const { t } = this.props;
        addWarningNotification(
            t('Your session has expired'),
            t('Please login again to continue')
        );
        this.props.history.push('/login');
    }

    render() {
        return null;
    }
}

export default withTranslation()(
    withRouter(Expired)
);
