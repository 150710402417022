import {getUser} from "services/AuthService";
import {getCurrentSeason, getSeason} from "services/SeasonService";

export function setCurrentLeagueId(leagueId) {
    localStorage.setItem('leagueId', leagueId);
}

export function getCurrentLeagueId() {
    const leagueId = localStorage.getItem('leagueId');

    return (leagueId !== null ? parseInt(leagueId) : null);
}

export function setSelectedSeasonId(seasonId) {
    localStorage.setItem('seasonId', seasonId);
}

export function getSelectedSeasonId() {
    const seasonId = localStorage.getItem('seasonId');

    return (seasonId !== null ? parseInt(seasonId) : null);
}

export function getSelectedSeason() {
    const seasonId = getSelectedSeasonId();
    if (seasonId) {
        return getSeason(seasonId);
    }

    return getCurrentSeason();
}


export function getLeagueMembership(leagueId) {
    const user = getUser();

    let foundMembership = null;
    user.leagueMemberships.forEach(leagueMembership => {
        if (leagueMembership.league.id === leagueId) {
            foundMembership = leagueMembership;
        }
    });

    return foundMembership;
}

export function canAdminLeague(leagueId)
{
    const user = getUser();
    if (user.roles.includes("SUPER_ADMIN")) {
        return true;
    }

    const membership = getLeagueMembership(leagueId);
    if (!membership) {
        return false;
    }

    return (membership.role === 'LEAGUE_ADMIN');
}
