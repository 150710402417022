import React, {useEffect}  from 'react';
import {useLocation} from "react-router-dom";

const RouteListener = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);

        //Google analytics
        if (window.ga !== undefined) {
            window.ga('send', 'pageview', location.pathname);
        }

    },[location.pathname]);

    return null;
};

export default RouteListener;
