import React, {Fragment} from "react";
import ReactTooltip from "react-tooltip";
import Moment from "react-moment";
import moment from "moment";
import {getPlayerSectionByPosition} from "services/PlayerService";
import {Trans} from "react-i18next";

export function PlayerToolTip({player, team, scores}) {
    if (!player) {
        return null;
    }

    if (!team) {
        team = player.team;
    }

    const weeks = !!scores ? Object.keys(scores) : [];
    const section = getPlayerSectionByPosition(player.position);

    return (
        <Fragment>
            <a data-tip data-type="info" data-for={`player${player.id}`} data-place="right"> {player.name}</a>
            <ReactTooltip style={{zIndex: 100000}} id={`player${player.id}`}>
                <div className="player-popout mt-small">
                    {player.photo && <img style={{float: 'left', marginBottom: 50, border: '1px solid silver'}}
                         className="mb-small mr-small" width={100}
                         src={player.photo}/>}
                    <div>
                        <p><strong><Trans>Name</Trans>:</strong> {player.name}</p>
                        <p><strong><Trans>Status</Trans>:</strong> {player.status}</p>
                        <p><strong><Trans>Team</Trans>:</strong> {team.name}</p>
                        <p><strong><Trans>Position</Trans>:</strong> <Trans>{player.position}</Trans>}</p>
                        {player.height !== null && <p><strong><Trans>Height</Trans>:</strong> {player.height} cm</p>}
                        {player.weight !== null && <p><strong><Trans>Weight</Trans>:</strong> {player.weight} kg</p>}
                        {player.weight !== null && <p><strong><Trans>Age</Trans>:</strong> {moment().diff(moment(player.birthDate), 'years')} (<Moment format='YYYY-MM-DD'>{player.birthDate}</Moment>)</p>}
                    </div>
                    <p className="mt-small mb-small"><strong><Trans>Analysis</Trans>:</strong> <br/> {player.analysis}</p>

                    <table className="table table-condensed table-bordered mb-default">
                        <thead>
                        <tr>
                            <th><Trans>Week</Trans></th>
                            {section === 'offense' && <Fragment>
                                <th><Trans>Pass yds</Trans></th>
                                <th><Trans>Rush yds</Trans></th>
                                <th><Trans>Catch yds</Trans></th>
                                <th><Trans>TD</Trans></th>
                                <th><Trans>2pt</Trans></th>
                                <th><Trans>Fumble</Trans></th>
                                <th><Trans>INT</Trans></th>
                            </Fragment>}

                            {section === 'defense' && <Fragment>
                                <th><Trans>Fum forced</Trans></th>
                                <th><Trans>INT</Trans></th>
                                <th><Trans>Sack</Trans></th>
                                <th><Trans>Pts allowed</Trans></th>
                            </Fragment>}

                            {section === 'special' && <Fragment>
                                <th><Trans>FG</Trans></th>
                                <th><Trans>XP</Trans></th>
                                <th><Trans>FG miss</Trans></th>
                                <th><Trans>XP miss</Trans></th>
                            </Fragment>}

                            {section === 'hc' && <Fragment>
                                <th><Trans>Win Loss</Trans></th>
                                <th><Trans>Pts gained</Trans></th>
                                <th><Trans>Score difference</Trans></th>
                            </Fragment>}
                        </tr>
                        </thead>
                        <tbody>
                        {weeks.map((week) => {
                            const playerScores = scores[week];
                            return (
                                <tr>
                                    <td>{week}</td>
                                    {section === 'offense' && <Fragment>
                                        <td>{playerScores['pass_yds']}</td>
                                        <td>{playerScores['rush_yds']}</td>
                                        <td>{playerScores['catch_yds']}</td>
                                        <td>{playerScores['td']}</td>
                                        <td>{playerScores['_2pt']}</td>
                                        <td>{playerScores['fumble']}</td>
                                        <td>{playerScores['int'] ? playerScores['int'] : playerScores['defense_int']}</td>
                                    </Fragment>}

                                    {section === 'defense' && <Fragment>
                                        <td>{playerScores['fum_forced']}</td>
                                        <td>{playerScores['defense_int']}</td>
                                        <td>{playerScores['sack']}</td>
                                        <td>{playerScores['pts_allowed']}</td>
                                    </Fragment>}

                                    {section === 'special' && <Fragment>
                                        <td>{playerScores['fg']}</td>
                                        <td>{playerScores['xp']}</td>
                                        <td>{playerScores['fg_miss']}</td>
                                        <td>{playerScores['xp_miss']}</td>
                                    </Fragment>}

                                    {section === 'hc' && <Fragment>
                                        <td>{playerScores['win_loss']}</td>
                                        <td>{playerScores['pts_gained']}</td>
                                        <td>{playerScores['score_difference']}</td>
                                    </Fragment>}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </ReactTooltip>
        </Fragment>
    )
}
