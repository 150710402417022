import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {normalizeErrors} from "services/ValidationService";
import TimezonePicker from "modules/Common/TimezonePicker";
import UploadThumbnail from "modules/Common/UploadThumbnail";
import {Link} from "react-router-dom";
import TeamLogoSelector from "modules/LeagueAdmin/EditTeam/TeamLogoSelector";
import TeamsNbSelect from "modules/Common/TeamsNbSelectWidget";
import {Trans, withTranslation} from "react-i18next";

const CreateLeagueForm = function({handleSubmit, teamLogos, values, setFieldValue, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form registration-form" noValidate>
            <h1><Trans>Create a League</Trans></h1>

            {errors.general && <div className="error-message">{errors.general}</div>}

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>League name</Trans></label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="nbMaxTeams"><Trans>Number of teams</Trans></label>
                <Field className="form-control"
                       name="nbMaxTeams"
                       component={TeamsNbSelect}
                />
                <ErrorMessage name="nbMaxTeams" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Team name</Trans></label>
                <Field type="text" name="teamName" className="form-control" />
                <ErrorMessage name="teamName" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Timezone</Trans></label>
                <Field component={TimezonePicker} name="timezone" className="form-control" />
                <ErrorMessage name="timezone" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="ownerLogo"><Trans>Select logo</Trans></label>
                <Field component={TeamLogoSelector} teamLogos={teamLogos} name="ownerLogo" />
                <ErrorMessage name="ownerLogo" />

                <label className="form-label" htmlFor="logo"><Trans>Browse on your pc and upload a picture</Trans>:</label>

                <input name="logo" type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                    setFieldValue("logo", event.currentTarget.files[0]);
                }} className="form-control"/>
                <ErrorMessage name="logo" />

                <div style={{marginLeft: '25rem'}} className='mt-default'>
                    <UploadThumbnail file={values.logo}/>
                </div>
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Create</Trans></button>
                <Link to="/secure" className="btn btn-default"><Trans>Cancel</Trans></Link>
            </div>
        </form>
    );
};


const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: () => ({
            'name': '',
            'nbMaxTeams': '',
            'teamName': '',
            'timezone': '',
            'logo': '',
            'ownerLogo': '',
        }),
        validationSchema: ({ t }) => yup.object().shape({
            name: yup.string().required(t('Please input league name')),
            nbMaxTeams: yup.number().required(t('Please select number of teams')),
            teamName: yup.string().required(t('Please input your team name')),
            timezone: yup.string().required(t('Please select your timezone')),
            // logo: yup
            //     .mixed()
            //     .test(
            //         "fileSize",
            //         "File too large",
            //         value => value && value.size <= FILE_SIZE
            //     )
            //     .test(
            //         "fileFormat",
            //         "Unsupported Format",
            //         value => value && SUPPORTED_FORMATS.includes(value.type)
            //     )
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(CreateLeagueForm);
