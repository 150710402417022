import API from "services/API";

export function getSettings() {
    return API.get(`/settings`)
        .then((response) => response.data)
    ;
}

export function saveSettings(settings) {
    return API.post(`/settings`, settings)
        .then((response) => response.data)
    ;
}
