import React from 'react';
import {Trans, withTranslation} from "react-i18next";
import {getLeague, getStandings} from "services/LeagueService";
import {withRouter} from "react-router-dom";
import StandingsTable from "modules/LeagueUser/StandingsTable";
import Loader from "modules/Common/Loader";

class StandingsPage extends React.Component {
    state = {
        isLoading: true,
        standings: null
    };

    componentDidMount() {
        const leagueId = this.props.match.params.leagueId;

        const requests = Promise.all([
            getLeague(leagueId),
            getStandings(leagueId)
                .catch((error) => {
                    if (error.response.status === 404) {
                        return [];
                    }

                    throw error;
                })
        ]);

        //fetch all at once
        this.setState({isLoading: true});
        requests
            .then(([league, standings]) => {
                this.setState({league, standings, isLoading: false})
            })
            .catch(() => {
               this.setState({isLoading: false});
            })
        ;
    }

    render() {
        const {league, standings, isLoading} = this.state;

        if (isLoading || !league) {
            return <Loader/>
        }

        return (
            <div>
                <h1>{league.name}</h1>

                <StandingsTable standings={standings} />
            </div>
        );
    }
}

export default withTranslation()(withRouter(StandingsPage));