import API from "services/API";

export function getDraftTeams(leagueId) {
    return API.get('leagues/'+leagueId+'/draft-teams')
        .then((response) => response.data)
    ;
}

export function getDraftTeam(leagueId) {
    return API.get('leagues/'+leagueId+'/draft-team')
        .then((response) => response.data)
    ;
}
