import React from 'react';
import {Field, withFormik} from "formik";
import * as yup from "yup";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import {Link} from "react-router-dom";
import RichEditor from "modules/Common/Form/RichEditor";
import TagsSelect from "modules/Owner/Blog/TagsSelect";
import UploadThumbnail from "modules/Common/UploadThumbnail";
import CategorySelect from "modules/Owner/Blog/CategorySelect";

function BlogPostForm({ blogPost, handleSubmit, values, setFieldValue, ...rest }) {
    const isNew = blogPost === null;

    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form form-pl2ayer" noValidate>
            <div className="form-row">
                <div className="form-group">
                    <label className="form-label" htmlFor="name">Title</label>
                    <Field type="text" name="title" className="form-control" />
                </div>
                <ErrorMessage name="title" />
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label className="form-label" htmlFor="name">Category</label>
                    <Field component={CategorySelect} name="category" className="form-control" />
                </div>
                <ErrorMessage name="category" />
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label className="form-label" htmlFor="name">Tags</label>
                    <Field component={TagsSelect} name="tags" className="form-control" />
                </div>
                <ErrorMessage name="tags" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor={`headerImage`}>Header image</label>

                <div>
                    <input name={`headerImage`}  type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                        setFieldValue(`headerImage`, event.currentTarget.files[0]);
                    }} className="form-control"/>

                    <ErrorMessage name={`headerImage`} />

                    <div className='mt-default mb-small'>
                        <UploadThumbnail file={values.headerImage} />
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <Field component={RichEditor}
                           editorOptions={{content_style: "body { font-family: sans-serif; }"}}
                           style={{flexBasis: '50%'}} rows={20} cols={100} name="body" className="form-control" />
                </div>
                <ErrorMessage name="body" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit">{isNew ? 'Create Post' : 'Update Post'}</button>
                <Link to="/owner/blog" className="btn btn-default">Cancel</Link>
            </div>
        </form>
    );
}

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

export default
    withFormik({
        mapPropsToValues: ({ blogPost }) => ({
            'title': blogPost ? blogPost.title : '',
            'category': blogPost ? blogPost.category : null,
            'tags': blogPost ? blogPost.tags : [],
            'body': blogPost ? blogPost.body : '',
            'headerImage': blogPost ? blogPost.headerImage : null,
        }),
        validationSchema: yup.object().shape({
            title: yup.string().required('Name is required'),
            body: yup.string().required('Message is required'),
            headerImage: yup
                .mixed()
                .test(
                    "fileSize",
                    "File too large",
                    value => {
                        if (!value || typeof value === 'string') {
                            return true;
                        }
                        return value.size <= FILE_SIZE;
                    }
                )
                .test(
                    "fileFormat",
                    "Unsupported Format",
                    value => {
                        if (!value || typeof value === 'string') {
                            return true;
                        }

                        return SUPPORTED_FORMATS.includes(value.type)
                    }
                ),
            tags: yup.array(),
        }),
        handleSubmit: (values, { props }) => {
            props.processData(values);
        }
    })(BlogPostForm)
;
