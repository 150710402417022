import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {
    formatPendingMemberType,
    getLeague,
    getLeagueSeason,
    getMembers,
    getPendingMembers,
    getStandings
} from "services/LeagueService";
import {addErrorAsNotification} from "services/ValidationService";
import {getSettings} from "services/SettingService";
import Moment from "react-moment";
import moment from "moment";
import {humanizeDuration} from "services/TimeHelper";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import StandingsTable from "modules/LeagueUser/StandingsTable";
import {getGames} from "services/FantasyGameService";
import {getLeagueRosters} from "services/RosterService";
import RostersSummaryTable from "modules/Draft/RostersSummaryTable";
import LeagueMembersTable from "modules/Owner/Users/LeagueMembersTable";
import GamesOverviewSection from "modules/GameCenter/GamesOverviewSection";
import {unstable_batchedUpdates} from "react-dom";
import {setCurrentLeagueId} from "services/UserStateService";
import {getLatestSeason} from "services/SeasonService";
import TeamModal from "modules/Owner/Leagues/TeamModal";
import {ownerUpdateFantasyTeam} from "services/FantasyTeamService";
import {addSuccessNotification} from "services/NotificationService";

const LeaguePage = ({ match, history, t }) => {
    const leagueId = match.params.id;
    let [isLoading, setLoading] = useState(false);
    let [settings, setSettings] = useState(null);
    let [season, setSeason] = useState(null);
    let [league, setLeague] = useState(null);
    let [leagueSeason, setLeagueSeason] = useState(null);
    let [rosters, setRosters] = useState([]);
    let [members, setMembers] = useState([]);
    let [pendingMembers, setPendingMembers] = useState([]);
    let [games, setGames] = useState([]);
    let [standings, setStandings] = useState([]);
    let [showTeamModal, setShowTeamModal] = useState(false);
    let [selectedTeam, setSelectedTeam] = useState(null);

    const onEditTeam = (team) => {
        setSelectedTeam(team);
        setShowTeamModal(true);
    };

    const onExecuteEditTeam = ({id, ...team}) => {
        setLoading(true);
        return ownerUpdateFantasyTeam(id, team)
            .then(() => {
                addSuccessNotification('Success', `Team "${team.name}" was successfully update`);
                history.go(0); //refresh page
            })
            .catch((response) => {
                setLoading(false);
                addErrorAsNotification(response, t);
            })
        ;
    }

    useEffect(() => {
        setLoading(true);

        getLatestSeason().then(( season ) => {
            const selectedSeasonId = season.id;
            const requests = Promise.all([
                getSettings(),
                getLeague(leagueId),
                getLeagueSeason(leagueId, selectedSeasonId),
                getMembers(leagueId),
                getPendingMembers(leagueId),
                getLeagueRosters(leagueId, selectedSeasonId),
                getGames(leagueId, selectedSeasonId),
                getStandings(leagueId, selectedSeasonId)
                    .catch((error) => {
                        if (error.response.status === 404) {
                            return [];
                        }

                        throw error;
                    })
            ]);

            requests
                .then(([settings, league, leagueSeason, members, pendingMembers, rosters, games, standings]) => {
                    unstable_batchedUpdates(() => {
                        setSettings(settings);
                        setLeague(league);
                        setLeagueSeason(leagueSeason);
                        setSeason(season);
                        setMembers(members);
                        setPendingMembers(pendingMembers);
                        setRosters(rosters);
                        setGames(games);
                        setStandings(standings);
                    })
                })
                .catch(({response}) => addErrorAsNotification(response, this.props.t))
                .finally(() => setLoading(false))
            ;
        });
    }, []);

    const manageLeague = (() => {
        setCurrentLeagueId(league.id);
        history.push('/league-admin/dashboard');
    });

    const timezone = settings ? settings.timezone : null;

    let draftDuration = null;
    if (leagueSeason) {
        const start = moment(leagueSeason.draftStartsAt);
        const end =  moment(leagueSeason.draftEndsAt);
        draftDuration = humanizeDuration(end.diff(start, 'seconds', true), t);
    }

    return (
        <main>
            {/*<div className="season-switcher">*/}
            {/*    <SeasonSwitcher />*/}
            {/*</div>*/}

            <div className="mb-default">
                <Link to="/owner/leagues" className="btn btn-sm btn-default">❮ &nbsp; Leagues</Link>
            </div>


            <LoadingOverlay active={isLoading}>
                {league && <>
                    <h1 className="mb-default">{league.name}</h1>

                    <button className="float-right btn btn-default" onClick={() => manageLeague()}>Manage League</button>
                    <Link className="float-right btn btn-default mr-default" to={`/owner/leagues/${league.id}/description`}>Edit Description</Link>

                    <div className="mb-default">
                        <h2 className="mb-small">League info</h2>
                        <dl className="dl-list">
                            <dt>League name</dt>
                            <dd>{league.name}</dd>

                            <dt>League admin</dt>
                            <dd>{league.adminUser ? league.adminUser.name : '-'}</dd>

                            <dt>League size</dt>
                            <dd>{league.nbTeams} / {league.nbMaxTeams}</dd>

                            {leagueSeason ? <>
                                <dt>Draft status</dt>
                                <dd>{leagueSeason.draftStatus}</dd>

                                <dt>Draft date</dt>
                                <dd>
                                    <Moment tz={timezone} format="DD/MM/YYYY hh:mma">{leagueSeason.draftStartsAt}</Moment>
                                    <span className="ml-tiny mr-tiny">&mdash;</span>
                                    <Moment tz={timezone} format="DD/MM/YYYY hh:mma">{leagueSeason.draftEndsAt}</Moment>
                                </dd>

                                <dt>Draft length</dt>
                                <dd>{draftDuration ? draftDuration : '-'}</dd>
                            </> : <>
                                <dt>Draft status</dt>
                                <dd>not scheduled</dd>
                            </>}
                        </dl>
                    </div>

                    {pendingMembers.length>0 && <div className="mb-default">
                        <h2 className="mb-small">Pending members</h2>
                        {pendingMembers.map(({ displayName, type }) => <>
                            <p>{displayName} &mdash; {formatPendingMemberType(type)}</p>
                        </>)}
                    </div>}

                    <div className="mb-default">
                        <h2 className="mb-small">Members</h2>
                        {members.length
                            ? <LeagueMembersTable
                                members={members}
                                standings={standings}
                                onEditTeam={onEditTeam}
                            />
                            : <p><strong>No members yet</strong></p>
                        }
                    </div>

                    {rosters && rosters.length>0 && <div className="mb-default">
                        <h2 className="mb-small">Rosters</h2>
                        <RostersSummaryTable rosters={rosters} withHighlight={false} />
                    </div>}

                    {standings && standings.length>0 && <div className="mb-default">
                        <h2 className="mb-small">Standings</h2>
                        <StandingsTable standings={standings} />
                    </div>}

                    {games && games.length>0 && <div className="mb-default">
                        <h2 className="mb-small">Games</h2>
                        <GamesOverviewSection
                            season={season}
                            league={league}
                            games={games}
                            standings={standings}
                        />
                    </div>}
                </>}

                <TeamModal
                    team={selectedTeam}
                    isOpen={showTeamModal}
                    handleCloseModal={() => setShowTeamModal(false)}
                    onSubmit={onExecuteEditTeam}
                />
            </LoadingOverlay>
        </main>
    );
};

export default compose(
    withRouter,
    withTranslation(),
)(LeaguePage);