import React, {useRef} from 'react';
import ReactModal from "react-modal";
import {formatLanguageName} from "services/LanguageService";
import LoadingOverlay from "modules/Common/LoadingOverlay";

export default function PreviewMessageModal(props) {
    const {
        previewContent,
        handleCloseModal,
        onSubmit,
        onCancel,
        isLoading,
        ...rest
    } = props;

    const onRequestCancel = () => {
        if (onCancel === undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };

    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal message-preview-modal autofit"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">Preview</p>

            <LoadingOverlay active={isLoading}>
                <div className="modal-content">
                    <div style={{display: 'flex', height: '100%'}}>
                        {previewContent.map(({language, subject, body}) => (
                            <div style={{flex: 1}}>
                                <h4 style={{marginBottom: 15}}>{formatLanguageName(language)}</h4>
                                <p><strong>Subject</strong></p>
                                <p>{subject}</p>

                                <p><strong>Body</strong></p>
                                <iframe srcDoc={body} frameBorder="0" width="100%" height="100%"></iframe>
                            </div>
                        ))}
                    </div>
                </div>
            </LoadingOverlay>

            <div className="modal-actions">
                <button className="btn btn-default" onClick={onRequestCancel}>Close</button>
            </div>
        </ReactModal>
    )
}
