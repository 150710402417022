import API from "services/API";

export function getTrophies(selectedSeasonId, fantasyTeamId) {
    return API.get(`/trophies/${selectedSeasonId}/${fantasyTeamId}`)
        .then((response) => response.data)
    ;
}

export function getTrophy(trophyId) {
    return API.get(`/trophies/${trophyId}`)
        .then((response) => response.data)
    ;
}

export function markTrophiesAsViewed(fantasyTeamId) {
    return API.post(`/trophies/${fantasyTeamId}/mark-viewed`)
        .then((response) => response.data)
    ;
}