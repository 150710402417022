import React from 'react';

const FAQ = () => {
    return (
        <div className="hierarchy faq">
            <ol>
                <li>
                    <h2>FANTASY FOOTBALL</h2>
                    <ol>
                        <li><h3>What is fantasy football?</h3> <div>Fantasy football is an online sports game, which is based on the real-life statistics of a sports league. You form leagues (playing groups of miniature championships) with your peers and draft players to form teams. Each week these teams match up against each other and the team which has more fantasy points wins. Fantasy points are calculated based on the real-life statistics of the individual players, such as touchdowns, yards gained, etc.</div></li>
                        <li><h3>What is the schedule of the fantasy season?</h3> <div>In HFF, we start with selecting the draft window, which is a time-frame in which the leagues need to perform a draft. The fantasy season is broken down to weeks, based on the game-weeks of the real football league. Once the real-life season starts, the fantasy teams will play against each other each week. Following each game, we upload the statistics in the system and the results become visible. The last week(s) of the real-life regular season is when the fantasy playoffs take place, during these times the top teams match up against each other and the final order of them is determined. This is when the fantasy season ends.</div></li>
                        <li><h3>What are fantasy points?</h3> <div>Fantasy points are scores each player receives based on their real-life statistics. The sum of these points determine which team wins each weekly matchup.</div></li>
                        <li><h3>How are fantasy points calculated?</h3> <div>For each fantasy player, the points are calculated in the following way: QB, RB, WR, TE: 0,1 points per yards, 6 points per touchdown -6 points for interception, -3 points for fumble, 2 points for successful 2-point conversion. K: 3 points per field goal, 1 point per extra point, -3 points per missed field goal, -1 point per missed extra point. DEF: 6 points per interception, 3 points per forced fumble, 3 points per sack,  6 points if points allowed are between 0-4 points, 3 points if points allowed are between 5-10 points. HC: 6 points per win, -6 points per loss, 0,25 points per points gained, in case of a win; 3 points if score difference is between 0-8, 4 points if score difference is between 9-16, 5 points if score difference is between 17-24, 6 points if score difference more than 24; in case of a loss; -3 points if score difference is between 0-8, -4 points if score difference is between 9-16, -5 points if score difference is between 17-24, -6 points if score difference more than 24.</div></li>
                        <li><h3>Where do the statistics come from?</h3> <div>We use the official Hungarian Football League (HFL) statistics, which we receive from the Hungarian American Football League (MAFSZ).</div></li>
                        <li><h3>When do the statistics get uploaded?</h3> <div>The statistics are being uploaded a few days following up the weekly games, normally between Wednesday-Friday, depending on when can we receive them.</div></li>
                        <li><h3>Does this cost me any money?</h3> <div>No, the Hungarian Fantasy Football is completely free for everyone to use.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>REGISTRY, PROFILE</h2>
                    <ol>
                        <li><h3>How can I register?</h3> <div>If you don't have an account yet, you can register by clicking the "Registration" link on the right corner of the website's main menu. Afterwards give your details on the registration page. If you would like to create a new or join an existing league but you haven't registered yet, you can also reach the registration page by clicking the "Create league" or "Join a league" buttons.</div></li>
                        <li><h3>How can I log in / log out?</h3> <div>You can log in by clicking the Login link on the upper header of the website. You can log out by clicking the Logout link on the upper header of the website.</div></li>
                        <li><h3>How can I change my password?</h3> <div>You can change your password by navigating to your profile page. You can reach it by clicking the <img className="faq-icon" src="/img/icons/faq/profile-bg-ico.png" /> icon on the upper header of the website. On the profile page, select the Change Password button. Give your old password and type in the new one twice then click Change Password to confirm the modification.</div></li>
                        <li><h3>How can I change profile settings?</h3> <div>You can change your profile settings by navigating to your profile page. You can reach it by clicking the <img className="faq-icon" src="/img/icons/faq/profile-bg-ico.png" /> icon on the upper header of the website. Click the "Edit" button to modify the existing details.</div></li>
                        <li><h3>What can I do if I forgot my password?</h3> <div>On the Login page, click "Forgot password" link below the login panel. Type in your e-mail and click "Send". A notification message will arrive to your e-mail address with a link to a password modification site. There, give the new password twice and click "Confirm". Your password will be changed.</div></li>
                        <li><h3>How can I view my notifications?</h3> <div>You can view your notifications by clicking the <img className="faq-icon" src="/img/icons/faq/notifications-bg-ico.png" /> icon on the upper header of the website.</div></li>
                        <li><h3>How can I subscribe or unsubscribe to the newsletter?</h3> <div>You can subscribe or unsubscribe to our newsletter by navigating to your profile page by clicking the <img className="faq-icon" src="/img/icons/faq/profile-bg-ico.png" /> icon on the upper header of the website. On the profile page, click "Edit" and tick the "I want to receive newsletter" checkbox.</div></li>
                        <li><h3>Can I delete my profile?</h3> <div>You can delete your profile on by navigating to the Settings page by clicking the <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> button on the upper header of the website. On the Settings page, click "Delete account". By deleting your account, you won't be able to participate in the fantasy football championship. If someone is deleting their account during an ongoing season, its teams will remain in the championship until the end of the season as "deleted user".</div></li>
                        <li><h3>I don't receive any confirmation e-mail. What should I do?</h3> <div>In case you do not receive a confirmation e-mail from us during registration, please also check your Spam folder, as often it is automatically placed there. In Gmail specifically, please also check the "Promotions" or "All mail" folders.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>LEAGUES</h2>
                    <ol>
                        <li><h3>What is a league?</h3> <div>The league is a mini-championship formed by a group of people within the fantasy football platform. The leagues are independent from each other and their members are playing against each others' fantasy teams within the league during the season.</div></li>
                        <li><h3>Who is the league administrator?</h3> <div>The league administrator is essentially the owner of the league, who is managing the members' list, settings of the league and draft. The admin has rights to invite or remove members, scheduling the draft and modifying the league's settings. The admin also has a team within the league and participates in the championship the same way as the members.</div></li>
                        <li><h3>Who is the league user?</h3> <div>The league user is the member of one or more leagues who does not have administrative rights. The member has a team within the league and plays in the championship with it.</div></li>
                        <li><h3>How can I create a league?</h3> <div>You can create a league by clicking the "Create a League" button on the Home Page or if you already have an account, the plus icon next to the league dropdown on each page. Fill in the details and click "Create". Afterwards you will have to create your own team as well for the league.</div></li>
                        <li><h3>Can I create multiple leagues at the same time?</h3> <div>Yes, you can create several leagues at the same time.</div></li>
                        <li><h3>Can I create a league if I didn't register?</h3> <div>No, you need to have a profile in HFF to be able to create a league.</div></li>
                        <li><h3>How can I invite league members?</h3> <div>You can invite members to your league by clicking the "Invite player" button next to each of your available slots on the Leagues page. Type in the person's e-mail address and click "Invite". Once the player accepts the invitation, they will appear in your league members list.</div></li>
                        <li><h3>How can I withdraw invitation to league members?</h3> <div>You can withdraw an invitation by clicking the "Remove" button next to the invited player on the member's list on the Leagues page.</div></li>
                        <li><h3>How can I change league settings?</h3> <div>You can change your league's settings by clicking the <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> icon on the upper header of the website and navigate to the Settings page.</div></li>
                        <li><h3>How can I remove the league from the system?</h3> <div>You can delete your league by clicking the <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> icon on the upper header of the website to navigate to the Settings page. On the Settings page, you can click "Delete League" button.</div></li>
                        <li><h3>How can I remove a league member?</h3> <div>You can remove a league member by clicking the "Remove" button next to the person on the members' list on the Leagues page.</div></li>
                        <li><h3>How can I transfer my league admin rights?</h3> <div>You can only transfer your league admin rights if you exit the league or delete your account. You can do these by clicking the <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> icon on the upper header of the website and navigate to the Settings page. In these cases a popup comes up for each of the leagues you are an admin, prompting you to select the league member you'd like to transfer your rights to. Select player and click OK.</div></li>
                        <li><h3>How can I join a league?</h3> <div>You can join a league by clicking the "Join a League" button on the Home page. You will be navigated to the leagues' list page where you can select a league to join by clicking the "Request to join league" button. A request is then sent to the league admin and if they accept it, you will become a member in the league.</div></li>
                        <li><h3>Can I join multiple leagues at the same time?</h3> <div>Yes, you can join several leagues at the same time.</div></li>
                        <li><h3>Can I join a league if I didn't register?</h3> <div>No, you need to have a profile in HFF to be able to join a league.</div></li>
                        <li><h3>How can I withdraw my joining request to a league?</h3> <div>If you want to withdraw your joining request, simply go click on "Join a League" button on the main page and look up the league in the list you've sent a request to. Then click the red button "Withdraw joining request" and the request will be deleted.</div></li>
                        <li><h3>How can I leave a league?</h3> <div>You can delete your league by clicking the <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> icon on the upper header of the website. On the Settings page, you can click "Exit League" button.</div></li>
                        <li><h3>How can I switch view between leagues?</h3> <div>You can switch view between your leagues by selecting the one you're member of in the dropdown menu on the top of each page.</div></li>
                        <li><h3>How can I review my league's standings?</h3> <div>You can review your league's standings on the Leagues page, which you can access by clicking the "Leagues" link on the upper header of the website.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>DRAFT</h2>
                    <ol>
                    <li><h3>What is a draft?</h3> <div>The draft is the event on the website during which the members of a league select their players for their teams. The draft is created by the league admin who gives a time-frame for the draft to happen. During this time the league members get to pick their players in a random order. The draft is broken down into Rounds and each member has 14 picks (the total number of players in a team). The players have a pre-set time window to make their picks, once a member picks a player, the system will automatically prompt the next member in the draft to make their pick.</div></li>
                    <li><h3>How can I add a draft?</h3> <div>You can create a draft only if you are a league admin. On the Leagues page, click "Add Draft" button. Select the start and end date of the draft and click "Continue". The time-frame for the whole draft should not be shorter than 3 hours and the end date should be before the season start. After confirmation, the draft details will become available to the league members.</div></li>
                    <li><h3>How can I change draft details?</h3> <div>You can modify the draft settings anytime before the draft start by clicking the "Change dates" on the Leagues page.</div></li>
                    <li><h3>How is the draft time-frame selected?</h3> <div>The time-frame of the draft is added by the league administrator, who is selecting the start date and end date of the draft. The first possible date is given by the HFF platform, the last possible date is the day before the HFL season starts.</div></li>
                    <li><h3>How much time do I have to draft?</h3> <div>The draft has a start and end date, you can pick players in between. The system calculates the order of how the members pick and each member gets 14 picks. Each pick has the same  amount of time, which depends on the length of the draft.</div></li>
                    <li><h3>How can I join the draft?</h3> <div>You can join the draft by clicking the "Enter draft" button on the Leagues page.</div></li>
                    <li><h3>How can I pick a player on the draft?</h3> <div>You can select a player by clicking the <img className="faq-icon" style={{width: 45, top: 12, height: 'auto'}} src="/img/icons/faq/pick-player-en-ico.png" /> icon next to the player's name. After confirmation the player will appear in your team and others won't be able to pick the player.</div></li>
                    <li><h3>What happens if I miss a pick on the draft?</h3> <div>If you miss your turn on the draft, the system will automatically pick a player for you based on the players' rankings.</div></li>
                </ol>
                </li>

                <li>
                    <h2>MY TEAM</h2>
                    <ol>
                    <li><h3>What is my team?</h3> <div>Your fantasy team is the group of real-life players that you draft or pick for yourself from the players' list. This team will play against your opponents in your league each week. It is broken down to a starting lineup and bench. The team consists of the following player types: QB - Quarterback, RB - Running Back, WR - Wide Receiver, TE - Tight-end, FLEX: it can be either RB, WR or TE, DEF - Defense, K - Kicker and HC - Head Coach.</div></li>
                    <li><h3>What is the starting lineup?</h3> <div>The starting lineup is the part of the team which takes part in the matchups you play against your peers in your league. It consists of a QB, RB, WR, TE, FLEX, K, DEF and HC player. The fantasy points that determine the outcome of each matchup are calculated based on the fantasy points that your players in the starting lineup gain.</div></li>
                    <li><h3>What is the bench?</h3> <div>The bench is the part of the team which does not take part in the weekly fantasy matchups. These players are on reserve and in case of need, they can be substituted into the starting lineup.</div></li>
                    <li><h3>How many players can I have on my team?</h3> <div>You can have 8 players in the starting lineup (QB, RB, WR, TE, FLEX, K, DEF, HC) and 6 players on bench, so a total of 14 players.</div></li>
                    <li><h3>How can I manage my team?</h3> <div>You can access your team by clicking the My Team link on the upper header of the website. Next to the players you can find an icon for each action (add, cut, move, trade) you can perform.</div></li>
                    <li><h3>How can I review a player's details?</h3> <div>You can review a player's details by clicking on the <img className="faq-icon" src="/img/icons/faq/player-info-bg-ico.png" /> icon next to their name.</div></li>
                    <li><h3>What is player analysis?</h3> <div>Player analysis details the current updates and possible performance in the next game.</div></li>
                    <li><h3>How can I cut a player?</h3> <div>You can cut a player from your team by clicking the <img className="faq-icon" src="/img/icons/faq/delete-player-bg-ico.png" /> icon next to their name. If you cut a player, they will be removed from your lineup and will be added back to the available players' list.</div></li>
                    <li><h3>How can I add a player?</h3> <div>You can add a player to your team by clicking the <img className="faq-icon" style={{width: 45, top: 12, height: 'auto'}} src="/img/icons/faq/pick-player-en-ico.png" /> icon next to the player's name in the Players list. If you add a player, they will appear in your team.</div></li>
                    <li><h3>How can I move a player?</h3> <div>You can move a player within your team by clicking the <img className="faq-icon" src="/img/icons/faq/move-player-down-bg-ico.png" /> icon next to the player's name. Then confirm in the popup window where do you want to move them and which player would you like to switch them with.</div></li>
                    <li><h3>How can I trade a player?</h3> <div>You can trade a player by clicking the <img className="faq-icon" src="/img/icons/faq/trade-players-bg-ico.png" /> icon next to the player's name. You can then create a trade proposal by selecting a team and a player you would like to claim in exchange for your player. Click "Confirm" to send the proposal, which will appear on the My Team page of the other user. If they accept the players will switch teams. You can withdraw the trade proposal by clicking the "Withdraw" on your My Team page. In case you receive a trade proposal, you can accept by clicking the "Accept" or decline by clicking the "Decline" buttons below the trade proposal details on the My Team page.</div></li>
                    <li><h3>When can I take actions on my team?</h3> <div>You can take actions on your team any time during the season except for when the real-life games are ongoing. During this time, the rosters are locked.</div></li>
                    <li><h3>How can I search for a player in the Players page?</h3> <div>You can search a player by typing their name in the search bar above the list. The list will automatically filter to the searched names.</div></li>
                </ol>
                </li>

                <li>
                    <h2>GAME CENTER</h2>
                    <ol>
                    <li><h3>What are the fantasy matchups?</h3> <div>The fantasy matchup is a game between two fantasy teams of the same league. The winner of the matchup is the team with the more fantasy points on the given regular season week.</div></li>
                    <li><h3>How can I review my matchups?</h3> <div>You can review your matchups by clicking the Game Center link on the upper header of the website.</div></li>
                    <li><h3>How can I switch between weeks in the Game Center?</h3> <div>You can switch between the weeks by clicking the given week above the shown matchup on the top of the page.</div></li>
                    <li><h3>How can I switch between matchups in the Game Center?</h3> <div>On a given week, several fantasy matchups are played in your league depending on the number of teams in the league. By selecting the individual matchups on the top of the page, you can switch between them in the Game Center.</div></li>
                    <li><h3>What are the fantasy playoffs?</h3> <div>Fantasy playoffs are a series of matchups between the top teams of your league. Depending on real-life season lengths, this could be 1 or 2 weeks. The matchups are selected automatically based on the teams' league rankings. At the end of the playoffs, the top 3 teams of the league are determined and get awarded the first 3 places that season.</div></li>
                    <li><h3>How is the final ranking determined?</h3> <div>The matchups of the playoffs are determined automatically based on the teams' league rankings. During the playoffs these teams play against each other and at the end of the playoffs, the top 3 teams of the league are determined based on their matchups' results. The top 3 teams are awarded the first 3 places that season.</div></li>
                </ol>
                </li>

                <li>
                    <h2>TROPHIES</h2>
                    <ol>
                        <li><h3>What are fantasy trophies?</h3> <div>Trophies are achievement awards you receive for a certain high score. For individual players it is the highest scoring player in the particular position. There is also an award if your defense makes a turnover as well as the highest fantasy score difference in your league each week. The 3 finalists of the league's playoff also receive each for 3rd, 2nd and 1st place.</div></li>
                        <li><h3>How can I view my trophies?</h3> <div>You can review your trophies by clicking on the Trophy Room link on the upper header of the website.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>LEADERBOARDS</h2>
                    <ol>
                        <li><h3>What are the leaderboards?</h3> <div>Leaderboards are the rankings of each fantasy team based on their overall fantasy points. You can filter the fantasy points based on their leagues' team counts.</div></li>
                        <li><h3>How can I view the leaderboards?</h3> <div>You can review the lists by clicking the Leaderboards link in the upper header.</div></li>
                        <li><h3>How can I search in the leaderboards?</h3> <div>You can search in the lists by entering a team or league name in the search field above the list.</div></li>
                        <li><h3>How can I filter in the leaderboards?</h3> <div>You can filter in the lists by clicking the dropdown menu above the list and selecting the league size.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>BLOG</h2>
                    <ol>
                        <li><h3>How can I review the blog?</h3> <div>You can review our blog by clicking the Blog link in the upper header of the website.</div></li>
                    </ol>
                </li>
            </ol>
        </div>
    )
};

export default FAQ;