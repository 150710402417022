import React from 'react';
import {getIn} from "formik";

export default function({ className, disabled, field, form }) {
    className = className ? className : 'form-control-min';
    const error = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name) || form.submitCount > 0;

    if (touched && error && (typeof error === 'string')) {
        className += ' has-error';
    }

    return <input disabled={disabled} type="text" className={className} {...field} />
};
