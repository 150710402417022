import React, {Component} from 'react';
import {getBlogPosts} from "services/BlogService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {Trans, withTranslation} from "react-i18next";
import FeaturedBlogPostListItem from "modules/Blog/FeaturedBlogPostListItem";
import {Link} from "react-router-dom";

const NB_PRIMARY_ITEMS = 4;
const NB_SECONDARY_ITEMS = 8;
const NB_TOTAL_ITEMS = NB_PRIMARY_ITEMS + NB_SECONDARY_ITEMS;

class FeaturedBlogPosts extends Component {
    state = {
        isLoading: true,
        blogPosts: undefined,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        getBlogPosts(NB_PRIMARY_ITEMS + NB_SECONDARY_ITEMS + 1)
            .then((blogPosts) => this.setState({blogPosts, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {blogPosts, isLoading} = this.state;
        if (blogPosts===undefined) {
            return <main><Loader /></main>;
        }

        //if has more items - leave space for "Show more" link
        const showMoreLink = blogPosts.length > NB_TOTAL_ITEMS;
        const nbShowItems = !showMoreLink ? blogPosts.length : NB_TOTAL_ITEMS - 1;

        const primaryBlogPosts = blogPosts.slice(0, NB_PRIMARY_ITEMS);
        const secondaryBlogPosts = blogPosts.slice(NB_PRIMARY_ITEMS, nbShowItems);

        return (
            <LoadingOverlay active={isLoading}>
                <div className="featured-blog-section">
                    <h2 className="featured-blog-title"><Trans>News</Trans></h2>

                    <div className="featured-blog-posts">
                        {primaryBlogPosts.map((blogPost) => <FeaturedBlogPostListItem blogPost={blogPost} type="primary" />)}

                        {secondaryBlogPosts.length>0 &&
                            secondaryBlogPosts.map((blogPost) => <FeaturedBlogPostListItem blogPost={blogPost} type="secondary" />)}

                        {showMoreLink && <Link to={`blog`} className={`featured-blog-post featured-blog-post-more`}>
                            <div className="text-center">
                                <button className="btn btn-primary btn-important"><Trans>Archived news</Trans></button>
                            </div>
                        </Link>}
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}

export default withTranslation()(FeaturedBlogPosts);
