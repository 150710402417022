import axios from 'axios';
import {getUser, logout} from "services/AuthService";
import i18next from "i18next";

const prepareAPI = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_ROOT,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // By default set the token for any request
    instance.interceptors.request.use(function (config) {
        if (i18next.language !== undefined) {
            config.headers['X-Locale'] = i18next.language;
        }

        const user = getUser();
        if (user && user.token) {
            config.headers.Authorization = 'Bearer ' + user.token;
        }
        return config;
    });

    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401 && !error.config.url.startsWith(error.config.baseURL+'auth')) {
                logout();
                window.location = '/expired';
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

export default prepareAPI();
