import React, {Component} from 'react';
import {addErrorAsNotification} from "services/ValidationService";
import {createLeague, deleteLeague, getLeagues, getLeaguesCsvUrl} from "services/LeagueService";
import LeaguesTable from "modules/Owner/Leagues/LeaguesTable";
import Loader from "modules/Common/Loader";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import DeleteLeagueConfirmationModal from "modules/Owner/Leagues/DeleteLeagueConfirmationModal";
import {addSuccessNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";
import LeagueModal from "modules/Owner/Leagues/LeagueModal";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class LeaguesPage extends Component {
    state = {
        isLoading: true,
        leagues: undefined,
        selectedLeague: null,
        showLeagueModal: false,
        showDeleteLeagueConfirmationModal: false,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        getLeagues()
            .then((leagues) => this.setState({leagues, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    showLeagueModal = () => {
        this.setState({showLeagueModal: true});
    };

    hideLeagueModal = () => {
        this.setState({showLeagueModal: false});
    };
    
    showDeleteLeagueConfirmationModal = (selectedLeague) => {
        this.setState({selectedLeague, showDeleteLeagueConfirmationModal: true});
    };

    hideDeleteLeagueConfirmationModal = () => {
        this.setState({showDeleteLeagueConfirmationModal: false});
    };

    onDelete = (league) => {
        this.showDeleteLeagueConfirmationModal(league);
    };

    onExecuteDeleteLeague = (league) => {
        this.setState({isLoading: true});
        deleteLeague(league.id)
            .then(() => {
                addSuccessNotification('Succcess', `League "${league.name}" was successfully deleted`);
                this.refreshData();
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onExecuteCreateLeague = (leagueData) => {
       this.setState({isLoading: true});
       createLeague(leagueData)
           .then(({ id }) => {
               addSuccessNotification('Success', `League "${leagueData.name}" was successfully created`);
               this.props.history.push(`/owner/leagues/${id}`);
           })
           .catch((response) => {
               addErrorAsNotification(response, this.props.t);
           })
           .finally(() => {
               this.setState({isLoading: false});
           })
       ;
    }

    render() {
        const {leagues, selectedLeague, showLeagueModal, showDeleteLeagueConfirmationModal, isLoading} = this.state;

        if (isLoading && leagues===undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <div className="mb-default clearfix">
                    <button onClick={() => this.showLeagueModal()} className="btn btn-default float-right">Create League</button>
                    <h1>Leagues</h1>
                </div>


                <LoadingOverlay active={isLoading}>
                    <LeaguesTable
                        leagues={leagues}
                        onDelete={this.onDelete}
                    />

                    <div className="mb-default">
                        <a className="btn btn-success"  target="_blank" href={getLeaguesCsvUrl()}>Export To Excel</a>
                    </div>
                </LoadingOverlay>

                <LeagueModal
                    isOpen={showLeagueModal}
                    handleCloseModal={this.hideLeagueModal}
                    onSubmit={this.onExecuteCreateLeague}
                />

                <DeleteLeagueConfirmationModal
                    isOpen={showDeleteLeagueConfirmationModal}
                    league={selectedLeague}
                    handleCloseModal={this.hideDeleteLeagueConfirmationModal}
                    onConfirm={() => this.onExecuteDeleteLeague(selectedLeague)}
                />
            </main>
        );
    }
}

export default compose(
    withRouter,
    withTranslation(),
)(LeaguesPage);