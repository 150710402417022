import React, {Component} from 'react';
import PlayersTable from "modules/Owner/Players/PlayersTable";
import SeasonSwitcher from "modules/Owner/Common/SeasonSwitcher";

export default class PlayersPage extends Component {
    render() {
        return (
            <main>
                <div className="season-switcher">
                    <SeasonSwitcher />
                </div>
                <h1>Players stats</h1>

                <PlayersTable />
            </main>
        );
    }
}
