import React, {Component} from 'react';
import {getCurrentLeagueId, setCurrentLeagueId} from "services/UserStateService";
import LeagueSwitcher from "modules/Dashboard/LeagueSwitcher";
import Loader from "modules/Common/Loader";
import {Link, Redirect, useLocation, withRouter} from "react-router-dom";
import {
    getLeague,
    getLeagueDashboardRoute,
    getStandings, getUserMembershipForLeague
} from "services/LeagueService";
import DraftSection from "modules/LeagueAdmin/Dashboard/DraftSection";
import {getCurrentUser} from "services/UserService";
import {addErrorAsNotification} from "services/ValidationService";
import MembersWidget from "modules/LeagueAdmin/Dashboard/MembersWidget";
import DashboardWidgets from "modules/LeagueUser/Dashboard/DashboardWidgets";
import StandingsTable from "modules/LeagueUser/StandingsTable";
import {Trans, withTranslation} from "react-i18next";
import LeagueRole from "modules/Common/LeagueRole";

class DashboardPage extends Component {
    state = {
        user: null,
        season: null,
        league: null,
        standings: null,
        isLoading: true,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        const leagueId = getCurrentLeagueId();
        if (!leagueId) {
            this.setState({isLoading: false});
            return;
        }

        this.setState({isLoading: true});

        const requests = Promise.all([
            getCurrentUser(),
            getLeague(leagueId),
            getStandings(leagueId)
                .catch((error) => {
                    if (error.response.status === 404) {
                        return [];
                    }

                    throw error;
                })
        ]);

        //fetch all at once
        requests
            .then(([user, league, standings]) => this.setState({user, league, standings}))
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}));
    }

    onChangeLeague = ({id: leagueId}, leagueMembership) => {
        setCurrentLeagueId(leagueId);

        const redirectRoute = getLeagueDashboardRoute(leagueMembership);

        if (this.props.location.pathname === redirectRoute) {
            this.refreshData();
        }

        this.props.history.push(
            getLeagueDashboardRoute(leagueMembership)
        );
    };

    getCurrentMembership = () => {
        const league = this.state.league;
        const user = this.state.user;

        return getUserMembershipForLeague(user, league);
    };

    getCurrentTeam = () => {
        const league = this.state.league;
        const user = this.state.user;
        if (!league || !user) {
            return null;
        }

        for (const team of user.teams) {
            if (team.league_id === league.id) {
                return team;
            }
        }

        return null;
    };

    getTimezone = () => {
        const team = this.getCurrentTeam();
        if (team) {
            return team.timezone;
        }

        if (this.isSuperAdmin()) {
            return 'Europe/Budapest'; //@todo
        }
    };

    isSuperAdmin = () => {
        return this.state.user.roles.includes("SUPER_ADMIN");
    }

    render() {
        const {league, user, standings, isLoading} = this.state;

        if (isLoading) {
            return <main><Loader /></main>;
        }

        if (!league || !user) {
            return <Redirect to="/secure" />;
        }

        const timezone = this.getTimezone();
        const membership = this.getCurrentMembership();
        const isSuperAdmin = this.isSuperAdmin();

        return (
            <main key={this.props.location.key}>
                {isSuperAdmin ?
                    <div className="clearfix">
                        <div className="mb-default">
                            <Link to={`/owner/leagues/${league.id}`} className="btn btn-sm btn-default">❮ &nbsp; League
                                Details</Link>
                        </div>

                        <div className="mb-default float-right">
                            <Link to="/settings" className="btn btn-default">League Settings</Link>
                        </div>
                    </div>
                    :
                    <div className="top-side-actions">
                        <LeagueSwitcher league={league} onChangeLeague={this.onChangeLeague}/>
                    </div>
                }

                {membership && <>
                    <h1>{league.name} - <LeagueRole role={membership.role} /></h1>

                    <DraftSection
                        league={league}
                        timezone={timezone}
                        onRefreshData={() => this.refreshData()}
                    />

                    {
                        membership.role === 'LEAGUE_ADMIN'
                            ? <MembersWidget league={league}/>
                            : <DashboardWidgets league={league}/>
                    }

                    {standings.length>0 && <div className="mb-default">
                        <h3 className="mb-small"><Trans>Standings</Trans></h3>
                        <StandingsTable standings={standings} />
                    </div>}
                </>}
            </main>
        );
    }
}

export default withTranslation()(withRouter(DashboardPage));
