import React from 'react';
import {NavLink} from "react-router-dom";
import {Trans} from "react-i18next";

function NavBar() {
    return (
        <nav className="main-nav-bar">
            <ul className="nav-links">
                <li><NavLink to="/"><Trans>Home</Trans></NavLink></li>
                <li><NavLink to="/leagues"><Trans>Leagues</Trans></NavLink></li>
                <li><NavLink to="/leaderboard"><Trans>Leaderboards</Trans></NavLink></li>
                {/*<li><NavLink to="/players">Players</NavLink></li>*/}
                <li><NavLink to="/blog"><Trans>Blog</Trans></NavLink></li>
                <li><NavLink to="/faq"><Trans>FAQ</Trans></NavLink></li>
            </ul>
        </nav>
    );
}

export default NavBar;
