import React from 'react';

const FAQ = () => {
    return (
        <div className="hierarchy faq">
            <ol>
                <li>
                    <h2>FANTASY FUTBALL</h2>
                    <ol>
                        <li><h3>Mi az a fantasy futball?</h3> <div>A fantasy futball egy online sport játék, ami egy sport liga valós statisztikáin alapul. A játékhoz a felhasználók ligákat hoznak létre az ismerőseikkel, ezek mini bajnokságok, melyeken belül a felhasználók saját csapatokat hoznak létre. Ezek a csapatok minden valós játékhéten megmérkőznek egymással és a több fantasy pontot szerző csapatok nyernek. A fantasy pontokat a sportolók valós statisztikái (touchdown-ok száma, hozott yardok, stb.) alapján számolja a rendszer.</div></li>
                        <li><h3>Mi a fantasy szezon menetrendje?</h3> <div>A HFF-ben a fantasy szezon a draft ablak meghatározásával indul, ami egy időintervallum, amin belül a ligákban meg kell történnie a draftnak. A szezon ezután hetekre bomlik a valós sport liga játékheteihez igazodva. Amint a valós szezon elkezdődik, a fantasy csapatok minden játékhéten megmérkőznek egymással. A valós meccseket követően feltöltjük a statisztikákat és a fantasy eredmények elérhetővé válnak. A valós alapszakasz utolsó hete(i) során kerül megrendezésre a rájátszás, amiben a legjobb fantasy csapatok mérkőznek meg egymás ellen és eldől a végső sorrend. Ekkor a fantasy szezon véget ér.</div></li>
                        <li><h3>Mik a fantasy pontok?</h3> <div>A fantasy pontokat a kiválasztott játékosok kapják a valós statisztikáik alapján. Ezen pontok összessége dönti el melyik fantasy csapat nyeri meg az adott heti meccset.</div></li>
                        <li><h3>Hogy számítják a fantasy pontokat?</h3> <div>A különböző fantasy posztokon a következőképpen számoljuk a pontokat: QB, RB, WR, TE: 0,1 pont yardonként, 6 pont touchdownonként, -6 pont minden interceptionért, -3 pont minden fumbleért, 2 pont a sikeres két pontos kísérletenként. K: 3 pont mezőnygólonként, 1 pont extra pontonként, -3 pont kihagyott mezőnygólonként, -1 pont kihagyott extra pontonként. DEF: 6 pont minden interceptionért, 3 pont minden kierőszakolt fumbleért, 3 pont sackenként, 6 pont ha az engedett pontok 0-4 pont között vannak, 3 pont ha az engedett pontok 5-10 pont között vannak. HC: 6 pont győzelmenként, -6 pont vereségenként, 0,25 pont szerzett pontonként, győzelem esetén; 3 pont ha a pontkülönbség 0-8 között van, 4 pont ha a pontkülönbség 9-16 között van, 5 pont ha a pontkülönbség 17-24 között van, 6 pont ha a pontkülönbség 24-nél magasabb; vereség esetén; -3 pont ha a pontkülönbség 0-8 között van, -4 pont ha a pontkülönbség 9-16 között van, -5 pont ha a pontkülönbség 17-24 között van, -6 pont ha a pontkülönbség 24-nél magasabb.</div></li>
                        <li><h3>Honnan származnak a statisztikák?</h3> <div>A Hungarian Football League (HFL) hivatalos statisztikáit használjuk, amit a Magyar Amerikai Futball Szövetségtől (MAFSZ) kapunk.</div></li>
                        <li><h3>Mikor kerülnek feltöltésre a statisztikák?</h3> <div>A statisztikák néhány nappal a heti meccsek után kerülnek feltöltésre, valamikor szerda-péntek között, attól függően mikor kapjuk meg őket.</div></li>
                        <li><h3>Pénzbe kerül nekem a játék?</h3> <div>Nem, a Hungarian Fantasy Football mindenki számára teljesen ingyenes.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>REGISZTRÁCIÓ, PROFIL</h2>
                    <ol>
                        <li><h3>Hogyan tudok regisztrálni?</h3> <div>Ha még nincs felhasználói fiókod, regisztrálni a jobb felső sarokban lévő "Regisztráció" menüpontra kattintva tudsz. Ezután a regisztráció lapon töltsd ki az adatokat: Amennyiben szeretnél saját ligát létrehozni vagy egy meglévőhöz csatlakozni, de még nem regisztráltál, úgy a "Liga létrehozása" vagy a "Csatlakozás ligához" gombokra kattintva is elérheted a Regisztráció oldalt.</div></li>
                        <li><h3>Hogy tudok bejelentkezni és kijelentkezni?</h3> <div>A bejelentkezéshez kattints a "Bejelentkezés" gombra a jobb felső sarokban. A kijelentkezéshez kattints a "Kijelentkezés" gombra a jobb felső sarokban.</div></li>
                        <li><h3>Hogyan tudom megváltoztatni a jelszavamat?</h3> <div>A jelszó megváltoztatásához menj a profil oldaladra. Ezt elérheted a(z) <img className="faq-icon" src="/img/icons/faq/profile-bg-ico.png" /> ikonra való kattintással a felső menüsorban. A profil oldalon válaszd a "Jelszó megváltoztatása" gombot. Add meg a régi jelszavad és írd be kétszer az újat, majd kattints a "Jelszó megváltoztatása" gombra, hogy elfogadd a változtatásokat.</div></li>
                        <li><h3>Hogyan tudom módosítani a profilom beállításait?</h3> <div>A profilod beállításait a Profil oldalon tudod megváltoztatni amit a(z) <img className="faq-icon" src="/img/icons/faq/profile-bg-ico.png" /> ikonra kattintva tudsz elérni a felső menüsorban. Kattints a "Szerkesztés" gombra, hogy módosítani tudd a jelenlegi adataidat.</div></li>
                        <li><h3>Mit tehetek ha elfelejtettem a jelszavamat?</h3> <div>A Bejelentkezés oldalon, kattints az "Elfelejtett jelszó" linkre a bejelentkezési panel alatt. Írd be az e-mail címedet és kattints a "Küldés" gombra. Egy értesítő üzenetet fogsz kapni az e-mail címedre egy linkkel a jelszóváltoztató oldalra. Ott add meg az új jelszavad kétszer egymás után és kattints a "Jelszó megváltoztatása" gombra. A jelszavad megváltoztatásra kerül.</div></li>
                        <li><h3>Hogyan tudom megtekinteni az értesítéseimet?</h3> <div>Az értesítéseidet a(z) <img className="faq-icon" src="/img/icons/faq/notifications-bg-ico.png" /> ikonra kattintva tudod megtekinteni a felső menüsorban.</div></li>
                        <li><h3>Hogyan tudok feliratkozni és leiratkozni a hírlevélről?</h3> <div>A Profil oldalon tudsz fel- vagy leiratkozni a hírlevelünkről, amit a(z) <img className="faq-icon" src="/img/icons/faq/profile-bg-ico.png" /> ikonra kattintva tudsz elérni a felső menüsorban. A profil oldalon kattints a "Szerkesztés" gombra és jelölt be/ki a "Szeretnék hírlevelet kapni" négyzetet.</div></li>
                        <li><h3>Hogyan tudom törölni a profilomat?</h3> <div>A profilodat a Beállítások oldalon tudod törölni, amit a(z) <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> ikonra kattintva tudsz elérni a felső menüsorban. A Beállítások oldalon kattints a "Profil törlése" gombra. A profilod törlésével nem fogsz tudni részt venni a fantasy futball bajnokságban. Ha egy folyamatban lévő szezon során törlöd a profilod, akkor annak csapatai a bajnokság végéig versenyben maradnak "törölt felhasználóként".</div></li>
                        <li><h3>Nem kapok megerősítő e-mailt a honlapról. Mitévő legyek?</h3> <div>Ha a regisztráció során rövid időn belül nem kapsz tőlünk visszaigazoló e-mailt, kérjük nézd meg a "Spam" könyvtárat, mivel gyakran az e-mail szolgáltató automatikusan oda helyezi a tőlünk beérkezett leveleket. A Gmail-en belül emellett a "Promóciók" és "Összes levél" mappákat is érdemes megnézni.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>LIGÁK</h2>
                    <ol>
                        <li><h3>Mi az a liga?</h3> <div>A liga egy felhasználói csoport által létrehozott mini bajnokság a platformon. A ligák egymástól függetlenek és a tagjaik fantasy csapatai egymás ellen játszanak a ligában a szezon során.</div></li>
                        <li><h3>Ki az a liga adminisztrátor?</h3> <div>A liga adminisztrátor tulajdonképpen a liga tulajdonosa, ő tartja kézben a tagok listáját, a liga beállításait és a draftot. Az adminnal joga van felhasználókat meghívni vagy törölni, draftot létrehozni és módosítani a liga beállításait. Az admin szintén rendelkezik saját csapattal a ligában és részt vesz vele a bajnokságban, ugyanúgy mint a többi tag.</div></li>
                        <li><h3>Ki a felhasználó?</h3> <div>A felhasználó egy vagy több liga tagja, akinek nincs adminisztratív jogköre. A tag a fantasy csapatával játszik a ligá(k)ban.</div></li>
                        <li><h3>Hogyan tudok ligát létrehozni?</h3> <div>Ligát a Főoldalon található "Liga létrehozása" gombra, vagy ha már regisztráltál, a minden oldalon megtalálható legördülő menüsor mellett található plusz gombra kattintva tudsz létrehozni. Töltsd ki a részleteket és kattints a Létrehozás gombra. Ezután a ligához tartozó saját csapatodat is létre kell hoznod.</div></li>
                        <li><h3>Több ligát is létre tudok hozni egyszerre?</h3> <div>Igen, egyszerre több ligát is létre tudsz hozni.</div></li>
                        <li><h3>Tudok ligát létrehozni regisztráció nélkül?</h3> <div>Nem, liga létrehozásához szükséges profilt létrehoznod.</div></li>
                        <li><h3>Hogyan tudok meghívni tagokat a ligámba?</h3> <div>Új tagokat a "Felhasználó meghívása" gombra kattintva tudsz a szabad helyeken a Ligák oldalon. Írd be a meghívni kívánt személy e-mail címét és kattintva a "Meghívás" gombra. Amint a felhasználó elfogadja a meghívást, megjelenik a liga tagjai között.</div></li>
                        <li><h3>Hogyan tudom visszavonni a tagoknak kiküldött meghívót?</h3> <div>Vissza tudod vonni a kiküldött meghívóidat a "Visszavonás" gombra kattintva a meghívott tag neve mellett a Ligák oldalon.</div></li>
                        <li><h3>Hogyan tudom módosítani a ligám beállításait?</h3> <div>A ligád beállításait a(z) <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> ikonra kattintva tudod módosítani a felső menüsorban a Beállítások oldalra navigálva.</div></li>
                        <li><h3>Hogyan tudom törölni a ligát a rendszerből?</h3> <div>A ligádat a(z) <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> ikonra kattintva tudod törölni a felső menüsorban, a Beállítások oldalra navigálva. Itt kattints a "Liga törlése" gombra.</div></li>
                        <li><h3>Hogyan tudok eltávolítani egy tagot a ligából?</h3> <div>Liga tagot a neve melletti "Törlés" gombra kattintva tudsz eltávolítani a ligádból a Ligák oldalon.</div></li>
                        <li><h3>Hogyan tudom átadni a liga adminisztrátori jogköreimet?</h3> <div>Csak a profilod törlése vagy a ligádból való kilépés esetén tudod átadni a liga adminisztrátori jogköreidet. A(z) <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> ikonra kattintva tudod ezt megtenni a Beállítások oldalon. Ebben az esetben minden liga esetén ahol admin vagy egy felugró ablak fog feljönni ahol ki kell választanod a felhasználót akinek átadod az admin szerepkört. Válaszd ki a felhasználót és kattints az OK gombra.</div></li>
                        <li><h3>Hogyan tudok ligához csatlakozni?</h3> <div>Ligához a "Csatlakozás ligához" gombra kattintva tudsz a Főoldalon. Innen a ligák listáját tartalmazó oldalon tudod kiválasztani a ligát amihez szeretnél csatlakozni a "Felvétel kérése a ligába" gombra kattintva a liga neve mellett. A kérelmet ezután elküldjük a liga adminisztrátornak aki ha elfogadja, akkor a liga tagjává válsz.</div></li>
                        <li><h3>Több ligához is tudok egyszerre csatlakozni?</h3> <div>Igen, egyszerre több ligához is tudsz csatlakozni.</div></li>
                        <li><h3>Tudok ligához csatlakozni regisztráció nélkül?</h3> <div>Nem, ligához való csatlakozáshoz szükséges profilt létrehoznod.</div></li>
                        <li><h3>Hogyan tudom visszavonni a ligához küldött csatlakozási kérelmemet?</h3> <div>Ha vissza szeretnéd vonni a csatlakozási kérelmedet egy ligához, kattints a "Csatlakozás ligához" gombra a főoldalon és keresd ki a listából a ligát ahová a kérelmedet küldted. Ezután kattints a piros "Csatlakozási kérelem visszavonása" gombra és a kérelmed törlésre kerül.</div></li>
                        <li><h3>Hogyan tudok kilépni egy ligából?</h3> <div>A ligádat a Beállítások oldalon tudod elhagyni, amit a(z) <img className="faq-icon" src="/img/icons/faq/settings-bg-ico.png" /> ikonra kattintva tudsz elérni a felső menüsorban. A Beállítások oldalon a "Liga elhagyása" gombra kattintva tudsz kilépni a ligádból.</div></li>
                        <li><h3>Hogyan tudok nézetet váltani a ligáim között?</h3> <div>A ligáid között az oldalak tetején található legördülő menüsorban található ligák kiválasztásával tudsz váltani.</div></li>
                        <li><h3>Hogyan tudom megtekinte a ligám tabelláját?</h3> <div>A ligád állását a Ligák oldalon tudod megtekinteni, amit a felső menüsorban található "Ligák" linkre kattintva tudsz elérni.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>DRAFT</h2>
                    <ol>
                        <li><h3>Mi az a draft?</h3> <div>A draft (vagy játékosbörze) az az esemény az oldalon, melynek során a liga tagjai kiválasztják a játékosaikat a csapataik számára. A draftot a liga adminisztrátor hozza létre, aki megad egy időkeretet a draftnak. Ezalatt az idő alatt a tagok véletlen sorrendben játékosokat választanak. A játékosbörze körökre oszlik, egy csapat  minden körben csak egyszer választhat és minden tagnak összesen 14 választási lehetősége van (egy csapatban lévő maximális játékosok száma). A tagoknak egy előre meghatározott időkeretük  van az adott választásra, amint egy csapat választ, a rendszer automatikusan átadja a választási lehetőséget a következő felhasználónak.</div></li>
                        <li><h3>Hogyan tudok draft-ot létrehozni?</h3> <div>Csak liga adminisztrátorként tudsz draftot létrehozni. A Ligák oldalon kattints a "Draft hozzáadása" gombra. Válaszd ki a kezdő és befejező dátumot és kattints a "Folytatás" gombra. Az időkeret nem lehet rövidebb 3 óránál és a végdátum nem lehet később a szezon kezdeténél. A megerősítés után a draft részletei elérhetőek lesznek a többi liga tag számára is.</div></li>
                        <li><h3>Hogyan tudom módosítani a draft beállításait?</h3> <div>A draft előtt bármikor megváltoztathatod a beállításokat a "Dátumok megváltoztatása" gombra kattintva a Ligák oldalon.</div></li>
                        <li><h3>Hogy kerül kiszámításra a draft időintervallum?</h3> <div>A draft időkeretét a liga adminisztrátor adja meg, aki kiválaszt egy kezdő és befejező dátumot. Az első lehetséges dátumot a HFF adja meg, az utolsó választható dátum pedig a szezon kezdete előtti nap.</div></li>
                        <li><h3>Mennyi időm van draftolni?</h3> <div>A draftnak van egy kezdő és befejező dátuma, játékosokat ezek között az időpontok között lehet. A rendszer automatikusan kiszámítja a sorrendet és minden tag 14 választási lehetőséget kap. Minden választási lehetőségre ugyanannyi időkeret áll rendelkezésre, ami a draft hosszától függ.</div></li>
                        <li><h3>Hogyan tudok csatlakozni a drafthoz?</h3> <div>A drafthoz a "Belépés a draftba" gombra kattintva tudsz csatlakozni a Ligák oldalon.</div></li>
                        <li><h3>Hogyan tudok játékost választani a drafton?</h3> <div>Játékost a(z) <img className="faq-icon" style={{width: 62, top: 12, height: 'auto'}} src="/img/icons/faq/pick-player-hu-ico.png" /> ikonra kattintva tudsz kiválasztani a játékos neve mellett. A megerősítés után a játékos megjelenik a csapatodban és a többi liga tag nem fogja tudni kiválasztani az adott játékost.</div></li>
                        <li><h3>Mi történik ha elmulasztok egy választást a drafton?</h3> <div>Ha elmulasztod a választásod a drafton, a rendszer automatikusan választ neked egy játékost a játékos helyezése alapján.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>CSAPATOM</h2>
                    <ol>
                        <li><h3>Mi a saját csapatom?</h3> <div>A fantasy csapatod az a valós játékosok csoportja, akiket ledraftolsz, vagy a játékosok listájából kiválasztasz. Ez a csapat mérkőzik meg a liga társaid csapatai ellen hétről hétre. A csapatod két részre oszlik: kezdőcsapatra és kispadra. A csapatodban a következő poszton játszó játékosok foglalnak helyet: QB - irányító, RB - futó, WR - elkapó, TE - elkapó-blokkoló, FLEX: ez lehet RB, WR vagy TE, DEF - védelmi egység, K - rúgó és HC - vezetőedző.</div></li>
                        <li><h3>Mi az a kezdő keret?</h3> <div>A kezdő keret a csapatod azon része, amelyik részt vesz a heti meccseken a ligád tagjai ellen. A kezdőcsapatban a QB, RB, WR, TE, FLEX (ez lehet RB/WR/TE), K, DEF és HC poszton szereplő játékosok lehetnek. A meccseket eldöntő fantasy pontok a csak a kezdő keretben szereplő játékosok pontjaiból adódnak össze.</div></li>
                        <li><h3>Mi az a kispad?</h3> <div>A kispad a csapatod azon része, amelyik nem vesz részt a heti meccseken a ligád tagjai ellen. Ezek a játékosok a cseréid, ha szükséges őket be tudod állítani a kezdő keretbe.</div></li>
                        <li><h3>Hány játékos lehet a csapatomban?</h3> <div>A kezdőcsapatban 8 játékosod lehet (QB, RB, WR, TE, FLEX, K, DEF, HC) és 6 játékosod lehet a kispadon, összesen tehát 14.</div></li>
                        <li><h3>Hogyan tudom kezelni a csapatomat?</h3> <div>A csapatodat a honlapunk főmenüjében a Csapatom linkre kattintva tudod elérni. A játékosok neve mellett található ikonokra kattintva tudsz különböző műveleteket (hozzáadás, törlés, mozgatás, csere) végrehajtani a játékosokkal.</div></li>
                        <li><h3>Hogyan tudom megtekinteni egy játékos részleteit?</h3> <div>Egy játékos részleteit a neve mellett szereplő <img className="faq-icon" src="/img/icons/faq/player-info-bg-ico.png" /> ikonra kattintva tudod megtekinteni.</div></li>
                        <li><h3>Mi az a játékoselemzés?</h3> <div>A játékos elemzés az adott sportolóval kapcsolatos friss hírek, lehetséges heti teljesítmény elemzése.</div></li>
                        <li><h3>Hogyan tudok eltávolítani egy játékost a csapatomból?</h3> <div>Egy játékost a neve mellett szereplő <img className="faq-icon" src="/img/icons/faq/delete-player-bg-ico.png" /> ikonra kattintva tudsz eltávolítani. Ha törölsz egy játékost, az eltűnik a keretedből és visszatér a választható játékosok listájába.</div></li>
                        <li><h3>Hogyan tudok hozzáadni egy játékost a csapatomhoz?</h3> <div>Egy játékost a neve mellett szereplő <img className="faq-icon" style={{width: 62, top: 12, height: 'auto'}} src="/img/icons/faq/pick-player-hu-ico.png" /> ikonra kattintva tudsz hozzáadni a keretedhez a Játékosok listán. Ha hozzáadsz egy játékost, az megjelenik a keretedben.</div></li>
                        <li><h3>Hogyan tudok mozgatni egy játékost csapaton belül?</h3> <div>Egy játékost a neve mellett szereplő <img className="faq-icon" src="/img/icons/faq/move-player-down-bg-ico.png" /> ikonra kattintva tudsz mozgatni a csapatodon belül. A megerősítéshez a felugró ablakban kell megadnod kinek a helyére akarod átmozgatni az adott játékost.</div></li>
                        <li><h3>Hogyan tudok elcserélni egy játékost?</h3> <div>El tudsz cserélni játékost a(z) <img className="faq-icon" src="/img/icons/faq/trade-players-bg-ico.png" /> ikonra kattintva a játékos neve mellett. Létre tudsz hozni egy csereajánlatot egy csapat a játékos kiválasztásával akit szeretnél megszerezni a te játékosodért cserébe. Kattints a "Megerősítés" gombra az ajánlat elküldéséhez, ami a másik felhasználó  Csapatom oldalán fog megjelenni. Ha elfogadja, a játékosok csapatot cserélnek. Vissza tudod vonni a csereajánlatod a "Visszavonás" gombra kattintva a Csapatom oldalon. Ha csereajánlatot kapsz, az "Elfogadás" gombra kattintva tudod elfogadni, vagy az "Elutasítás" gombra kattintva tudod elutasítani a Csapatom oldalon.</div></li>
                        <li><h3>Mikor tudok változtatni a csapatomon?</h3> <div>A csapatodon bármikor változtatni tudsz, kivéve valós meccsek ideje alatt, ekkor a csapatod zárolva van.</div></li>
                        <li><h3>Hogyan tudok játékosra keresni a Játékosok lapon?</h3> <div>A Játékosok lapon a lista fölötti keresőmezőbe írva tudsz játékosnévre keresni. A lista automatikusan leszűrődik a keresett névre.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>MECCSKÖZPONT</h2>
                    <ol>
                        <li><h3>Mi az a fantasy meccs?</h3> <div>A fantasy meccs két fantasy csapat közötti mérkőzés egy adott ligán belül. Az adott játékhéten több fantasy pontot szerző csapat győz a meccsen.</div></li>
                        <li><h3>Hogyan tudom megtekinteni a heti meccseimet?</h3> <div>A heti meccseidet a honlapunk főmenüjében a Meccsközpont linkre kattintva tudod megtekinteni.</div></li>
                        <li><h3>Hogyan tudok váltani a hetek között a Meccsközpontban?</h3> <div>A meccsek között a Meccsközpont oldalának tetején tudsz váltani.</div></li>
                        <li><h3>Hogyan tudok váltani a meccsek között a Meccsközpontban?</h3> <div>Egy adott héten a ligában több meccset is játszanak a liga létszámától függően. A meccsek között a Meccsközpont oldalának tetején tudsz váltani.</div></li>
                        <li><h3>Mi az a fantasy rájátszás?</h3> <div>A fantasy rájátszás a liga legjobb csapatai közötti meccs sorozat. A valós alapszakasz hosszától függően ez lehet 1 vagy 2 hetes.</div></li>
                        <li><h3>Hogyan alakul ki a végső sorrend?</h3> <div>A rájátszás párosításait a rendszer a helyezések alapján automatikusan választja ki. A rájátszás alatt a csapatok megmérkőznek egymással és a végén a top 3 csapat a mérkőzéseik eredményei alapján kerülnek kiválasztásra. A legjobb 3 három helyezett kapja a top 3 csapatnak járó díjakat.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>TRÓFEÁK</h2>
                    <ol>
                        <li><h3>Mik azok a fantasy trófeák?</h3> <div>A trófeák teljesítmény után járó díjak, melyeket különböző pontok után kapsz. Az egyéni játékoson esetében ez az adott poszton legtöbb fantasy pontot szerzett játékost jelenti. Ezen kívül van még trófea ha a Védelmed megszerzi a labdát, illetve ha a csapatod a legnagyobb pontkülönbséggel nyeri az adott heti találkozóját a meccsek közül a ligádban. A szezon végi három top helyezett is kap harmadik, második illetve első helyezést megillető trófeát.</div></li>
                        <li><h3>Hogyan tudom megtekinteni a trófeáimat?</h3> <div>A trófeáidat a honlapunk főmenüjében található Vitrin linkre kattintva tudod megtekinteni.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>RANGLISTÁK</h2>
                    <ol>
                        <li><h3>Mik azok a ranglisták?</h3> <div>A ranglisták a fantasy csapatok toplistái az összesített fantasy pontjaik alapján. A listákat a ligák csapatlétszáma alapján lehet szűrni.</div></li>
                        <li><h3>Hogyan tudom megtekinteni a ranglistákat?</h3> <div>A honlapunk főmenüjében a Ranglista linkre kattintva tudod megtekinteni a ranglistákat.</div></li>
                        <li><h3>Hogyan tudok keresni a ranglistákban?</h3> <div>A ranglistákban a lista fölötti keresőmezőbe beírva tudsz adott játékos vagy liga névre keresni.</div></li>
                        <li><h3>Hogyan tudok szűrni a ranglistákban?</h3> <div>A ranglistákban a lista fölötti legördülő menüsorra kattintva a liga létszámának kiválasztásával tudsz szűrni.</div></li>
                    </ol>
                </li>

                <li>
                    <h2>BLOG</h2>
                    <ol>
                        <li><h3>Hogyan tudom megtekinteni a blogot?</h3> <div>A honlapunk főmenüjében a Blog linkre kattintva tudod megtekinteni a blogunkat.</div></li>
                    </ol>
                </li>
            </ol>
        </div>
    )
};

export default FAQ;