import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {addErrorAsNotification, normalizeErrors} from "services/ValidationService";
import {Trans, withTranslation} from "react-i18next";

const ConfirmResetPasswordForm = function({handleSubmit}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form center-form" noValidate>
            <h1><Trans>New password</Trans></h1>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Password</Trans></label>
                <Field type="password" name="newPassword" className="form-control" />
                <ErrorMessage name="newPassword" />
            </div>

            <div className="form-group">
                <><label className="form-label" htmlFor="name"><Trans>Confirm password</Trans></label></>
                <Field type="password" name="newPasswordConfirm" className="form-control" />
                <ErrorMessage name="newPasswordConfirm" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Change password</Trans></button>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: () => ({
            'newPassword': '',
            'newPasswordConfirm': '',
        }),
        validationSchema: ({ t }) => yup.object().shape({
            newPassword: yup.string().required(t('Please input new password')),
            newPasswordConfirm: yup.string()
                .required(t('Please confirm new password'))
                .oneOf([yup.ref('newPassword'), null], t('Passwords don\'t match'))
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(ConfirmResetPasswordForm);
