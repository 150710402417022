import React from 'react';
import {formatMembershipStatus, getUserPendingMembershipForLeague} from "services/LeagueService";
import {Trans} from "react-i18next";

const LeagueActions = ({ size = 'sm', league, user, onJoinLeague, onCancelPendingMembership }) => {
    if (!league) {
        return null;
    }

    const btnClass = (size === 'sm') ? 'btn-sm' : 'btn';

    return (
        <>
            {league.membershipStatus &&
            league.membershipStatus === 'REQUESTED'
                ?
                <button
                    onClick={() => {
                        const pendingMembership = getUserPendingMembershipForLeague(user, league);
                        onCancelPendingMembership(pendingMembership)
                    }}
                    className={`ml-small btn btn-danger ${btnClass}`}>
                    <Trans>Withdraw joining request</Trans>
                </button>
                :
                <span style={{fontSize: '1.5rem'}} className="ml-small">{formatMembershipStatus(league.membershipStatus)}</span>
            }

            {!league.membershipStatus && league.nbAvailableSlots > 0 &&
            <button onClick={() => onJoinLeague(league)} className={`btn-primary ${btnClass}`}>
                {league.adminUser ? <Trans>Request to join league</Trans> : <Trans>Join League</Trans>}
            </button>
            }
        </>
    );
};

export default LeagueActions;