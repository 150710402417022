import React, {Suspense}  from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import DefaultLayout from "layouts/DefaultLayout";
import 'react-notifications-component/dist/theme.css'
import './styles/main.scss';
import {HomePage} from "modules/Home";
import {LeaguesPage} from "modules/Leagues";
import OwnerLeaguesPage from "modules/Owner/Leagues/LeaguesPage";
import OwnerLeaguePage from "modules/Owner/Leagues/LeaguePage";
import OwnerUsersPage from "modules/Owner/Users/UsersPage";
import OwnerTeamsPage from "modules/Owner/Teams/TeamsPage";
import OwnerRostersPage from "modules/Owner/Teams/RostersPage";
import OwnerBlogPostsPage from "modules/Owner/Blog/BlogPostsPage";
import OwnerBlogPostPage from "modules/Owner/Blog/BlogPostPage";
import OwnerFilesPage from "modules/Owner/Files/FilesPage";
import OwnerSettingsPage from "modules/Owner/Settings/SettingsPage";
import {LeaderboardPage} from "modules/Leaderboard";
import {PlayersPage} from "modules/Players";
import OwnerPlayersPage from "modules/Owner/Players/PlayersPage";
import BlogPage from "modules/Blog/BlogPage";
import BlogPostPage from "modules/Blog/BlogPostPage";
import {ContactPage, MediaPage, PressPage, PrivacyPage, TermsPage} from "modules/Content";
import RegisterPage from "modules/Auth/Register/RegisterPage";
import LoginPage from "modules/Auth/Login/LoginPage";
import ThankYouPage from "modules/Auth/Register/ThankYouPage";
import LeagueAdminDashboardPage from "modules/LeagueAdmin/Dashboard/DashboardPage";
import LeagueUserDashboardPage from "modules/LeagueUser/Dashboard/DashboardPage";
import {logout} from "services/AuthService";
import NotFoundPage from "modules/Error/NotFoundPage";
import DashboardPage from "modules/Dashboard/DashboardPage";
import CreateLeaguePage from "modules/LeagueAdmin/CreateLeague/CreateLeaguePage";
import InvitePage from "modules/Invite/InvitePage";
import ConfirmAccountPage from "modules/Auth/Register/ConfirmAccountPage";
import AcceptInvitationPage from "modules/LeagueUser/AcceptInvitationPage";
import ReactNotification from 'react-notifications-component';
import SecureLayout from "layouts/SecureLayout";
import ProfilePage from "modules/Profile/ProfilePage";
import LeagueSettingsPage from "modules/LeagueAdmin/LeagueSettings/LeagueSettingsPage";
import EditLeaguePage from "modules/LeagueAdmin/EditLeague/EditLeaguePage";
import ChangePasswordPage from "modules/Auth/ChangePassword/ChangePasswordPage";
import ChangeEmailPage from "modules/Auth/ChangeEmail/ChangeEmailPage";
import EditProfilePage from "modules/Auth/EditProfile/EditProfilePage";
import EditTeamPage from "modules/LeagueAdmin/EditTeam/EditTeamPage";
import RequestResetPasswordPage from "modules/Auth/ResetPassword/RequestResetPassword/RequestResetPasswordPage";
import ConfirmResetPasswordPage from "modules/Auth/ResetPassword/ConfirmResetPassword/ConfirmResetPasswordPage";
import ChangeEmailConfirmPage from "modules/Auth/ChangeEmail/ChangeEmailConfirmPage";
import Expired from "modules/Common/Expired";
import NotificationsPage from "modules/Notification/NotificationsPage";
import CreateTeamPage from "modules/LeagueUser/Team/CreateTeamPage";
import LeaguePage from "modules/Leagues/LeaguePage";

import OwnerLayout from "layouts/OwnerLayout";
import DraftPage from "modules/Draft/DraftPage";
import MyTeamPage from "modules/Team/MyTeamPage";
import GameCenterPage from "modules/GameCenter/GameCenterPage";
import moment from "moment-timezone";
import TestPage from "modules/TestPage";
import SeasonsPage from "modules/Owner/Season/SeasonsPage";
import SeasonPage from "modules/Owner/Season/SeasonPage";
import EditSchedulePage from "modules/Owner/Season/EditSchedulePage";
import ScoreConfigPage from "modules/Owner/ScoreConfig/ScoreConfigPage";
import StatsPage from "modules/Owner/Stats/StatsPage";
import GameStatsPage from "modules/Owner/Stats/GameStatsPage";
import Loader from "modules/Common/Loader";
import TrophiesPage from "modules/Trophies/TrophiesPage";
import AccountDeletedPage from "modules/Auth/AccountDeletedPage";
import SimpleLayout from "layouts/SimpleLayout";
import StandingsPage from "modules/LeagueUser/Standings/StandingsPage";
import TeamPage from "modules/Team/TeamPage";
import CookiePolicyPage from "modules/Content/CookiePolicyPage";
import CopyrightNoticePage from "modules/Content/CopyrightNoticePage";
import FAQPage from "modules/Content/FAQPage";
import RouteListener from "RouteListener";
import TrophyPage from "modules/Trophies/TrophyPage";
import OwnerEditLeagueDescriptionPage from "modules/Owner/Leagues/OwnerEditLeagueDescriptionPage";

moment.tz.setDefault('UTC');

function App() {
    return (
        <Router>
            <RouteListener />
            <Suspense fallback={<Loader />}>
                <ReactNotification />
                <Switch>
                    <SimpleLayout path="/" component={HomePage} exact />
                    <DefaultLayout path="/leagues" component={LeaguesPage} exact />
                    <DefaultLayout path="/leagues/:id/join" component={LeaguesPage} exact />
                    <DefaultLayout path="/leaderboard" component={LeaderboardPage} exact />
                    <DefaultLayout path="/players" component={PlayersPage} exact />
                    <DefaultLayout path="/faq" component={FAQPage} exact />
                    <DefaultLayout path="/blog" component={BlogPage} exact />
                    <DefaultLayout path="/blog/:id" component={BlogPostPage} exact />

                    <DefaultLayout path="/press" component={PressPage} exact />
                    <DefaultLayout path="/contact" component={ContactPage} exact />
                    <DefaultLayout path="/media" component={MediaPage} exact />
                    <DefaultLayout path="/terms" component={TermsPage} exact />
                    <DefaultLayout path="/privacy" component={PrivacyPage} exact />
                    <DefaultLayout path="/cookie-policy" component={CookiePolicyPage} exact />
                    <DefaultLayout path="/copyright-notice" component={CopyrightNoticePage} exact />

                    <DefaultLayout path="/register" component={RegisterPage} exact />
                    <DefaultLayout path="/account/confirm/:confirmationToken" component={ConfirmAccountPage} exact />
                    <DefaultLayout path="/thanks" component={ThankYouPage} exact />


                    <DefaultLayout path="/expired" component={Expired} exact />
                    <DefaultLayout path="/login" component={LoginPage} exact />

                    {/* Forgot password flow */}
                    <DefaultLayout path="/reset-password" component={RequestResetPasswordPage} exact />
                    <DefaultLayout path="/reset-password/:resetPasswordToken" component={ConfirmResetPasswordPage} exact />

                    {/* Change email flow */}
                    <SecureLayout path="/change-email" component={ChangeEmailPage} exact />
                    <SecureLayout path="/change-email/:emailChangeToken" component={ChangeEmailConfirmPage} exact />

                    <DefaultLayout path="/invitation/accept/:id/:confirmationToken" component={AcceptInvitationPage} exact />
                    <DefaultLayout path="/create-league" component={CreateLeaguePage} exact />
                    <SecureLayout path="/secure" component={DashboardPage} exact />
                    <SecureLayout path="/invite/:leagueId" component={InvitePage} exact />
                    <SecureLayout path="/profile" component={ProfilePage} exact />
                    <SecureLayout path="/edit-profile" component={EditProfilePage} exact />
                    <SecureLayout path="/change-password" component={ChangePasswordPage} exact />
                    <SecureLayout path="/settings" component={LeagueSettingsPage} exact />
                    <SecureLayout path="/leagues/:id/edit" component={EditLeaguePage} exact />
                    <SecureLayout path="/teams/:id/edit" component={EditTeamPage} exact />
                    <SecureLayout path="/leagues/:id/create-team" component={CreateTeamPage} exact />
                    <SecureLayout path="/leagues/:id" component={LeaguePage} exact />
                    <SecureLayout path="/leagues/:id/draft" component={DraftPage} exact />
                    <SecureLayout path="/leagues/:leagueId/standings" component={StandingsPage} exact />
                    <SecureLayout path="/team" component={MyTeamPage} exact />
                    <SecureLayout path="/leagues/:leagueId/team/:teamId/roster" component={TeamPage} exact />
                    <SecureLayout path="/game-center" component={GameCenterPage} wide exact />
                    <SecureLayout path="/trophies" component={TrophiesPage} exact />
                    <SecureLayout path="/trophies/:lang/:trophyId" component={TrophyPage} exact />
                    <SecureLayout
                        path="/notifications"
                        component={NotificationsPage}
                        exact
                    />
                    <SecureLayout path="/account-deleted" component={AccountDeletedPage} exact />
                    <SecureLayout path="/test" component={TestPage} exact />


                    <Route path="/logout" component={() => {
                        logout();
                        return (<Redirect to="/"/>);
                    }} exact />

                    <SecureLayout path="/league-admin/dashboard" component={LeagueAdminDashboardPage} exact />
                    <SecureLayout path="/league-user/dashboard" component={LeagueUserDashboardPage} exact />

                    {/*Owner Pages*/}
                    <OwnerLayout path="/owner" component={SeasonsPage} exact />
                    <OwnerLayout path="/owner/seasons" component={SeasonsPage} exact />
                    <OwnerLayout path="/owner/seasons/add" component={SeasonPage} exact />
                    <OwnerLayout path="/owner/seasons/:id" component={SeasonPage} exact />
                    <OwnerLayout path="/owner/season/edit-schedule" component={EditSchedulePage} exact />
                    <OwnerLayout path="/owner/stats" component={StatsPage} exact />
                    <OwnerLayout path="/owner/stats/games/:id" component={GameStatsPage} exact />
                    <OwnerLayout path="/owner/players" component={OwnerPlayersPage} exact />
                    <OwnerLayout path="/owner/score-config" component={ScoreConfigPage} exact />
                    <OwnerLayout path="/owner/leagues" component={OwnerLeaguesPage} exact />
                    <OwnerLayout path="/owner/leagues/:id" component={OwnerLeaguePage} exact />
                    <OwnerLayout path="/owner/leagues/:id/description" component={OwnerEditLeagueDescriptionPage} exact />
                    <OwnerLayout path="/owner/users" component={OwnerUsersPage} exact />
                    <OwnerLayout path="/owner/teams" component={OwnerTeamsPage} exact />
                    <OwnerLayout path="/owner/rosters" component={OwnerRostersPage} exact />
                    <OwnerLayout path="/owner/blog" component={OwnerBlogPostsPage} exact />
                    <OwnerLayout path="/owner/blog/create-post" component={OwnerBlogPostPage} exact />
                    <OwnerLayout path="/owner/blog/:id" component={OwnerBlogPostPage} exact />
                    <OwnerLayout path="/owner/files" component={OwnerFilesPage} exact />
                    <OwnerLayout path="/owner/settings" component={OwnerSettingsPage} exact />

                    <DefaultLayout component={NotFoundPage} />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default App;
