import React, {Component} from 'react';
import {compose} from "recompose";
import {withFormik} from "formik";
import * as yup from "yup";
import {WeeksList} from "modules/Owner/Season/WeeksList";
import moment from "moment";
import {Link} from "react-router-dom";
import {combineDateAndTime} from "services/DateTime";
import ScheduleSection from "modules/Owner/Season/ScheduleSection";
import {prepareScheduleForm} from "modules/Owner/Season/ScheduleFormHelper";

class EditScheduleForm extends Component {
    render() {
        const {season, handleSubmit, values} = this.props;

        // let updateSinceWeek = season.currentWeek; //@todo
        // if (season.isWeekActive) {
        //     updateSinceWeek++;
        // }

        return (
            <div>
                <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form form-season mb-default" noValidate>
                    {/*<WeeksList updateSinceWeek={updateSinceWeek} values={values.weeks} />*/}

                    <ScheduleSection values={values} />

                    <div style={{marginTop: '7rem'}}>
                        <input type="submit" className="btn btn-success mr-small" value="Update Schedule" />
                        <Link to="/owner/stats" className="btn btn-default">Cancel</Link>
                    </div>

                </form>
            </div>
        );
    }
}

export default compose(
    withFormik({
        mapPropsToValues: ({season, teams, timezone}) => {
            return prepareScheduleForm(season, teams, timezone);
        },
        validationSchema: yup.object().shape({
            roundGames: yup.array().required('Please add at least one game').of(
                yup.object().shape({
                    team1: yup.number().required('Please input team1'),
                    team2: yup.number().required('Please input team2').notOneOf([yup.ref('team1')], 'Teams should differ'),
                    date: yup.date()
                        .typeError('This date is invalid, use DD/MM/YYYY format')
                        .transform(function(value, originalValue) {
                            value = moment(originalValue, 'DD/MM/YYYY', true);
                            return value.isValid() ? value.toDate() : new Date('');
                        })
                        .required('Date is required'),
                })
            )
        }),
        handleSubmit: (values, { props, setErrors }) => {
            let data = JSON.parse(JSON.stringify(values)); //deep copy
            delete data.teams;

            //Round games - combine date and time
            for (const game of data.roundGames) {
                game.date = combineDateAndTime(game.date, game.time, props.timezone).utc().format('YYYY-MM-DD HH:mm:ss');
                delete game.time;
            }

            props.handleSubmit(data);
        }
    })
)(EditScheduleForm);
