import React from 'react';
import {Trans} from "react-i18next";
import {formatLeagueRole} from "services/LeagueService";

const LeagueRole = ({ role }) => {
    return (
        <Trans>{formatLeagueRole(role)}</Trans>
    );
};

export default LeagueRole;
