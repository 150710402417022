import React, {useRef} from 'react';
import ReactModal from 'react-modal';
import {Trans} from "react-i18next";
import MessageTemplateForm from "modules/Owner/Users/MessageTemplateForm";
import {getMessageTemplates} from "services/MessageService";
import LoadingOverlay from "modules/Common/LoadingOverlay";

export default class SendEmailModal extends React.Component {
    state = {
        isLoading: true,
        templates: null,
    }
    bagRef = null;

    componentDidMount() {
        this.refreshData();
        this.bagRef = React.createRef();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        return getMessageTemplates()
            .then((templates) => this.setState({templates}))
            .finally(() => this.setState({isLoading: false}))
    }

    submitForm = () => {
        const {submitForm, setFieldValue} = this.bagRef.current;
        setFieldValue('action', 'send', false)
        setTimeout(() => submitForm(), 100);
    }

    render() {
        let {
            handleCloseModal,
            content,
            onSaveTemplate,
            onDeleteTemplate,
            onSendMessages,
            onLoadMessagePreview,
            onCancel,
            isLoading,
            ...rest
        } = this.props;

        const templates = this.state.templates;

        const onRequestCancel = () => {
            if (onCancel === undefined || onCancel() !== false) {
                handleCloseModal();
            }
        };

        return (
            <ReactModal
                onRequestClose={onRequestCancel}
                className="default-modal message-modal autofit"
                overlayClassName="modal-overlay"
                closeTimeoutMS={200}
                {...rest}
            >
                <span onClick={onRequestCancel} className="modal-close">&times;</span>

                <p className="modal-title">Bulk Message</p>
                <div className="modal-content">
                    <LoadingOverlay active={isLoading}>
                        <MessageTemplateForm
                            innerRef={this.bagRef}
                            templates={templates}
                            onLoadMessagePreview={onLoadMessagePreview}
                            onSendMessages={onSendMessages}
                            onDeleteTemplate={onDeleteTemplate}
                            onSaveTemplate={(data) => {
                                return onSaveTemplate(data)
                                    .then(async(response) => {
                                        await this.refreshData();
                                        return Promise.resolve(response);
                                    })
                            }}
                        />
                    </LoadingOverlay>
                </div>

                <div className="modal-actions">
                    <button id="close-button" className="btn btn-default" onClick={onRequestCancel}><Trans>Close</Trans></button>
                    {/*<button className="btn btn-success" onClick={() => {this.submitForm()}}><Trans>Send</Trans></button>*/}
                </div>
            </ReactModal>
        );
    }
}
