import React, {Component} from 'react';
import ReactModal from "react-modal";
import {formatActivityStatus, formatPlayerPosition} from "services/RosterService";
import {withTranslation} from "react-i18next";

class SwitchPlayersModal extends Component {
    state = {
        selectedSwitchPlayer: null,
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({selectedSwitchPlayer: null});
    }

    onSelectSwitchPlayer = (player) => {
        this.setState({selectedSwitchPlayer: player});
    };

    render() {
        const {
            player,
            switchPlayers,
            handleCloseModal,
            onConfirm,
            onCancel,
            t,
            ...rest
        } = this.props;

        const {selectedSwitchPlayer} = this.state;

        if (!player || !switchPlayers || !switchPlayers.length) {
            return null;
        }

        const onRequestCancel = () => {
            if (onCancel === undefined || onCancel() !== false) {
                handleCloseModal();
            }
        };
        const positionTitle = t(player.position);

        return (
            <ReactModal
                onRequestClose={onRequestCancel}
                className="default-modal"
                overlayClassName="modal-overlay"
                closeTimeoutMS={200}
                {...rest}
            >
                <span onClick={onRequestCancel} className="modal-close">&times;</span>

                <p className="modal-title">Switch players</p>
                <div className="modal-content">

                    <p className="mb-default">
                        Select a player you want to switch <strong>{player.name} ({positionTitle})</strong> to.
                    </p>

                    <p className="mb-small"><strong>{formatActivityStatus(switchPlayers[0].activityStatus)}:</strong></p>
                    <table className="table selectable table-condensed table-bordered">
                        <thead>
                        <tr>
                            <th>Position</th>
                            <th>Name</th>
                        </tr>
                        </thead>
                        <tbody>
                            {switchPlayers.map((player) => {
                                const rowClass = (selectedSwitchPlayer && player.id === selectedSwitchPlayer.id ? 'selected' : '');
                                return <tr key={player.id} className={rowClass}
                                    onClick={() => this.onSelectSwitchPlayer(player)}>
                                    <td>{formatPlayerPosition(player)}</td>
                                    <td>{player.name}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="modal-actions">
                    <button className="btn btn-default" onClick={onRequestCancel}>Cancel</button>
                    <button disabled={!selectedSwitchPlayer} className="btn btn-success" onClick={() => {
                        handleCloseModal();
                        onConfirm(player, selectedSwitchPlayer)
                    }}>Confirm Switch
                    </button>
                </div>
            </ReactModal>
        )
    }
}

export default withTranslation()(SwitchPlayersModal);
