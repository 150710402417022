import React, {Component} from 'react';
import {getLeague, getUserTeam} from "services/LeagueService";
import {addErrorAsNotification} from "services/ValidationService";
import {getCurrentLeagueId, setCurrentLeagueId} from "services/UserStateService";
import {Link, withRouter} from "react-router-dom";
import {addWarningNotification} from "services/NotificationService";
import LeagueSwitcher from "modules/Dashboard/LeagueSwitcher";
import {getGames} from "services/FantasyGameService";
import Loader from "modules/Common/Loader";
import {getCurrentSeason} from "services/SeasonService";
import {Trans, withTranslation} from "react-i18next";
import TrophiesNotification from "modules/Trophies/TrophiesNotification";
import {getUser} from "services/AuthService";
import {getTrophies, markTrophiesAsViewed} from "services/TrophyService";
import GameResultsNotification from "modules/GameCenter/GameResultsNotification";
import GamesOverviewSection from "modules/GameCenter/GamesOverviewSection";

class GameCenterPage extends Component {
    state = {
        isLoading: true,
        league: null,
        season: null,
        team: null,
        showTrophies: false,
        notViewedGameResults: [],
        notViewedTrophies: []
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        const leagueId = getCurrentLeagueId();
        if (!leagueId) {
            addWarningNotification(
                'No league selected',
                'Please choose a league first'
            );
            this.props.history.push('/secure');
            return;
        }

        this.setState({isLoading: true});

        //league
        getLeague(leagueId)
            .then((league) => {
                this.setState({league});
                const requests = Promise.all([
                    getCurrentSeason()
                        .catch((error) => {
                            if (error.response.status === 404) {
                                return null;
                            }

                            throw error;
                        }),
                    getGames(leagueId),
                    getUserTeam(leagueId, getUser().id)
                ]);

                //fetch all at once
                requests
                    .then(([season, games, team]) => {
                        this.setState({season, games, team}, () => {
                            if (!team) {
                                return;
                            }
                            const notViewedGameResults = this.checkGameResults(team, games);
                            let notViewedTrophies = [];
                            this.checkTrophies(team)
                                .then((trophies) => {
                                    notViewedTrophies = trophies;
                                })
                                .finally(() => {
                                    this.setState({notViewedGameResults, notViewedTrophies});
                                })
                            ;
                        });
                    })
                    .catch(({response}) => {
                        if (response.status !== 422) {
                            addErrorAsNotification(response, this.props.t)
                        }
                        this.setState({season: null, games: null});

                        return response;
                    })
                    .finally(() => this.setState({isLoading: false}))
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    isSuperAdmin = () => {
        return getUser().roles.includes("SUPER_ADMIN");
    }


    checkGameResults = (team, games) => {
        const teamId = team.id;
        let myGameResults = [];

        for (const game of games) {
            if (game.isFrozen) {
                continue;
            }

            const gameResult = game.gameResult;

            //Result not know yet - skip
            if (game.gameResult === null) {
                continue;
            }

            //1 - win, 0 - loss, null - draw
            let myResult = null;
            let oppositeTeam = null;

            if (game.roster1.team.id === teamId && !game.team1SawResult) {
                oppositeTeam = game.roster2.team;
                if (gameResult === 1) {
                    myResult = 1;
                } else {
                    myResult = (gameResult === 0) ? null : 0;
                }
            } else if (game.roster2.team.id === teamId && !game.team2SawResult) {
                oppositeTeam = game.roster1.team;
                if (gameResult === 2) {
                    myResult = 1;
                } else {
                    myResult = (gameResult === 0) ? null : 0;
                }
            } else {
                continue;
            }

            myGameResults.push({
                game,
                oppositeTeam,
                result: myResult,
            });
        }

        return myGameResults;
    };

    checkTrophies = (team) => {
        const teamId = team.id;
        if (!this.state.season) {
            return Promise.resolve([]);
        }

        return getTrophies(this.state.season.id, teamId)
            .then((trophies) => {
                markTrophiesAsViewed(teamId);
                return trophies.filter(trophy => !trophy.isViewed);
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
            })
        ;
    };

    onChangeLeague = ({id: leagueId}) => {
        setCurrentLeagueId(leagueId);
        this.refreshLeague(leagueId)
            .then(() => this.refreshData())
    };

    refreshLeague(leagueId) {
        this.setState({isLoading: true});
        return getLeague(leagueId)
            .then((league) => {
                this.setState({league});
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {league, team, showTrophies, notViewedGameResults, notViewedTrophies, season, isLoading} = this.state;

        if (!league || isLoading) {
            return <main><Loader /></main>;
        }

        const PageLayout = ({ children }) => <main>
            {this.isSuperAdmin()
                ?
                <div className="clearfix">
                    <div className="mb-default">
                        <Link to={`/owner/leagues/${league.id}`} className="btn btn-sm btn-default">❮ &nbsp; League
                            Details
                        </Link>
                    </div>
                </div>
                :
                <>
                    <div className="top-side-actions">
                        <LeagueSwitcher league={league} onChangeLeague={this.onChangeLeague} />
                    </div>
                </>
            }
            <h1><Trans>Game Center</Trans></h1>

            {children}
        </main>


        if (!league.currentSeason) {
            return (
                <PageLayout>
                    <h2><Trans values={{name: league.name}}>There's no active season for league</Trans></h2>
                </PageLayout>
            );
        }

        return (
            <PageLayout>
                <GamesOverviewSection
                    season={season}
                    league={league}
                    games={this.state.games}
                />

                {notViewedGameResults.length>0 && <GameResultsNotification
                    key={`league_${league.id}`}
                    league={league}
                    team={team}
                    gameResults={notViewedGameResults}
                    onFinished={() => this.setState({showTrophies: true})}
                />}

                {showTrophies && notViewedTrophies.length>0 && <TrophiesNotification key={`league_${league.id}`} trophies={notViewedTrophies} />}
            </PageLayout>
        );
    }
}

export default withTranslation()(withRouter(GameCenterPage));
