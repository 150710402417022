import React, {useState, useEffect} from 'react';
import MatchupsSection from "modules/GameCenter/MatchupsSection";
import WeekSwitcher from "modules/GameCenter/WeekSwitcher";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import GameSection from "modules/GameCenter/GameSection";
import {groupScoresByWeekAndPlayer} from "services/SeasonService";
import {getGames} from "services/FantasyGameService";
import {addErrorAsNotification} from "services/ValidationService";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {getStandings} from "services/LeagueService";

const GamesOverviewSection = ({ season, league, games: initialGames, standings: initialStandings, t }) => {
    const [isLoading, setLoading] = useState(false);
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState(season ? season.currentWeek : null);
    const [nbVisibleWeeks, setNbVisibleWeeks] = useState(null);
    const [weekGames, setWeekGames] = useState([]);
    const [teamRanks, setTeamRanks] = useState([]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
                initialGames ? initialGames : getGames(league.id, season.id),
                initialStandings ? initialStandings : getStandings(league.id, season.id),
            ])
            .then(([games, standings]) => {
                setGames(games);

                const ranks = getTeamsRanksFromStandings(standings);
                setTeamRanks(ranks);
            })
            .catch(({response}) => {
                addErrorAsNotification(response, t);
            })
            .finally(() => setLoading(false))
        ;
    }, []);

    //Refresh week when games list changes
    useEffect(() => {
        onSelectWeek(selectedWeek);
    }, [games]);

    const onSelectWeek = (selectedWeek) => {
        selectedWeek = selectedWeek ? selectedWeek : 1;

        const nbVisibleWeeks = games.reduce((currentMaxWeek, game) => {
            return Math.max(currentMaxWeek, game.week);
        }, 0);

        const weekGames = games.filter((game) => game.week === selectedWeek);
        setSelectedWeek(selectedWeek);
        setNbVisibleWeeks(nbVisibleWeeks);
        setWeekGames(weekGames);
        setSelectedGame(weekGames[0]);
    };

    const getTeamsRanksFromStandings = (standings) => {
        if (!standings) {
            return [];
        }

        let result = {};
        for (const standing of standings) {
            result[standing.team.id] = standing.rank;
        }

        return result;
    };

    if (!games) {
        return null;
    }

    if (!season || !league) {
        return null;
    }

    const scores = groupScoresByWeekAndPlayer(season.games);
    const weekScores = scores[selectedWeek] !== undefined ? scores[selectedWeek] : [];

    return (
        <div>
            <MatchupsSection games={weekGames} selectedGame={selectedGame} onGameSelected={setSelectedGame} />
            <WeekSwitcher
                nbWeeks={season.nbWeeks}
                nbVisibleWeeks={nbVisibleWeeks}
                currentWeek={selectedWeek}
                nbPlayoffWeeks={season.nbPlayoffWeeks}
                onSelectWeek={onSelectWeek}
            />

            <LoadingOverlay active={isLoading}>
                {selectedGame && <GameSection
                    season={season}
                    teamsRanks={teamRanks}
                    scores={weekScores}
                    game={selectedGame}
                />}
            </LoadingOverlay>
        </div>
    );
};

export default compose(
    withTranslation(),
)(GamesOverviewSection);
