import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";

export default function DeletePlayerConfirmationModal({player, onConfirm, ...rest}) {
    if (!player) {
        return null;
    }

    const name = player.name;
    return (
        <ConfirmationModal
            title={"Delete Player?"}
            content={"Are you sure you want to delete player \""+name+"\"?"}
            buttons={{
                yes: 'Yes, delete',
                no: 'Cancel'
            }}
            type="destructive"
            onConfirm={() => onConfirm(player)}
            {...rest}
        />
    );
}
