import React, {Component} from 'react';
import ChangePasswordForm from "./ChangePasswordForm";

import {changePassword} from "services/AuthService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import { withRouter } from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";

class ChangePasswordPage extends Component {
    state = {
        isLoading: false
    };

    handlePasswordChange = ({oldPassword, newPassword}) => {
        this.setState({isLoading: true});
        return changePassword(oldPassword, newPassword)
            .then(() => {
                addSuccessNotification('Success', 'Your password was successfully updated');
                this.props.history.push('/profile');
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    render() {
        const {isLoading} = this.state;

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <ChangePasswordForm processData={this.handlePasswordChange}/>
                </LoadingOverlay>
            </main>
        );
    }
}

export default withRouter(ChangePasswordPage);
