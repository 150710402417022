import React from 'react';
import {Trans} from "react-i18next";
import {getUser} from "services/AuthService";

const StandingsTable = ({ standings }) => {
    const user = getUser();

    return (
        <div className="table-responsive">
            <table className="table table-condensed table-bordered mb-default standings-table">
            <thead>
                <tr>
                    <th><Trans>Team</Trans></th>
                    <th><Trans>User</Trans></th>
                    <th><Trans>Rank</Trans></th>
                    <th><Trans>Win</Trans></th>
                    <th><Trans>Loss</Trans></th>
                    <th><Trans>Tie</Trans></th>
                    <th><Trans>PF</Trans></th>
                    <th><Trans>PA</Trans></th>
                    <th><Trans>Net</Trans></th>
                    <th><Trans>PCT</Trans></th>
                </tr>
            </thead>
            <tbody>
                {standings.map((standing, idx) => <tr key={idx} className={user && user.id === standing.owner.id ? 'current' : ''}>
                    <td>{standing.team.name}</td>
                    <td>{standing.owner.name}</td>
                    <td>{standing.rank ? standing.rank : '-'}</td>
                    <td>{standing.wins}</td>
                    <td>{standing.losses}</td>
                    <td>{standing.ties}</td>
                    <td>{standing.points}</td>
                    <td>{standing.pointsAgainst}</td>
                    <td>{(standing.points - standing.pointsAgainst).toFixed(2)}</td>
                    <td>{formatPercentages(standing.wins, standing.losses)}</td>
                </tr>)}
            </tbody>
        </table>
        </div>
    );
};

function formatPercentages(wins, losses) {
    const total = (wins + losses);
    if (total === 0) {
        return '0%';
    }

    return Math.floor((wins/total) * 100) + '%';
}

export default StandingsTable;
