import React from 'react';

export default function ThumbnailImg({width, height, ...rest}) {
    const style = {
        width,
        height,
        objectFit: 'cover',
    };

    return (<img alt="" style={style} {...rest} />);
}
