import React, {Component, Fragment} from 'react';
import {addPlayerToRoster} from "services/RosterService";
import {addSuccessNotification} from "services/NotificationService";
import {addErrorAsNotification} from "services/ValidationService";
import PlayersTable from "./PlayersTable";
import PickPlayerConfirmationModal from "modules/Draft/PickPlayerConfirmationModal";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {withTranslation} from "react-i18next";

class PickablePlayersTable extends Component {
    state = {
        isLoading: false,
        selectedPlayer: null,
        showPickPlayerConfirmationModal: false,
    };

    showPickPlayerConfirmationModal = (player) => {
        this.setState({selectedPlayer: player, showPickPlayerConfirmationModal: true});
    };

    hidePickPlayerConfirmationModal = () => {
        this.setState({showPickPlayerConfirmationModal: false});
    };

    onPickPlayer = (player) => {
        this.showPickPlayerConfirmationModal(player);
    };

    onExecutePickPlayer = (player) => {
        const {t} = this.props;
        this.setState({isLoading: true});

        addPlayerToRoster(player.id, this.props.rosterId)
            .then(() => {
                addSuccessNotification(
                    t('Pick successful'),
                    t('You have successfully drafted {{namePosition}}', {namePosition: `${player.name} (${player.position})`}),
                );

                this.setState({isLoading: false});
                this.props.onPlayerPicked();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {leagueId} = this.props;
        const {isLoading, selectedPlayer, showPickPlayerConfirmationModal} = this.state;

        return (
            <Fragment>
                <LoadingOverlay active={isLoading}>
                    <PlayersTable
                        search={this.props.search}
                        leagueId={leagueId}
                        pickEnabled={true}
                        onPickPlayer={this.onPickPlayer}
                        sort={this.props.sort}
                        sortDirection={this.props.sortDirection}
                        onSort={this.props.onSort}
                    />
                </LoadingOverlay>

                <PickPlayerConfirmationModal
                    isOpen={showPickPlayerConfirmationModal}
                    handleCloseModal={() => this.hidePickPlayerConfirmationModal()}
                    player={selectedPlayer}
                    onConfirm={() => this.onExecutePickPlayer(selectedPlayer)}
                />
            </Fragment>
        );
    }
}

export default withTranslation()(PickablePlayersTable);
