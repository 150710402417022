import React from 'react';

export default function MediaPage() {
    return (
        <main>
            <h1>Media</h1>

            <div id="lipsum">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin maximus metus ac odio semper, eget pretium ipsum efficitur. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque porta orci vel ex feugiat, at commodo tortor scelerisque. Fusce non leo non eros suscipit ornare. Aenean tincidunt sollicitudin augue, sed cursus dolor malesuada eget. Aenean ipsum enim, aliquam sed ullamcorper vel, tincidunt at magna. Nulla facilisi. Sed placerat, sem ac varius hendrerit, metus enim fermentum tortor, eget posuere ex odio a orci. Sed sed fringilla nunc, sed accumsan justo.
                </p>
                <p>
                    Curabitur tristique, nisi in consectetur tincidunt, arcu risus tristique ex, at euismod dolor erat in diam. Nam pretium elit id nibh bibendum, sit amet dictum odio suscipit. Nullam et accumsan elit, a commodo nunc. Curabitur congue urna lacus, eget vestibulum turpis luctus at. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam ut tortor aliquet, ultricies velit id, posuere dolor. Ut lobortis dui non maximus efficitur. Curabitur lacinia commodo justo nec viverra. Nulla nec quam luctus, tempus orci eu, malesuada velit.
                </p>
            </div>
        </main>
    );
}
