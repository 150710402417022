import React from 'react';
import {Trans, withTranslation} from "react-i18next";
import {Share} from "react-facebook";
import {withRouter} from "react-router-dom";
import AppFacebookProvider from "modules/Common/AppFacebookProvider";

class Trophy extends React.Component {
    render() {
        let {i18n, trophy, wide} = this.props;
        const {id, week, type, fantasyTeam, player} = trophy;

        let className = 'trophy';
        if (wide) {
            className += ' wide';
        }

        return (
            <div className={className} key={id}>

                <h3><Trans i18nKey={`trophy_${type}`}/></h3>
                <p>
                    <Trans i18nKey={`trophy_description_${type}`} values={{week}}/>
                </p>
                <p>
                    {fantasyTeam.name} {player ? ' - ' + player.name : ''}
                </p>

                <img src={`/img/trophies/${i18n.language}/${type}.png`}
                     alt={`Trophy ${type}`}/>

                <div className="share-row">
                    <AppFacebookProvider key="FB">
                        <Share href={window.location.href}>
                            {({ handleClick, loading }) => (
                                <button className="btn btn-primary" type="button" onClick={() => {
                                    window.location = `/trophies/${i18n.language}/${id}`;
                                }}>
                                    <Trans>Share</Trans>
                                </button>
                            )}
                        </Share>
                    </AppFacebookProvider>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(Trophy));