import React from 'react';

const Terms = () => {
    return (
        <div>
            <h1>FANTASY FOOTBALL FELHASZNÁLÁSI FELTÉTELEK</h1><p><strong>Hatálybalépés napja: </strong>2019. október
            21. </p><p><em>Kérjük, olvassa el figyelmesen ezt a dokumentumot, mert fontos információkat tartalmaz a
            Hungarian
            Fantasy Football használatáról, jogi vonatkozásokról, kötelezettségekről és korlátozásokról. Ha nem ért
            egyet a
            jelen Feltételeknek egy vagy több rendelkezésével, kérjük, ne használja a Hungarian Fantasy Football-t.</em>
        </p><p>
            Ezek a Hungarian Fantasy Football felhasználási feltételek ("Feltételek") egy jogilag kötelező érvényű
            megállapodás
            Kiss Gábor, akinek címe Farman-Strasse 47. 8152 Glattpark (Opfikon) ("mi"), valamint az egyéni felhasználó
            (a
            "Felhasználó", "te" vagy "Ön") között, fellépve illetve használva a <a
            href="http://fantasyfootball.hu/">http://fantasyfootball.hu</a>
            ("Hungarian Fantasy Football") weboldalt.</p>

            <ol>
                <li><a href="#_Toc">Általános információk</a></li>
                <li><a href="#_Toc1">Felhasználói fiók</a></li>
                <li><a href="#_Toc2">Felhasználó Által Létrehozott Tartalom</a></li>
                <li><a href="#_Toc3">Értesítések</a></li>
                <li><a href="#_Toc4">Harmadik felek linkjei</a></li>
                <li><a href="#_Toc5">Tiltott felhasználás</a></li>
                <li><a href="#_Toc6">Szellemi tulajdon</a></li>
                <li><a href="#_Toc7">Jótállás megtagadása</a></li>
                <li><a href="#_Toc8">A felelősség korlátozása</a></li>
                <li><a href="#_Toc9">Rendelkezésre állás</a></li>
                <li><a href="#_Toc10">Kártalanítás</a></li>
                <li><a href="#_Toc11">Elkülöníthetőség</a></li>
                <li><a href="#_Toc12">Irányadó jog és vitarendezés</a></li>
                <li><a href="#_Toc13">Átruházás</a></li>
                <li><a href="#_Toc14">Időtartam és megszűnés</a></li>
                <li><a href="#_Toc15">Változások és módosítások</a></li>
                <li><a href="#_Toc16">Lépjen velünk kapcsolatba</a></li>
            </ol>
            
            <ul>
                <li><a id="_Toc"></a><h3>Általános információk</h3>
                    <ol>
                        <li><strong>A Hungarian Fantasy Football-ról. </strong>A Hungarian Fantasy Football egy
                            platform, amely
                            hozzáférést biztosít a magyarországi amerikai futball nemzeti ligájáról szóló
                            információkhoz, és
                            lehetőséget kínál a más Hungarian Fantasy Football felhasználókkal történő kommunikációhoz,
                            beleértve az
                            üzenőfalat, szerencsejátékot, csapat alakítást.
                        </li>
                        <li><strong>Harmadik felek.</strong> Ezek a Feltételek csak az Ön Hungarian Fantasy Football
                            használatára
                            vonatkoznak. Hacsak az alkalmazandó jog másképp nem rendelkezik, nem vagyunk felelősek vagy
                            felelősségre
                            vonhatók bármilyen harmadik felek által véghez vitt cselekményekért vagy mulasztásokért, a
                            harmadik
                            felek által kínált szolgáltatások minőségéért, beleértve a hirdetők, a Felhasználók vagy
                            harmadik felek
                            által feltöltött tartalmakat a Hungarian Fantasy Football-on, valamint a biztonsági,
                            adatvédelmi
                            gyakorlatokért, melyeket a harmadik felek weboldalai alkalmaznak, az alkalmazásokért,
                            valamint a
                            Hungarian Fantasy Football-hoz és kapcsolódó szolgáltatásokért.
                        </li>
                        <li><strong>Kisebb jelentőségű információk.</strong> A Hungarian Fantasy Football nem
                            hozzáférhető és
                            használható 16 év alatti személyeknek.
                        </li>
                        <li><strong>Engedély a Hungarian Fantasy Football használatára.</strong> A jelen Feltételek
                            alapján
                            személyes, visszavonható, nem kizárólagos, nem átruházható és korlátozott engedélyt
                            biztosítunk Önnek a
                            Hungarian Fantasy Football használatára.
                        </li>
                        <li><strong>Jogi nyilatkozat.</strong> Bár rendszeresen figyelemmel kísérjük a Hungarian Fantasy
                            Football-on
                            rendelkezésre álló információkat, nem garantáljuk a valódiságot, megbízhatóságot, valutát,
                            relevanciát,
                            valamint a Hungarian Fantasy Football-on rendelkezésre álló összes információ teljességét.
                            Továbbá nem
                            vállalunk felelősséget a Felhasználók által a Hungarian Fantasy Football-on keresztül
                            biztosított
                            információk és tartalmak helyességéért, jogszerűségéért és megfelelőségéért.
                        </li>
                        <li><strong>Támogatás.</strong> Bármely vevői igény vagy technikai támogatás iránti kérelmet
                            e-mailben
                            szükséges eljuttatnia hozzánk a(z) <a
                                href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a>
                            címre. Indokolatlan késedelem nélkül, de legkésőbb 5 munkanapon belül válaszolunk a
                            kérdésekre.
                        </li>
                        <li><strong>Adatvédelem</strong>. Elkötelezettek vagyunk az Ön adatvédelme, valamint az
                            alkalmazandó
                            adatvédelmi jogszabályok betartása iránt, beleértve a Svájci Szövetségi Adatvédelmi Törvényt
                            és az EU
                            Általános Adatvédelmi Szabályzatát (GDPR). Ezért, mielőtt használja a Hungarian Fantasy
                            Football-t,
                            ajánlott az adatvédelmi irányelvek áttekintése, melyek elérhetők a <a href="//fantasyfootball.hu/privacy">fantasyfootball.hu/privacy</a>
                            linken (az
                            "Adatvédelmi Irányelvek"), valamint a süti szabályzatunké, mely elérhető a <a href="//fantasyfootball.hu/cookie-policy">fantasyfootball.hu/cookie-policy</a> linken,
                            amely részletesen elmagyarázza alkalmazott gyakorlatainkat, eljárásainkat a személyes
                            adatainak
                            feldolgozására vonatkozóan.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc1"></a><h3>Felhasználói fiók</h3>
                    <ol>
                        <li><strong>A Felhasználói fiók regisztrációja.</strong> A Hungarian Fantasy Football teljes
                            funkcionalitásának elérése és használata érdekében a Felhasználónak regisztrálnia kell egy
                            Felhasználói
                            fiók (a "Felhasználói Fiók") létrehozásával, a kért személyes adatok megadásával, a
                            Feltételek
                            elfogadásával, valamint az Adatvédelmi Szabályzat áttekintésével. Amennyiben a Felhasználó
                            nem ért egyet
                            az említett dokumentumok egy vagy több rendelkezésével, a Felhasználó nem regisztrálhatja a
                            Felhasználói
                            Fiókot; a Felhasználói Fiók regisztrálásával a Felhasználó tudomásul veszi, hogy a
                            Felhasználó elolvasta
                            az említett dokumentumokat. Figyelemmel kísérhetjük és felügyelhetjük az új Felhasználói
                            Fiókokat, és
                            fenntartjuk a jogot, hogy bármely Felhasználói Fiók regisztrációját saját belátásunk szerint
                            visszautasítsuk. A Felhasználó nem jogosult a Felhasználó jogainak átruházására a jelen
                            Feltételek
                            alapján, és a Felhasználói Fiók sem ruházható át. A Felhasználói Fiók regisztrációjának
                            befejezésekor a
                            Felhasználó tudomásul veszi, beleegyezik és szavatolja, hogy:
                            <ul>
                                <li>A Felhasználó köteles betartani a jelen Feltételeket és az összes alkalmazandó
                                    jogszabályt;
                                </li>
                                <li>A Felhasználó köteles kizárólag valódi, pontos, teljes és naprakész személyes
                                    adatokat
                                    szolgáltatni;
                                </li>
                                <li>A Felhasználó ember, nem gép (nem megengedett a gép, bot vagy más automatizált
                                    módszer által
                                    regisztrált Felhasználói Fiókok létrehozása);
                                </li>
                                <li>A Felhasználó legfeljebb egy Felhasználói Fiókot regisztrálhat; és</li>
                                <li>A Felhasználó kizárólagos felelősséggel tartozik minden információért és
                                    tevékenységért, melyek
                                    a Felhasználói Fiók alatt felmerülnek.
                                </li>
                            </ul>
                        </li>
                        <li><strong>Korhatár.</strong> Nem fogadjuk el a Felhasználói Fiók regisztrációját, ha a
                            Felhasználó még nem
                            töltötte be a 16. életévét, még akkor sem, ha a Felhasználó engedélyt kapott a szülőktől
                            vagy a
                            törvényes gondviselőitől az ilyen regisztrációhoz. Fenntartjuk a jogot, hogy bármikor
                            töröljük a
                            Felhasználói Fiókot, ha tudomást szerzünk arról, hogy a Felhasználó manipulálta a
                            regisztrációs
                            folyamatot, és fenntartjuk a jogot, hogy megakadályozzuk, hogy a Felhasználó további
                            regisztrációkat
                            hajtson végre, amíg a Felhasználó nem lesz legalább 16 éves. Javasoljuk a szülőknek és a
                            törvényes
                            gyámoknak, hogy felügyeljék gyermekeik internethasználatát, és segítsenek nekünk a
                            Feltételek
                            betartatásában azáltal, hogy utasítják kisgyermekeiket, hogy ne: (i) lépjenek fel és
                            használják a
                            Hungarian Fantasy Football-t, (ii) ne regisztráljanak egy Felhasználói Fiókot, és (iii) ne
                            adjanak meg
                            személyes adatokat a Hungarian Fantasy Football-on keresztül.
                        </li>
                        <li><strong>Személyes adatok feldolgozása.</strong> A Felhasználói Fiók regisztrációja során,
                            valamint a
                            Hungarian Fantasy Football-on keresztül gyűjtött személyes adatok feldolgozása Adatvédelmi
                            Irányelveinkkel összhangban történik. A Felhasználói Fiók regisztrációja előtt a rendszer
                            felkéri, hogy
                            tekintse át az Adatvédelmi Irányelveket. Kérjük, vegye figyelembe, hogy személyes adatait
                            kizárólag az
                            Adatvédelmi Irányelvekben kifejezetten felsorolt célokra használjuk fel.
                        </li>
                        <li><strong>A Felhasználói Fiók biztonsága.</strong> A Felhasználói Fiók regisztrációjának
                            befejezéséhez,
                            illetve biztonságának biztosításához erős jelszót kell létrehoznia. Ha másként dönt, akkor
                            nem tudja
                            befejezni Felhasználói Fiókjának regisztrációját. Szintén kizárólag Ön felelős (i) a
                            bejelentkezési
                            adatok biztonságának fenntartásáért, (ii) minden olyan tevékenységért, amely az Ön
                            Felhasználói
                            Fiókjában történik, valamint (iii) minden más, a Felhasználói Fiókjával kapcsolatban tett
                            intézkedésért.
                            Azonnal értesítenie kell minket Felhasználói Fiókjának bármilyen jogosulatlan használatáról
                            vagy a
                            biztonság bármely egyéb megsértéséről. Ön a felelős a Hungarian Fantasy Football elérésekor
                            a
                            biztonságos internetkapcsolat és a védett hálózatok használatáért. Nem tudunk, illetve nem
                            vállalunk
                            felelősséget semmilyen veszteségért vagy kárért, amelyek az óvintézkedések elmulasztása
                            miatt merülnek
                            fel, amelyek szükségesek a saját és az Ön készülékének védelme érdekében, hogy megvédje
                            magát az
                            eszközöket, amelyeken használja a Hungarian Fantasy Football-t, a vírusoktól, malware-ektől,
                            trójai
                            programoktól, illetve egyéb káros vagy romboló tartalmaktól.
                        </li>
                        <li><strong>Felhasználói Fiók törlése. </strong>Bármikor törölheti a Felhasználói Fiókot a
                            Felhasználói Fiók
                            „Beállítások” funkcióján keresztül. A Felhasználói Fiók törlésével ezek a Feltételek
                            megszűnnek.
                        </li>
                        <li><strong>A Felhasználói Fiók felfüggesztése és megszüntetése.</strong> Felfüggeszthetjük,
                            letilthatjuk
                            vagy törölhetjük Felhasználói Fiókját (vagy annak bármely részét), ha okunk van
                            feltételezni, hogy Ön
                            megsértette a jelen Feltételek bármely rendelkezését, vagy hogy magatartása károsítja
                            hírnevünket és jó
                            megítélésünket. Ha a fenti okok miatt töröljük Felhasználói Fiókját, nem regisztrálhat újra.
                            Valamely
                            törvényes hatóság jogszerű kérésére szintén felfüggeszthetjük vagy megszüntethetjük a
                            Felhasználói
                            Fiókot.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc2"></a><h3>Felhasználó Által Létrehozott Tartalom</h3>
                    <ol>
                        <li>A felhasználó bizonyos típusú tartalmakat küldhet be a Hungarian Fantasy Football-on
                            keresztül,
                            beleértve a nyilvános és privát üzeneteket, kommenteket, linkeket, szövegeket és képeket (a
                            "Felhasználó
                            Által Létrehozott Tartalom"). A Felhasználó Által Létrehozott Tartalom elérhetővé válhat a
                            Hungarian
                            Fantasy Football más felhasználói számára. A Felhasználó nem teheti közzé azon személyek
                            személyes
                            adatait, akik nem adták meg a Felhasználónak előzetesen engedélyüket vagy hozzájárulásukat a
                            személyes
                            adatok megosztásához. A Felhasználó vállalja, hogy nem nyújt be olyan Felhasználó Által
                            Létrehozott
                            tartalmat, amely megsérti a jelen Feltételeket vagy az alkalmazandó jogszabályokat.
                        </li>
                        <li>Nem rendelkezünk semmilyen Felhasználó Által Létrehozott Tartalommal. A Felhasználó felelős
                            a
                            Felhasználó által benyújtott összes Felhasználó Által Létrehozott Tartalom pontosságáért,
                            minőségéért,
                            integritásáért, jogszerűségéért, megbízhatóságáért, megfelelősségéért, szellemi
                            tulajdonáért, illetve
                            használatáért. Fenntartjuk a jogot, de nem vagyunk kötelesek, saját belátásunk szerint
                            megtagadni vagy
                            eltávolítani azokat a Felhasználó Által Létrehozott Tartalmakat, amelyek ésszerű véleményünk
                            szerint
                            megsértik a Feltételeket, bármely más rendelkezésünket, vagy bármilyen módon károsak vagy
                            kifogásolhatók.
                        </li>
                        <li>A Felhasználó Által Létrehozott Tartalom benyújtásával a Hungarian Fantasy Football-on
                            keresztül, a
                            Felhasználó korlátlan, al-licenszelhető, jogdíjmentes, határozatlan, és visszavonhatatlan
                            jogokat ad
                            nekünk, hogy használjuk, terjesszük, reklámozzuk, adaptáljuk, remixeljük, módosítsuk,
                            nyilvánosan
                            bemutassuk, nyilvánosan előadjuk, kivonatoljuk, származékos műveket készítsünk, valamint
                            reprodukáljuk a
                            Felhasználó Által Létrehozott Tartalmat a Hungarian Fantasy Football-on keresztül történő
                            szolgáltatásnyújtás céljából.
                        </li>
                        <li>A Felhasználó Által Létrehozott Tartalmakat, azok későbbi kiadásait, módosításait
                            szándékosan vagy
                            rendszeresen nem vizsgáljuk át. Fenntartjuk a jogot, de nem vagyunk kötelesek, saját
                            belátásunk szerint
                            megtagadni vagy eltávolítani azokat a Felhasználó Által Létrehozott Tartalmakat, amelyek
                            ésszerű
                            véleményünk szerint megsértik a Feltételeket, bármely más rendelkezésünket, vagy bármilyen
                            módon károsak
                            vagy kifogásolhatók.
                        </li>
                        <li>A Hungarian Fantasy Football segítségével közvetlenül a Felhasználók között továbbított
                            kommunikáció(k)
                            tartalmát nem irányítjuk vagy általunk nem ismert, és az ilyen tartalom azoknak a
                            Felhasználóknak a
                            felelőssége, akiktől az ilyen tartalom származik. A jelen Feltételek bármely, különösen a
                            Feltételek 6.
                            szakaszának rendelkezéseit sértő tartalom nem küldhető be a Hungarian Fantasy Football-on
                            keresztül.
                        </li>
                        <li>A Felhasználó kizárólag a Hungarian Fantasy Football-hoz, azon keresztül vagy azzal
                            kapcsolatban
                            beküldött bármely Felhasználó Által Létrehozott Tartalom vagy egyéb információ másolatának
                            elkészítéséért, megtartásáért és karbantartásáért felelős.
                        </li>
                        <li>A Felhasználó tudomásul veszi, megérti és elfogadja, hogy technikai problémák, hibák, emberi
                            hiba vagy
                            hasonló miatt a Felhasználó Által Létrehozott Tartalom egy része vagy egésze a Hungarian
                            Fantasy
                            Football más Felhasználói számára elérhető marad az illető Felhasználó fiókjának
                            kikapcsolása után is.
                        </li>
                        <li>Nem képviseljük vagy utalunk arra, hogy támogatnánk a Felhasználó Által Létrehozott
                            Tartalmat, illetve a
                            Felhasználó által közzétett bármely más anyagot, vagy hogy úgy gondoljuk, hogy ezek az
                            anyagok pontosak,
                            hasznosak vagy nem károsak.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc3"></a><h3>Értesítések</h3>
                    <ol>
                        <li>Miután regisztrálta Felhasználói Fiókját, fontos technikai értesítéseket kaphat tőlünk
                            Felhasználói
                            Fiókjáról és a Hungarian Fantasy Football-ról (az "Értesítések"), beleértve a
                            szolgáltatással
                            kapcsolatos, technikai vagy adminisztratív e-maileket, a Felhasználói Fiókjával kapcsolatos
                            információkat, az adatvédelmet és a biztonságot, valamint egyéb fontos kérdéseket
                            tartalmazva. Kérjük,
                            vegye figyelembe, hogy az Értesítéseket "amikor szükséges" alapon küldjük, és azok nem
                            tartoznak az Ön
                            előzetes hozzájárulását igénylő közvetlen marketing célú kommunikáció hatálya alá. Kérjük,
                            vegye
                            figyelembe, hogy a marketing célú kommunikáció letiltása esetén sem iratkozhat le az
                            Értesítésekről.
                        </li>
                        <li><strong>Marketing. </strong>Annak érdekében, hogy naprakész legyen a Hungarian
                            FantasyvFootball-al
                            kapcsolatban, marketing üzeneteket küldhetünk, tájékoztatva Önt az új szolgáltatásainkról
                            vagy
                            különleges ajánlatainkról. A marketing célú üzenetek fogadása bármikor ingyenesen
                            lemondható, ha
                            rákattint az Önnek küldött üzenetek bármelyikében található "leiratkozás" linkre vagy
                            közvetlen
                            megkeresésünkkel. Csak akkor kap marketing célú üzeneteket, ha:
                            <ul>
                                <li>Az Ön kifejezett ("opt-in") hozzájárulását kapjuk a marketing célú üzenetek
                                    fogadására (kérjük,
                                    vegye figyelembe, hogy az Ön önkéntes feliratkozása hírleveleinkre és
                                    frissítéseinkre ilyen
                                    hozzájárulásnak minősül); vagy
                                </li>
                                <li>Úgy döntünk, hogy marketing célú üzeneteket küldünk Önnek az új szolgáltatásainkról,
                                    amelyek
                                    szorosan kapcsolódnak az Ön által már igénybe vett szolgáltatásokhoz.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc4"></a><h3>Harmadik felek linkjei</h3>
                    <ol>
                        <li>A Hungarian Fantasy Football tartalmazhat harmadik fél tulajdonában lévő, üzemeltetett és
                            felügyelt
                            weboldalakra mutató linkeket. Kérjük, vegye figyelembe, hogy nem vagyunk felelősek az ilyen
                            harmadik
                            felek által alkalmazott üzleti vagy biztonsági gyakorlatokért. Javasoljuk, hogy legyen ennek
                            tudatában,
                            amikor elhagyja a Hungarian Fantasy Football-t, és kérjük, hogy gondosan mérlegelje az egyes
                            weboldalakat vagy szolgáltatásokat, amelyekhez hozzáfér.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc5"></a><h3>Tiltott felhasználás</h3>
                    <ol>
                        <li>A Hungarian Fantasy Football használatakor követnie kell az ebben a 6. Szakaszban
                            ismertetett
                            irányelveinket. Felhívjuk figyelmét, hogy szorosan együttműködünk bűnüldöző szervekkel, és
                            jelentünk
                            minden olyan nem megfelelő tartalmat, amely sértheti az alkalmazandó jogszabályokat.
                        </li>
                        <li>A Hungarian Fantasy Football nem használható olyan módon, amely helyettesíti vagy hozzájárul
                            a következő
                            tevékenységekhez (a lista reprezentatív és nem teljes):
                            <ul>
                                <li>Bármilyen jogellenes tevékenység, beleértve bármely törvény, rendelet vagy szabályok
                                    megsértését;
                                </li>
                                <li>Csalás;</li>
                                <li>Hamis, pontatlan vagy félrevezető információk szolgáltatása;</li>
                                <li>A jogi aktusokra vonatkozó információk terjesztése, beleértve a csínytevéseket és
                                    kihívásokat,
                                    amelyek károkhoz és fizikai sérülésekhez vezethetnek;
                                </li>
                                <li>A Felhasználó Által Létrehozott Tartalom közzététele, amely erőszakos cselekményeket
                                    ábrázol,
                                    vagy másokat felbujt arra;
                                </li>
                                <li>A gyermekeket ábrázoló, illetve a gyermekek számára érzelmi megrázkódtatást okozó,
                                    Felhasználó
                                    Által Létrehozott Tartalom biztosítása;
                                </li>
                                <li>Szerencsejáték, beleértve a versenyeket, lottókat, licit árveréseket, sport
                                    előrejelzéseket vagy
                                    szorzókat, internetes szerencsejátékokat, fantasy sportligákat készpénzes
                                    nyereményekkel,
                                    valamint nyereményjátékokat;
                                </li>
                                <li>Rosszindulatú programok (pl. vírusok, trójai programok), spamek és egyéb illegális
                                    üzenetek
                                    terjesztése;
                                </li>
                                <li>Etnikai, faji vagy más módon kifogásolható információk terjesztése;</li>
                                <li>Szexuálisan nyílt, meztelen, becsületsértő, zaklató, erőszakos, rágalmazó,
                                    gyalázkodó,
                                    gyalázatos, vulgáris, fenyegető, utálatos, obszcén viselkedéssel és a terrorizmussal
                                    kapcsolatos
                                    tartalom;
                                </li>
                                <li>A dohány, az alkohol és bármely illegális anyag reklámozása vagy használatának
                                    ösztönzése;
                                </li>
                                <li>A Hungarian Fantasy Football másolása, terjesztése, bérlése, viszonteladása,
                                    módosítása,
                                    kompromittálása, letiltása, károsítása és túlterhelése;
                                </li>
                                <li>A Hungarian Fantasy Football más felhasználóinak megzavarása vagy bántalmazása;</li>
                                <li>Bot, szkript és más automatizált módszer használata; és</li>
                                <li>A Hungarian Fantasy Football más Felhasználóiról szóló információk gyűjtése és
                                    közzététele.
                                </li>
                            </ul>
                        </li>
                        <li><strong>Nem megfelelő tartalom jelentése.</strong> Ha úgy gondolja, hogy valamely Hungarian
                            Fantasy
                            Football-on elérhető tartalom nem megfelelő, sérti ezeket a Feltételeket, a vonatkozó
                            jogszabályokat
                            vagy az adatvédelmi jogokat, kérjük, azonnal lépjen kapcsolatba velünk a(z) <a
                                href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a> e-mail címen. Ha
                            bármilyen
                            tartalmat vagy felhasználót nem megfelelőnek minősítenek, azonnal töröljük az ilyen
                            tartalmat a
                            Hungarian Fantasy Football-ról, majd megvizsgáljuk a jelentett Felhasználó viselkedését.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc6"></a><h3>Szellemi tulajdon</h3>
                    <ol>
                        <li>A Hungarian Fantasy Football-on elérhető tartalmak nagy részét mi, partnereink, ügynökeink,
                            engedélyezőink, beszállítóink és/vagy más tartalomszolgáltatók ("Tartalmunk") tulajdonát
                            képezi.
                            Tartalmunk magában foglalja, de nem korlátozódik szövegre, képekre, audiovizuális
                            tartalomra,
                            forráskódra, védjegyekre, szolgáltatási védjegyekre, kereskedelmi nevekre. Tartalmunkat az
                            alkalmazandó
                            szellemi tulajdonjogokra vonatkozó jogszabályok és nemzetközi szerződések védik.
                        </li>
                        <li>A Felhasználónak, előzetes írásbeli engedélyünk nélkül, tilos:
                            <ul>
                                <li>Tartalmunk terjesztése;</li>
                                <li>Tartalmunk másolása;</li>
                                <li>Tartalmunk szétszedése, módosítása, lebontása, lefordítása és adaptálása; illetve
                                </li>
                                <li>Bérletbe, kölcsönbe adása, használata, lízingelése vagy más jogok átadásának
                                    kísérlete
                                    Tartalmunkhoz harmadik felek részére.
                                </li>
                            </ul>
                        </li>
                        <li> A Hungarian Fantasy Football márka. A Felhasználó nem használja a Hungarian Fantasy
                            Football márkát, a
                            védjegyeket, melyek a Hungarian Fantasy Football-hoz társítottak vagy harmadik féltől
                            származó
                            védjegyeket, amelyek kifejezetten kívül esnek. A Felhasználó semmilyen módon nem
                            használhatja ezeket a
                            márkákat vagy védjegyeket, ami azt sugallja, hogy mi a Felhasználót előzetes írásos
                            beleegyezés nélkül
                            szponzoráljuk, támogatjuk vagy társulunk vele.
                        </li>
                        <li>Harmadik fél szellemi tulajdona. A Felhasználó Által Létrehozott Tartalom, néhány védjegy,
                            valamint a
                            Hungarian Fantasy Football-on szereplő egyéb szellemi tulajdon harmadik fél tulajdonában
                            lehet. Az ilyen
                            harmadik féltől származó szellemi tulajdon joga nem a miénk, és a harmadik fél
                            tulajdonosainak
                            kizárólagos tulajdona marad. Ön beleegyezik, hogy nem sérti meg az ilyen harmadik fél
                            tulajdonát. Az
                            ilyen szellemi tulajdonhoz nem férhetünk hozzá, és nem használhatjuk azt más célokra, mint
                            hogy a
                            Hungarian Fantasy Football-on keresztül szolgáltatásainkat biztosítsuk.
                        </li>
                        <li>Ezek a Feltételek nem adnak át Önnek semmilyen tulajdonunk alá tartozó, vagy harmadik fél
                            tulajdonát
                            képező szellemi tulajdonjogot, valamint minden jog, cím és érdek, illetve az ilyen
                            tulajdonok továbbra
                            is kizárólag a mi, vagy a megfelelő harmadik fél tulajdonát képezik.
                        </li>
                        <li>Szerzői jogsértési követelések. Ha bármilyen okból kifolyóan feltételezi, hogy bármely a
                            Hungarian
                            Fantasy Football-on elérhető tartalom sérti az Ön vagy harmadik fél szellemi tulajdonához
                            fűződő jogait,
                            kérjük, lépjen velünk kapcsolatba, fejezze ki aggályait, vagy kérelmezze az állítólagosan
                            jogsértő
                            tartalom eltávolítását. A szerzői jog megsértéssel kapcsolatos követelésre a lehető
                            leghamarabb, de
                            legkésőbb 2 héten belül válaszolunk. Mielőtt elküldené nekünk követelését, kérjük, győződjön
                            meg arról,
                            hogy aláírta azt, és olyan információkat tartalmaz, amelyek lehetővé teszik számunkra, hogy
                            megtaláljuk
                            az állítólagos jogsértő tartalmat.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc7"></a><h3>Jótállás megtagadása</h3>
                    <ol>
                        <li>Ön egyetért azzal, hogy a Hungarian Fantasy Football használata kizárólag a saját
                            felelősségére
                            történik. Ön egyetért azzal, hogy a Hungarian Fantasy Football "úgy, ahogy van", vagy "ahogy
                            elérhető"
                            alapon biztosított, s ezennel kifejezetten megtagadjuk bármilyen garancia nyújtását, akár
                            kifejezve,
                            akár hallgatólagosan, beleértve, de nem kizárólagosan, a forgalmazhatóságot, a meghatározott
                            célra való
                            alkalmasságot és a jogsértéstől való mentességet.
                        </li>
                        <li>Nem garantáljuk, hogy a Hungarian Fantasy Football megfelel az Ön igényeinek, vagy, hogy a
                            Hungarian
                            Fantasy Football zavartalanul fog működni, pontos, biztonságos vagy hibamentes lesz; sem
                            azt, hogy
                            bármilyen garanciát vállalunk a Hungarian Fantasy Football használatával elérhető
                            eredményekre
                            vonatkozóan vagy a Hungarian Fantasy Football-on szerzett információk pontosságáért vagy
                            megbízhatóságáért, vagy, hogy a Hungarian Fantasy Football-on fellelhető hibák korrigálva
                            lesznek.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc8"></a><h3>A felelősség korlátozása</h3>
                    <ol>
                        <li>A legteljesebb alkalmazható törvény által megengedett mértékig, semmi esetre sem fogjuk
                            hagyni, hogy a
                            mi, a partnereink, az alapítók, tisztviselők, igazgatók, alkalmazottak, ügynökök, a
                            beszállítók, vagy
                            licencpartnerek bármely személyért felelősek legyenek, bármilyen közvetett, véletlenszerű,
                            különleges,
                            büntetőjellegű fedezetért vagy következményes károkért, beleértve, korlátozás nélkül,
                            kártérítés az
                            elmaradt haszonért, bevétel, értékesítés, üzleti kapcsolatok, használat vagy tartalom, hatás
                            a
                            vállalkozásra, üzleti tevékenység megszakadás, várható megtakarítás elvesztése, üzleti
                            lehetőség
                            elvesztése, bármi okozta, semmilyen elméleti felelősség, ideértve korlátozás nélkül
                            szerződést,
                            kártalanítást, garanciát, törvényben előírt kötelezettségszegést, gondatlanságot vagy más
                            módot, még
                            akkor sem, ha értesítést kaptak az ilyen károk lehetőségéről, vagy ilyen károk előre
                            láthatók voltak.
                        </li>
                        <li>Egyes joghatóságok nem teszik lehetővé a véletlen vagy következményes károk kizárását vagy
                            korlátozását,
                            ezért előfordulhat, hogy a jelen 9. Szakasz korlátozása vagy kizárása Önre nem vonatkozik.
                        </li>
                        <li>A Hungarian Fantasy Football használatával Ön tudomásul veszi, hogy harmadik féltől származó
                            beszállítókat használhatunk szoftver, hardver, tárolás, hálózatépítés, valamint egyéb
                            technológiai
                            szolgáltatások nyújtására. Az ilyen harmadik fél beszállítóinak tettei és mulasztásai kívül
                            eshetnek a
                            mi hatáskörünkön. A törvény által megengedett maximális mértékig kizárunk minden
                            felelősséget az ilyen
                            harmadik fél beszállítóinak cselekedeteiből vagy mulasztásaiból eredő veszteségekért vagy
                            károkért.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc9"></a><h3>Rendelkezésre állás</h3>
                    <ol>
                        <li>Ésszerű erőfeszítéseket tettünk annak biztosítására, hogy a Hungarian Fantasy Football
                            mindig elérhető
                            legyen. Mindazonáltal a Hungarian Fantasy Football elérhetőségét befolyásolhatják olyan
                            tényezők,
                            amelyeket nem tudunk ésszerűen irányítani, például a sávszélességi problémákat, a
                            berendezések
                            meghibásodását vagy a vis maioreseményeket, beleértve, de nem kizárólagosan az alábbiakat:
                            Isten
                            cselekedetei; sztrájkok; munkaszünetek; balesetek; háborús vagy terrorcselekmények; polgári
                            vagy katonai
                            zavargások; nukleáris vagy természeti katasztrófák és közbelépések; ellátási hiány és
                            üzemzavarok. Nem
                            vállalunk felelősséget az ilyen tényezők által okozott Hungarian Fantasy Football
                            elérhetetlenségéért.
                            Ha problémák merülnek fel a Hungarian Fantasy Football használata közben, kérjük, lépjen
                            kapcsolatba
                            velünk azonnal.
                        </li>
                        <li> Fenntartjuk magunknak a jogot, hogy saját belátásunk szerint módosítsuk, változtassuk,
                            felfüggesszük,
                            vagy megszüntessük a Hungarian Fantasy Football bármely aspektusát, valamint bármely általa
                            nyújtott
                            szolgáltatást bármikor, beleértve a Hungarian Fantasy Football technikai funkcióit,
                            tartalmát,
                            adatbázisait, elérhetőségét, valamint a szükséges felszerelés specifikációit. Korlátozhatjuk
                            a Hungarian
                            Fantasy Football bizonyos funkcióit, vagy korlátozhatjuk a hozzáférést részekhez, vagy a
                            Hungarian
                            Fantasy Football egészéhez, értesítés vagy felelősség nélkül.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc10"></a><h3>Kártalanítás</h3>
                    <ol>
                        <li>Ön egyetért azzal, hogy nem tehet felelőssé minket, a partnereinket, alapítóinkat,
                            igazgatóinkat,
                            tisztségviselőinket, alkalmazottainkat, valamint az ügynököket semmiféle kötelezettségért,
                            veszteségért,
                            kárért vagy költségért, beleértve az ésszerű ügyvédi díjakat, az azzal kapcsolatban
                            felmerülő vagy
                            bármely harmadik fél állításaiért, igényekért, intézkedésekért, vitákért, vagy
                            bármelyikükkel szemben a
                            Felhasználó Által Létrehozott Tartalom eredményeként vagy azzal kapcsolatosan felmerülő
                            követelésekért,
                            az Ön általi Hungarian Fantasy Football használatáért vagy az Ön részéről történő bármilyen
                            szándékos
                            kötelességszegésért.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc11"></a><h3>Elkülöníthetőség</h3>
                    <ol>
                        <li>Abban az esetben, ha a jelen Feltételek bármely rendelkezése jogellenesnek, semmisnek vagy
                            végrehajthatatlannak minősül, az ilyen rendelkezés az alkalmazandó jog által megengedett
                            legteljesebb
                            mértékben végrehajtható, és a végrehajthatatlan rész e Feltételektől elkülönítettnek
                            tekintendő. Ennek
                            következtében a további rendelkezések érvényességét és végrehajthatóságát nem befolyásolja.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc12"></a><h3>Irányadó jog és vitarendezés</h3>
                    <ol>
                        <li>A kialakítása, értelmezése és teljesítménye ezen Feltételeknek és a Feltételekből fakadó
                            bármilyen vita
                            esetén Svájc, Zürich kanton anyagi és eljárási törvényei irányadóak, tekintet nélkül annak
                            kollíziós
                            szabályaira vagy a jogválasztásra, valamint - amennyire alkalmazható - a svájci törvényekre.
                        </li>
                        <li>A jelen ügy tárgyával kapcsolatos kizárólagos joghatóság és a kereset helye a Svájcban,
                            Bernben
                            található bíróságok.
                        </li>
                        <li>Semmi sem akadályozza a Felhasználót vagy minket abban, hogy olyan ideiglenes intézkedést
                            kérjünk,
                            amelyet szükségesnek tartunk annak érdekében, hogy a status quo-t a jogviták rendezése
                            előtt, bármely
                            joghatóságban megőrizzük.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc13"></a><h3>Átruházás</h3>
                    <ol>
                        <li>Ön nem ruházhatja át, értékesítheti, adhatja tovább, illetve továbbíthatja más módon a jelen
                            Feltételek
                            szerinti jogait vagy kötelezettségeit teljes egészében vagy részben, előzetes írásbeli
                            hozzájárulásunk
                            nélkül, amely a saját belátásunk szerint és kötelezettség nélkül történik; az ilyen megbízás
                            vagy
                            átruházás semmisnek minősül.
                        </li>
                        <li>Fenntartjuk a jogot arra, hogy jogainkat vagy kötelezettségeinket részben vagy egészben
                            bármely harmadik
                            félre átruházzuk, az összes vagy lényegében minden eszközünk vagy részvényünk eladásának
                            vagy egy fúzió
                            részeként.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc14"></a><h3>Időtartam és megszűnés</h3>
                    <ol>
                        <li>Időtartam. Ezek a Feltételek a Feltételek tetején feltüntetett hatálybalépési napon
                            emelkednek erőre, és
                            addig maradnak hatályban, amíg az általunk meg nem szűnik. Fenntartjuk magunknak a jogot,
                            hogy a saját
                            belátásunk szerint bármikor megszűntessük a Hungarian Fantasy Football Feltételeit, a
                            Felhasználó
                            tájékoztatásával. A jelen Feltételek szerinti felhasználói jogok automatikusan megszűnnek,
                            ha a
                            Felhasználó nem tesz eleget a jelen Feltételek bármely rendelkezésének. A Feltételek
                            megszűnése után a
                            Felhasználó számára a Feltételek alapján biztosított minden jog megszűnik, a Felhasználó
                            pedig
                            abbahagyja a Hungarian Fantasy Football használatát.
                        </li>
                        <li>A Feltételek megszegése. Ha saját belátásunk szerint úgy véljük, hogy a Felhasználó megszegi
                            ezeket a
                            Feltételeket, és ez helyénvaló, szükséges vagy kívánatos, akkor mi:
                            <ul>
                                <li>Hivatalos figyelmeztetést küldünk a Felhasználónak;</li>
                                <li>A Felhasználói Fiókot ideiglenesen felfüggesztjük;</li>
                                <li>A Felhasználói Fiókot töröljük;</li>
                                <li>Ideiglenesen vagy véglegesen megtiltjuk a Felhasználó hozzáférését a Hungarian
                                    Fantasy
                                    Football-hoz;
                                </li>
                                <li>A Felhasználót jelentjük a bűnüldöző hatóságoknak; vagy</li>
                                <li>Jogi eljárást indítunk a Felhasználó ellen.</li>
                            </ul>
                        </li>
                        <li>Egyesülés vagy felvásárlás. Abban az esetben, ha a jelen Feltételek alatt, megszerzésre,
                            összevonásra
                            kerül, vagy eladjuk az összes, vagy lényegében az összes eszközt, ezek a Feltételek nem
                            automatikusan
                            szűnnek meg, és vállaljuk, hogy minden erőfeszítést megteszünk annak biztosítása érdekében,
                            hogy az
                            átvevő vagy a túlélő társaság vállalni fogja és a jelen Feltételek rendelkezései kötik.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc15"></a><h3>Változások és módosítások</h3>
                    <ol>
                        <li>Fenntartjuk a jogot, hogy módosítsuk ezeket a Feltételeket, a Hungarian Fantasy Football
                            irányelveket, a
                            Hungarian Fantasy Football-on keresztül nyújtott bármely szolgáltatást, valamint az üzleti
                            gyakorlatunkat (beleértve a marketingpolitikát is) bármikor, és mely a jelen Feltételek
                            frissített
                            változatának közzétételét követően lép hatályba, a Hungarian Fantasy Football-on keresztül,
                            valamint
                            értesítést küldünk Önnek.
                        </li>
                        <li>A Hungarian Fantasy Football további használata az ilyen változások után az Ön beleegyezését
                            jelenti az
                            ilyen változások esetén. Ha nem ért egyet a frissített Feltételek egy vagy több
                            rendelkezésével, kérjük,
                            hagyja abba a Hungarian Fantasy Football használatát.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc16"></a><h3>Lépjen velünk kapcsolatba</h3></li>
            </ul>
            <p>Ha bármilyen kérdése, javaslata, vagy aggályai lennének ezekkel a Feltételekkel, vagy a Hungarian Fantasy
                Football használatával kapcsolatban, kérjük, vegye fel velünk a kapcsolatot.</p><p>
            <strong>E-mail:</strong> <a
            href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a></p><p><strong>Postai cím a
            kommunikációhoz: </strong>Farman-Strasse 47, 8152 Glattpark (Opfikon), Svájc</p><p>***</p>
        </div>
    );
};

export default Terms;