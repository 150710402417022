import React from 'react';
import Select from "react-select";

const LeagueFillStatusSelect = ({form, field, ...rest}) => {
    const options = [
        {value: 'full', label: 'Full'},
        {value: 'available', label: 'Available'}
    ];

    const selectedOption = field.value ?
        options.filter((option) => field.value.includes(option.value))
        : []
    ;

    return (
        <Select
            isMulti={true}
            placeholder='Any'
            value={selectedOption}
            menuPortalTarget={document.body}
            options={options}
            onChange={(options) => {
                if (!options) {
                    options = [];
                }
                form.setFieldValue(field.name, options.map((option) => option.value));
            }}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default LeagueFillStatusSelect;
