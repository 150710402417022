import React from 'react';

import facebookIcon from "img/social/facebook.svg";
import instagramIcon from "img/social/instagram.svg";
import twitterIcon from "img/social/twitter.svg";
import snapchatIcon from "img/social/snapchat.svg";

export default function SocialIcons() {
    return (
        <div className="social-icons">
            <a target="_blank" href="https://www.facebook.com/FantasyFootball.hu/"><img src={facebookIcon} alt="Facebook" style={{width: 32}} /></a>
            <a target="_blank" href="https://www.instagram.com/fantasyfootball.hu/"><img src={instagramIcon} alt="Instagram" style={{width: 32}} /></a>
            {/*<a href="#twitter"><img src={twitterIcon} alt="Twitter" style={{width: 32}} /></a>*/}
            {/*<a href="#snapchat"><img src={snapchatIcon} alt="Snapchat" style={{width: 32}} /></a>*/}
        </div>
    );
}
