import API from "services/API";
import {getCurrentUser} from "services/UserService";
import i18n from "i18next";

export function login(username, password) {
    return API.post(`auth`, {username, password})
        .then((response) => response.data)
        .then((user) => localStorage.setItem('user', JSON.stringify(user)))
    ;
}

export function registerUser(userData) {
    return API.post('user/register', userData)
        .then((response) => response.data)
    ;
}

export function confirmAccount(confirmationToken) {
    return API.post('user/confirm/'+confirmationToken)
        .then((response) => response.data)
    ;
}

export function changePassword(oldPassword, newPassword) {
    return API.post('users/me/change-password', {oldPassword, newPassword})
        .then((response) => response.data)
    ;
}

export function requestEmailChange(newEmail) {
    return API.post('users/me/email-change/request', {newEmail})
        .then((response) => response.data)
    ;
}

export function confirmEmailChange(emailChangeToken) {
    return API.post('users/me/email-change', {emailChangeToken})
        .then((response) => response.data)
    ;
}

export function requestResetPassword(email) {
    return API.post('users/password-reset/request', {email})
        .then((response) => response.data)
    ;
}

export function resetPassword(resetPasswordToken, newPassword) {
    return API.post('users/reset-password', {resetPasswordToken, newPassword})
        .then((response) => response.data)
    ;
}

export function refreshUser() {
    const loggedUser = getUser();

    return getCurrentUser()
        .then((user) => {
            user.token = loggedUser.token;

            if (i18n.language !== user.locale) {
                i18n.changeLanguage(user.locale)
            }

            localStorage.setItem('user', JSON.stringify(user));
            return user;
        })
}

export function getUser() {
    return JSON.parse(localStorage.getItem('user'));
}

export function isLoggedIn() {
    return !!localStorage.getItem('user') && !!JSON.parse(localStorage.getItem('user')).token;
}

export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

export function setPostLoginRoute(route, hash = null) {
    localStorage.setItem('postLoginRoute', route);
    localStorage.setItem('postLoginRouteHash', hash);
}

export function extractPostLoginRoute() {
    const user = getUser();

    const postLoginRouteHash = localStorage.getItem('postLoginRouteHash');
    if (!postLoginRouteHash || (postLoginRouteHash && user.hash === postLoginRouteHash)) {
        const postLoginRoute = localStorage.getItem('postLoginRoute');
        localStorage.removeItem('postLoginRouteHash');
        localStorage.removeItem('postLoginRoute');

        return postLoginRoute;
    }

    return null;
}

export function generateHash()
{
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}