import React, {Component} from 'react';

import {Link, Redirect} from "react-router-dom";
import LeagueSelector from "modules/Dashboard/LeagueSelector";
import {getCurrentLeagueId, setCurrentLeagueId} from "services/UserStateService";
import {deletePendingLeagueMember, getLeagueDashboardRoute} from "services/LeagueService";
import PendingMembershipsList from "modules/Dashboard/PendingMembershipsList";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import {refreshUser} from "services/AuthService";
import {Trans, withTranslation} from "react-i18next";
import WithdrawRequestToJoinModal from "modules/Dashboard/WithdrawRequestToJoinModal";
import {addSuccessNotification} from "services/NotificationService";

class DashboardPage extends Component {
    state = {
        isLoading: true,
        user: null,
        league: null,
        showWithdrawRequestToJoinModal: false,
        selectedPendingLeagueMembership: null,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        this.setState({isLoading: true});
        refreshUser()
            .then((user) => this.setState({user}))
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    }

    findMembership = (user, currentLeagueId) => {
        let foundMembership = null;

        user.leagueMemberships.forEach(leagueMembership => {
            if (leagueMembership.league.id === currentLeagueId) {
                foundMembership = leagueMembership;
            }
        });

        return foundMembership;
    };

    getRedirectRoute = (user) => {
        if (!user) {
            return null;
        }

        if (user.roles.includes("SUPER_ADMIN")) {
            return '/owner';
        }

        let leagueMembership = null;

        //If has league selected
        const currentLeagueId = getCurrentLeagueId();
        if (currentLeagueId) {
            leagueMembership = this.findMembership(user, currentLeagueId);
            if (!leagueMembership) {
                //league is not in user's membership - clear it
                setCurrentLeagueId(null);
            }
        }

        //If only has one league
        if (!leagueMembership && user.leagueMemberships.length === 1) {
            leagueMembership = user.leagueMemberships[0];
        }

        //Membership found - redirect
        if (leagueMembership) {
            setCurrentLeagueId(leagueMembership.league.id);
            return getLeagueDashboardRoute(leagueMembership);
        }

        return null;
    };

    onLeagueSelected(league) {
        setCurrentLeagueId(league.id);
        this.setState({league: league});
    };

    onCancelPendingMembership = (pendingLeagueMembership) => {
        this.setState({
            showWithdrawRequestToJoinModal: true,
            selectedPendingLeagueMembership: pendingLeagueMembership
        });
    };

    onExecuteCancelPendingMembership = ({ league, id}) => {
        this.setState({isLoading: true});
        deletePendingLeagueMember(league.id, id)
            .then(() => {
                addSuccessNotification(
                    this.props.t('Success'),
                    this.props.t('Your request to join league has been withdrawn')
                );
                this.refreshData();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {user, isLoading} = this.state;

        if (isLoading) {
            return <main><Loader /></main>;
        }

        if (!user) {
            return null;
        }

        const redirectRoute = this.getRedirectRoute(user);
        if (redirectRoute) {
            return (<Redirect to={redirectRoute}/>);
        }

        const leagueMemberships = user.leagueMemberships;
        const pendingLeagueMemberships = user.pendingLeagueMemberships;

        return (
            <main>
                <WithdrawRequestToJoinModal
                    isOpen={this.state.showWithdrawRequestToJoinModal}
                    pendingLeagueMembership={this.state.selectedPendingLeagueMembership}
                    handleCloseModal={() => this.setState({showWithdrawRequestToJoinModal: false})}
                    onConfirm={() => this.onExecuteCancelPendingMembership(this.state.selectedPendingLeagueMembership)}
                />

                <h1><Trans>Welcome</Trans></h1>

                <LeagueSelector leagueMemberships={leagueMemberships} onLeagueSelected={(league) => this.onLeagueSelected(league)} />
                <PendingMembershipsList
                    onCancelPendingMembership={this.onCancelPendingMembership}
                    pendingLeagueMemberships={pendingLeagueMemberships}
                />

                <div className="create-or-join">
                    <p className="mb-small"><strong><Trans>Other leagues</Trans>:</strong></p>
                    <Link to="/leagues" className="btn btn-default mb-small"><Trans>Join a League</Trans></Link>
                    <span style={{display: 'inline-block', margin: '0 7px'}}><Trans>or</Trans></span>
                    <Link to="/create-league" className="btn btn-default"><Trans>Create a New League</Trans></Link>
                </div>
            </main>
        );
    }
}

export default withTranslation()(DashboardPage);
