import React, {Component} from "react";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {Redirect} from "react-router-dom";
import CreateLeagueForm from "./CreateLeagueForm";
import {createLeague} from "services/LeagueService";
import {isLoggedIn, refreshUser, setPostLoginRoute} from "services/AuthService";
import {setCurrentLeagueId} from "services/UserStateService";
import {addSuccessNotification, addWarningNotification} from "services/NotificationService";
import {getTeamLogos} from "services/TeamLogoService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import {withTranslation} from "react-i18next";

class CreateLeaguePage extends Component
{
    state = {
        teamLogos: undefined,
        isLoading: true,
        redirectRoute: null,
    };

    componentDidMount = ()  => {
        const {t} = this.props;

        if (!isLoggedIn()) {
            addWarningNotification(
                t('Join a League'),
                t('Please create an account or login first to create a league')
            );

            setPostLoginRoute('/create-league');
            this.setState({redirectRoute: '/register'});
        }

        this.setState({isLoading: true});
        getTeamLogos()
            .then((teamLogos) => this.setState({teamLogos, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false})
            })
        ;
    };

    processData = (values) => {
        const {t} = this.props;
        this.setState({isLoading: true});
        return createLeague(values)
            .then(({id: leagueId}) => {
                refreshUser().then(() => {
                    setCurrentLeagueId(leagueId);
                    addSuccessNotification(t('Success'), t('New League was successfully created'));
                    this.redirectToNextPage();
                });
            })
            .finally(() => this.setState({isLoading: false}))
    };

    redirectToNextPage = () => {
        this.setState({redirectRoute: '/secure'})
    };

    render() {
        const {teamLogos, isLoading, redirectRoute} = this.state;

        if (redirectRoute) {
            return <Redirect to={redirectRoute} />
        }

        if (isLoading) {
            return <Loader/>
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <CreateLeagueForm processData={this.processData} teamLogos={teamLogos} />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(CreateLeaguePage);
