import React from 'react';
import {Trans, withTranslation} from "react-i18next";
import {Cell, Column, Table} from "fixed-data-table-2";
import Dimensions from "react-dimensions";
import PlayerInfoPopup from "modules/Players/PlayerInfoPopup";

class BenchRosterTableRawFixed extends React.Component {
    state = {
        isPlayerPopupOpen: false,
        selectedPlayer: null,
        selectedPlayerScores: null,
    };

    showPlayerPopup = (player, scores) => {
        this.setState({
            isPlayerPopupOpen: true,
            selectedPlayer: player,
            selectedPlayerScores: scores
        })
    };

    hidePlayerPopup = () => {
        this.setState({
            isPlayerPopupOpen: false
        });
    };

    render() {
        let {
            actionsCell, players, scores,
            containerWidth, containerHeight, t
        } = this.props;
        if (!players) {
            return null;
        }

        const SortButton = ({label}) => {
            return <Cell><Trans>{label}</Trans></Cell>;
        };

        const ActionsCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player || player.emptySlot) {
                return null;
            }

            return (<Cell>{actionsCell(player)}</Cell>);
        };

        const AvatarCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }
            return (
                <Cell>
                    <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                        {player.photo && <img className="mb-small mr-small" width={40} src={player.photo}/>}
                    </div>
                </Cell>
            );
        };

        const NameCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player || player.emptySlot===true) {
                return null;
            }

            return (
                <Cell>
                    {player.name}
                </Cell>
            );
        };

        const PlayerInfoCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player.id || player.emptySlot === true) {
                return null;
            }

            return <Cell>
                <button className="btn btn-info btn-danger"
                        onClick={() => this.showPlayerPopup(player, this.props.playersWeeklyScores[player.id])}>
                    <span style={{fontSize: '1.4em'}}>ⓘ</span>
                </button>
            </Cell>
        };

        const PositionCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            if (player.emptySlot) {
                return <Cell><Trans>Available slot</Trans></Cell>;
            }

            return <Cell><Trans>{player.position}</Trans></Cell>;
        };

        const TeamCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            if (!player.team) {
                return null;
            }

            return <Cell>{player.team.name}</Cell>;
        };

        const StatusCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            return <Cell><Trans>{player.status}</Trans></Cell>;
        };

        const ScoreCell = ({rowIndex, columnKey}) => {
            const player = players[rowIndex];
            if (!player || player.emptySlot) {
                return null;
            }

            let playerScores = (scores && scores[player.id] !== undefined) ? scores[player.id] : player.scores;
            if (!playerScores) {
                return <Cell>Z</Cell>;
            }
            if (!columnKey in playerScores) {
                return null;
            }

            return (<Cell className="text-right">{playerScores[columnKey]}</Cell>);
        };


        return (
            <div style={{fontSize: 14}}>
                <PlayerInfoPopup
                    isOpen={this.state.isPlayerPopupOpen}
                    handleCloseModal={this.hidePlayerPopup}
                    player={this.state.selectedPlayer}
                    scores={this.state.selectedPlayerScores}
                />

                <Table
                    rowHeight={50}
                    headerHeight={30}
                    rowsCount={players.length}
                    width={containerWidth}
                    height={containerHeight}
                >
                    {actionsCell !== null && <Column
                        columnKey="actions"
                        width={145}
                        flexGrow={1}
                        cell={ActionsCell}
                        fixed={true}
                    />}

                    <Column
                        columnKey="position"
                        header={<SortButton sortKey="position"
                                            label="Position"/>}
                        width={90}
                        flexGrow={1}
                        cell={PositionCell}
                        fixed={true}
                    />

                    {actionsCell !== null && <Column
                        columnKey="avatar"
                        width={50}
                        cell={AvatarCell}
                        fixed={false}
                    />}

                    <Column
                        columnKey="name"
                        header={<SortButton sortKey="name" label="Name"/>}
                        width={200}
                        flexGrow={2}
                        cell={NameCell}
                        fixed={false}
                    />


                    <Column
                        columnKey="popup"
                        header={<Cell/>}
                        width={35}
                        flexGrow={1}
                        cell={PlayerInfoCell}
                        fixed={false}
                    />

                    <Column
                        columnKey="team"
                        header={<SortButton sortKey="teamName" label="Team"/>}
                        width={145}
                        flexGrow={1}
                        cell={TeamCell}
                        fixed={false}
                    />

                    <Column
                        columnKey="status"
                        header={<SortButton sortKey="status" label="Status"/>}
                        width={145}
                        flexGrow={1}
                        cell={StatusCell}
                    />

                    <Column
                        columnKey="pass_yds"
                        header={<SortButton sortKey="pass_yds"
                                            label="Pass yds"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="rush_yds"
                        header={<SortButton sortKey="rush_yds"
                                            label="Rush yds"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="catch_yds"
                        header={<SortButton sortKey="catch_yds"
                                            label="Catch yds"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />
                    <Column
                        columnKey="td"
                        header={<SortButton sortKey="td" label="TD"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="_2pt"
                        header={<SortButton sortKey="_2pt" label="2pt"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fumble"
                        header={<SortButton sortKey="fumble" label="Fumble"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    {/*<td>{playerScores['int'] ? playerScores['int'] : playerScores['defense_int']}</td>*/}
                    <Column
                        columnKey="int"
                        header={<SortButton sortKey="int" label="INT"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fg"
                        header={<SortButton sortKey="fg" label="FG"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="xp"
                        header={<SortButton sortKey="xp" label="XP"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fg_miss"
                        header={<SortButton sortKey="fg_miss"
                                            label="FG miss"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="xp_miss"
                        header={<SortButton sortKey="xp_miss"
                                            label="XP miss"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fum_forced"
                        header={<SortButton sortKey="fum_forced"
                                            label="Fum forced"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="sack"
                        header={<SortButton sortKey="sack" label="Sack"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="pts_allowed"
                        header={<SortButton sortKey="pts_allowed"
                                            label="Pts allowed"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="win_loss"
                        header={<SortButton sortKey="win_loss"
                                            label="Win Loss"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="pts_gained"
                        header={<SortButton sortKey="pts_gained"
                                            label="Pts gained"/>}
                        width={145}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="score_difference"
                        header={<SortButton sortKey="score_difference"
                                            label="Score difference"/>}
                        width={140}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="points"
                        header={<SortButton sortKey="points"
                                            label="Points"/>}
                        width={60}
                        flexGrow={1}
                        cell={ScoreCell}
                        fixedRight={true}
                    />
                </Table>
            </div>
        );
    }
}


// See react-dimensions for the best way to configure
// https://github.com/digidem/react-dimensions
export default withTranslation()(
    Dimensions({
        getHeight: function(element) {
            return 350;
        }
    })(BenchRosterTableRawFixed)
);