import API from "services/API";
import {formatPositionTitle, getRemainingPositions, groupPlayersByActivityStatus} from "services/PlayerService";

export function getMyRoster(leagueId) {
    return API.get(`/leagues/${leagueId}/rosters/me`)
        .then((response) => response.data)
    ;
}

export function getLeagueRosters(leagueId, seasonId = null) {
    let route = `/leagues/${leagueId}/rosters`;
    if (seasonId) {
        route += '/season/' + seasonId;
    }

    return API.get(route)
        .then((response) => response.data)
    ;
}

export function addPlayerToRoster(playerId, rosterId) {
    return API.post(`rosters/${rosterId}/players/${playerId}`)
        .then((response) => response.data)
    ;
}

export function switchPlayers(player1Id, player2Id, rosterId) {
    return API.post(`rosters/${rosterId}/switch-players/${player1Id}/${player2Id}`)
        .then((response) => response.data)
        ;
}

export function deletePlayerFromRoster(playerId, rosterId) {
    return API.delete(`rosters/${rosterId}/players/${playerId}`)
        .then((response) => response.data)
    ;
}

export function movePlayerToActive(rosterPlayerId, rosterId) {
    return API.post(`rosters/${rosterId}/players/${rosterPlayerId}/move`, {'direction': 'active'})
        .then((response) => response.data)
    ;
}

export function movePlayerToBench(rosterPlayerId, rosterId) {
    return API.post(`rosters/${rosterId}/players/${rosterPlayerId}/move`, {'direction': 'bench'})
        .then((response) => response.data)
        ;
}


export const NB_TOTAL_PLAYERS = 14;
export const NB_BENCH_PLAYERS = 6;
export const NB_ACTIVE_PLAYERS = NB_TOTAL_PLAYERS - NB_BENCH_PLAYERS;

const EXTRA_POSITIONS = {
    TE_WR: ['TE', 'WR'],
    FLEX: ['RB', 'WR', 'TE'],
};

//Returns array containing position + any extra positions it is part of
function expandFantasyPosition(position)
{
    let output = [position];

    const extraPositions = Object.keys(EXTRA_POSITIONS);
    for (const extraPosition of extraPositions) {
        if (EXTRA_POSITIONS[extraPosition].indexOf(position) !== -1) {
            output.push(extraPosition);
        }
    }

    return output;
}

/**
 * Use this function when moving player to bench.
 * If there's available spot on the bench it will return `null`
 * Otherwise it will return a list of players for switching with starting lineup player
 */
export function getMoveToBenchSwitchList(roster, player) {
    let switchList = [];
    const {bench: benchPlayers} = groupPlayersByActivityStatus(roster.players);

    if (benchPlayers.length >= NB_BENCH_PLAYERS) {
        for (const benchPlayer of benchPlayers) {
            const possibleBenchPositions = expandFantasyPosition(benchPlayer.position);

            if (possibleBenchPositions.includes(player.fantasyPosition)) {
                switchList.push(benchPlayer);
            }
        }
    }

    return switchList.length > 0 ? switchList : null;
}

export function getMoveToActiveSwitchList(roster, player) {
    const {active: activePlayers} = groupPlayersByActivityStatus(roster.players);
    const position = player.position;

    const desiredPositions = expandFantasyPosition(position);

    //If one of possible positions is available - use it
    const availablePositions = getRemainingPositions(activePlayers);
    const possiblePositions = availablePositions.filter(position => desiredPositions.includes(position));
    if (possiblePositions.length !== 0) {
        return null;
    }

    //If all desired positions are taken - add them to switch list
    const switchList = activePlayers.filter(({ fantasyPosition }) => desiredPositions.includes(fantasyPosition));

    return (switchList.length > 0 ? switchList : null);
}

export function formatActivityStatus(activityStatus) {
    return (activityStatus === 'active' ? 'Starting lineup' : 'Bench');
}

export function formatPlayerPosition({position, fantasyPosition}) {
    if (!fantasyPosition) {
        return position;
    }

    return formatPositionTitle(fantasyPosition);
}
