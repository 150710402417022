import React, { Component } from 'react';
import { addErrorAsNotification } from "services/ValidationService";
import Loader from "modules/Common/Loader";
import { getGame, updateGameScores } from "services/GameService";
import EditPlayersScoresForm from "modules/Owner/Stats/EditPlayersScoresForm";
import { withRouter } from "react-router-dom"
import Switch from "react-switch";
import { addSuccessNotification } from "services/NotificationService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { withTranslation } from "react-i18next";

class GameStatsPage extends Component {
    state = {
        game: undefined,
        editing: false,
        team1Editable: false,
        team2Editable: false,
        isLoading: true,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        const gameId = this.props.match.params.id;

        this.setState({ isLoading: true });
        getGame(gameId)
            .then((game) => this.setGame(game))
            .catch(({ response }) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({ isLoading: false }))
            ;
    }

    setGame(game) {
        console.log('GAME: ', game);
        this.setState({
            game,
            team1Editable: game.team1Editable,
            team2Editable: game.team2Editable,
        });
    }

    handleSubmit = (values) => {
        this.setState({ isLoading: true });
        console.log('VALUES: ', values);
        updateGameScores(this.props.match.params.id, values)
            .then(() => {
                addSuccessNotification('Success', 'Successfully updated scores');
                this.props.history.push('/owner/stats');
            })
            .catch(({ response }) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({ isLoading: false }))
            ;
    };

    toggleEditing = (checked) => {
        if (checked) {
            //allow editing of everything
            this.setState({
                editing: true,
                team1Editable: true,
                team2Editable: true,
            });
        } else {
            //reset to game defaults
            this.setState({
                editing: false,
                team1Editable: this.state.game.team1Editable,
                team2Editable: this.state.game.team2Editable,
            });
        }
    };

    render() {
        const { game, editing, team1Editable, team2Editable, isLoading } = this.state;
        const showForceEditing = editing || (!team1Editable || !team2Editable);

        if (isLoading && game === undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main className="game-stats-page">
                <div className="mb-default">
                    <Link to="/owner/stats" className="btn btn-sm btn-default">❮ &nbsp; Back to Games</Link>
                </div>

                <div className="clearfix">
                    {showForceEditing && <label className="switch-label float-right">
                        <Switch
                            onChange={this.toggleEditing}
                            checked={this.state.editing}
                            checkedIcon={null}
                            uncheckedIcon={null}
                        />
                        <span>Force Editing</span>
                    </label>}

                    <h1>Game <Moment format="DD/MM/YYYY">{game.date}</Moment></h1>
                </div>

                <LoadingOverlay active={isLoading}>
                    <EditPlayersScoresForm
                        game={game}
                        team1Editable={team1Editable}
                        team2Editable={team2Editable}
                        handleSubmit={this.handleSubmit}
                    />
                </LoadingOverlay>

                <br /><br /><br /><br /><br />
            </main>
        );
    }
}

export default withTranslation()(withRouter(GameStatsPage));
