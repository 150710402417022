import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Loader from "modules/Common/Loader";
import {Trans, withTranslation} from "react-i18next";
import {getTrophy} from "services/TrophyService";
import AppFacebookProvider from "modules/Common/AppFacebookProvider";
import {Initialize, Share} from "react-facebook";

class TrophyPage extends Component {
    state = {
        isLoading: true,
        trophy: null
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        const trophyId = this.props.match.params.trophyId;

        this.setState({isLoading: true});
        getTrophy(trophyId)
            .then((trophy) => {
                this.setState({trophy, isLoading: false})
            })
        ;
    };

    render() {
        const {isLoading, trophy} = this.state;

        if (isLoading) {
            return <main><Loader /></main>;
        }

        // {isLoading ? <Loader/> :
        //     <AppFacebookProvider key="FB">
        //         <div className="trophies">
        //             {trophies && trophies.map((trophy) => <Trophy
        //                 key={trophy.id}
        //                 trophy={trophy}
        //             />)}
        //             <h3>{!trophies.length && <Trans>No trophies yet</Trans>}</h3>
        //         </div>
        //     </AppFacebookProvider>
        // }

        const {t, i18n} = this.props;
        const {id, week, type, fantasyTeam, player} = trophy;

        return (
            <main>
                {/*<AppFacebookProvider key="FB">*/}
                {/*    <Initialize>*/}
                {/*        {({ isReady, api }) => {*/}
                {/*            if (!window.FB) {*/}
                {/*                return null;*/}
                {/*            }*/}

                {/*            window.FB.ui({*/}
                {/*                    method: 'share_open_graph',*/}
                {/*                    action_type: 'og.shares',*/}
                {/*                    action_properties: JSON.stringify({*/}
                {/*                        object: {*/}
                {/*                            'og:title': t(`trophy_${type}`),*/}
                {/*                            'og:description': t(`trophy_description_${type}`),*/}
                {/*                            'og:image': `http://fantasyfootball.hu/img/trophies/${i18n.language}/${type}.png`,*/}
                {/*                            'og:url': "http://fantasyfootball.hu/trophies"*/}
                {/*                        }*/}
                {/*                    })*/}
                {/*                },*/}
                {/*                function (response) {*/}
                {/*                    // Action after response*/}
                {/*                })*/}
                {/*            ;*/}

                {/*            return null;*/}
                {/*        }}*/}
                {/*    </Initialize>*/}
                {/*</AppFacebookProvider>*/}

                <p className="mb-default">

                    <button className="btn btn-primary" type="button" onClick={() => {
                        window.location = `/trophies`;
                    }}>
                        <Trans>Back</Trans>
                    </button>
                </p>

                <div className="trophy wide">
                    <h3><Trans i18nKey={`trophy_${type}`}/></h3>
                    <p>
                        <Trans i18nKey={`trophy_description_${type}`} values={{week}}/>
                    </p>
                    <p>
                        {fantasyTeam.name} {player ? ' - ' + player.name : ''}
                    </p>

                    <img src={`/img/trophies/${i18n.language}/${type}.png`}
                         alt={`Trophy ${type}`}/>

                    <div className="share-row">
                        <AppFacebookProvider key="FB">
                            <Share href={window.location.href}>
                                {({ handleClick, loading }) => (
                                    <button className="btn btn-primary" type="button" onClick={handleClick}>
                                        <Trans>Share</Trans>
                                    </button>
                                )}
                            </Share>
                        </AppFacebookProvider>
                    </div>
                </div>
            </main>
        );
    }
}

export default withTranslation()(withRouter(TrophyPage));
