import API from "services/API";
import {toFormData} from "services/FormService";

export function getCategories() {
    return API.get(`blog-categories`, {
        params: {
        }
    }).then((response) => response.data);
}

export function getTags(filter = null) {
    return API.get(`tags`, {
        params: {
            filter: filter
        }
    }).then((response) => response.data);
}

export function getBlogPost(blogPostId) {
    return API.get(`blog-posts/${blogPostId}`)
        .then((response) => response.data)
    ;
}

export function getBlogPosts(limit = null) {
    return API.get('blog-posts', {params: {limit: limit}})
        .then((response) => response.data)
    ;
}

export function createBlogPost(blogPostData) {
    return API.post('blog-posts', toFormData(blogPostData))
        .then((response) => response.data)
    ;
}

export function updateBlogPost(blogPostId, blogPostData) {
    return API.post(`blog-posts/${blogPostId}`, toFormData(blogPostData))
        .then((response) => response.data)
    ;
}

export function deleteBlogPost(blogPostId) {
    return API.delete('blog-posts/'+blogPostId)
        .then((response) => response.data)
    ;
}
