import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <p><strong>HUNGARIAN FANTASY FOOTBALL ADATVÉDELMI IRÁNYELVEK</strong></p><p><strong>Hatálybalépés napja: </strong>2019.
            október 21. </p><p><em>Adatvédelme fontos a számunkra. Kérjük, olvassa el figyelmesen az adatvédelmi irányelveket
            (az "Adatvédelmi Irányelvek"), mert az részletesen leírja, hogyan gyűjtjük, használjuk fel és hozzuk nyilvánosságra
            személyes adatait, valamint milyen választási lehetőségei vannak az Ön személyes adataival kapcsolatban. </em></p>

            <ol>
                <li><a href="#_Toc">ÁLTALÁNOS INFORMÁCIÓK</a></li>
                <li><a href="#_Toc1">MILYEN SZEMÉLYES ADATOKAT GYŰJTÜNK, ÉS MILYEN CÉLOKRA HASZNÁLJUK FEL ŐKET?</a></li>
                <li><a href="#_Toc2">NEM SZEMÉLYES ADATOK</a></li>
                <li><a href="#_Toc3">MARKETINGKOMMUNIKÁCIÓ</a></li>
                <li><a href="#_Toc4">MENNYI IDEIG ŐRIZZÜK MEG AZ ADATAIT?</a></li>
                <li><a href="#_Toc5">HOGYAN OSZTJUK MEG ÉS HOZZUK NYILVÁNOSSÁGRA AZ ADATOKAT?</a></li>
                <li><a href="#_Toc6">SZEMÉLYI ADATOK NEMZETKÖZI ÁTADÁSA</a></li>
                <li><a href="#_Toc7">A SZEMÉLYES ADATOK VÉDELME</a></li>
                <li><a href="#_Toc8">KORHATÁROK</a></li>
                <li><a href="#_Toc9">A SZEMÉLYES ADATOKKAL KAPCSOLATOS JOGAI</a></li>
                <li><a href="#_Toc10">IDŐTARTAM, MEGSZŰNÉS ÉS MÓDOSÍTÁSOK</a></li>
                <li><a href="#_Toc11">LÉPJEN VELÜNK KAPCSOLATBA</a></li>
            </ol>

            <p><a id="_Toc"></a><h3>1. ÁLTALÁNOS INFORMÁCIÓK</h3></p><p>1.1 <strong>Az
            Adatvédelmi Irányelvekről. </strong>Az Adatvédelmi Irányelvek szabályozzák az egyes felhasználóktól ("Ön") a <a
            href="http://fantasyfootball.hu/">http://fantasyfootball.hu</a> ("Hungarian Fantasy Football") honlapon
            keresztül gyűjtött személyes adatok feldolgozását . Az Adatvédelmi Irányelvek nem terjednek ki a harmadik féltől
            származó alkalmazásokra vagy szoftverre, amelyet integrál a Hungarian Fantasy Football, vagy bármely más, harmadik
            féltől származó termékekre, szolgáltatásokra vagy ügyletekre. </p><p>1.2 <strong>Adatkezelő. </strong>A személyes
            adatainak Hungarian Fantasy Football-on keresztül történő feldolgozásáért felelős adatkezelő [kérjük, adja meg
            teljes nevét], akinek címe: [kérjük, adja meg postai címét (pl. postafiókját vagy üzleti címét)] ("mi"). </p><p>1.3
            <strong>Mi a Hungarian Fantasy Football? </strong>A Hungarian Fantasy Football egy platform, amely hozzáférést
            biztosít a magyarországi amerikai futball nemzeti ligájáról szóló információkhoz, és lehetőséget kínál a más
            Hungarian Fantasy Football felhasználókkal történő kommunikációhoz, beleértve az üzenőfalat, szerencsejátékot,
            csapat alakítást.</p><p>1.4 <strong>Fontos feltételek. </strong>Ebben az Adatvédelmi Szabályzatban ismétlődő
            kifejezésekkel fog találkozni. Az Ön kényelme érdekében szeretnénk elmagyarázni, hogy ezek a feltételek mit
            jelentenek:</p>
            <ul>
                <li>
                    <ul>
                        <li><strong>"Hozzájárulás"</strong> a személyes adatok feldolgozására vonatkozó, szabadon megadott, egyedi,
                            tájékozott és egyértelmű megállapodást jelenti;
                        </li>
                        <li><strong>“Adatkezelő”</strong> az a jogalany, amely meghatározza a személyes adatok feldolgozásának
                            céljait és eszközeit;
                        </li>
                        <li><strong>“Adatfeldolgozó”</strong> az a természetes vagy jogi személy, hatóság, ügynökség vagy egyéb
                            szerv, amely az adatkezelő nevében személyes adatokat dolgoz fel;
                        </li>
                        <li><strong>“Személyes adatok”</strong> bármely olyan természetes személyre vonatkozó információk, akit az
                            ilyen információk (pl. név, cím, e-mail, telefonszám és IP-cím) használatával közvetlenül vagy közvetve
                            azonosítani lehet; és
                        </li>
                        <li><strong>“Feldolgozás”</strong> a személyes adatok bármilyen módon történő felhasználása, beleértve - de
                            nem kizárólagosan - a személyes adatok gyűjtését, tárolását, törlését, továbbítását és nyilvánosságra
                            hozatalát.
                        </li>
                    </ul>
                </li>
            </ul><p>1.5 <strong>Alkalmazandó jogszabályok. </strong>A személyes adatokat az alkalmazandó adatvédelmi jogszabályoknak
            megfelelően dolgozzuk fel, beleértve a Svájci Szövetségi Adatvédelmi Törvényt , valamint az EU Általános Adatvédelmi
            Szabályzatát (GDPR).</p><p>1.6 <strong>Az Ön hozzájárulása az Adatvédelmi Irányelvekhez. </strong>A Hungarian
            Fantasy Football használata a jelen Adatvédelmi Irányelvek hatálya alá tartozik. Mielőtt bármilyen személyes adatot
            adna meg a Hungarian Fantasy Football-on keresztül, javasoljuk, hogy olvassa el ezt az Adatvédelmi Szabályzatot.
            Egyes esetekben (amennyiben az alkalmazandó törvény előírja) kérhetjük az Ön hozzájárulását személyes adatainak
            feldolgozásához. Például kérhetjük előzetes hozzájárulását a következő célokra:</p>
            <ul>
                <li>
                    <ul>
                        <li>Ha más típusú személyes adatokat kívánunk gyűjteni, amelyek nem szerepelnek ebben az Adatvédelmi
                            Szabályzatban;
                        </li>
                        <li>Ha személyes adatait olyan célokra kívánjuk felhasználni, amelyek nem szerepelnek az Adatvédelmi
                            Szabályzatban;
                        </li>
                        <li>Ha személyes adatait olyan harmadik felek számára szeretnénk közzétenni vagy továbbítani, amelyek nem
                            szerepelnek ebben az Adatvédelmi Szabályzatban; vagy
                        </li>
                        <li>Ha jelentősen módosítjuk ezeket az Adatvédelmi Irányelveket.</li>
                    </ul>
                </li>
            </ul><p>1.7 <strong>Sütik. </strong>Sütiket használunk a Hungarian Fantasy Football-on. A sütikkel kapcsolatos részletes
            információkért kérjük, olvassa el a süti szabályzatunkat, mely elérhető a(z) <a href="//fantasyfootball.hu/cookie-policy">fantasyfootball.hu/cookie-policy</a> oldalon.</p>
            <p><a id="_Toc1"></a><h3>2. MILYEN SZEMÉLYES ADATOKAT GYŰJTÜNK, ÉS MILYEN CÉLOKRA HASZNÁLJUK FEL ŐKET?</h3></p>
            <p>2.1<strong> A személyes adatok típusai. </strong>Betartjuk az adatminimalizálási
            elveket, és csak minimális mennyiségű személyes adatot gyűjtünk, ami szükséges a Hungarian Fantasy Football
            megfelelő használatának biztosításához. Az Öntől begyűjtött személyes adatok típusainak listáját az alábbi 2.3.
            Szakasz tartalmazza.</p><p>2.2<strong>A személyes adatok céljai.</strong> Mi személyes adatait kizárólag
            meghatározott, törvényes célokból dolgozzuk fel, amelyeket ez az Adatvédelmi Szabályzat kifejezetten megemlít.
            Röviden, a személyes adatokat kizárólag arra a célra használjuk fel, amely lehetővé teszi, hogy használja a
            Hungarian Fantasy Football-t, ill. a Hungarian Fantasy Football fenntartásához, illetve javításához, kutatások
            végzésére, a mi üzleti tevékenységünkhöz, valamint a kérelmek megvizsgálásához. Személyes adatait semmilyen olyan
            célra nem használjuk fel, amelyek különböznek azoktól a céloktól, amikért az Ön személyes adatai megadásra kerültek.
        </p><p>2.3 <strong> A személyes adatok típusainak és céljainak áttekintése. </strong>Az alábbi táblázat részletesen
            ismerteti az általunk gyűjtött személyes adatok típusait, azokat a célokat, amelyekre felhasználjuk őket, valamint
            azokat a jogalapokat, amelyekre a személyes adatok feldolgozása során támaszkodunk.</p>
            <table className="table">
                <tr>
                    <td><p><strong>Személyes adatok</strong></p></td>
                    <td><p><strong>Cél</strong></p></td>
                    <td><p><strong>Jogalap</strong></p></td>
                </tr>
                <tr>
                    <td><p>Amikor regisztrálja felhasználói fiókját, összegyűjtésre kerülnek az Ön adatai, mint:</p>
                        <ul>
                            <li>Teljes név;</li>
                            <li>E-mail cím;</li>
                            <li>Jelszó;</li>
                            <li>Születési idő;</li>
                            <li>Hely;</li>
                            <li>Nem;</li>
                            <li>Telefonszám; és</li>
                            <li>Ország.</li>
                        </ul>
                        <p>Ha úgy dönt, hogy a Facebook fiókjával regisztrál, akkor hozzáférést kapunk olyan adataihoz, mint:</p>
                        <ul>
                            <li>Keresztnév;</li>
                            <li>Vezetéknév;</li>
                            <li>E-mail cím; és</li>
                            <li>Profilkép.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Felhasználói fiókjának regisztrációjához és fenntartásához;</li>
                            <li>Ahhoz, hogy a hozzáférhessen a Hungarian Fantasy Football teljes funkcionalitásához;</li>
                            <li>A kért szolgáltatások nyújtásához;</li>
                            <li>Szükség esetén az Önnel való kapcsolatba lépéshez; és</li>
                            <li>Hogy elemezzük és javítsuk az üzletünket.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Az Önnel kötött szerződés teljesítése; és</li>
                            <li>Törvényes üzleti érdekeink érvényesítése (üzleti tevékenységünk igazgatása és javítása).</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>Ha egy hozzászólást fűz egy blogbejegyzéshez vagy egy üzenőfalra, akkor összegyűjtjük adatait, úgy
                        mint:</p>
                        <ul>
                            <li>Név; és</li>
                            <li>E-mail cím.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Megjegyzésének vagy üzenetének megjelenítéséhez;</li>
                            <li>A Hungarian Fantasy Football biztonságának biztosítása érdekében;</li>
                            <li>A jogi dokumentumok érvényesítéséhez; valamint</li>
                            <li>Az Önnel való kapcsolatba lépéshez, ha az szükséges.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Az Önnel kötött szerződés teljesítése; és</li>
                            <li>Jogos üzleti érdekeink érvényesítése (a biztonság és a teljesítés biztosítása érdekében).</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>Amikor kapcsolatba lép velünk e-mailben vagy a kapcsolatfelvételi űrlapon keresztül, akkor összegyűjtjük
                        adatait, úgy mint:</p>
                        <ul>
                            <li>Teljes név;</li>
                            <li>E-mail cím; és</li>
                            <li>Minden olyan információ vagy személyes adat, amelyről Ön úgy dönt, hogy az üzenetében biztosít a
                                számunkra.
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Hogy válaszoljunk a kérdéseire.</li>
                            <li>A kért információk biztosításához</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Törvényes üzleti érdekeink érvényesítése (az üzleti tevékenységünk bővítése és előmozdítása)</li>
                            <li>Az Ön hozzájárulása (opcionális személyes adatok esetén).</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>Amikor hírlevélre vagy frissítésekre iratkozik fel, akkor összegyűjtjük adatait, úgy mint:</p>
                        <ul>
                            <li>E-mail cím.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Hogy kézbesítsük a hírlevelet</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Az Ön beleegyezése</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>Amikor használja Hungarian Fantasy Football-t, akkor összegyűjtjük adatait, úgy mint:</p>
                        <ul>
                            <li>IP cím.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Üzleti tevékenységeink elemzése, javítása, értékelése érdekében;</li>
                            <li>Hogy testreszabjuk a Hungarian Fantasy Football-t az Ön tartózkodási helyéhez igazítva; valamint
                            </li>
                            <li>Hogy biztosítsuk a Hungarian Fantasy Football biztonságát.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Törvényes üzleti érdekeink érvényesítése (üzleti tevékenységeink elemzése, javítása, biztonság
                                biztosítása)
                            </li>
                        </ul>
                    </td>
                </tr>
            </table><p>2.4 <strong>További adatok. </strong>Időről időre bizonyos egyéb adatokat kaphatunk, ha Ön részt vesz egy
            fókuszcsoportban, versenyben, tevékenységben vagy eseményen, támogatást kér, kapcsolatba lép a közösségi média
            fiókjainkkal, benyújtja visszajelzéseit, véleményeit vagy egyéb módon kommunikál velünk. Kérjük, vegye figyelembe,
            hogy az ilyen adatok szolgáltatása nem kötelező, így Ön kiválaszthatja, hogy milyen személyes adatokat szeretne
            megosztani velünk. Kérjük, hogy személyes adatainak nyilvánosan történő hozzáférhetővé tételekor gyakoroljon kellő
            gondosságot. Ezeket a személyes adatokat arra fogjuk használni, hogy válaszoljunk Önnek, biztosítsuk Önnek a kért
            szolgáltatásokat, vagy jogos üzleti érdekeink érvényesítésére (azaz üzleti tevékenységünk elemzésére és javítására).
        </p><p>2.5<strong> Érzékeny adatok. </strong>Nem gyűjtünk, semmilyen körülmények között, különleges kategóriákba tartozó
            személyes adatokat ("érzékeny adatok"), pl. az egészségügyi információkat, véleményeket a vallási vagy politikai
            meggyőződéséről, faji eredetről, tagságról szakmai vagy kereskedelmi szövetségben, vagy információkat a szexuális
            orientációiról, kivéve, ha úgy dönt, hogy ilyen érzékeny adatokat oszt meg velünk, a saját belátása szerint. </p><p>
            2.6<strong>Harmadik féltől szerzett személyes adatok.</strong> A Hungarian Fantasy Football használatakor
            engedélyezheti vagy korlátozhatja a harmadik felek által nyújtott szolgáltatásokat, funkciókat, illetve
            integrációkat, például a közösségi médiaszolgáltatókat (együttesen a "Harmadik Fél Szolgáltatások"). Az
            engedélyezést követően a Harmadik Fél Szolgáltatásainak szolgáltatói információkat oszthatnak meg, melyeket Ön
            elérhetővé tett fiókjának beállításain keresztül. Ezeket az információkat kizárólag (i) az Ön által kért
            szolgáltatások nyújtására fogjuk felhasználni, valamint (ii) üzleti tevékenységeink elemzésére és javítására.</p><p>
            2.7<strong>Személyes adatok szolgáltatásának elmulasztása.</strong> Ha nem biztosít számunkra személyes adatokat,
            amikor kérjük, lehet, hogy nem tudjuk elvégezni a kért műveletet, és lehet, hogy nem lesz képes használni a
            Hungarian Fantasy Football-t teljes funkcionalitásában, megkapni a Hungarian Fantasy Football által nyújtott
            szolgáltatásokat, vagy megkapni visszajelzésünket.</p><p>2.8<strong>Személyes adatok nyilvános
            üzenetekben. </strong>Ha úgy dönt, hogy egy blogbejegyzést vagy üzenetet tesz közzé az üzenőfalon, dönthet úgy, hogy
            felfedi bizonyos személyes adatait magáról vagy más személyekről. Kérjük, tartsa szem előtt, hogy az ilyen adatok
            elérhetővé válnak a többi Hungarian Fantasy Football felhasználó számára. Ezért kérjük, hogy kellő gondossággal
            járjon el, és ne tegye közzé személyes adatait, amennyiben nem szükségesek, széleskörűek vagy érzékenyek. Például
            erősen visszatartjuk Önt attól, hogy közzétegye a címét vagy pénzügyi adatait, mivel az ilyen adatokat harmadik
            felek törvénytelen célokra használhatják fel. Továbbá, kérjük, vegye figyelembe, hogy ÖN NEM TEHET közzé személyes
            adatokat más személyekre vonatkozóan, ha nem kapta meg előzetes hozzájárulásukat az ilyen adatok közzétételére.
            Azonnali lépéseket teszünk a Hungarian Fantasy Football-ról történő üzeneteinek vagy észrevételeinek eltávolítására,
            ha tudomásunkra jut, hogy azok jogellenesen nyilvánosságra hozott személyes adatokat tartalmaznak. </p>
            
            <p><a id="_Toc2"></a><h3>3. NEM SZEMÉLYES ADATOK</h3></p><p>3.1<strong>Milyen nem
            személyes adatokat gyűjtünk? </strong>Amikor a Hungarian Fantasy Football-t használja, automatikusan gyűjthetünk
            bizonyos nem személyes adatokat a Hungarian Fantasy Football használatával kapcsolatban elemzési célokra. Az ilyen
            nem személyes adatok nem teszik lehetővé számunkra, hogy bármilyen módon azonosítsuk Önt. Az általunk gyűjtött nem
            személyes adatok az alábbiakra vonatkozó információkat tartalmazzák: (i) a használt eszköz típusa; (ii) az Ön által
            használt operációs rendszer; és (iii) URL-cím, melyre a Hungarian Fantasy Football-on kattintott rá. Kérjük, vegye
            figyelembe, hogy a személyazonosításra alkalmatlan személyes adatok szintén nem személyes adatnak tekintendők.</p>
            <p>3.2<strong>Az Ön visszajelzése</strong>. Ha kapcsolatba lép velünk, nyilvántartást vezethetünk az Ön által feltett
                kérdésekről, panaszokról vagy elismerésekről, valamint a válaszról, ha van ilyen. Amennyiben lehetséges,
                személyazonosításra alkalmatlanná tesszük az Ön személyes adatait. Kérjük, vegye figyelembe, hogy a
                személyazonosításra alkalmatlan személyes adatok szintén nem személyes adatnak tekintendők. </p><p>3.3<strong>Hogyan
            használjuk fel a nem személyes adatokat? </strong>A nem személyes adatokat a Hungarian Fantasy Football
            működtetésével kapcsolatos jogos érdekeink előmozdítása érdekében, üzleti tevékenységeink folytatásához, valamint új
            termékek és szolgáltatások kifejlesztéséhez fogjuk felhasználni. Konkrétabban, a nem személyes adatokat a következő
            célokra gyűjtjük:</p>
            <ul>
                <li>
                    <ul>
                        <li>Hogy elemezzük, milyen felhasználók látogatják meg és használják a Hungarian Fantasy Football-t;</li>
                        <li>A Hungarian Fantasy Football-on elérhető tartalom relevanciájának, népszerűségének és
                            elkötelezettségének vizsgálatához;
                        </li>
                        <li>A biztonsági kérdések és visszaélések kivizsgálásához és megelőzéséhez;</li>
                        <li>A Hungarian Fantasy Football további funkcióinak kifejlesztéséhez és biztosításához; és</li>
                        <li>A Hungarian Fantasy Football személyre szabásához az Ön igényeinek megfelelően.</li>
                    </ul>
                </li>
            </ul><p>3.4<strong> Összesített adatok. </strong>Abban az esetben, ha az Ön nem személyes adatait olyan módon
            kombinálják személyes adatainak bizonyos elemeivel, amely lehetővé teszi számunkra, hogy azonosíthassuk Önt, ezeket
            az összesített adatokat úgy fogjuk kezelni, mint például a személyes adatokat. Ha az Ön személyes adatait olyan
            módon összesítik vagy személyazonosításra alkalmatlanná teszik, hogy az már nem hozható kapcsolatba egy azonosított
            vagy azonosítható természetes személlyel, az nem minősül személyes adatnak, és azt bármilyen üzleti célra
            felhasználhatjuk.</p><p><a id="_Toc3"></a><h3>4. MARKETINGKOMMUNIKÁCIÓ</h3></p>
            <p>4.1<strong> Marketing célú üzenetek. </strong>Annak érdekében, hogy naprakész legyen a Hungarian Fantasy Football-al
                kapcsolatosan, marketing üzeneteket küldhetünk Önnek, például hírleveleket, brosúrákat, promóciókat és reklámokat,
                tájékoztatva Önt az új szolgáltatásainkról vagy a Hungarian Fantasy Football új funkcióiról. Ilyen
                marketingüzeneteket akkor fog kapni vagy akkor veszi fel velünk a kapcsolatot marketing célból, ha:</p>
            <ul>
                <li>
                    <ul>
                        <li>Az Ön kifejezett ("opt-in") hozzájárulását kapjuk a marketing célú üzenetek fogadására (kérjük, vegye
                            figyelembe, hogy az Ön önkéntes feliratkozása hírleveleinkre és frissítéseinkre ilyen hozzájárulásnak
                            minősül); vagy
                        </li>
                        <li>Úgy döntünk, hogy marketing célú üzeneteket küldünk Önnek az új szolgáltatásainkról, amelyek szorosan
                            kapcsolódnak az Ön által már igénybe vett szolgáltatásokhoz.
                        </li>
                    </ul>
                </li>
            </ul><p>4.2<strong> Leiratkozás.</strong> A marketingüzenetek fogadását bármikor ingyenesen lemondhatja, ha rákattint az
            Önnek küldött üzenetek bármelyikében található "leiratkozás" linkre, vagy közvetlenül kapcsolatba lép velünk.</p><p>
            4.3<strong> Tájékoztató és szervizfrissítések.</strong> Időről-időre (ha megvan nekünk az Ön e-mail címe),
            küldhetünk tájékoztató hirdetményeket, mint például a szolgáltatással kapcsolatosan, műszaki vagy adminisztratív
            e-maileket, információkat a Hungarian Fantasy Football-ról, a felhasználói fiókjáról, adatvédelemről és
            biztonságról, valamint egyéb fontos okból kifolyólag. Kérjük, vegye figyelembe, hogy ezeket a hirdetményeket "amikor
            szükséges" alapon fogjuk küldeni, és azok nem tartoznak az Ön előzetes hozzájárulását igénylő közvetlen
            marketingkommunikáció kategóriája alá. </p>
            
            <p><a id="_Toc4"></a><h3>5. MENNYI IDEIG ŐRIZZÜK MEG AZ ADATAIT?</h3></p><p>5.1<strong>A személyes adatok megőrzése. </strong>Személyes adatait csak
            addig tároljuk rendszerünkben, amíg az ilyen személyes adatok szükségesek az Adatvédelmi Irányelvekben leírt
            célokhoz, vagy addig, amíg Ön személyes adatainak törlését nem kéri tőlünk. Miután az Ön személyes adatai már nem
            szükségesek a céljai szempontjából, és nincs más jogalapja annak tárolására (pl. a törvény nem kötelez minket
            személyes adatainak tárolására), azonnal töröljük személyes adatait rendszereinkből.</p><p>5.2<strong>A nem
            személyes adatok megőrzése.</strong> A jelen Adatvédelmi Szabályzatban leírt célokhoz szükséges ideig megtarthatjuk
            az Önre vonatkozó nem személyes adatokat. Ez magában foglalhatja a nem személyes adatok megtartását, miután
            deaktiválta felhasználói fiókját, a számunkra szükséges időtartamra, jogos üzleti érdekek érvényesítéséhez,
            ellenőrzések végzéséhez, jogi kötelezettségeknek (illetve betartásának igazolására) való megfeleléshez, viták
            rendezéséhez, valamint a megállapodások érvényesítéséhez.</p><p>5.3<strong>A törvény által megkövetelt
            megőrzés. </strong>Kérjük, vegye figyelembe, hogy bizonyos esetekben a törvény kötelezhet bennünket arra, hogy
            személyes adatait bizonyos ideig tároljuk. Ilyen esetekben az Ön személyes adatait az alkalmazandó törvényben
            meghatározott ideig tároljuk, a szükséges megőrzési időszak lejártával pedig töröljük a személyes adatokat.</p>
            
            <p><a id="_Toc5"></a><h3>6. HOGYAN OSZTJUK MEG ÉS HOZZUK NYILVÁNOSSÁGRA AZ ADATOKAT?</h3></p><p>6.1 <strong>Nyilvánosságra hozzuk személyes adatait? </strong>Ha szükséges, személyes adatait
            átadjuk azoknak a szolgáltatóknak, akikkel együttműködünk (adatfeldolgozóink). Például megoszthatjuk az Ön személyes
            és nem személyes adatait olyan entitásokkal, amelyek bizonyos technikai támogató szolgáltatásokat nyújtanak nekünk,
            például webanalitikai, reklámozási vagy e-mail küldő szolgáltatásokat, vagy ha kifejezetten kéri, hogy átadjuk a
            személyes adatokat. </p><p>6.2.<strong>Mikor hozzuk nyilvánosságra személyes adatait? </strong>Az Ön személyes
            adatainak közzététele azokra a helyzetekre korlátozódik, amikor az ilyen adatokra a következő célokból van szükség:
        </p>
            <ul>
                <li>
                    <ul>
                        <li>A Hungarian Fantasy Football megfelelő működésének biztosítása;</li>
                        <li>Az Ön által kért szolgáltatások teljesítésének biztosítása;</li>
                        <li>A kért információk biztosítása az Ön számára;</li>
                        <li>Törvényes üzleti érdekeink érvényesítése;</li>
                        <li>Jogaink érvényre juttatása, csalás megakadályozása és biztonsági célok;</li>
                        <li>Szerződéses kötelezettségeink teljesítése;</li>
                        <li>Bűnüldözési célok; vagy</li>
                        <li>Ha Ön előzetes hozzájárulását adja az ilyen nyilvánosságra hozatalhoz.</li>
                    </ul>
                </li>
            </ul><p>6.3<strong>Milyen adatfeldolgozókkal osztjuk meg személyes adatait?</strong> Személyes adatait csak azokkal az
            adatfeldolgozókkal osztjuk meg, amelyek beleegyeznek a személyes adatok megfelelő szintű védelmének biztosításába,
            amely összhangban van ezzel az Adatvédelmi Szabályzattal és az alkalmazandó adatvédelmi jogszabályokkal. Az
            alábbiakban felsoroljuk azokat az adatfeldolgozókat, amelyek hozzáférnek az Ön személyes adataihoz.</p>
            <table className="table">
                <tr>
                    <td><p><strong>Szolgáltatás</strong></p></td>
                    <td><p><strong>Név</strong></p></td>
                    <td><p><strong>Hely</strong></p></td>
                    <td><p><strong>További információk</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Hosting szolgáltató</strong></p></td>
                    <td><p>Amazon Webszolgáltatások</p></td>
                    <td><p>Az Egyesült Államok</p></td>
                    <td><p><a href="https://aws.amazon.com/">https://aws.amazon.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Adatbázis szolgáltató</strong></p></td>
                    <td><p>MySQL</p></td>
                    <td><p>Az Egyesült Államok</p></td>
                    <td><p><a href="https://www.mysql.com/">https://www.mysql.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Analitikai szolgáltató</strong></p></td>
                    <td><p>GoogleAnalytics</p></td>
                    <td><p>Az Egyesült Államok</p></td>
                    <td><p><a href="https://analytics.google.com/">https://analytics.google.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Hírlevél szolgáltató</strong></p></td>
                    <td><p>MailChimp</p></td>
                    <td><p>Az Egyesült Államok</p></td>
                    <td><p><a href="https://www.mailchimp.com/">https://www.mailchimp.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Hirdetési szolgáltató</strong></p></td>
                    <td><p>Google AdSense</p></td>
                    <td><p>Az Egyesült Államok</p></td>
                    <td><p><a href="https://www.google.com/adsense">https://www.google.com/adsense</a></p></td>
                </tr>
            </table><p>6.4<strong>Nem személyes adatok megosztása. </strong>A nem személyes adatokat, illetve a személyazonosításra
            alkalmatlan adatokat bármilyen céllal nyilvánosságra hozhatjuk vagy felhasználhatjuk. Megoszthatjuk például üzleti
            vagy kutatási célokból a potenciális ügyfelekkel vagy partnerekkel, a Hungarian Fantasy Football fejlesztéséért, a
            hatóságok jogszerű kéréseinek megválaszolásáért vagy új termékek és szolgáltatások fejlesztéséért. </p><p>
            6.5<strong>Jogi kérelmek.</strong> Ha szükséges, nyilvánosságra fogjuk hozni a Hungarian Fantasy Football
            felhasználóiról szóló információkat a közérdekű cél eléréséhez szükséges mértékben, mint például nemzetbiztonsági
            vagy bűnüldözési célból.</p><p>6.6<strong>Jogutódok.</strong> Abban az esetben, ha üzleti tevékenységünket részben
            vagy teljes egészében értékesítjük, személyes adatait átadjuk a vevőnek vagy jogutódnak, és felkérjük az jogutódot,
            hogy az Ön személyes adatait a jelen Adatvédelmi Szabályzattal összhangban kezelje.</p>
            <p><a id="_Toc6"></a><h3>7. SZEMÉLYI ADATOK NEMZETKÖZI ÁTADÁSA</h3></p><p>Az
            Adatvédelmi szabályzat 6. Szakaszában felsorolt adatfeldolgozóink egy része az Ön lakóhelye szerinti országon kívül
            található. Amennyiben Ön az Európai Gazdasági Térségben (EGT) tartózkodik, személyes adatait lehet, hogy át kell
            adnunk az EGT-n kívüli joghatóságoknak. Abban az esetben, ha szükséges az ilyen átruházás, akkor győződjön meg
            arról, hogy a hatáskör, amelyben a kedvezményezett harmadik fél található, megfelelő szintű védelmet biztosít a
            személyes adatokat tekintve (pl. a címzett egy Privacy-Shield tanúsítvánnyal rendelkező szervezet) vagy
            megállapodást kötünk a megfelelő harmadik féllel, hogy biztosítsa az ilyen védelmet (pl. adatfeldolgozási
            megállapodás alapján előzetesen jóváhagyott általános szerződési feltételek).</p>
            <p><a id="_Toc7"></a><h3>8. A SZEMÉLYES ADATOK VÉDELME</h3></p><p>8.1 <strong>Biztonsági
            intézkedéseink.</strong> Minden tőlünk telhetőt megteszünk, hogy személyes adatait biztonságban tartsuk. Az Ön
            személyes adatainak elvesztésével, az azokkal való visszaéléssel, jogosulatlan hozzáféréssel és nyilvánosságra
            hozatalával szembeni védelmére szervezeti és technikai biztonsági intézkedéseket alkalmazunk. Az általunk hozott
            biztonsági intézkedések közé tartoznak a biztonságos hálózatok, a titkosítás, az SSL, az erős jelszavak, a személyes
            adatokhoz való korlátozott hozzáférés munkatársaink által, valamint a személyes adatok anonimizálása (ha
            lehetséges). Személyes adatainak biztonsága érdekében kérjük, hogy kizárólag biztonságos hálózaton keresztül
            használja a Hungarian Fantasy Football-t. </p><p>8.2<strong>A biztonsági rések kezelése.</strong> Bár a lehető
            legtöbbet tesszük meg személyes adatai védelmének érdekében, mégis a kommunikáció és információ-feldolgozó
            technológia ill. az internet természetéből adódóan, nem vagyunk felelőssé tehetők semmilyen a személyes adatok
            jogtalan megsemmisítése, elvesztése, használata, másolása, módosítása, szivárogtatása, illetve hamisítása okozta
            olyan körülmények által, amelyeket nem tudunk ésszerűen befolyásolni. Súlyos jogsértés esetén haladéktalanul
            értesítjük a helyi adatvédelmi hatóságunkat, nevezetesena Szövetségi Adatvédelmi és Információs Biztost (FDPIC), és
            azonnal meghozzuk a megfelelő intézkedéseket a jogsértés enyhítésére, amint azt az alkalmazandó törvény előírja. A
            biztonsági előírások megsértéséért való felelősségünk az alkalmazandó jogszabályok által megengedett legmagasabb
            mértékre korlátozódik. </p>
            
            <p><a id="_Toc8"></a><h3>9. KORHATÁROK</h3></p><p>Nem
            engedjük, hogy 16 évnél fiatalabb személy használja a Hungarian Fantasy Football-t. Ezért tudatosan nem gyűjtünk
            személyes adatokat 16 év alatti személyekről. Ha megtudja, hogy 16 évesnél fiatalabb személy jogellenesen adott át
            nekünk személyes adatokat, és Ön ennek a személynek a szülője vagy törvényes gyámja, lépjen kapcsolatba velünk, és
            azonnali lépéseket teszünk az ilyen személyes adatok törlésére.</p>
            
            <p><a id="_Toc9"></a><h3>10. A SZEMÉLYES ADATOKKAL KAPCSOLATOS JOGAI</h3></p><p>10.1 <strong>Milyen jogai vannak
            Önnek? </strong>A törvény által biztosított mentességekre is figyelemmel, megkérhet minket, hogy:</p>
            <ul>
                <li>
                    <ul>
                        <li>Másolatot szerezzen be az általunk tárolt személyes adatairól;</li>
                        <li>Beszerezzen egy a személyes adatok feldolgozásának céljait tartalmazó listát;</li>
                        <li>A pontatlan személyes adatok helyesbítése;</li>
                        <li>Személyes adatainak egy másik feldolgozóhoz való áthelyezése;</li>
                        <li>Személyes adatainak törlése rendszereinkből;</li>
                        <li>Ellenezze és korlátozza személyes adatainak kezelését;</li>
                        <li>Visszavonja beleegyezését, ha Ön korábban biztosította azt; vagy</li>
                        <li>A személyes adataival kapcsolatos panaszának feldolgozása.</li>
                    </ul>
                </li>
            </ul><p>10.2 <strong>Hogyan gyakorolhatja jogait?</strong> Ha szeretné gyakorolni a fent felsorolt jogait, kérjük, vegye
            fel velünk a kapcsolatot e-mailben a(z) <a href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a> címen,
            és részletezze kérését. Kérése legitimitásának igazolására kérhetjük, hogy adjon meg nekünk egy azonosítható
            információt, hogy azonosíthassuk Önt a rendszerünkben. A kérését ésszerű határidőn belül, de legkésőbb 2 héten belül
            megválaszoljuk.</p><p>10.3<strong> Hogyan lehet panaszt benyújtani?</strong> Ha panaszt szeretne tenni személyes
            adatainak kezelésével kapcsolatban, kérjük, vegye fel velünk a kapcsolatot először, hogy kifejezze aggályait. Miután
            kapcsolatba lépett velünk, kivizsgáljuk panaszát,és a lehető leghamarabb választ adunk Önnek. Ha nem elégedett a
            panaszának kimenetelével, joga van panaszt tenni a helyi adatvédelmi hatóságnál.</p>
            <p><a id="_Toc10"></a><h3>11. IDŐTARTAM, MEGSZŰNÉS ÉS MÓDOSÍTÁSOK</h3></p><p>
            11.1<strong>Időtartam és megszűnés. </strong>Ez az Adatvédelmi Irányelvek az Adatvédelmi Szabályzat tetején
            feltüntetett hatálybalépési napon lép életbe, és mindaddig érvényes marad, amíg általunk megszüntetésre vagy
            frissítésre nem kerül.</p><p>11.2<strong>Módosítások.</strong> Az Adatvédelmi Irányelvek időről időre módosíthatók a
            törvényekben, rendeletekben és ipari szabványokban bekövetkezett változások kezelése érdekében. Az Adatvédelmi
            Irányelvek módosított változatát ezen az oldalon fogjuk közzétenni, és ha rendelkezünk az Ön e-mail címével,
            értesíteni fogjuk Önt az általunk végrehajtott változásokról. Javasoljuk, hogy tekintse át Adatvédelmi
            Irányelveinket, hogy folyamatosan tájékozott maradjon. Az Adatvédelmi Irányelvekben bekövetkezett jelentős
            változások esetén, vagy amennyiben az alkalmazandó törvény előírja, az Ön beleegyezését kérhetjük. Ha nem ért egyet
            az Adatvédelmi Irányelvek módosításaival, abba kell hagynia a Hungarian Fantasy Football használatát. </p>
            <p><a id="_Toc11"></a><h3>12. LÉPJEN VELÜNK KAPCSOLATBA</h3></p><p>Kérjük, vegye
            fel velünk a kapcsolatot, ha bármilyen kérdése van az Adatvédelmi Irányelvekkel, az adatvédelmi gyakorlatunkkal,
            illetve az Adatvédelmi Szabályzat 10. Szakaszában felsorolt jogaival kapcsolatban. Az alábbi elérhetőségeken léphet
            velünk kapcsolatba:</p><p><strong>E-mail:</strong> <a
            href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a></p><p><strong>Postai cím a
            kommunikációhoz: </strong>Farman-strasse 47, 8152 Glattpark (Opfikon), Svájc</p><p><strong>***</strong></p>
        </div>
    );
};

export default PrivacyPolicy;