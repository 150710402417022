import API from "services/API";

export function getPossiblePages() {
    return {
        'create_league': 'Create League',
        'join_league': 'Join League',
        'league': 'League',
        'team': 'Team',
        'game_center': 'Game Center',
        'players': 'Players',
        'trophy_room': 'Trophy Room',
        'blog': 'Blog',
        'leaderboard': 'Leaderboard',
        'faq': 'FAQ',
    };
}

export function getPossiblePlacements() {
    return {
        'top': 'Top',
        'right': 'Right',
    };
}

export function getPageNameByLocation(location) {
    if (location.match(/^\/blog(\/\d+)?$/)) {
        return 'blog';
    }

    if (location.match(/^\/league-/)) {
        return 'league';
    }

    if (location === '/players') {
        return 'players';
    }

    if (location === '/team') {
        return 'trophy_room';
    }

    if (location === '/game-center') {
        return 'game_center';
    }

    if (location === '/trophies') {
        return 'trophy_room';
    }

    if (location === '/leaderboard') {
        return 'leaderboard';
    }

    return null;
}

export function getAds() {
    return API.get('ads')
        .then((response) => response.data)
        .then((ads) => {
            //Group by page and placement
            let groupped = {};
            for (const ad of ads) {
                const {page, placement} = ad;
                if (groupped[page] === undefined) {
                    groupped[page] = {};
                }
                groupped[page][placement] = ad;
            }

            return groupped;
        })
    ;
}

export function getAdByPageAndPlacement(page, placement) {
    return API.get(`ads/${page}/${placement}`)
        .then((response) => response.data)
    ;
}

export function upsertAd(adData) {
    let formData = new FormData();
    for (let key in adData) {
        formData.append(key, adData[key] !== null ? adData[key] : '');
    }

    return API.post('ads', formData, {
        headers: {'Content-Type': 'multipart/form-data'}}
    ).then((response) => response.data);
}
