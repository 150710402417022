import moment from "moment";
import 'moment/min/locales';

export function formatDateTime(isoDate, format = 'L LT', timezone = 'Europe/Budapest') {
    moment.locale('en_GB');
    return moment.tz(isoDate, timezone).format(format);
}

export function combineDateAndTime(date, timeStr, timezone) {
    if (!date) {
        return null;
    }

    date = moment.tz(date, timezone);

    timeStr = timeStr ? timeStr : '12:00am';
    const time = moment(timeStr, 'HH:mma', 'en');

    date.set({
        hour:   time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second')
    });

    return date;
}
