import React, {Component} from 'react';
import {finishSeason, finishWeek, freezeCurrentWeek, recalculatePoints, startNextWeek} from "services/SeasonService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import StatsSection from "modules/Owner/Stats/StatsSection";
import PlayersPointsTable from "modules/Owner/Stats/PlayersPointsTable";
import {Link, withRouter} from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";
import LockWeekConfirmationModal from "modules/Owner/Stats/LockWeekConfirmationModal";
import FreezeWeekConfirmationModal from "modules/Owner/Stats/FreezeWeekConfirmationModal";
import FinishSeasonConfirmationModal from "modules/Owner/Stats/FinishSeasonConfirmationModal";
import {getSelectedSeason} from "services/UserStateService";
import SeasonSwitcher from "modules/Owner/Common/SeasonSwitcher";
import {getSettings} from "services/SettingService";
import GameDateModal from "modules/Owner/Stats/GameDateModal";
import {changeGameDate} from "services/GameService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {getPlayersPoints} from "services/PlayerService";
import {withTranslation} from "react-i18next";
import {groupGamesByRound} from "services/GameService";

class StatsPage extends Component {
    state = {
        season: undefined,
        timezone: undefined,
        selectedWeek: undefined,
        games: [],
        isLoading: true,
        showEditDateConfirmationModal: false,
        showLockWeekConfirmationModal: false,
        showFreezeWeekConfirmationModal: false,
        showFinishSeasonConfirmationModal: false,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        this.setState({isLoading: true});
        getSelectedSeason()
            .then((season) => {
                const requests = Promise.all([
                    getSettings(),
                    getPlayersPoints(season.id)
                ]);

                requests
                    .then(([settings, playersPoints]) => {
                        this.setSeason(season);
                        this.setState({playersPoints, timezone: settings.timezone, isLoading: false})
                    })
                    .catch(({response}) => {
                        addErrorAsNotification(response, this.props.t);
                        this.setState({season: null, isLoading: false});
                    })
                ;
            })
            .catch(() => {
                this.setState({season: null, games: [], isLoading: false});
            })
        ;
    }

    setSeason(season) {
        let games = [];
        if (season && season.games) {
            games = groupGamesByRound(season.games);
        }

        this.setState({season, games});
    }

    onStartWeek() {
        this.setState({isLoading: true});
        startNextWeek()
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Week started'
                );
                this.refreshData()
            })
    }

    //EDIT GAME DATE
    showEditDateConfirmationModal = (game) => {
        this.setState({showEditDateConfirmationModal: true, selectedGame: game});
    };

    hideEditDateConfirmationModal = () => {
        this.setState({showEditDateConfirmationModal: false});
    };

    onEditDate = (game) => {
        this.showEditDateConfirmationModal(game);
    };

    onExecuteEditDate = (game, date) => {
        this.setState({isLoading: true});
        return changeGameDate(game.id, date.format('YYYY-MM-DD'))
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Game date successfully changed'
                );
                this.refreshData()
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    //LOCK
    showLockWeekConfirmationModal = (week) => {
        this.setState({showLockWeekConfirmationModal: true, selectedWeek: week});
    };

    hideLockWeekConfirmationModal = () => {
        this.setState({showLockWeekConfirmationModal: false});
    };

    onLockWeek = (week) => {
        this.showLockWeekConfirmationModal(week);
    };

    onExecuteLockWeek = (week) => {
        this.setState({isLoading: true});
        finishWeek(week)
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Week was successfully locked'
                );
                this.refreshData()
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    //FREEZE
    showFreezeWeekConfirmationModal = () => {
        this.setState({showFreezeWeekConfirmationModal: true});
    };

    hideFreezeWeekConfirmationModal = () => {
        this.setState({showFreezeWeekConfirmationModal: false});
    };

    onFreezeWeek = () => {
        this.showFreezeWeekConfirmationModal();
    };

    onExecuteFreezeWeek = () => {
        this.setState({isLoading: true});
        freezeCurrentWeek()
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Week was successfully freezed'
                );
                this.refreshData()
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    //Finish season
    showFinishSeasonConfirmationModal = () => {
        this.setState({showFinishSeasonConfirmationModal: true});
    };

    hideFinishSeasonConfirmationModal = () => {
        this.setState({showFinishSeasonConfirmationModal: false});
    };

    onFinishSeason = () => {
        this.showFinishSeasonConfirmationModal();
    };

    onRecalculatePoints = () => {
        recalculatePoints()
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Points were recalculated'
                );
                this.refreshData()
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    }

    onExecuteFinishSeason = () => {
        this.setState({isLoading: true});
        finishSeason()
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Season was successfully finished'
                );
                this.refreshData()
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {season, playersPoints, timezone, games, showEditDateConfirmationModal, showLockWeekConfirmationModal, showFreezeWeekConfirmationModal, showFinishSeasonConfirmationModal, isLoading} = this.state;

        if (season===undefined && isLoading) {
            return <main><Loader /></main>;
        }

        if (!season) {
            return (
                <main>
                    <div className="season-switcher">
                        <SeasonSwitcher />
                    </div>

                    <h1>Statistics</h1>
                    <h3>No data available</h3>
                </main>
            )
        }

        const isOngoingSeason = (season.status === 'ongoing');
        return (
            <main>
                <div className="season-switcher">
                    <SeasonSwitcher />
                </div>
                <h1>Statistics</h1>

                <LoadingOverlay active={isLoading}>
                    {isOngoingSeason && <div className="mb-default">
                        <strong>Current Round:</strong> {season.currentWeek ? season.currentWeek+' '+(season.isWeekActive ? "(ongoing)" : "(finished)") : '-'}

                        <br/><br/>

                        {!season.isWeekActive && season.currentWeek<season.nbWeeks &&
                            <button onClick={() => this.onStartWeek()} className="mr-small btn btn-sm btn-primary">Start Round {season.currentWeek + 1}</button>
                        }
                    </div>}

                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}} className="mr-default">
                            {season.status === 'ongoing' && <Link to="/owner/season/edit-schedule" className="btn btn-sm btn-primary" style={{float: 'right'}}>
                                Edit Schedule
                            </Link>}

                            <h3 className="mb-default">Games statistics</h3>
                            <StatsSection
                                season={season}
                                timezone={timezone}
                                weeklyGames={games}
                                onEditDate={this.onEditDate}
                                onLockWeek={this.onLockWeek}
                                onFreezeWeek={this.onFreezeWeek}
                                onFinishSeason={this.onFinishSeason}
                            />
                        </div>

                        <div>
                            <div className="d-flex">
                                <h3 className="mb-default flex-grow-1">Players points</h3>

                                <div>
                                    <button onClick={() => this.onRecalculatePoints()} className="mr-small btn btn-sm btn-primary">
                                        Recalculate
                                    </button>
                                </div>
                            </div>

                            <PlayersPointsTable
                                playersPoints={playersPoints}
                                nbRounds={season.nbWeeks}
                            />
                        </div>
                    </div>
                </LoadingOverlay>

                <GameDateModal
                    isOpen={showEditDateConfirmationModal}
                    game={this.state.selectedGame}
                    handleCloseModal={() => this.hideEditDateConfirmationModal()}
                    onSubmit={this.onExecuteEditDate}
                />

                <LockWeekConfirmationModal
                    isOpen={showLockWeekConfirmationModal}
                    week={this.state.selectedWeek}
                    handleCloseModal={() => this.hideLockWeekConfirmationModal()}
                    onConfirm={this.onExecuteLockWeek}
                />

                <FreezeWeekConfirmationModal
                    isOpen={showFreezeWeekConfirmationModal}
                    handleCloseModal={() => this.hideFreezeWeekConfirmationModal()}
                    onConfirm={() => this.onExecuteFreezeWeek()}
                />

                <FinishSeasonConfirmationModal
                    isOpen={showFinishSeasonConfirmationModal}
                    handleCloseModal={() => this.hideFinishSeasonConfirmationModal()}
                    onConfirm={() => this.onExecuteFinishSeason()}
                />
            </main>
        );
    }
}

export default withTranslation()(withRouter(StatsPage));
