import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function AcceptPendingMemberConfirmationModal({pendingMember, onConfirm, ...rest}) {
    if (!pendingMember) {
        return null;
    }

    const name = pendingMember.displayName;
    return (
        <ConfirmationModal
            title={"Accept request to join your league?"}
            content={"Do you want to accept request to join your league from "+name+"?"}
            buttons={{
                yes: 'Yes, accept',
                no: 'Cancel'
            }}
            type="creative"
            onConfirm={() => onConfirm(pendingMember)}
            {...rest}
        />
    );
}

export default withTranslation()(AcceptPendingMemberConfirmationModal);
