import React, {Component} from 'react';
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {addErrorAsNotification} from "services/ValidationService";
import {addSuccessNotification} from "services/NotificationService";
import {withRouter} from "react-router-dom";
import {getAdByPageAndPlacement, upsertAd} from "services/AdsService";
import FileForm from "modules/Owner/Files/FileForm";
import TeamLogoForm from "modules/Owner/Files/TeamLogoForm";
import {createTeamLogo, deleteTeamLogo, getTeamLogos} from "services/TeamLogoService";
import Loader from "modules/Common/Loader";
import {withTranslation} from "react-i18next";

class FilesPage extends Component {
    state = {
        isLoading: true,
        teamLogos: undefined,

        activePage: null,
        activePlacement: null,
        activeAd: null,
    };

    componentDidMount() {
        this.refreshTeamLogos();
    }

    refreshTeamLogos() {
        this.setState({isLoading: true});
        getTeamLogos()
            .then((teamLogos) => {
                this.setState({teamLogos, isLoading: false});
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    }

    onSaveFile = (fileData) => {
        return upsertAd(fileData)
            .then(() => {
                this.setState({isLoading: false});
                addSuccessNotification('Success', 'Ad was successfully saved!');
                return this.refreshAd();
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onFormChange = ({page, placement}) => {
        const {activePage, activePlacement} = this.state;

        if (activePage === page && activePlacement === placement) {
            return null;
        }

        let newState = {
            activePage: page,
            activePlacement: placement,
            activeAd: {
                page: page,
                placement: placement,
                file: null,
                url: '',
            },
        };

        if (!page || !placement) {
            return null;
        }

        newState.isLoading = true;
        this.setState(newState);

        getAdByPageAndPlacement(page, placement)
            .then((activeAd) => {
                this.setState({activeAd, isLoading: false});
            })
            .catch(({response}) => {
                if (response.status !== 404) {
                    addErrorAsNotification(response, this.props.t);
                }
                this.setState({isLoading: false});
            })
        ;
    };

    refreshAd = () => {
        const {activePage, activePlacement} = this.state;

        if (!activePage || !activePlacement) {
            return;
        }

        this.setState({isLoading: true});
        return getAdByPageAndPlacement(activePage, activePlacement)
            .then((activeAd) => {
                this.setState({activeAd, isLoading: false});
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onCreateTeamLogo = (teamLogoData) => {
        return createTeamLogo(teamLogoData)
            .then(() => {
                this.setState({isLoading: false});
                addSuccessNotification('Success', 'Team logo was successfully uploaded!');
                return this.refreshTeamLogos();
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onDeleteTeamLogo = (teamLogo) => {
        deleteTeamLogo(teamLogo.id)
            .then(() => {
                this.setState({isLoading: false});
                addSuccessNotification('Success', 'Team logo was successfully deleted!');
                return this.refreshTeamLogos();
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {isLoading, teamLogos, activeAd} = this.state;

        if (isLoading && teamLogos === undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <h1>Files</h1>

                    <div className="mb-default">
                        <h3 className="mb-small">Team Logos</h3>
                        <div className="team-logos">
                            {teamLogos.map((teamLogo) => (
                                <div key={teamLogo.id} className="logo">
                                    <img alt="" src={teamLogo.image} />

                                    <input type="button" className="btn btn-sm btn-default" value="✕"
                                           onClick={() => this.onDeleteTeamLogo(teamLogo)} />
                                </div>
                            ))}
                        </div>
                        <div>
                            <TeamLogoForm processData={this.onCreateTeamLogo} />
                        </div>
                    </div>

                    <h3 className="mb-small">Ads</h3>
                    <div className="files-section">
                        <div className="col">

                            <FileForm
                                key={activeAd ? activeAd.id : 0}
                                ad={activeAd}
                                processData={this.onSaveFile}
                                onChange={this.onFormChange}
                            />
                        </div>
                        <div className="col">
                            {(activeAd !== null) ?
                                (activeAd.image
                                    ? <img alt="" style={{height: '40rem'}} src={activeAd.image} />
                                    : <h3>No Image</h3>
                                )
                                : null
                            }
                        </div>
                    </div>
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(FilesPage));
