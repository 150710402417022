import React, {Component} from 'react';
import DateRangeSelector from "modules/LeagueAdmin/Dashboard/DateRangeSelector";
import moment from "moment";

export default class TestPage extends Component {
    render() {
        return (
            <div>
                <DateRangeSelector
                    startDate={moment()}
                    onDateChange={({startDate}) => console.log(startDate)}
                />
            </div>
        );
    }
}
