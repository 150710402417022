import React, {Component} from 'react';
import EditTeamForm from "modules/LeagueAdmin/EditTeam/EditTeamForm";
import {withRouter} from "react-router-dom";
import {addErrorAsNotification, normalizeGeneralError} from "services/ValidationService";
import {addErrorNotification, addSuccessNotification} from "services/NotificationService";
import Loader from "modules/Common/Loader";
import {acceptInvitation, getLeague, requestToJoinLeague} from "services/LeagueService";
import {setCurrentLeagueId} from "services/UserStateService";
import {getTeamLogos} from "services/TeamLogoService";
import {withTranslation} from "react-i18next";

class CreateTeamPage extends Component {
    state = {
        league: null,
        isLoading: true
    };

    componentDidMount() {
        const leagueId = this.props.match.params.id;
        if (!leagueId || !this.props.location.state.intent) {
            this.props.history.push('/secure');
            return;
        }

        this.setState({isLoading: true});
        const requests = Promise.all([
            getLeague(leagueId),
            getTeamLogos()
        ]);

        //fetch all at once
        requests
            .then(([league, teamLogos]) => this.setState({league, teamLogos, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false})
            })
        ;
    }

    processData = (values) => {
        const {t} = this.props;
        const {intent} = this.props.location.state;
        const {league} = this.state;

        if (intent === 'JOIN_LEAGUE') {
            this.setState({isLoading: true});
            return requestToJoinLeague(league.id, values)
                .then(({ status }) => {
                    if (status === 'active') {
                        addSuccessNotification(
                            t('Success'),
                            t('Your have joined {{leagueName}}!', {leagueName: league.name}),
                        );
                    } else {
                        addSuccessNotification(
                            t('Your request to join {{leagueName}} was successfully sent!', {leagueName: league.name}),
                            t('You will receive e-mail when league administrator confirms your membership')
                        );
                    }
                    this.props.history.push('/secure');
                })
                .catch(({response}) => {
                    addErrorNotification(t('Error'), normalizeGeneralError(response));
                })
                .finally(() => {
                    this.setState({isLoading: false});
                })
            ;
        } else if (intent === 'ACCEPT_INVITATION') {
            this.setState({isLoading: true});
            return acceptInvitation(this.props.location.state.confirmationToken, values)
                .then(() => {
                    addSuccessNotification(
                        t('Your have accepted invitation to join the league'),
                        t('Enjoy the game!')
                    );

                    setCurrentLeagueId(league.id);
                    this.props.history.push('/secure');
                })
                .catch(({response}) => addErrorNotification(t('Error'), normalizeGeneralError(response)))
                .finally(() => {
                    this.setState({isLoading: false});
                })
        }

        throw "Unrecognized intent";
    };

    render() {
        const {league, teamLogos, isLoading} = this.state;

        if (isLoading) {
            return <main><Loader isActive={isLoading} /></main>
        }

        if (!league) {
            return null;
        }

        return (
            <main>
                <EditTeamForm
                    processData={this.processData}
                    teamLogos={teamLogos}
                    onCancel={() => this.props.history.push('/settings')}
                />
            </main>
        );
    }
}

export default withTranslation()(withRouter(CreateTeamPage));
