import React from 'react';
import CookiePolicy_en from "./html/en/CookiePolicy";
import CookiePolicy_hu from "./html/hu/CookiePolicy";
import {withTranslation} from "react-i18next";

function CookiePolicyPage({ i18n }) {
    return (
        <main className="content-page">
            {i18n.language === 'en' ? <CookiePolicy_en/> : <CookiePolicy_hu/>}
        </main>
    );
}

export default withTranslation()(CookiePolicyPage);
