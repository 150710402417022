import React from 'react';

import {Trans, withTranslation} from "react-i18next";

const TradeProposalsTable = ({ tradePlayersProposals, team, onAccept, onReject, onWithdraw, t }) => {

    return (
        <div className="table-responsive">
            <table className="table table-bordered mb-default">
            <thead>
                <tr>
                    <th><Trans>Team</Trans>1</th>
                    <th><Trans>Player</Trans>1</th>
                    <th><Trans>Team</Trans>2</th>
                    <th><Trans>Player</Trans>2</th>
                    <th><Trans>Action</Trans></th>
                </tr>
            </thead>
            <tbody>
                {tradePlayersProposals.map(({id, fromTeam, fromPlayer, toTeam, toPlayer}) => <tr key={id}>
                    <td>{fromTeam.name}</td>
                    <td>{`${fromPlayer.name} (${t(fromPlayer.position)})`}</td>
                    <td>{toTeam.name}</td>
                    <td>{`${toPlayer.name} (${t(toPlayer.position)})`}</td>
                    <td>
                        {fromTeam.id === team.id
                            ? <button onClick={() => onWithdraw(id)} type="button" className="btn btn-sm btn-default"><Trans>Withdraw</Trans></button>
                            : <div>
                                <button onClick={() => onAccept(id)} type="button" className="btn btn-sm btn-success mb-small mr-small"><Trans>Accept</Trans></button>
                                <button onClick={() => onReject(id)} type="button" className="btn btn-sm btn-default"><Trans>Reject</Trans></button>
                            </div>
                        }
                    </td>
                </tr>)}
            </tbody>
        </table>
        </div>
    );
};

export default withTranslation()(TradeProposalsTable);