import React, {Component} from 'react';
import RequestResetPasswordForm from "./RequestResetPasswordForm";

import {requestResetPassword} from "services/AuthService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import { withRouter } from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";

class RequestResetPasswordPage extends Component {
    state = {
        isLoading: false
    };

    handleResetPasswordRequest = ({email}) => {
        this.setState({isLoading: true});
        return requestResetPassword(email)
            .then(() => {
                const { t } = this.props;
                addSuccessNotification(
                    t('Password reset requested'),
                    t('Link to reset password was emailed to you')
                );
                this.props.history.push('/login');
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    render() {
        const {isLoading} = this.state;

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <RequestResetPasswordForm processData={this.handleResetPasswordRequest}/>
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(RequestResetPasswordPage));
