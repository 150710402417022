import React from 'react';
import Moment from "react-moment";

const BlogPostsTable = ({blogPosts, onEdit, onDelete}) => {
    if (!blogPosts) {
        return null;
    }

    return (
        <table className="table table-bordered mb-default">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Posted By</th>
                    <th>Creation Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {blogPosts.map((blogPost) =>
                    <tr key={blogPost.id}>
                        <td>{blogPost.title}</td>
                        <td>{blogPost.postedByUser ? blogPost.postedByUser.name : '-'}</td>
                        <td className="min-content"><Moment format="DD/MM/YYYY">{blogPost.createdAt}</Moment></td>

                        <td className="min-content">
                            <input
                                type="button"
                                className="btn btn-sm btn-primary"
                                value="✎"
                                onClick={() => onEdit(blogPost)}
                            />

                            &nbsp;

                            <input
                                type="button"
                                className="btn btn-sm btn-default"
                                value="✕"
                                onClick={() => onDelete(blogPost)}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default BlogPostsTable;
