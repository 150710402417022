import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function PickPlayerConfirmationModal({player, onConfirm, t, ...rest}) {
    if (!player) {
        return null;
    }

    const positionTitle = t(player.position);
    const namePosition = `${player.name} (${positionTitle})`;

    return (
        <ConfirmationModal
            title={t("Confirm pick")}
            content={t("Are you sure you want to draft {{namePosition}}?", {namePosition})}
            buttons={{
                yes: t('Confirm'),
                no: t('Cancel')
            }}
            type="creative"
            onConfirm={() => onConfirm(player)}
            {...rest}
        />
    );
}

export default withTranslation()(PickPlayerConfirmationModal);
