import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import React from "react";

export default function SendMessagesConfirmationModal({onConfirm, nbRecipients, ...rest}) {
    return (
        <ConfirmationModal
            title={"Send Messages"}
            content={`Confirm sending messages to ${nbRecipients} ${nbRecipients > 1 ? 'recipients' : 'recipient'}?`}
            buttons={{
                yes: `Yes, send ${nbRecipients} ${nbRecipients > 1 ? 'messages' : 'message'}`,
                no: 'No, cancel'
            }}
            type="creative"
            onConfirm={onConfirm}
            {...rest}
        />
    );
}
