import React, {Fragment} from 'react';
import {Field} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import TeamSelect from "modules/Owner/Season/TeamSelect";
import DatePickerWidget from "modules/Common/DatePickerWidget";
import TimePickerWidget from "modules/Common/TimePickerWidget";

export default function RoundGameRow({ field, form, disabled }) {
    const name = field.name;
    const teams = form.values.teams;
    return (
        <Fragment>
            <Field name={`${name}[id]`} type="hidden" />

            <div>
                <Field isDisabled={disabled} placeholder="Team 1" className="mr-small" name={`${name}[team1]`} options={teams} component={TeamSelect} />
                <ErrorMessage name={`${name}[team1]`} />
            </div>

            <div className="round-col">
                <Field pattern="\d*" maxlength="2" style={{width: '7rem'}} disabled={disabled} placeholder="Round" className="mr-small" name={`${name}[team1Round]`} />
                <ErrorMessage name={`${name}[team1Round]`} />
            </div>

            <div>
                <Field isDisabled={disabled} placeholder="Team 2" className="mr-small" name={`${name}[team2]`} options={teams} component={TeamSelect} />
                <ErrorMessage name={`${name}[team2]`} />
            </div>

            <div className="round-col">
                <Field pattern="\d*" maxlength="2" style={{width: '7rem'}} disabled={disabled} placeholder="Round" className="mr-small" name={`${name}[team2Round]`} />
                <ErrorMessage name={`${name}[team2Round]`} />
            </div>

            <div className="date-col">
                <Field disabled={disabled} placeholder="Date" className="mr-small" name={`${name}[date]`}
                       component={DatePickerWidget} />

                <ErrorMessage name={`${name}[date]`} />
            </div>


            <div className="time-col">
                <Field disabled={disabled} placeholder="Time" className="mr-small" name={`${name}[time]`}
                       component={TimePickerWidget} />

                <ErrorMessage name={`${name}[time]`} />
            </div>
        </Fragment>
    );
}
