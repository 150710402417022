import React from 'react';
import Select from "react-select";
import {withTranslation} from "react-i18next";

function getPossiblePositionsAsOptions() {
    const positions = [
        'QB', 'RB', 'WR', 'TE', 'K', 'DEF', 'HC'
    ];
    let options = [];

    for (const position of positions) {
        options.push({
            value: position,
            label: position
        });
    }

    return options;
}

const PositionSelect = ({t, selectedValue, onChange, ...rest}) => {
    const options = getPossiblePositionsAsOptions();
    const selectedOption = options.find((option) => option.value === selectedValue);

    return (
        <Select
            placeholder={t('select_placeholder')}
            value={selectedOption ? selectedOption : null}
            menuPortalTarget={document.body}
            options={options}
            onChange={onChange}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default withTranslation()(PositionSelect);
