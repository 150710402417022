import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {addErrorAsNotification, normalizeGeneralError} from "services/ValidationService";
import {Trans, withTranslation} from "react-i18next";
import {addErrorNotification} from "services/NotificationService";

const RegisterForm = function({handleSubmit, t}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form login-form" noValidate>
            <h1><Trans>Login</Trans></h1>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>E-mail</Trans></label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage name="email" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Password</Trans></label>
                <Field type="password" name="password" className="form-control" />
                <ErrorMessage name="password" />
            </div>

            <p className="text-right"><Link to="/reset-password"><Trans>Forgot password</Trans></Link></p>
            <input className="submit btn btn-primary" type="submit" value={t('Login')} />

            <div className="form-group mt-large">
                <label className="form-label"/>
                <p className="form-control text-center-mobile"><Trans>Don't have an account yet?</Trans> <Link to="/register"><Trans>Click here to register</Trans></Link></p>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: ({ t }) => ({
            'email': '',
            'password': '',
        }),
        validationSchema: ({ t }) => {
            return yup.object().shape({
                email: yup.string()
                    .required(t('You didn\'t fill in your e-mail'))
                    .email(t('E-mail format is incorrect')),
                password: yup.string()
                    .required(t('Please input your password'))
            })
        },
        handleSubmit: (values, { props }) => {
            props.processData(values)
                .catch(({response}) => {
                    const { t } = props;
                    const error = normalizeGeneralError(response);
                    addErrorNotification(t('Incorrect e-mail / password'), error);
                })
            ;
        }
    })
)(RegisterForm);
