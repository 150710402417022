import React, {Component} from 'react';
import {confirmEmailChange, logout} from "services/AuthService";
import { withRouter } from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";
import Loader from "modules/Common/Loader";
import {addErrorAsNotification} from "services/ValidationService";
import {withTranslation} from "react-i18next";

class ChangeEmailConfirmPage extends Component {
    state = {
        isLoading: true
    };

    componentDidMount() {
        const emailChangeToken = this.props.match.params.emailChangeToken;
        this.setState({isLoading: true});

        return confirmEmailChange(emailChangeToken)
            .then(() => {
                addSuccessNotification('Success', 'Your e-mail was successfully changed');
                logout();
                this.props.history.push('/login');
            })
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    render() {
        const {isLoading} = this.state;

        return (<Loader active={isLoading} />);
    }
}

export default withTranslation()(withRouter(ChangeEmailConfirmPage));
