import React, {Component} from 'react';
import ChangeEmailForm from "./ChangeEmailForm";

import {requestEmailChange} from "services/AuthService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import { withRouter } from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";

class ChangeEmailPage extends Component {
    state = {
        isLoading: false
    };

    handleEmailChange = ({newEmail}) => {
        this.setState({isLoading: true});
        return requestEmailChange(newEmail)
            .then(() => {
                addSuccessNotification('Email change requested', 'Link to confirm this was sent your new e-mail address');
                this.props.history.push('/profile');
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    render() {
        const {isLoading} = this.state;

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <ChangeEmailForm processData={this.handleEmailChange}/>
                </LoadingOverlay>
            </main>
        );
    }
}

export default withRouter(ChangeEmailPage);
