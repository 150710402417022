import React from 'react';
import Moment from "react-moment";

const SeasonsTable = ({seasons, timezone, onSelect}) => {
    if (!seasons) {
        return null;
    }

    return (
        <table className="table table-bordered mb-default">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {seasons.map((season) =>
                    <tr key={season.id}>
                        <td>{season.id}</td>
                        <td><Moment format="DD/MM/YYYY">{season.startsAt}</Moment></td>
                        <td><Moment format="DD/MM/YYYY">{season.endsAt}</Moment></td>
                        <td>{season.status}</td>
                        <td className="min-content">
                            <input type="button" className="btn btn-sm btn-primary" value="Select" onClick={() => onSelect(season)} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default SeasonsTable;
