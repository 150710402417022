import React, {Component} from 'react';
import {PlayerToolTip} from "modules/Players/PlayerTooltip";
import {getPlayers} from "services/PlayerService";
import {addErrorAsNotification} from "services/ValidationService";
import {groupScoresByPlayerAndRound} from "services/SeasonService";
import Loader from "modules/Common/Loader";
import {withRouter} from "react-router-dom";
import {getSelectedSeason} from "services/UserStateService";
import {withTranslation} from "react-i18next";

class PlayersTable extends Component {
    state = {
        season: null,
        players: null,
        isLoading: true,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});

        const requests = Promise.all([
            getSelectedSeason().catch(() => null),
            getPlayers()
        ]);

        //fetch all at once
        requests
            .then(([season, players]) => this.setState({season, players, isLoading: false}))
            .catch(({response}) =>  addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    render() {
        if (this.state.isLoading) {
            return <main><Loader /></main>;
        }

        const { t } = this.props;
        const { players, season } = this.state;
        const scores = season ? groupScoresByPlayerAndRound(season.games) : [];

        if (!scores || !scores.length) {
            return <h3>No data available</h3>
        }

        return (
            <table className="table table-condensed table-bordered mb-default">
                <thead>
                <tr>
                    <th>Round</th>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Team</th>
                    <th>Pass yds</th>
                    <th>Rush yds</th>
                    <th>Catch yds</th>
                    <th>TD</th>
                    <th>2pt</th>
                    <th>Fumble</th>
                    <th>INT</th>
                    <th>FG</th>
                    <th>XP</th>
                    <th>FG miss</th>
                    <th>XP miss</th>
                    <th>Fum forced</th>
                    <th>Sack</th>
                    <th>Pts allowed</th>
                    <th>Win Loss</th>
                    <th>Pts gained</th>
                    <th>Score difference</th>
                </tr>
                </thead>
                <tbody>
                {players.map((player) => {
                    const playerScores = (scores && scores[player.id] !== undefined) ? scores[player.id] : {};
                    const rounds = Object.keys(playerScores);

                    return rounds.map((round) => {
                        const playerScore = playerScores[round];

                        return (
                            <tr key={`${player.id}-${round}`}>
                                <td>{round}</td>
                                <td className="min-content">
                                    <PlayerToolTip player={player} scores={scores[player.id]} />
                                </td>
                                <td className="min-content">{t(player.position)}</td>
                                <td className="min-content">{player.team.name}</td>
                                <td>{playerScore['pass_yds']}</td>
                                <td>{playerScore['rush_yds']}</td>
                                <td>{playerScore['catch_yds']}</td>
                                <td>{playerScore['td']}</td>
                                <td>{playerScore['_2pt']}</td>
                                <td>{playerScore['fumble']}</td>
                                <td>{playerScore['int']}</td>
                                <td>{playerScore['fg']}</td>
                                <td>{playerScore['xp']}</td>
                                <td>{playerScore['fg_miss']}</td>
                                <td>{playerScore['xp_miss']}</td>
                                <td>{playerScore['fum_forced']}</td>
                                <td>{playerScore['sack']}</td>
                                <td>{playerScore['pts_allowed']}</td>
                                <td>{playerScore['win_loss']}</td>
                                <td>{playerScore['pts_gained']}</td>
                                <td>{playerScore['score_difference']}</td>
                            </tr>
                        );
                    });
                })}
                </tbody>
            </table>
        );
    }
}

export default withTranslation()(withRouter(PlayersTable));
