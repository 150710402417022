import React from 'react';

import {Editor} from "@tinymce/tinymce-react";
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';

export default function RichEditor({ field, form, children, editorOptions, ...rest }) {
    return (
        <Editor
            value={field.value}
            init={{
                relative_urls : false,
                remove_script_host : false,
                document_base_url: process.env.REACT_APP_API_ROOT,
                height: 500,
                width: '100%',
                menubar: false,
                // imagetools_cors_hosts: ['localhost'],
                // imagetools_proxy: 'proxy.php',
                images_upload_url: process.env.REACT_APP_API_ROOT + 'upload-image',
                plugins: [
                    'advlist autolink lists link image imagetools charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                    'image | undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
                ...editorOptions
            }}
            onEditorChange={content => form.setFieldValue(field.name, content)}
        />
    );
}
