import API from "services/API";

export function formatLeagueRole(role) {
    switch (role) {
        case 'LEAGUE_ADMIN':
            return 'League Admin';
        case 'LEAGUE_USER':
            return 'League User';
        default:
            return role;
    }
}

export function formatPendingMemberType(type) {
    switch (type) {
        case 'INVITED':
            return 'Pending invitation';
        case 'REQUESTED':
            return 'Requested to join';
        default:
            return type;
    }
}

export function formatMembershipStatus(membershipStatus) {
    if (membershipStatus === null) {
        return null;
    }

    let result = formatPendingMemberType(membershipStatus);
    if (result !== membershipStatus) {
        return result;
    }

    return formatLeagueRole(membershipStatus);
}

export function getLeagueDashboardRoute(leagueMembership) {
    if (leagueMembership.role === 'LEAGUE_ADMIN') {
        return '/league-admin/dashboard';
    } else {
        return '/league-user/dashboard';
    }
}

export function transferRights(leagueId, fromLeagueMemberId, toLeagueMemberId)
{
    return API.post(`/leagues/${leagueId}/members/${fromLeagueMemberId}/transfer-rights/${toLeagueMemberId}`)
        .then((response) => response.data)
    ;
}

export function createLeague(leagueData) {
    let formData = new FormData();
    for (let key in leagueData) {
        if (key === 'images') {
            for (let imgKey in leagueData['images']) {
                formData.append(`images[${imgKey}][title]`, leagueData['images'][imgKey]['title']);
                formData.append(`images[${imgKey}][image]`, leagueData['images'][imgKey]['image']);
            }
        } else {
            formData.append(key, leagueData[key]);
        }
    }

    return API.post('leagues', formData, {
            headers: {'Content-Type': 'multipart/form-data'}}
        )
        .then((response) => response.data)
    ;
}

export function ownerUpdateLeague(leagueId, leagueData) {
    let formData = new FormData();

    for (let key in leagueData) {
        if (key === 'images') {
            for (let imgKey in leagueData['images']) {
                formData.append(`images[${imgKey}][id]`,    leagueData['images'][imgKey]['id']);
                formData.append(`images[${imgKey}][title]`, leagueData['images'][imgKey]['title']);
                formData.append(`images[${imgKey}][image]`, leagueData['images'][imgKey]['image']);
            }
        } else {
            formData.append(key, leagueData[key]);
        }
    }

    return API.post('owner/leagues/'+leagueId+'/update', formData, {
            headers: {'Content-Type': 'multipart/form-data'}}
        )
        .then((response) => response.data)
    ;
}

export function updateLeague(leagueId, leagueData) {
    return API.post('leagues/'+leagueId+'/update', leagueData)
        .then((response) => response.data)
    ;
}

export function deleteLeague(leagueId) {
    return API.delete('leagues/'+leagueId)
        .then((response) => response.data)
    ;
}

export function deleteLeagueMember(leagueId, memberId) {
    return API.delete('leagues/'+leagueId+'/members/'+memberId)
        .then((response) => response.data)
    ;
}

export function deletePendingLeagueMember(leagueId, memberId) {
    return API.delete('leagues/'+leagueId+'/pending-members/'+memberId)
        .then((response) => response.data)
    ;
}

export function getLeagues(search = []) {
    return API.get('leagues', {params: search})
        .then((response) => response.data)
    ;
}

export function getLeague(leagueId) {
    return API.get('leagues/'+leagueId)
        .then((response) => response.data)
    ;
}

export function getLeagueSeason(leagueId, seasonId) {
    return API.get(`leagues/${leagueId}/seasons/${seasonId}`)
        .then((response) => response.data)
        .catch((error) => {
            if (error.response.status === 404) {
                return null;
            }

            throw error;
        })
    ;
}

export function checkLeagueQualifications(leagueId, seasonId) {
    return API.get(`leagues/${leagueId}/qualifications/${seasonId}`)
        .then((response) => response.data)
    ;
}

export function getLeaguesCsvUrl() {
    const baseUrl = process.env.REACT_APP_API_ROOT;
    return `${baseUrl}leagues.csv`;
}

export function requestToJoinLeague(leagueId, teamData) {
    let formData = new FormData();
    for (let key in teamData) {
        formData.append(key, teamData[key]);
    }

    return API.post('leagues/'+leagueId+'/request-join', formData)
        .then((response) => response.data)
    ;
}

export function inviteToJoinLeague(leagueId, userData) {
    return API.post('leagues/'+leagueId+'/invite', userData)
        .then((response) => response.data)
    ;
}

export function acceptInvitation(confirmationToken, teamData) {
    let formData = new FormData();
    for (let key in teamData) {
        formData.append(key, teamData[key]);
    }

    return API.post('invitations/'+confirmationToken+'/accept', formData)
        .then((response) => response.data)
    ;
}

export function getPendingMembers(leagueId) {
    return API.get('leagues/'+leagueId+'/pending-members')
        .then((response) => response.data)
    ;
}

export function acceptPendingMember(pendingMemberId) {
    return API.post('pending-members/'+pendingMemberId+'/accept')
        .then((response) => response.data)
    ;
}

export function getMembers(leagueId) {
    return API.get('leagues/'+leagueId+'/members')
        .then((response) => response.data)
    ;
}

export function getTeams(leagueId, skipUserId) {

    return getMembers(leagueId).then((members) => {
        let teams = [];
        for (const member of members) {
            if (!member.team) {
                continue;
            }

            if (skipUserId && skipUserId === member.user.id) {
                continue;
            }

            teams.push(member.team);
        }

        return teams;
    });
}

export function getUserTeam(leagueId, userId) {
    return getMembers(leagueId).then((members) => {
        for (const member of members) {
            if (!member.team) {
                continue;
            }

            if (userId === member.user.id) {
                return member.team;
            }
        }
    })
}

export function getFantasyTeamRoster(leagueId, fantasyTeamId) {
    return API.get(`leagues/${leagueId}/rosters/${fantasyTeamId}`)
        .then((response) => response.data)
    ;
}

export function getStandings(leagueId, seasonId = null) {
    let route = 'leagues/'+leagueId+'/standings';
    if (seasonId) {
        route += '/season/' + seasonId;
    }

    return API.get(route)
        .then((response) => response.data)
    ;
}

export function prepareSeason(leagueId, draftStartsAt, draftEndsAt) {
    return API.get(`/leagues/${leagueId}/prepare-season/${draftStartsAt}/${draftEndsAt}`)
        .then((response) => response.data)
    ;
}

export function startSeason(leagueId, details) {
    return API.post(`/leagues/${leagueId}/seasons`, details)
        .then((response) => response.data)
    ;
}

export function updateDraft(leagueId, leagueSeasonId, details) {
    return API.post(`/leagues/${leagueId}/seasons/${leagueSeasonId}`, details)
        .then((response) => response.data)
    ;
}

export function checkDraftOverdue(leagueId) {
    return API.post(`/leagues/${leagueId}/check-draft-overdue`)
        .then((response) => response.data)
    ;
}

export function getUserMembershipForLeague(user, league) {
    if (!league || !user) {
        return null;
    }

    //Super admin is "League admin" of every league
    if (user.roles.includes("SUPER_ADMIN")) {
        return {
            id: null,
            role: 'LEAGUE_ADMIN',
            league: league,
        };
    }

    let foundMembership = null;
    const memberships = user.leagueMemberships;

    if (memberships) {
        memberships.forEach((membership) => {
            if (league.id === membership.league.id) {
                foundMembership = membership;
            }
        });
    }

    return foundMembership;
}

export function getUserPendingMembershipForLeague(user, league) {
    let foundPendingMembership = null;
    const pendingMemberships = user.pendingLeagueMemberships;

    if (pendingMemberships) {
        pendingMemberships.forEach((pendingMembership) => {
            if (league.id === pendingMembership.league.id) {
                foundPendingMembership = pendingMembership;
            }
        });
    }

    return foundPendingMembership;
}

export function groupStandingsByTeamId(standings)
{
    if (!standings) {
        return {};
    }

    let output = {};
    for (let standing of standings) {
        output[standing.team.id] = standing;
    }

    return output;
}
