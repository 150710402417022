import React, {Component} from 'react';
import DefaultLayout from "layouts/DefaultLayout";
import {Redirect} from "react-router-dom";

export default class SecureLayout extends Component {
    render() {
        const { render: render, ...rest } = this.props;

        return  <DefaultLayout {...rest} render={props => {
            if (localStorage.getItem('user')) {
                return render(props);
            }

            return <Redirect to={{ pathname: '/login', state: { from: props.location, notification: true } }} />;
        }} />
    }
}
