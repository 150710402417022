import React, {Component} from 'react';
import InviteForm from "modules/Invite/InviteForm";
import {inviteToJoinLeague} from "services/LeagueService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {withRouter} from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";

class InvitePage extends Component {
    state = {
        isLoading: false,
    };

    onInvite = (userData) => {
        const {t} = this.props;
        const leagueId = this.props.match.params.leagueId;
        this.setState({isLoading: true});
        return inviteToJoinLeague(leagueId, userData)
            .then(() => {
                this.setState({isLoading:false});
                addSuccessNotification(
                    t('Invite member'),
                    t('Invitation sent to the following recipient', {email: userData.email})
                );
                this.props.history.goBack();
            })
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        const {isLoading} = this.state;

        const {t} = this.props;
        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <InviteForm
                        onInvite={this.onInvite}
                        onCancel={this.props.history.goBack}
                    />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(InvitePage));
