import API from "services/API";

export function getScoreConfigs(scoreConfigs) {
    return API.get('score-configs')
        .then((response) => response.data)
    ;
}

export function updateScoreConfigs(scoreConfigs) {
    return API.post('score-configs', scoreConfigs)
        .then((response) => response.data)
    ;
}

