import React, { Component } from 'react';
import { compose } from "recompose";
import { Field, withFormik } from "formik";
import * as yup from "yup";
import EditRosterScoresTable from "modules/Owner/Stats/EditRosterScoresTable";
import {
    getAttributesBySection,
    getPlayerSectionByPosition,
    getSectionsAttributes,
    groupPlayersBySection
} from "services/PlayerService";
import ScoreWidget from "modules/Owner/Stats/ScoreWidget";

let addPlayers = {
    'Team1': [],
    'Team2': []
};
class EditPlayersScoresForm extends Component {


    setNewPlayers(data, team) {
        addPlayers[team] = data;
    }

    render() {
        const { game, team1Editable, team2Editable, setFieldValue, values, handleSubmit } = this.props;

        const fillScoresWithZeros = () => {
            let players = [];

            if (team1Editable) {
                players = [...players, ...game.team1.players];
            }

            if (team2Editable) {
                players = [...players, ...game.team2.players];
            }

            const grouppedPlayers = groupPlayersBySection(players)
            const sections = Object.keys(grouppedPlayers);

            for (const section of sections) {
                const attrs = getAttributesBySection(section);

                const groupPlayers = grouppedPlayers[section];
                for (const player of groupPlayers) {
                    for (const attr of attrs) {
                        const field = `players.${player.id}.${attr}`;
                        const currentValue = values.players[player.id][attr];
                        if (!currentValue) {
                            setFieldValue(field, '0', false);
                        }
                    }
                }
            }
        };

        return (
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }} className="form" noValidate>
                <div className="mb-default" style={{ 'display': 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <h3 className="mb-default">{game.team1.name} - Round {game.team1Round}</h3>
                        <div className="mb-default">
                            <label htmlFor="scoreTeam1"><strong>Game Score</strong></label>
                            <Field disabled={!team1Editable} component={ScoreWidget} name="scoreTeam1" maxLength={3} className="ml-small form-control-md" />
                        </div>
                        <EditRosterScoresTable editable={team1Editable} team={game.team1} onSetNewPlayers={(e) => this.setNewPlayers(e, 'Team1')} />
                    </div>
                    <div className="ml-default" style={{ flex: 1 }}>
                        <h3 className="mb-default">{game.team2.name} - Round {game.team2Round}</h3>

                        <div className="mb-default">
                            <label htmlFor="scoreTeam2"><strong>Game Score</strong></label>
                            <Field disabled={!team2Editable} component={ScoreWidget} name="scoreTeam2" maxLength={3} className="ml-small form-control-md" />
                        </div>
                        <EditRosterScoresTable editable={team2Editable} team={game.team2} onSetNewPlayers={(e) => this.setNewPlayers(e, 'Team2')} />
                    </div>
                </div>

                {(team1Editable || team2Editable) && <div style={{ margin: '10px 0 20px 0' }}>
                    <button type="button" className="btn btn-sm btn-default" onClick={fillScoresWithZeros}>Fill scores with zeros</button>
                </div>}

                {(team1Editable || team2Editable) && <input type="submit" className="btn btn-success" value="Save Scores" />}
            </form>
        );
    }
}

function groupAttributes(players) {
    let attrs = {};

    for (const player of players) {
        const playerId = player.id;
        attrs[playerId] = player.scores !== null ? player.scores : {};
    }

    return attrs;
}

function getSectionsAttributesValidationSchema() {
    let sectionsAttributesValidationSchema = [];
    const sectionsAttributes = getSectionsAttributes();

    for (const section in sectionsAttributes) {
        const sectionAttributes = sectionsAttributes[section];
        for (const sectionAttribute of sectionAttributes) {
            if (undefined === sectionsAttributesValidationSchema[section]) {
                sectionsAttributesValidationSchema[section] = {};
            }
            sectionsAttributesValidationSchema[section][sectionAttribute] = yup.number().required();
        }
        sectionsAttributesValidationSchema[section] = yup.object().shape(sectionsAttributesValidationSchema[section]);
    }

    return sectionsAttributesValidationSchema;
}

export default compose(
    withFormik({
        mapPropsToValues: ({ game }) => {
            const allPlayers = game.team1.players.concat(game.team2.players);
            return {
                scoreTeam1: game.team1.score,
                scoreTeam2: game.team2.score,
                players: groupAttributes(allPlayers)
            };
        },

        validationSchema: ({ game }) => {
            let validationSchema = {};
            const sectionsAttributesValidationSchema = getSectionsAttributesValidationSchema();
            const allPlayers = game.team1.players.concat(game.team2.players);

            for (const player of allPlayers) {
                const section = getPlayerSectionByPosition(player.position);
                validationSchema[player.id] = sectionsAttributesValidationSchema[section];
            }

            validationSchema = yup.object().shape({
                'scoreTeam1': yup.number().min(0).required(),
                'scoreTeam2': yup.number().min(0).required(),
                'players': yup.object().shape(validationSchema)
            });

            return validationSchema;
        },

        handleSubmit: (values, { props, setErrors }) => {
            let data = { ...addPlayers };
            data.Team1 = data.Team1.map(p => +p.value);
            data.Team2 = data.Team2.map(p => +p.value);
            console.log('DATA: ', data);
            addPlayers = {
                'Team1': [],
                'Team2': []
            }

            props.handleSubmit({ ...values, addPlayers: data });
        }
    })
)(EditPlayersScoresForm);
