import React from 'react';
import {Field, withFormik} from "formik";
import * as yup from "yup";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import TeamSelectWidget from "modules/Owner/Season/TeamSelectWidget";

function TransferPlayerForm({ player, teams, submitButtonRef, handleSubmit }) {
    //Exclude team which player is currently in
    const transferTeams = teams.filter((team) => player.team.id !== team.id);

    return (
        <form style={{minWidth: 400}} onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form form-vertical" noValidate>
            <div className="form-group">
                <label className="form-label">Current Team</label>
                <p><strong>{player.team.name}</strong></p>
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="team">Tranfer Team</label>
                <Field placeholder="Select Team" className="mr-small" id="team" name="team" teams={transferTeams} component={TeamSelectWidget} />
                <ErrorMessage name="team" />
            </div>

            <div className="form-group mt-default" style={{display: 'none'}}>
                <button ref={submitButtonRef} className="submit btn btn-primary mr-small" type="submit">Save Changes</button>
            </div>
        </form>
    );
}

export default
    withFormik({
        mapPropsToValues: (props) => {
            return {
                'team': null,
            }
        },
        validationSchema: yup.object().shape({
            team: yup.number().typeError('Please select team').required('Please select team'),
        }),
        handleSubmit: (values, { props }) => {
            props.processData(values);
        }
    })(TransferPlayerForm)
;
