import React, {useState, useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {getLeague, ownerUpdateLeague} from "services/LeagueService";
import {compose} from "recompose";
import {addErrorAsNotification} from "services/ValidationService";
import {withTranslation} from "react-i18next";
import LeagueForm from "modules/Owner/Leagues/LeagueForm";
import {addSuccessNotification} from "services/NotificationService";

const OwnerEditLeagueDescriptionPage = ({ match, history, t }) => {
    const leagueId = match.params.id;
    let [isLoading, setLoading] = useState(false);
    let [league, setLeague] = useState(null);

    useEffect(() => {
        setLoading(true);
        getLeague(leagueId)
            .then((league) => setLeague(league))
            .catch(({response}) => addErrorAsNotification(response, t))
            .finally(() => setLoading(false))
        ;
    }, [])

    return (
        <main>
            <div className="mb-default">
                <Link to={`/owner/leagues/${leagueId}`} className="btn btn-sm btn-default">❮ &nbsp; League</Link>
            </div>

            <LoadingOverlay active={isLoading}>
                {league && <>
                    <h1 className="mb-default">{league.name}</h1>

                    <LeagueForm
                        league={league}
                        submitButtonName="Update League"
                        processData={(leagueData) => {
                            setLoading(true);
                            ownerUpdateLeague(league.id, leagueData)
                                .then(({ id }) => {
                                    addSuccessNotification('Success', `League "${leagueData.name}" was successfully updated`);
                                    history.push(`/owner/leagues/${id}`);
                                })
                                .finally(() => setLoading(false))
                            ;
                        }}
                    />
                </>}
            </LoadingOverlay>
        </main>
    );
};

export default compose(
    withRouter,
    withTranslation(),
)(OwnerEditLeagueDescriptionPage);