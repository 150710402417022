import React, {Component} from 'react';

import 'react-dates/initialize';
import {DayPickerSingleDateController} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import TimePicker from 'modules/Common/TimePicker';
import moment from "moment";
import {combineDateAndTime} from "services/DateTime";
import {Trans, withTranslation} from "react-i18next";

class DateRangeSelector extends Component {
    state = {
        startDate: null,
        startTime: null,
        startDateFocused: null,
        endDate: null,
        endTime: null,
        endDateFocused: null,
    };

    componentDidMount() {
        const timezone = this.props.timezone ? this.props.timezone : 'UTC';
        const startDate = this.props.startDate ? moment.tz(this.props.startDate, timezone) : null;
        const endDate = this.props.endDate ? moment.tz(this.props.endDate, timezone) : null;

        this.setState({
            startDate: startDate,
            startTime: startDate ? startDate.locale('en').format('HH:mma') : null,
            endDate: endDate,
            endTime: endDate ? endDate.locale('en').format('HH:mma') : null,
        })
    }

    handleDateChange = ({ startDate, endDate }) => {
        this.setState({startDate, endDate}, this.notifyRangeChanged);
    };

    handleStartTimeChange = (value) => {
        this.setState({startTime: value}, this.notifyRangeChanged);
    };

    handleEndTimeChange = (value) => {
        this.setState({endTime: value}, this.notifyRangeChanged);
    };

    notifyRangeChanged = () => {
        const {timezone} = this.props;
        const {startDate, startTime, endDate, endTime} = this.state;

        this.props.onDateChange({
            startDate: combineDateAndTime(startDate, startTime, timezone),
            endDate: combineDateAndTime(endDate, endTime, timezone),
        });
    };

    render = () => (
        <div className="draft-range-selector">
            <div className="draft-range-1">
                <div className="mb-small"><strong><Trans>Start date</Trans></strong></div>
                <DayPickerSingleDateController
                    date={this.state.startDate}
                    onDateChange={date => this.handleDateChange({ startDate: date, endDate: this.state.endDate })}
                    focused={this.state.startDateFocused}
                    onFocusChange={({ focused }) => this.setState({ startDateFocused: focused })}
                    hideKeyboardShortcutsPanel={true}
                    isOutsideRange={this.props.isOutsideRange}
                />

                <div className="range-time">
                    <strong className="mr-small"><Trans>Time</Trans>:</strong>
                    <TimePicker
                        onChange={this.handleStartTimeChange}
                        value={this.state.startTime}
                        step={15}
                    />
                </div>
            </div>

            <div className="draft-range-2">
                <div className="mb-small"><strong><Trans>End date</Trans></strong></div>
                <DayPickerSingleDateController
                    date={this.state.endDate}
                    onDateChange={date => this.handleDateChange({ startDate: this.state.startDate, endDate: date })}
                    focused={this.state.endDateFocused}
                    onFocusChange={({ focused }) => this.setState({ endDateFocused: focused })}
                    hideKeyboardShortcutsPanel={true}
                    isOutsideRange={this.props.isOutsideRange}
                />

                <div className="range-time">
                    <strong className="mr-small"><Trans>Time</Trans>:</strong>
                    <TimePicker
                        onChange={this.handleEndTimeChange}
                        value={this.state.endTime}
                        step={15}
                    />
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(DateRangeSelector);
