import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function DeleteAccountConfirmationModal({onConfirm, t, ...rest}) {
    return (
        <ConfirmationModal
            title={t("Delete account")}
            content={t("Are you sure you want to delete your account?")}
            buttons={{
                yes: t('OK'),
                no: t('Cancel')
            }}
            type="destructive"
            onConfirm={() => onConfirm()}
            {...rest}
        />
    );
}
export default withTranslation()(DeleteAccountConfirmationModal);
