import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {getTags} from "services/BlogService";

export default function TagsSelect({ field, form, children, ...rest }) {
    const promiseOptions = inputValue =>
        getTags(inputValue).then(tags => tags.map((_) => ({
            value: _,
            label: _
        })))
    ;

    return (
        <AsyncCreatableSelect
            className="form-control"
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            defaultOptions
            loadOptions={promiseOptions}
            menuPortalTarget={document.body}
            value={
                !field.value ? [] : field.value.map(_ => ({
                    value: _,
                    label: _
                }))
            }
            // inputValue={field.value ? field.value.join(',') : ''}
            onChange={(value) => form.setFieldValue(field.name, value ? value.map(_ => _.value) : [])}
            isMulti
        />
    );
}




