import React from 'react';
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import moment from "moment";
import Truncate from "react-truncate";

const BlogPostListItem = ({blogPost}) => {
    return (
        <div key={blogPost.id} className="blog-post mb-default">
            <Link className="blog-post-title" to={`blog/${blogPost.id}`}>{blogPost.title}</Link>

            {blogPost.headerImage && <div>
                <Link to={`blog/${blogPost.id}`}>
                    <div className="header-image">
                        <img src={blogPost.headerImage}/>
                    </div>
                </Link>
            </div>}

            <div className="date">
                <Trans i18nKey="blog_posted_at" values={{dateTime: moment(blogPost.createdAt).format('LLLL')}} />
            </div>
            <Truncate lines={3} ellipsis={<span>...<br/><Link className="btn btn-sm btn-default mt-small" to={`blog/${blogPost.id}`}><Trans>Continue reading</Trans></Link></span>}>
                <div dangerouslySetInnerHTML={{__html: blogPost.body.replace(/\n/g, '<br />')}} />
            </Truncate>

            {blogPost.tags.length>0 && <div className="mt-default">
                <Trans>Tags</Trans>: {blogPost.tags.join(', ')}
            </div>}
        </div>
    );
};

export default BlogPostListItem;