import React from 'react';
import {formatPendingMemberType} from "services/LeagueService";
import {Trans, withTranslation} from "react-i18next";
import LeagueRole from "modules/Common/LeagueRole";

function MembersTable(props) {
    const {
        members,
        pendingMembers,
        nbSlots,
        onInviteMember,
        onAcceptPendingMember,
        onDeleteMember,
        onDeletePendingMember,
    } = props;

    const nbOpenSlots = Math.max(0, (nbSlots - members.length - pendingMembers.length));

    //Open slots
    let openSlots = [];
    for (let i=1; i<=nbOpenSlots; ++i) {
        openSlots.push(null);
    }

    return (
        <div className="table-responsive">
            <table className="table" style={{margin: '3rem 0'}}>
            <thead>
            <tr>
                <th><Trans>User name</Trans></th>
                <th><Trans>Status</Trans></th>
                <th><Trans>Type</Trans></th>
                <th><Trans>Team name</Trans></th>
                <th><Trans>Action</Trans></th>
            </tr>
            </thead>
            <tbody>
                {members.map((member, index) => {
                    const memberUser = member.user;
                    return (
                        <tr key={index}>
                            <td>{memberUser.firstName + ' ' + memberUser.lastName}</td>
                            <td><Trans>Registered</Trans></td>
                            <td><LeagueRole role={member.role} /></td>
                            <td>{member.team ? member.team.name : ''}</td>
                            <td>
                                {member.role==='LEAGUE_USER' && <button onClick={() => onDeleteMember(member)} className="btn-sm btn-default">
                                    <Trans>Remove</Trans>
                                </button>}
                            </td>
                        </tr>
                    );
                })}

                {pendingMembers.map((pendingMember, index) => {
                    return (
                        <tr key={'pending_'+index} className="pending-member">
                            <td>{pendingMember.displayName}</td>
                            <td><Trans>{formatPendingMemberType(pendingMember.type)}</Trans></td>
                            <td><LeagueRole role="LEAGUE_USER" /></td>
                            <td></td>
                            <td>
                                {pendingMember.type === 'REQUESTED' &&
                                    <button onClick={() => onAcceptPendingMember(pendingMember)} className="btn-sm btn-primary mr-tiny">
                                        <Trans>Accept Request</Trans>
                                    </button>
                                }

                                <button onClick={() => onDeletePendingMember(pendingMember)} className="btn-sm btn-default">
                                    <Trans>Remove</Trans>
                                </button>
                            </td>
                        </tr>
                    );
                })}

                {openSlots.map((_, index) => (
                    <tr key={'open_'+index} className="open-slot">
                        <td>
                            <button onClick={() => onInviteMember()} className="btn-sm btn-primary"><Trans>Invite player</Trans></button>
                        </td>
                        <td><Trans>Open slot</Trans></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
}

export default withTranslation()(MembersTable);
