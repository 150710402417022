import React, {Component} from 'react';
import LeagueSwitcher from "modules/Dashboard/LeagueSwitcher";
import {getCurrentLeagueId, setCurrentLeagueId} from "services/UserStateService";
import {
    deleteLeague,
    deleteLeagueMember,
    getLeague,
    getMembers,
    getUserMembershipForLeague, transferRights
} from "services/LeagueService";
import Loader from "modules/Common/Loader";
import LeagueSection from "./LeagueSection";
import TeamSection from "./TeamSection";
import {getUser, logout, refreshUser} from "services/AuthService";
import {addErrorNotification, addSuccessNotification, addWarningNotification} from "services/NotificationService";
import {getFantasyTeam} from "services/FantasyTeamService";
import {addErrorAsNotification, normalizeGeneralError} from "services/ValidationService";
import ExitLeagueConfirmationModal from "./ExitLeagueConfirmationModal";
import {Link, withRouter} from "react-router-dom";
import MembersWidget from "modules/LeagueAdmin/Dashboard/MembersWidget";
import RemoveLeagueConfirmationModal from "./RemoveLeagueConfirmationModal";
import {Trans, withTranslation} from "react-i18next";
import DeleteAccountConfirmationModal from "./DeleteAccountConfirmationModal";
import {deleteUser} from "services/UserService";
import TransferRightsModal from "modules/LeagueAdmin/LeagueSettings/TransferRightsModal";
import LoadingOverlay from "modules/Common/LoadingOverlay";

const ACTION_EXIT_LEAGUE = 'EXIT_LEAGUE';
const ACTION_DELETE_ACCOUNT = 'DELETE_ACCOUNT';

class LeagueSettingsPage extends Component {
    state = {
        user: null,
        membership: null,
        members: null,
        league: null,
        isLoading: true,
        postTransferRightsAction: null,
        showTransferRightsModal: false,
        showExitLeagueConfirmationModal: false,
        showRemoveLeagueConfirmationModal: false,
        showDeleteAccountConfirmationModal: false,
        refreshAt: null
    };

    componentDidMount() {
        const currentLeagueId = getCurrentLeagueId();
        if (!currentLeagueId) {
            addWarningNotification('No league selected', 'You need to select league first');
            this.props.history.push('/secure');
            return;
        }

        this.refreshDetails(currentLeagueId);
    }

    refreshDetails(currentLeagueId) {
        this.setState({isLoading: true});

        refreshUser()
            .then((user) => {
                if (!user.roles.includes("SUPER_ADMIN")) {
                    let leagueFound = false;
                    user.leagueMemberships.forEach((membership) => {
                        if (currentLeagueId === membership.league.id) {
                            leagueFound = true;
                        }
                    });

                    if (!leagueFound) {
                        if (!user.leagueMemberships.length) {
                            throw 'You don\'t have any active memberships!';
                        }
                        currentLeagueId = user.leagueMemberships[0].league.id;
                        setCurrentLeagueId(currentLeagueId);
                    }
                }

                return getLeague(currentLeagueId).then((league) => {
                    return {league, user};
                })
            })
            .then((data) => {
                const {user, league} = data;
                const membership = getUserMembershipForLeague(user, league);

                let teamId = null;
                user.teams.forEach((currentTeam) => {
                    if (league.id === currentTeam.league_id) {
                        teamId = currentTeam.id;
                    }
                });

                if (!teamId) {
                    return {user, league, membership, team: null};
                }

                return getFantasyTeam(teamId).then((team) => {
                    return {user, league, membership, team};
                });
            }).then(({user, league, membership, team}) => {
                this.setState({user, league, membership, team});
            }).catch(({response}) => {
                 addErrorNotification(this.props.t('Error'), normalizeGeneralError(response));
            }).finally(() => {
                this.setState({isLoading: false, refreshAt: Date.now()});
            })
        ;
    };

    showExitLeagueConfirmationModal = () => {
        this.setState({showExitLeagueConfirmationModal: true});
    };

    hideExitLeagueConfirmationModal = () => {
        this.setState({showExitLeagueConfirmationModal: false});
    };

    onRequestExitLeague = () => {
        const {membership} = this.state;
        const isLeagueAdmin = (membership.role === 'LEAGUE_ADMIN');

        if (isLeagueAdmin) {
            this.showTransferRightsModal(membership.league, ACTION_EXIT_LEAGUE);
        } else {
            this.showExitLeagueConfirmationModal();
        }
    };

    showTransferRightsModal = (league, postTransferRightsAction) => {
        this.setState({isLoading: true});

        const collectMembers = async () => {
            let leaguesMembers = [];
            const user = await refreshUser();

            //If provided in param - use specific league, otherwise use all user's leagues where he's admin
            let leagues = [];
            if (league) {
                leagues = [league];
            } else {
                for (const membership of user.leagueMemberships) {
                    if (membership.role !== 'LEAGUE_ADMIN') {
                        continue;
                    }

                    leagues.push(membership.league);
                }
            }

            //Collect members from leagues
            for (const currentLeague of leagues) {
                const leagueId = currentLeague.id;
                const members = await getMembers(leagueId);

                let currentLeagueMembers = [];
                for (const currentMember of members) {
                    if (currentMember.user.id !== user.id) {
                        currentLeagueMembers.push(currentMember);
                    }
                }

                if (currentLeagueMembers.length) {
                    leaguesMembers.push({
                        league: currentLeague,
                        members: currentLeagueMembers
                    });
                }
            }

            return leaguesMembers;
        };

        collectMembers()
            .then((members) => {
                if (members.length > 0) {
                    this.setState({members, postTransferRightsAction, showTransferRightsModal: true, isLoading: false});
                } else {
                    this.setState({members, isLoading: false});
                    if (postTransferRightsAction === ACTION_EXIT_LEAGUE) {
                        this.showExitLeagueConfirmationModal();
                    } else if (postTransferRightsAction === ACTION_DELETE_ACCOUNT) {
                        this.showDeleteAccountConfirmationModal();
                    }
                }
            }).catch(() => {
                this.setState({members: null, isLoading: false});
            })
        ;
    };

    hideTransferRightsModal = () => {
        this.setState({showTransferRightsModal: false});
    };

    showRemoveLeagueConfirmationModal = () => {
        this.setState({showRemoveLeagueConfirmationModal: true});
    };

    hideRemoveLeagueConfirmationModal = () => {
        this.setState({showRemoveLeagueConfirmationModal: false});
    };

    showDeleteAccountConfirmationModal = () => {
        this.setState({showDeleteAccountConfirmationModal: true});
    };

    hideDeleteAccountConfirmationModal = () => {
        this.setState({showDeleteAccountConfirmationModal: false});
    };

    isUserAdminOfAnyLeague = () => {
        const user = this.state.user;
        for (const membership of user.leagueMemberships) {
            if (membership.role === 'LEAGUE_ADMIN') {
                return true;
            }
        }

        return false;
    };

    onRequestDeleteAccount = () => {
        if (this.isUserAdminOfAnyLeague()) {
            this.showTransferRightsModal(null, ACTION_DELETE_ACCOUNT);
        } else {
            this.showDeleteAccountConfirmationModal();
        }
    };

    onChangeLeague = ({id: leagueId}) => {
        setCurrentLeagueId(leagueId);
        this.refreshDetails(leagueId);
    };

    onTransferRights = async (members) => {
        await this.transferRights(members);
        this.executePostTransferRightsAction();
    };

    executePostTransferRightsAction = () => {
        const {postTransferRightsAction} = this.state;
        if (postTransferRightsAction === ACTION_EXIT_LEAGUE) {
            this.onExitLeague();
        } else if (postTransferRightsAction === ACTION_DELETE_ACCOUNT) {
            this.onDeleteAccount();
        }
    };

    transferRights = async (members) => {
        let requests = [];

        let leagueMembers = {};
        for (const membership of this.state.user.leagueMemberships) {
            if (membership.role !== 'LEAGUE_ADMIN') {
                continue;
            }
            leagueMembers[membership.league.id] = membership;
        }

        for (const leagueId in members) {
            const currentLeagueMember = members[leagueId];

            this.setState({isLoading: true});
            const fromMemberId = leagueMembers[leagueId].id;
            const leagueName = leagueMembers[leagueId].league.name;
            const request = transferRights(leagueId, fromMemberId, currentLeagueMember.id)
                .then(() => {
                    const {t} = this.props;
                    addSuccessNotification(
                        t('Success'),
                        t('You transferred admin rights on "{{leagueName}}" to {{recipientMemberName}}', {leagueName: leagueName, recipientMemberName: currentLeagueMember.name})
                    );
                })
                .catch(({response}) => {
                    addErrorAsNotification(response, this.props.t);
                })
            ;

            requests.push(request);
        }

        return Promise.all(requests);
    };

    onExitLeague = () => {
        const {league, membership} = this.state;
        this.setState({isLoading: true});
        return deleteLeagueMember(league.id, membership.id)
            .then(() => {
                const { t } = this.props;
                addSuccessNotification(
                    t('Success'),
                    'You\'ve left "' + league.name + '" league.'
                );
                setCurrentLeagueId(null);
                refreshUser();
                this.props.history.push('/secure');
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.refreshDetails(getCurrentLeagueId());
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    onRemoveLeague = () => {
        const {league} = this.state;

        this.setState({isLoading: true});
        deleteLeague(league.id)
            .then(() => {
                addSuccessNotification(
                    this.props.t('Success'),
                    this.props.t("You've deleted {{leagueName}} league", {leagueName: league.name})
                );
                setCurrentLeagueId(null);
                this.props.history.push('/secure');
            })
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    onDeleteAccount = () => {
        const user = getUser();
        deleteUser(user.id)
            .then(() => {
                setCurrentLeagueId(null);
                logout();
                this.props.history.push('/account-deleted');
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.refreshDetails(getCurrentLeagueId());
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    isSuperAdmin = () => {
        return this.state.user.roles.includes("SUPER_ADMIN");
    }

    render() {
        const {team, league, members, membership, showTransferRightsModal, showExitLeagueConfirmationModal, showRemoveLeagueConfirmationModal, showDeleteAccountConfirmationModal, isLoading} = this.state;

        if (!league && isLoading) {
            return <main><Loader /></main>;
        }

        const isSuperAdmin = this.isSuperAdmin();
        return (
            <main key={this.state.refreshAt}>
                {isSuperAdmin ?
                    <>
                        <div className="mb-default">
                            <Link to={`/owner/leagues/${league.id}`} className="btn btn-sm btn-default">❮ &nbsp; League
                                Details</Link>
                        </div>

                        {/*<div className="mb-default float-right">*/}
                        {/*    <Link to="/settings" className="btn btn-default">League Settings</Link>*/}
                        {/*</div>*/}
                    </>
                    :
                    <div className="top-side-actions">
                        <LeagueSwitcher league={league} onChangeLeague={this.onChangeLeague}/>
                    </div>
                }

                <h1 className="mb-default"><Trans i18nKey="League administration" values={{leagueName: league.name}} /></h1>

                <h2 className="mt-default"><Trans>Settings</Trans></h2>

                <LoadingOverlay active={isLoading}>
                    <div className='profile-settings'>
                        {team &&
                        <TeamSection
                            team={team}
                            onDeleteAccount={this.onRequestDeleteAccount}
                        />
                        }

                        <LeagueSection
                            league={league}
                            membership={membership}
                            onRemoveLeague={this.showRemoveLeagueConfirmationModal}
                            onExitLeague={this.onRequestExitLeague}
                        />
                    </div>

                    {membership && membership.role === 'LEAGUE_ADMIN' && <div>
                        <h2 className="mt-normal"><Trans>Members</Trans></h2>
                        <MembersWidget league={league} />
                    </div>}
                </LoadingOverlay>

                {members && <TransferRightsModal
                    isOpen={showTransferRightsModal}
                    handleCloseModal={() => this.hideTransferRightsModal()}
                    league={league}
                    members={members}
                    onConfirm={this.onTransferRights}
                />}

                <ExitLeagueConfirmationModal
                    isOpen={showExitLeagueConfirmationModal}
                    handleCloseModal={() => this.hideExitLeagueConfirmationModal()}
                    league={league}
                    onConfirm={this.onExitLeague}
                />

                <RemoveLeagueConfirmationModal
                    isOpen={showRemoveLeagueConfirmationModal}
                    handleCloseModal={() => this.hideRemoveLeagueConfirmationModal()}
                    league={league}
                    onConfirm={this.onRemoveLeague}
                />

                <DeleteAccountConfirmationModal
                    isOpen={showDeleteAccountConfirmationModal}
                    handleCloseModal={() => this.hideDeleteAccountConfirmationModal()}
                    league={league}
                    onConfirm={this.onDeleteAccount}
                />
            </main>
        );
    }
}

export default withTranslation()(withRouter(LeagueSettingsPage));
