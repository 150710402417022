import API from "services/API";

export function getCurrentUser() {
    return API.get('users/me')
        .then((response) => response.data)
    ;
}

export function getUsersFilters() {
    return API.get('users-filters')
        .then((response) => response.data)
    ;
}

export function getUsers(filters = {}) {
    return API.get('users', {params: filters})
        .then((response) => response.data)
    ;
}

export function getUsersCsvUrl() {
    const baseUrl = process.env.REACT_APP_API_ROOT;
    return `${baseUrl}users.csv`;
}

export function deleteUser(userId) {
    return API.delete('users/'+userId)
        .then((response) => response.data)
    ;
}

export function updateUser(userId, userData) {
    return API.post('users/'+userId, userData)
        .then((response) => response.data)
    ;
}

export function formatGender(gender) {
    switch (gender) {
        case 'm': return 'Male';
        case 'f': return 'Female';
        default: return '-';
    }
}

export function rememberLanguagePreference(userId, locale)
{
    return API.post(`users/${userId}/locale/${locale}`)
        .then((response) => response.data)
    ;
}