import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import BlogPostForm from "modules/Owner/Blog/BlogPostForm";
import {createBlogPost, getBlogPost, updateBlogPost} from "services/BlogService";
import {addSuccessNotification} from "services/NotificationService";
import {addErrorAsNotification} from "services/ValidationService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import Loader from "modules/Common/Loader";

class BlogPostPage extends Component {
    state = {
        blogPost: null,
        isLoading: true
    };

    componentDidMount() {
        this.onRefreshData();
    }

    onRefreshData = () => {
        const blogPostId = this.props.match.params.id;

        if (blogPostId) {
            return getBlogPost(blogPostId)
                .then((blogPost) => {
                    this.setState({blogPost, isLoading: false});
                })
                .catch(({response}) => {
                    addErrorAsNotification(response, this.props.t);
                    this.setState({isLoading: false});
                })
        } else {
            this.setState({isLoading: false});
        }

        return null;
    };

    onSaveBlogPost = (blogPostData) => {
        const isNew = (this.state.blogPost === null);

        this.setState({isLoading: true});

        //Create
        if (isNew) {
            return createBlogPost(blogPostData)
                .then(() => {
                    addSuccessNotification('Success', 'Blog post was successfully created');
                    this.props.history.push('/owner/blog');
                })
                .catch((response) => addErrorAsNotification(response, this.props.t))
                .finally(() => this.setState({isLoading: false}))
        }

        //Edit
        return updateBlogPost(this.state.blogPost.id, blogPostData)
            .then(() => {
                addSuccessNotification('Success', 'Blog post was successfully updated');
                this.props.history.push('/owner/blog');
            })
            .catch((response) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        if (this.state.isLoading) {
            return <Loader/>;
        }

        const isNew = this.state.blogPost === null;

        return (
            <main>
                <LoadingOverlay active={this.state.isLoading}>
                    <h1 className="mb-default">{isNew ? 'Create Blog Post' : 'Update Blog Post'}</h1>

                    <BlogPostForm
                        blogPost={this.state.blogPost}
                        processData={this.onSaveBlogPost}
                    />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withRouter(BlogPostPage);
