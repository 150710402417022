import React, {Component} from 'react';
import LoadingOverlay from "modules/Common/LoadingOverlay";
import Loader from "modules/Common/Loader";
import TeamsTable from "./TeamsTable";
import PlayersTable from "./PlayersTable";
import {addErrorAsNotification} from "services/ValidationService";
import {addTeam, deleteTeam, editTeam, getPlayers, getTeams} from "services/TeamService";
import {addPlayer, deletePlayer, transferPlayer} from "services/PlayerService";
import PlayerModal from "./PlayerModal";
import DeletePlayerConfirmationModal from "./DeletePlayerConfirmationModal";
import TeamModal from "./TeamModal";
import DeleteTeamConfirmationModal from "./DeleteTeamConfirmationModal";
import {updatePlayer} from "services/PlayerService";
import {getCurrentSeason} from "services/SeasonService";
import {withTranslation} from "react-i18next";
import TransferPlayerModal from "modules/Owner/Teams/TransferPlayerModal";
import {addSuccessNotification} from "services/NotificationService";

class TeamsPage extends Component {
    state = {
        currentSeason: null,
        teams: undefined,
        players: undefined,

        isLoading: true,
        isLoadingPlayers: false,

        activeTeam: null,
        selectedTeam: null,
        showTeamModal: false,
        showDeleteTeamModal: false,

        showPlayerModal: false,
        showDeletePlayerModal: false,
        showTransferPlayerModal: false,
        selectedPlayer: null,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});

        getCurrentSeason()
            .then((season) => this.setState({currentSeason: season}))
            .catch(() => null)
        ;

        return getTeams()
            .then((teams) => {
                this.setState({teams, isLoading: false});

                const activeTeam = this.state.activeTeam;
                let existsActiveTeam = false;

                if (activeTeam) {
                    for (const team of teams) {
                        if (activeTeam.id === team.id) {
                            existsActiveTeam = true;
                        }
                    }
                }

                if (!existsActiveTeam) {
                    this.onSelectTeam(teams.length ? teams[0] : null);
                }

                return {teams};
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onSelectTeam = (team) => {
        if (!team) {
            this.setState({players: [], activeTeam: null});
            return;
        }

        this.setState({isLoadingPlayers: true});
        getPlayers(team.id)
            .then((players) => {
                this.setState({players, activeTeam: team, isLoadingPlayers: false});
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoadingPlayers: false, activeTeam: team});
            })
        ;
    };

    refreshPlayers = () => {
        this.onSelectTeam(this.state.activeTeam);
    };

    onExecuteAddTeam = (teamData) => {
        this.setState({isLoading: true});
        return addTeam(teamData)
            .then(({ id: teamId }) => {
                this.refreshData().then(({teams}) => {
                    for (const team of teams) {
                        if (team.id === teamId) {
                            this.onSelectTeam(team);
                            return;
                        }
                    }
                });
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onExecuteEditTeam = (team, teamData) => {
        this.setState({isLoading: true});
        return editTeam(team.id, teamData)
            .then(() => this.refreshData())
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false})
            })
        ;
    };

    onExecuteDeleteTeam = (team) => {
        this.setState({isLoading: true});
        deleteTeam(team.id)
            .then(() => this.refreshData())
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false})
            })
        ;
    };

    onExecuteAddPlayer = (team, playerData) => {
        this.setState({isLoadingPlayers: true});
        return addPlayer({...playerData, teamId: parseInt(team.id)})
            .then(() => this.refreshPlayers())
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoadingPlayers: false});
                throw response;
            })
        ;
    };

    onExecuteTransferPlayer = (playerId, newTeamId) => {
        this.setState({isLoadingPlayers: true});
        return transferPlayer(playerId, newTeamId)
            .then(() => {
                const player = this.state.players.find(({id}) => id === playerId);
                const newTeam = this.state.teams.find(({id}) => id === newTeamId);

                this.refreshPlayers();
                addSuccessNotification(
                    'Success',
                    `${player.name} was successfully moved to ${newTeam.name}`
                );
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoadingPlayers: false});
                throw response;
            })
        ;
    };

    onExecuteEditPlayer = (playerId, playerData) => {
        this.setState({isLoadingPlayers: true});
        return updatePlayer(playerId, playerData)
            .then(() => this.refreshPlayers())
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoadingPlayers: false});
                throw response;
            })
        ;
    };

    onExecuteDeletePlayer = (player) => {
        this.setState({isLoadingPlayers: true});
        deletePlayer(player.id)
            .then(() => this.refreshPlayers())
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoadingPlayers: false})
            })
        ;
    };

    showTeamModal = (team = null) => {
        this.setState({showTeamModal: true, selectedTeam: team});
    };

    hideAddTeamModal = () => {
        this.setState({showTeamModal: false});
    };


    showPlayerModal = (player) => {
        this.setState({
            showPlayerModal: true,
            selectedPlayer: player ? player : null
        });
    };

    hidePlayerModal = () => {
        this.setState({showPlayerModal: false});
    };

    showDeletePlayerModal = (player) => {
        this.setState({showDeletePlayerModal: true, selectedPlayer: player});
    };

    showTransferPlayerModal = (player) => {
        this.setState({showTransferPlayerModal: true, selectedPlayer: player});
    };

    hideTransferPlayerModal = () => {
        this.setState({showTransferPlayerModal: false});
    };

    hideDeletePlayerModal = () => {
        this.setState({showDeletePlayerModal: false});
    };

    showDeleteTeamModal = (team) => {
        this.setState({showDeleteTeamModal: true, selectedTeam: team});
    };

    hideDeleteTeamModal = () => {
        this.setState({showDeleteTeamModal: false});
    };

    render() {
        const {teams, players, activeTeam, currentSeason, isLoading} = this.state;

        if (isLoading && teams===undefined) {
            return <main><Loader /></main>;
        }

        const isDeletable = (!currentSeason || currentSeason.status !== 'ongoing');

        return (
            <main>
                <h1>Teams Players</h1>

                <LoadingOverlay active={isLoading}>
                    <div className="rosters-section">
                        <div className="col">
                            <div className="mb-small clearfix">
                                {isDeletable && <input onClick={() => this.showTeamModal()} style={{float: 'right', marginBottom: 4}} type="button" className="btn btn-primary" value="Add Team" />}
                                <h3>Teams</h3>
                            </div>

                            <TeamsTable
                                editable={true}
                                deletable={isDeletable}
                                teams={teams}
                                selectedTeam={activeTeam}
                                onEditTeam={this.showTeamModal}
                                onSelectTeam={this.onSelectTeam}
                                onDeleteTeam={this.showDeleteTeamModal}
                            />
                        </div>
                        <div className="col">
                            <LoadingOverlay active={this.state.isLoadingPlayers}>
                                <PlayersColumn
                                    editable={true}
                                    activeTeam={activeTeam}
                                    players={players}
                                    onAddPlayer={this.showPlayerModal}
                                    onEditPlayer={this.showPlayerModal}
                                    onDeletePlayer={this.showDeletePlayerModal}
                                    onTransferPlayer={this.showTransferPlayerModal}
                                />
                            </LoadingOverlay>
                        </div>
                    </div>
                </LoadingOverlay>

                <TeamModal
                    isOpen={this.state.showTeamModal}
                    team={this.state.selectedTeam}
                    handleCloseModal={() => this.hideAddTeamModal()}
                    onSubmit={(teamData) => {
                        const team = this.state.selectedTeam;
                        const isNew = (team === null);
                        if (isNew) {
                            return this.onExecuteAddTeam(teamData);
                        } else {
                            return this.onExecuteEditTeam(team, teamData);
                        }
                    }}
                />

                <PlayerModal
                    isOpen={this.state.showPlayerModal}
                    team={this.state.activeTeam}
                    player={this.state.selectedPlayer}
                    handleCloseModal={() => this.hidePlayerModal()}
                    onSubmit={(team, playerData) => {
                        const player = this.state.selectedPlayer;
                        const isNew = (player === null);
                        if (isNew) {
                            return this.onExecuteAddPlayer(team, playerData);
                        } else {
                            return this.onExecuteEditPlayer(player.id, playerData);
                        }
                    }}
                />

                <TransferPlayerModal
                    isOpen={this.state.showTransferPlayerModal}
                    player={this.state.selectedPlayer}
                    teams={this.state.teams}
                    handleCloseModal={() => this.hideTransferPlayerModal()}
                    onSubmit={({ team: newTeamId }) => {
                        const player = this.state.selectedPlayer;
                        return this.onExecuteTransferPlayer(player.id, newTeamId);
                    }}
                />

                <DeletePlayerConfirmationModal
                    isOpen={this.state.showDeletePlayerModal}
                    player={this.state.selectedPlayer}
                    handleCloseModal={() => this.hideDeletePlayerModal()}
                    onConfirm={(player) => this.onExecuteDeletePlayer(player)}
                />

                <DeleteTeamConfirmationModal
                    isOpen={this.state.showDeleteTeamModal}
                    team={this.state.selectedTeam}
                    handleCloseModal={() => this.hideDeleteTeamModal()}
                    onConfirm={(team) => this.onExecuteDeleteTeam(team)}
                />
            </main>
        );
    }
}

const PlayersColumn = ({activeTeam, players, editable, onAddPlayer, onEditPlayer, onTransferPlayer, onDeletePlayer}) => {
    if (!activeTeam) {
        return null;
    }

    return (
        <div>
            {editable && <div className="mb-small clearfix">
                <input onClick={() => onAddPlayer()} style={{float: 'right', marginBottom: 4}} type="button" className="btn btn-primary" value="Add Player" />
                <h3>Players ({activeTeam.name})</h3>
            </div>}

            <PlayersTable
                editable={editable}
                players={players}
                onDelete={onDeletePlayer}
                onTransfer={onTransferPlayer}
                onEdit={onEditPlayer}
            />
        </div>
    );
};

export default withTranslation()(TeamsPage);