import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {normalizeErrors} from "services/ValidationService";
import {Trans, withTranslation} from "react-i18next";

const FantasyTeamForm = function({handleSubmit, submitButtonName, submitButtonRef, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form owner-team-form" noValidate>
            {errors.general && <div className="error-message">{errors.general}</div>}

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Team name</Trans></label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" />
            </div>

            <div className="form-group mt-default" style={{display: submitButtonName ? 'block' : 'none'}}>
                <button ref={submitButtonRef} className="submit btn btn-primary mr-small" type="submit"><Trans>{submitButtonName ? submitButtonName : 'Create'}</Trans></button>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: ({ team }) => ({
            'name': team ? team.name : ''
        }),
        validationSchema: ({ t }) => yup.object().shape({
            name: yup.string().required(t('Please input team name'))
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(FantasyTeamForm);
