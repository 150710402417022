import React from 'react';
import Select from "react-select";

const DraftStatusSelect = ({form, field, ...rest}) => {
    const options = [
        {value: 'done', label: 'Done'},
        {value: 'scheduled', label: 'Scheduled'},
        {value: 'not_scheduled', label: 'Not scheduled'},
    ];

    const selectedOption = field.value ?
        options.filter((option) => field.value.includes(option.value))
        : []
    ;

    return (
        <Select
            isMulti={true}
            placeholder='Any'
            value={selectedOption}
            menuPortalTarget={document.body}
            options={options}
            onChange={(options) => {
                if (!options) {
                    options = [];
                }
                form.setFieldValue(field.name, options.map((option) => option.value));
            }}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default DraftStatusSelect;
