import React from 'react';
import logo from 'logo.png';
import {Link} from "react-router-dom";

export default function Logo({ linkTo }) {
    return (
        <Link to={linkTo ? linkTo : '/'} className="logo-box">
            <img style={{height: "5rem"}} src={logo} alt="Logo" className="logo"/>
        </Link>
    );
}
