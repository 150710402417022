import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Modal from 'react-modal';

import "tinymce/tinymce"
import "tinymce/themes/silver"

import "tinymce/plugins/advlist"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/link"
import "tinymce/plugins/image"
import "tinymce/plugins/imagetools"
import "tinymce/plugins/charmap"
import "tinymce/plugins/print"
import "tinymce/plugins/preview"
import "tinymce/plugins/anchor"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/visualblocks"
import "tinymce/plugins/code"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/insertdatetime"
import "tinymce/plugins/media"
import "tinymce/plugins/table"
import "tinymce/plugins/paste"
import "tinymce/plugins/code"
import "tinymce/plugins/help"
import "tinymce/plugins/wordcount"

import "./skins/ui/oxide/skin.min.css"
import "./skins/ui/oxide/content.min.css"
// import "./skins/content/default/content.min.css"


import Moment from 'react-moment';
Modal.setAppElement('#root');

Moment.globalFormat = 'Do MMMM YYYY, h:mm:ss A';

i18n.on('initialized', function() {
    ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
