import React from 'react';
import {Trans} from "react-i18next";

export default function ThankYouPage() {
    return (
        <main className="thanks">
            <h1><Trans>Thank you for registering!</Trans></h1>
            <h2><Trans>You will receive e-mail with confirmation link shortly</Trans></h2>
        </main>
    );
}
