import React, {Component} from 'react';
import {compose} from "recompose";
import {Field, withFormik} from "formik";
import * as yup from "yup";
import {WeeksList} from "modules/Owner/Season/WeeksList";
import moment from "moment";
import DateRangePickerWidget from "modules/Common/DateRangePickerWidget";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import {Link} from "react-router-dom";
import {combineDateAndTime} from "services/DateTime";
import PlayoffWeeksSelect from "modules/Owner/Season/PlayoffWeeksSelect";
import ScheduleSection from "modules/Owner/Season/ScheduleSection";
import {prepareScheduleForm} from "modules/Owner/Season/ScheduleFormHelper";
import TeamsNbSelect from "modules/Common/TeamsNbSelectWidget";

class SeasonForm extends Component {
    render() {
        const {handleSubmit, timezone, values} = this.props;
        const today = moment().startOf('day');

        return (
            <div>
                <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form form-season mb-default" noValidate>
                    <div className="mb-default">
                        <h3 className="mb-small">Draft window</h3>
                        <Field className="mr-small"
                               name="draftWindow"
                               component={DateRangePickerWidget}
                               timezone={timezone}
                               isOutsideRange={(date) => date.isBefore(today)}
                        />
                        <ErrorMessage name="draftWindow" />
                    </div>

                    <div className="mb-default">
                        <h3 className="mb-small">Season dates</h3>
                        <Field className="mr-small"
                               name="seasonDates"
                               component={DateRangePickerWidget}
                               timezone={timezone}
                               isOutsideRange={(date) => date.isBefore(today)}
                        />
                        <ErrorMessage name="seasonDates" />
                    </div>

                    <div className="mb-default" style={{width: '50%'}}>
                        <h3 className="mb-small">Playoff weeks</h3>
                        <Field className="mr-small"
                               name="nbPlayoffWeeks"
                               component={PlayoffWeeksSelect}
                        />
                        <ErrorMessage name="nbPlayoffWeeks" />
                    </div>

                    <div className="mb-default" style={{width: '50%'}}>
                        <h3 className="mb-small">Max number of Fantasy Teams</h3>
                        <Field className="mr-small"
                               name="nbMaxFantasyTeams"
                               component={TeamsNbSelect}
                        />
                        <ErrorMessage name="nbMaxFantasyTeams" />
                    </div>

                    <ScheduleSection values={values} />

                    <div style={{marginTop: '7rem'}}>
                        <button type="submit" className="btn btn-default mr-small">Lock Season</button>
                        <Link to="/owner/seasons" className="btn btn-default">Cancel</Link>
                    </div>

                </form>
            </div>
        );
    }
}

export default compose(
    withFormik({
        mapPropsToValues: ({season, teams, timezone}) => {
            let seasonFormData;
            const scheduleFormData = prepareScheduleForm(season, teams, timezone);

            if (season) {
                seasonFormData = {
                    draftWindow: {startDate: moment(season.draftWindow.startDate), endDate: moment(season.draftWindow.endDate)},
                    seasonDates: {startDate: moment(season.startsAt), endDate: moment(season.endsAt)},
                    nbPlayoffWeeks: season.nbPlayoffWeeks,
                    nbMaxFantasyTeams: season.nbMaxFantasyTeams,
                }
            } else {
                seasonFormData = {
                    draftWindow: {startDate: null, endDate: null},
                    seasonDates: {startDate: null, endDate: null},
                    nbPlayoffWeeks: null,
                    nbMaxFantasyTeams: null,
                };
            }

            return {...seasonFormData, ...scheduleFormData};
        },
        validationSchema: yup.object().shape({
            draftWindow: yup.mixed()
                .test(
                    "draftWindow",
                    "Please choose date/time for the draft window",
                    function (value) {
                        const {startDate, endDate} = value;
                        return (startDate && endDate);
                    }
                )
                .test(
                    "draftWindow",
                    "End date should be after start date",
                    function (value) {
                        const {startDate, endDate} = value;
                        if (!startDate || !endDate) {
                            return true;
                        }
                        return endDate.isAfter(startDate);
                    }
                )
                .test(
                    "draftWindow",
                    "Draft end date should be before the season start date",
                    function (value) {
                        const {startDate: draftStartDate, endDate: draftEndDate} = value;
                        const {startDate: seasonStartDate, endDate: seasonEndDate} = this.parent.seasonDates;

                        if (!draftStartDate || !draftEndDate || !seasonStartDate || !seasonEndDate) {
                            return true;
                        }

                        return draftEndDate.isBefore(seasonStartDate);
                    }
                )
                .test(
                    "draftWindow",
                    "Draft window should be at least 3 hours long",
                    function (value) {
                        const {startDate: draftStartDate, endDate: draftEndDate} = value;

                        if (!draftStartDate || !draftEndDate) {
                            return true;
                        }

                        let draftHours = draftEndDate.diff(draftStartDate, 'hours', true);
                        return (draftHours >= 3);
                    }
                )
            ,
            seasonDates: yup.mixed()
                .test(
                    "seasonDates",
                    "Please choose start and end date/time for the season",
                    value => {
                        const {startDate, endDate} = value;
                        return (startDate && endDate);
                    }
                )
                .test(
                    "seasonDates",
                    "End date should be after start date",
                    function (value) {
                        const {startDate, endDate} = value;
                        if (!startDate || !endDate) {
                            return true;
                        }

                        return endDate.isAfter(startDate);
                    }
                )
            ,

            nbPlayoffWeeks: yup.number().nullable()
                .required('Please select number playoff weeks')
                .test(
                    "nbPlayoffWeeks",
                    "Too little weeks for this playoff setting, add more weeks or reduce playoff weeks number.",
                    function (nbPlayoffWeeks) {
                        const nbRounds = this.parent.roundGames.reduce((maxRound, game) => Math.max(maxRound, game.team1Round, game.team2Round), 0);

                        return (nbRounds > nbPlayoffWeeks);
                    }
                )
            ,

            nbMaxFantasyTeams: yup.number().nullable()
                .required('Please select maximum number of Fantasy Teams')
            ,

            //@todo
            // nbPlayoffWeeks: yup.number().nullable()
            //     .required('Please select number playoff weeks')
            //     .test(
            //         "nbPlayoffWeeks",
            //         "Too little weeks for this playoff setting, add more weeks or reduce playoff weeks number.",
            //         function (nbPlayoffWeeks) {
            //             const nbWeeks = this.parent.weeks.length;
            //             return (nbWeeks > nbPlayoffWeeks);
            //         }
            //     )
            // ,

            //@todo
            // weeks: yup.array().required('Please add at least one week').of(
            //     yup.object().shape({
            //         games: yup.array().required('Please add at least one game for this week').of(
            //             yup.object().shape({
            //                 team1: yup.number().required('Please input team1'),
            //                 team2: yup.number().required('Please input team2').notOneOf([yup.ref('team1')], 'Teams should differ'),
            //                 date: yup.date()
            //                     .typeError('This date is invalid, use DD/MM/YYYY format')
            //                     .transform(function(value, originalValue) {
            //                         value = moment(originalValue, 'DD/MM/YYYY', true);
            //                         return value.isValid() ? value.toDate() : new Date('');
            //                     })
            //                     .required('Date is required'),
            //             })
            //         )
            //     }
            // )),

            roundGames: yup.array().required('Please add at least one game').of(
                yup.object().shape({
                    team1: yup.number().required('Please input team1'),
                    team2: yup.number().required('Please input team2').notOneOf([yup.ref('team1')], 'Teams should differ'),
                    date: yup.date()
                        .typeError('This date is invalid, use DD/MM/YYYY format')
                        .transform(function(value, originalValue) {
                            value = moment(originalValue, 'DD/MM/YYYY', true);
                            return value.isValid() ? value.toDate() : new Date('');
                        })
                        .required('Date is required'),
                })
            )
        }),
        handleSubmit: (values, { props, setErrors }) => {
            function formatDateTime(date) {
                return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
            }

            let data = JSON.parse(JSON.stringify(values)); //deep copy
            delete data.teams;

            //Format dates
            data.draftWindow.startDate = formatDateTime(data.draftWindow.startDate);
            data.draftWindow.endDate = formatDateTime(data.draftWindow.endDate);
            data.seasonDates.startDate = formatDateTime(data.seasonDates.startDate);
            data.seasonDates.endDate = formatDateTime(data.seasonDates.endDate);


            //Round games - combine date and time
            let teams = {};
            for (const game of data.roundGames) {
                const {team1, team2} = game;
                teams[team1] = true;
                teams[team2] = true;

                game.date = combineDateAndTime(game.date, game.time, props.timezone).utc().format('YYYY-MM-DD HH:mm:ss');
                delete game.time;
            }

            const nbPlayingTeams = Object.keys(teams).length;

            if (data.nbMaxFantasyTeams > nbPlayingTeams) {
                setErrors({
                    'nbMaxFantasyTeams': `Too many teams selected. Total of ${nbPlayingTeams} real life teams participate in this season, you cannot select more than this number for Max Fantasy Teams.`
                });
                return;
            }


            props.handleSubmit(data);
        }
    })
)(SeasonForm);
