import React from 'react';
import {Field, withFormik} from "formik";
import * as yup from "yup";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import TeamStatusSelect from "modules/Owner/Teams/TeamStatusSelect";

function TeamForm({ submitButtonRef, handleSubmit }) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form form-team" noValidate>
            <div className="form-group">
                <label className="form-label" htmlFor="name">Name</label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="status">Status</label>
                <Field component={TeamStatusSelect} name="status" className="form-control" />
                <ErrorMessage name="status" />
            </div>

            <div className="form-group mt-default" style={{display: 'none'}}>
                <button ref={submitButtonRef} className="submit btn btn-primary mr-small" type="submit">Save Changes</button>
            </div>
        </form>
    );
}

export default
    withFormik({
        mapPropsToValues: (props) => {
            const team = props.team;
            return {
                'name': team ? team.name : null,
                'status': team ? team.status : null,
            }
        },
        validationSchema: yup.object().shape({
            name: yup.string().required('Name is required'),
            status: yup.string().nullable(),
        }),
        handleSubmit: (values, { props }) => {
            props.processData(values);
        }
    })(TeamForm)
;
