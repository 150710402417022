import React, {Component} from 'react';
import LoadingOverlay from "modules/Common/LoadingOverlay";
import Loader from "modules/Common/Loader";
import TeamsTable from "./TeamsTable";
import PlayersTable from "./PlayersTable";
import {addErrorAsNotification} from "services/ValidationService";
import {getCurrentRosterPlayers, getSeasonPlayers, getSeasonTeams, getTeams} from "services/TeamService";
import {getSelectedSeason} from "services/UserStateService";
import SeasonSwitcher from "modules/Owner/Common/SeasonSwitcher";
import {withTranslation} from "react-i18next";

class RostersPage extends Component {
    state = {
        season: undefined,
        teams: undefined,
        players: undefined,

        isLoading: true,
        isLoadingPlayers: false,

        activeTeam: null,
        selectedTeam: null,
        showAddTeamModal: false,
        showDeleteTeamModal: false,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});

        getSelectedSeason()
            .then((season) => {
                getSeasonTeams(season.id)
                    .then((teams) => {
                        this.setState({season, teams, isLoading: false});

                        const activeTeam = this.state.activeTeam;
                        let existsActiveTeam = false;

                        if (activeTeam) {
                            for (const team of teams) {
                                if (activeTeam.id === team.id) {
                                    existsActiveTeam = true;
                                }
                            }
                        }

                        if (!existsActiveTeam) {
                            this.onSelectTeam(teams.length ? teams[0] : null);
                        }
                    })
                    .catch(({response}) => {
                        addErrorAsNotification(response, this.props.t);
                        this.setState({isLoading: false});
                    })
                ;
            })
            .catch(() => this.setState({
                teams: null, season: null, isLoading: false
            }))
        ;
    };

    onSelectTeam = (team) => {
        if (!team) {
            this.setState({players: [], activeTeam: null});
        }

        this.setState({isLoadingPlayers: true});

        const playersPromise = this.state.season
            ? getSeasonPlayers(this.state.season.id, team.id)
            : getCurrentRosterPlayers(team.id)
        ;

        playersPromise
            .then((players) => {
                this.setState({players, activeTeam: team, isLoadingPlayers: false});
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoadingPlayers: false, activeTeam: team});
            })
        ;
    };

    render() {
        const {teams, players, activeTeam, isLoading} = this.state;

        if (isLoading && teams===undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <div className="season-switcher">
                    <SeasonSwitcher />
                </div>
                <h1>Rosters</h1>

                {teams ? <LoadingOverlay active={isLoading}>
                    <div className="rosters-section">
                        <div className="col">
                            <h3 className="mb-small">Teams</h3>

                            <TeamsTable
                                deletable={false}
                                editable={false}
                                teams={teams}
                                selectedTeam={activeTeam}
                                onSelectTeam={this.onSelectTeam}
                            />
                        </div>
                        <div className="col">
                            <LoadingOverlay active={this.state.isLoadingPlayers}>
                                <PlayersColumn
                                    activeTeam={activeTeam}
                                    players={players}
                                    onAddPlayer={this.showPlayerModal}
                                    onEditPlayer={this.showPlayerModal}
                                    onDeletePlayer={this.showDeletePlayerModal}
                                />
                            </LoadingOverlay>
                        </div>
                    </div>
                </LoadingOverlay> : <h3>No data available</h3>}
            </main>
        );
    }
}

const PlayersColumn = ({activeTeam, players}) => {
    if (!activeTeam) {
        return null;
    }

    return (
        <div>
            <h3 className="mb-small">Players ({activeTeam.name})</h3>

            <PlayersTable players={players} editable={false} />
        </div>
    );
};

export default withTranslation()(RostersPage);