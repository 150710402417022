import React from 'react';

const Banner = ({ ad }) => {
    if (ad === null || !ad.image) {
        return null;
    }

    let bannerTag = <img src={ad.image} alt={ad.placement} />;
    if (ad.url) {
        bannerTag = <a href={ad.url}>{ bannerTag }</a>
    }

    return bannerTag;
};

export default Banner;