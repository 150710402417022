import React from 'react';
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import logo from "logo.png";
import playersBg from "../../img/welcome_players.png";
import fogBg from "../../img/welcome_fog.png";
import FeaturedBlogPosts from "modules/Blog/FeaturedBlogPosts";


export default function HomePage() {
    return (
        <main className="homepage">

            <div className="welcome-banner">
                <div className="left-section">
                    <p><Trans>Welcome to</Trans></p>
                    <h1><Trans>Hungarian fantasy football</Trans></h1>
                    <p><Trans>official website</Trans><br/><br/></p>
                    <p><Trans>The leading fantasy football platform of Hungary</Trans></p>
                </div>



                <div className="center-section">
                    <img src={logo} alt="Logo" className="logo"/>
                    <div className='light-lines-h-container'></div>
                    <div className='light-lines-v-container'></div>
                    <div className='players-bg-block'>
                        <div className="fog-bg"></div>
                        <div className="players-bg"></div>
                    </div>

                    <h3><Trans>Register and start playing now!</Trans></h3>

                    <div className="actions">
                        <p>
                            <Link to="/create-league" className="btn btn-primary"><Trans>Create a League</Trans></Link>
                        </p>
                        <p>
                            <Link to="/leagues" className="btn btn-primary"><Trans>Join a League</Trans></Link>
                        </p>
                    </div>

                    <p className="mt-small">
                        <Trans>Log in to your profile</Trans> <Link className="link" to="/login"><Trans>here</Trans></Link>
                    </p>
                </div>

                <div className="right-section">
                    <h1><Trans>Our mission</Trans></h1>
                    <p><Trans i18nKey="mission_body" /></p>

                </div>
            </div>

            <FeaturedBlogPosts />
        </main>
    );
}
