import React, {Component} from 'react';
import Loader from "modules/Common/Loader";
import {formatGender, getCurrentUser} from "services/UserService";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import {Trans} from "react-i18next";

export default class ProfilePage extends Component {
    state = {
        isLoading: false,
        user: null,
    };

    componentDidMount() {
        this.setState({isLoading: true});

        getCurrentUser()
            .then((user) => {
                this.setState({
                    user: user,
                    isLoading: false,
                });
            })
            .finally(() => this.setState({isLoading: false})
        );
    }

    render() {
        const {isLoading, user} = this.state;

        if (isLoading) {
            return <main><Loader /></main>;
        }

        if (!user) {
            return null;
        }

        return (
            <main>
                <h1><Trans>Profile</Trans></h1>

                <div className='profile-settings'>
                    <div className="mb-default">
                        <dl className="dl-list mb-small">
                            <dt><Trans>First name</Trans>:</dt> <dd>{user.firstName}</dd>
                            <dt><Trans>Last name</Trans>:</dt> <dd>{user.lastName}</dd>
                            <dt><Trans>Gender</Trans>:</dt> <dd>{formatGender(user.gender)}</dd>
                            <dt><Trans>Location</Trans>:</dt> <dd>{user.location}</dd>
                            <dt><Trans>DOB</Trans>:</dt> <dd>
                                <Moment format="DD/MM/YYYY">{user.birthDate}</Moment>
                            </dd>
                        </dl>
                        <Link to="/edit-profile" className="btn-sm btn-default"><Trans>Edit</Trans></Link>
                    </div>

                    <div>
                        <div className="mb-default">
                            <dl className="dl-list mb-small">
                                <dt><Trans>E-mail</Trans>:</dt> <dd>{user.email}</dd>
                            </dl>
                            <Link to="/change-email" className="btn-sm btn-default"><Trans>Change E-mail</Trans></Link>
                        </div>

                        <div className="mb-default">
                            <dl className="dl-list mb-small">
                                <dt><Trans>Password</Trans>:</dt> <dd>************</dd>
                            </dl>
                            <Link to="/change-password" className="btn-sm btn-default"><Trans>Change Password</Trans></Link>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
