import React from 'react';

import {formatNotificationType} from "services/UserNotificationService";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import moment from "moment";

export default function NotificationsTable({ notifications }) {
    const timezone = 'Europe/Budapest';
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th><Trans>Date</Trans></th>
                    <th><Trans>League</Trans></th>
                    <th><Trans>Status</Trans></th>
                    <th><Trans>Type</Trans></th>
                    <th><Trans>Notification</Trans></th>
                </tr>
                </thead>
                <tbody>
                {notifications && notifications.map((notification) => (
                    <tr key={notification.id} className={'notification-'+(notification.isViewed ? 'viewed' : 'new')}>
                        <td><Moment format="Do MMMM YYYY, h:mm:ss A">{moment.tz(notification.createdAt, timezone)}</Moment></td>
                        <td>
                            {notification.league && <Link className="link" to={'/leagues/'+notification.league.id}>
                                {notification.league.name}
                            </Link>}
                        </td>
                        <td><Trans>{notification.isViewed ? 'viewed_notification' : 'new_notificatiton'}</Trans></td>
                        <td><Trans>{formatNotificationType(notification.type)}</Trans></td>
                        <td dangerouslySetInnerHTML={{__html: notification.message.replace(/\n/g, '<br />')}}></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}
