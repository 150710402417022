import React, {Component} from 'react';
import EditProfileForm from "./EditProfileForm";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import Loader from "modules/Common/Loader";
import {getCurrentUser, updateUser} from "services/UserService";
import {addErrorAsNotification} from "services/ValidationService";
import {withRouter} from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";
import {refreshUser} from "services/AuthService";
import {withTranslation} from "react-i18next";

class EditProfilePage extends Component {
    state = {
        user: null
    };

    componentDidMount() {
        this.setState({isLoading: true});
        getCurrentUser()
            .then((user) => this.setState({user}))
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    }

    processData = (values) => {
        const {t} = this.props;
        this.setState({isLoading: true});
        return updateUser(this.state.user.id, values)
            .then(() => {
                refreshUser();
                addSuccessNotification(t('Success'), t('Profile details were updated'));
                this.props.history.push('/profile');
            })
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        const {user, isLoading} = this.state;

        if (!user) {
            return <main><Loader isActive={isLoading} /></main>
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <EditProfileForm user={user} processData={this.processData} />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(EditProfilePage));
