import {Trans} from "react-i18next";
import React from "react";

export default function AccountDeletedPage()
{
    return (
        <main>
            <h1 className="mb-default"><Trans>Deleted account</Trans></h1>
            <div style={{width: '50%'}}><Trans>Your account has been deleted</Trans></div>
        </main>
    );
}
