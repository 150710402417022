import React from 'react';
import {formatLeagueRole} from "services/LeagueService";
import {Trans} from "react-i18next";

export default function MembersTable({ members }) {
    return (
        <table className="table" style={{margin: '3rem 0'}}>
            <thead>
            <tr>
                <th><Trans>User name</Trans></th>
                <th><Trans>Type</Trans></th>
                <th><Trans>Team name</Trans></th>
            </tr>
            </thead>
            <tbody>
                {members.map((member, index) => {
                    const memberUser = member.user;
                    return (
                        <tr key={index}>
                            <td>{memberUser.firstName + ' ' + memberUser.lastName}</td>
                            <td><Trans>{formatLeagueRole(member.role)}</Trans></td>
                            <td>{member.team ? member.team.name : ''}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
