import React, {Component} from 'react';
import {getBlogPost} from "services/BlogService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {Link} from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";
import moment from "moment";
import AppFacebookProvider from "modules/Common/AppFacebookProvider";
import {Share} from "react-facebook";

class BlogPostPage extends Component {
    state = {
        isLoading: true,
        blogPost: undefined,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        getBlogPost(this.props.match.params.id)
            .then((blogPost) => this.setState({blogPost, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {blogPost, isLoading} = this.state;
        if (blogPost===undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <div className="mb-default">
                    <Link to="/blog" className="btn btn-sm btn-default">❮ &nbsp; <Trans>Back to posts list</Trans></Link>
                </div>

                <h1><Trans>Blog</Trans></h1>

                <LoadingOverlay active={isLoading}>
                    <div className="blog-post">
                        {blogPost.headerImage && <div>
                            <img className="blog-post-header-image" src={blogPost.headerImage}/>
                        </div>}

                        <h3>{blogPost.title}</h3>
                        <div className="date">
                            <Trans i18nKey="blog_posted_at" values={{dateTime: moment(blogPost.createdAt).format('LLLL')}} />
                        </div>
                        <div className="share-row">
                            <AppFacebookProvider key="FB">
                                <Share href={window.location.href}>
                                    {({ handleClick, loading }) => (
                                        <button className="btn btn-primary" type="button" onClick={handleClick}>
                                            <Trans>Share</Trans>
                                        </button>
                                    )}
                                </Share>
                            </AppFacebookProvider>
                        </div>

                        <div dangerouslySetInnerHTML={{__html: blogPost.body.replace(/\n/g, '<br />')}}>
                        </div>

                        {blogPost.tags.length && <div className="mt-default">
                            <Trans>Tags</Trans>: {blogPost.tags.join(', ')}
                        </div>}
                    </div>
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(BlogPostPage);