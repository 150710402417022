import React from 'react';

export default function MatchupsSection({ games, selectedGame, onGameSelected }) {
    return (
        <ul className="games-slider">
            {games.map((game) => <li className={selectedGame && game.id === selectedGame.id ? 'selected' : null} onClick={() => onGameSelected(game)}>
                <div className="team">
                    <img style={{width:32, height: 32, objectFit: 'cover'}} src={game.roster1.team.logoUrl} />
                    {game.roster1.team.name}

                    {game.team1Points !== null && <span>&nbsp;[{game.team1Points}]</span>}
                </div>

                <div className="vs">vs</div>
                <div className="team">
                    <img style={{width:32, height: 32, objectFit: 'cover'}} src={game.roster2.team.logoUrl} />
                    {game.roster2.team.name}
                    {game.team2Points !== null && <span>&nbsp;[{game.team2Points}]</span>}
                </div>
            </li>)}
        </ul>
    );
}
