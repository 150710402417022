import React, {Component} from 'react';
import PickablePlayersTable from "modules/Players/PickablePlayersTable";
import {getCurrentLeagueId, setCurrentLeagueId} from "services/UserStateService";
import {getMyRoster} from "services/RosterService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import PlayersTable from "modules/Players/PlayersTable";
import LeagueSwitcher from "modules/Dashboard/LeagueSwitcher";
import {getLeague} from "services/LeagueService";
import {addWarningNotification} from "services/NotificationService";
import {Trans, withTranslation} from "react-i18next";
import 'styles/_fixed-data-table.css';
import TeamSelect from "modules/Leagues/TeamSelect";
import {getTeams} from "services/TeamService";
import PositionSelect from "modules/Common/Form/PositionSelect";
import PlayerStatusSelect from "modules/Common/Form/PlayerStatusSelect";

class PlayersPage extends Component {
    state = {
        league: null,
        roster: null,
        isLoading: false,
        search: {
            team: null,
            position: null,
            status: null,
            term: '',
        },
        teams: null,
        sort: 'fantasy_points',
        sortDirection: 'desc',
    };

    componentDidMount() {
        this.refreshData();
    }

    onChangeLeague = ({id: leagueId}) => {
        setCurrentLeagueId(leagueId);
        this.refreshLeague(leagueId)
            .then(() => this.refreshData())
    };

    refreshLeague(leagueId) {
        this.setState({isLoading: true});
        return getLeague(leagueId)
            .then((league) => {
                this.setState({league});
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
        ;
    };

    refreshData = () => {
        const leagueId = getCurrentLeagueId();
        if (!leagueId) {
            addWarningNotification(
                'No league selected',
                'Please choose a league first'
            );
            this.props.history.push('/secure');
            return;
        }

        this.setState({isLoading: true});

        getTeams()
            .then((teams) => {
                this.setState({teams});
            })
        ;

        //league
        getLeague(leagueId)
            .then((league) => {
                //roster
                getMyRoster(league.id)
                    .then((roster) => this.setState({league, roster, isLoading: false}))
                    .catch(({response}) => {
                        if (response.status === 422) {
                            //ignore validation error, just skip roster
                            this.setState({league, roster: null, isLoading: false});
                            return;
                        }
                        return addErrorAsNotification(response, this.props.t)
                    })
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onChangeSearch = (values) => {
        const search = {
            ...this.state.search,
            ...values
        };
        this.setState({search});
    };

    search = () => {
        this.refreshData();
    };

    resetSearch = () => {
      this.setState({search: {
          team: null,
          position: null,
          status: null,
          term: ''
      }}, () => {
          this.refreshData()
      });
    };

    onSort = (sort, sortDirection) => {
        this.setState({sort, sortDirection}, () => this.refreshData());
    };

    render() {
        const {league, roster, isLoading} = this.state;

        if (!league) {
            return <main><Loader /></main>;
        }

        const leagueStatus = league.currentSeason ? league.currentSeason.status : null;
        const playersTable = (roster && roster.status === 'open' && leagueStatus !== 'drafting')
            ? <PickablePlayersTable search={this.state.search} sort={this.state.sort} sortDirection={this.state.sortDirection} onSort={this.onSort} leagueId={league.id} rosterId={roster.id} onPlayerPicked={this.refreshData} />
            : <PlayersTable search={this.state.search} sort={this.state.sort} sortDirection={this.state.sortDirection} onSort={this.onSort} leagueId={league.id} />
        ;

        return (
            <main>
                <div className="top-side-actions">
                    <LeagueSwitcher league={league} onChangeLeague={this.onChangeLeague} />
                </div>

                <h1><Trans>Players</Trans></h1>

                <div className="form players-search">
                    <div className="form-group">
                        <label className="form-label"><Trans>Team</Trans></label>
                        <TeamSelect
                            isClearable
                            selectedTeamId={this.state.search.team}
                            className="form-control"
                            teams={this.state.teams}
                            onChange={(team) => {this.onChangeSearch({team})}}
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label"><Trans>Position</Trans></label>
                        <PositionSelect
                            isClearable
                            className="form-control"
                            selectedValue={this.state.search.position}
                            onChange={(data) => {this.onChangeSearch({position: data ? data.value : null})}}
                        />
                    </div>

                    {/*<div className="form-group">*/}
                    {/*    <label className="form-label"><Trans>Status</Trans></label>*/}
                    {/*    <PlayerStatusSelect*/}
                    {/*        isClearable*/}
                    {/*        className="form-control"*/}
                    {/*        selectedValue={this.state.search.status}*/}
                    {/*        onChange={(data) => {this.onChangeSearch({status: data ? data.value : null})}}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                <div className="players-search form mb-default" style={{display: 'block'}}>
                    <label className="form-label"><Trans>Search</Trans></label>
                    <div className="form-group">
                        <input id="term" onChange={(e) => this.onChangeSearch({term: e.target.value})} className="form-control mb-small mr-small" type="text" value={this.state.search.term} />
                        <button onClick={() => this.search()} className="btn mr-small btn-primary"><Trans>Search</Trans></button>
                        <button onClick={() => this.resetSearch()} className="btn btn-primary"><Trans>Reset</Trans></button>
                    </div>
                </div>

                {isLoading ? <Loader/> : playersTable}
            </main>
        );
    }
}

export default withTranslation()(PlayersPage);
