import { withFormik } from 'formik';
import FormikEffect from "modules/Common/FormikEffect";
import React, { Fragment } from 'react';

export const withFormikAndEffect = ({ onChange, ...rest }) => BaseComponent => {
    return withFormik(rest)(props => (
        <Fragment>
            <FormikEffect onChange={onChange.bind(this, props)} />
            <BaseComponent {...props} />
        </Fragment>
    ));
};
