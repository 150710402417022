import React from 'react';

import { compose } from "recompose";
import {withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {normalizeErrors} from "services/ValidationService";
import UploadThumbnail from "modules/Common/UploadThumbnail";

const TeamLogoForm = function({handleSubmit, values, setFieldValue, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form" noValidate>

            <input key={values.image} name="file" type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                setFieldValue("image", event.currentTarget.files[0]);
            }} className="form-control"/>

            <ErrorMessage name="image" />
            <div className='mt-default mb-small'>
                <UploadThumbnail file={values.image}/>
            </div>

            <button className="submit btn btn-sm btn-primary mr-small" type="submit">Upload Logo</button>
        </form>
    );
};


const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

export default compose(
    withFormik({
        mapPropsToValues: () => ({
            'image': null,
        }),
        validationSchema: yup.object().shape({
            image: yup
                .mixed()
                .required("Please select logo")
                .test(
                    "fileSize",
                    "File too large",
                    value => value && value.size <= FILE_SIZE
                )
                .test(
                    "fileFormat",
                    "Unsupported Format",
                    value => value && SUPPORTED_FORMATS.includes(value.type)
                )
        }),
        handleSubmit: (values, { props, setErrors, resetForm }) => {
            props.processData(values)
                .then(() => resetForm())
                .catch(({response}) => {
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(TeamLogoForm);
