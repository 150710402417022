import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import Header from "modules/Common/Header";
import Footer from "modules/Common/Footer";
import {getUser} from "services/AuthService";
import OwnerHeader from "modules/Owner/Common/Header";
import {getAds, getPageNameByLocation} from "services/AdsService";
import Banner from "modules/Common/Banner";
import {countUnreadNotifications} from "services/UserNotificationService";

class DefaultLayout extends Component {
    state = {
        ads: null,
        pageAds: null,
        nbNotifications: null
    };

    componentDidMount() {
        getAds()
            .then((ads) => {
                this.setState({ads}, () => {
                    this.loadPageAds();
                })
            })
            .catch(() => null)
        ;

        countUnreadNotifications()
            .then((nbNotifications) => {
                this.setState({nbNotifications});
            })
        ;
    }

    onUpdateNotificationsCount = (nbNotifications) => {
        this.setState({nbNotifications});
    };

    componentDidUpdate(prevProps) {
        this.loadPageAds(prevProps);
    }

    loadPageAds(prevProps) {
        if (!this.state.ads) {
            return;
        }

        if (!prevProps || this.props.location !== prevProps.location) {
            const page = getPageNameByLocation(this.props.location.pathname);

            const loadPage = 'trophy_room';
            let pageAds =  (loadPage && this.state.ads[loadPage] !== undefined) ? this.state.ads[loadPage] : null;

            // if (['players', 'game-center'].indexOf(page) >= 0) {
            //     delete pageAds['right'];
            // }

            this.setState({pageAds});
        }
    }

    render() {
        const {component: Component, wide, ...rest} = this.props;

        const user = getUser();
        const header = (user && user.roles && user.roles.includes("SUPER_ADMIN"))
            ? <OwnerHeader />
            : <Header
                nbNotifications={this.state.nbNotifications}
                onUpdateNotificationsCount={this.onUpdateNotificationsCount}
            />
        ;
        const pageAds = this.state.pageAds;

        const topAd = (pageAds && pageAds['top'] !== undefined) ? pageAds['top'] : null;
        const rightAd = (pageAds && pageAds['right'] !== undefined) ? pageAds['right'] : null;

        return (
            <Route {...rest} render={matchProps => (
                <div className="container">
                    {header}
                    <div className="wrapper">
                        <section className={"content" + (wide ? ' wide' : '')}>
                            {topAd !== null && <div className="top-banner">
                                <Banner ad={topAd} />
                            </div>}

                            <div>
                                <Component onUpdateNotificationsCount={this.onUpdateNotificationsCount} {...matchProps} />

                                {!wide && rightAd !== null && <aside className="side-banner">
                                    <Banner ad={rightAd} />
                                </aside>}
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            )}/>
        )
    }
}

export default withRouter(DefaultLayout);
