import React, {Component} from 'react';
import {getLeague, getMembers} from "services/LeagueService";
import {addErrorAsNotification} from "services/ValidationService";
import {getCurrentLeagueId, setCurrentLeagueId} from "services/UserStateService";
import {withRouter} from "react-router-dom";
import {addWarningNotification} from "services/NotificationService";
import LeagueSwitcher from "modules/Dashboard/LeagueSwitcher";
import Loader from "modules/Common/Loader";
import {Trans, withTranslation} from "react-i18next";
import {getTrophies, markTrophiesAsViewed} from "services/TrophyService";
import {refreshUser} from "services/AuthService";
import TeamSelect from "modules/Leagues/TeamSelect";
import {Initialize, Share} from 'react-facebook';
import AppFacebookProvider from "modules/Common/AppFacebookProvider";
import TrophiesNotification from "modules/Trophies/TrophiesNotification";
import Trophy from "modules/Trophies/Trophy";
import SeasonSelect from "modules/Common/Form/SeasonSelect";
import {getLatestSeason, getSeasons} from "services/SeasonService";
import Helmet from 'react-helmet';


function SEO({ pageProps }) {
    return (
        <Helmet>
            {/*<title>{pageProps.title}</title>*/}
            <meta property="og:title" content={pageProps.title} />
            <meta property="og:image" content={pageProps.thumbnail} />
            <meta property="og:url" content={pageProps.url} />
        </Helmet>
    )
}

class TrophiesPage extends Component {
    state = {
        seasons: null,
        selectedSeasonId: null,
        isLoading: true,
        league: null,
        teams: null,
        user: null,
        currentUserTeamId: null,
        trophies: null,
        notViewedTrophies: []
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = (selectedSeasonId = null) => {
        const leagueId = getCurrentLeagueId();
        if (!leagueId) {
            addWarningNotification(
                'No league selected',
                'Please choose a league first'
            );
            this.props.history.push('/secure');
            return;
        }

        this.setState({isLoading: true});

        let resolveSeason;
        if (selectedSeasonId) {
            resolveSeason = new Promise((resolve) => {
                resolve(selectedSeasonId);
            });
        } else {
            resolveSeason = getLatestSeason()
                .then((season) => {
                    return season.id;
                })
                .catch(() => {
                    return null;
                })
            ;
        }

        resolveSeason
            .then((seasonId) => {
                let requests = [
                    getSeasons()
                ];

                requests.push(
                    getLeague(leagueId)
                );

                requests.push(
                    getMembers(leagueId)
                );

                requests.push(
                    refreshUser()
                );

                Promise.all(requests)
                    .then(([seasons, league, members, user]) => {

                        let teams = [];
                        let currentUserTeamId = null;
                        for (const member of members) {
                            if (!member.team) {
                                continue;
                            }

                            if (member.user.id === user.id) {
                                currentUserTeamId = member.team.id;
                            }

                            teams.push(member.team);
                        }

                        this.setState({selectedSeasonId: seasonId, seasons, league, teams, user, currentUserTeamId, isLoading: false});

                        if (currentUserTeamId) {
                            this.onChangeTeam(currentUserTeamId, selectedSeasonId);
                        }
                    })
                    .catch(({response}) => {
                        addErrorAsNotification(response, this.props.t);
                        this.setState({isLoading: false})
                    })
                ;
            })
        ;

        //league
        getLeague(leagueId)
            .then((league) => {
                this.setState({league, isLoading: false});
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onChangeTeam = (teamId, seasonId = null) => {
        const {currentUserTeamId } = this.state;
        if (!seasonId) {
            seasonId = this.state.selectedSeasonId;
        }

        this.setState({isLoading: true, selectedSeasonId: seasonId, team: this.getTeam(teamId)});

        if (!seasonId) {
            return;
        }

        getTrophies(seasonId, teamId)
            .then((trophies) => {
                this.setState({trophies, isLoading: false});

                if (teamId === currentUserTeamId) {
                    markTrophiesAsViewed(teamId);
                    const notViewedTrophies = trophies.filter(trophy => !trophy.isViewed);
                    this.setState({notViewedTrophies});
                }
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    getTeam = (teamId) => {
        const { teams } = this.state;
        if (!teams) {
            return null;
        }

        for (const team of teams) {
            if (team.id === teamId) {
                return team;
            }
        }

        return null;
    };

    onChangeLeague = ({id: leagueId}) => {
        setCurrentLeagueId(leagueId);
        this.refreshLeague(leagueId)
            .then(() => this.refreshData())
    };

    refreshLeague(leagueId) {
        this.setState({isLoading: true});
        return getLeague(leagueId)
            .then((league) => {
                this.setState({league});
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
        ;
    };

    onChangeSeason = (selectedSeasonId) => {
        this.onChangeTeam(this.state.team.id, selectedSeasonId);
    };

    render() {
        const {league, seasons, selectedSeasonId, teams, team, trophies, notViewedTrophies, selectedTrophy, isLoading} = this.state;
        const {i18n, t} = this.props;

        if (!league || trophies === null || isLoading) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <SEO pageProps={{
                    title: 'Trophies - Fantasy Football Website',
                    thumbnail: `http://fantasyfootball.hu/img/trophies/en/MASTERMIND.png`,
                    url: "http://fantasyfootball.hu/trophies"
                }} />

                {notViewedTrophies.length>0 && team && <TrophiesNotification key={`team_${team.id}`} trophies={notViewedTrophies} />}

                <div className="top-side-actions">
                    {seasons && <SeasonSelect
                        seasons={seasons}
                        selectedValue={selectedSeasonId}
                        onChange={this.onChangeSeason}
                    />}

                    {/*<LeagueSwitcher league={league} onChangeLeague={this.onChangeLeague} />*/}
                </div>

                {team && <h1><Trans i18nKey={'Trophies - {{team}}'} values={{'team': team.name}} /></h1>}
                {team && <div style={{maxWidth: 400}}>
                    <TeamSelect
                        teams={teams}
                        selectedTeam={team}
                        onChange={(teamId) => this.onChangeTeam(teamId, selectedSeasonId)}
                    />
                </div>}

                {isLoading ? <Loader/> :
                    <AppFacebookProvider key="FB">
                        <div className="trophies">
                            {trophies && trophies.map((trophy) => <Trophy
                                key={trophy.id}
                                trophy={trophy}
                            />)}
                            <h3>{!trophies.length && <Trans>No trophies yet</Trans>}</h3>
                        </div>
                    </AppFacebookProvider>
                }
            </main>
        );
    }
}

export default withTranslation()(withRouter(TrophiesPage));
