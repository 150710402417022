import React, {Component, Fragment} from 'react';
import AddDraftModal from "./AddDraftModal";
import {updateDraft, startSeason, checkLeagueQualifications} from "services/LeagueService";
import {addErrorAsNotification} from "services/ValidationService";
import Moment from "react-moment";
import {Link, withRouter} from "react-router-dom";
import {addErrorNotification, addSuccessNotification} from "services/NotificationService";
import {canAdminLeague} from "services/UserStateService";
import ConfirmDraftModal from "modules/LeagueAdmin/Dashboard/ConfirmDraftModal";
import {getCurrentSeason} from "services/SeasonService";
import moment from "moment";
import {Trans, withTranslation} from "react-i18next";

class DraftSection extends Component {
    state = {
        showAddDraftModal: false,
        showConfirmDraftModal: false,
        startDate: null,
        endDate: null,
        focusedInput: 'START_DATE',
        isLoading: false,
        draftStartDate: null,
        draftEndDate: null,
        season: null,
        qualificationsMet: null,
    };

    componentDidMount() {
        getCurrentSeason()
            .then((season) => {
                checkLeagueQualifications(this.props.league.id, season.id)
                    .then(({qualificationsMet}) => {
                        this.setState({
                            season: (season.status==='ongoing' ? season : null),
                            qualificationsMet
                        })
                    });
            })
            .catch()
        ;
    }

    //Add draft modal
    showAddDraftModal = () => {
        this.setState({showAddDraftModal: true});
    };

    hideAddDraftModal = () => {
        this.setState({showAddDraftModal: false});
    };

    //Add draft modal
    showConfirmDraftModal = (startDate, endDate) => {
        this.setState({
            draftStartDate: startDate,
            draftEndDate: endDate,
        });

        this.setState({showConfirmDraftModal: true});
    };

    hideConfirmDraftModal = () => {
        this.setState({showConfirmDraftModal: false});
    };

    onStartAddDraft = () => {
        const {t} = this.props;
        const league = this.props.league;
        const season = this.state.season;

        if (league.nbTeams < league.nbMaxTeams) {
            addErrorNotification(
                t('Cannot add draft'),
                t('League slots are not filled yet for the draft')
            );
            return;
        }

        if (league.nbTeams > season.nbMaxFantasyTeams) {
            addErrorNotification(
                t('Cannot add draft'),
                t('league_not_qualified', {nbLeagueTeams: league.nbTeams, nbSeasonTeams: season.nbTeams})
            );
            return;
        }

        this.showAddDraftModal();
    };

    onStartChangeDraft = () => {
        const currentSeason = this.props.league.currentSeason;
        const timezone = this.props.timezone;
        const draftStartDate = moment.tz(currentSeason.draftStartsAt, timezone);
        const draftEndDate = moment.tz(currentSeason.draftEndsAt, timezone);
        this.setState({draftStartDate, draftEndDate});

        this.showAddDraftModal();
    };

    onDraftDatesSelected = ({ startDate, endDate }) => {
        this.showConfirmDraftModal(startDate, endDate);
    };

    onDraftConfirmed = (startDate, endDate) => {
        if (this.props.league.currentSeason) {
            this.executeChangeDraft(startDate, endDate);
        } else {
            this.executeStartDraft(startDate, endDate);
        }
    };

    executeStartDraft = (startDate, endDate) => {
        const {t} = this.props;
        const leagueId = this.props.league.id;
        startSeason(
            leagueId,
            {
                draftStartsAt: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
                draftEndsAt: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
            }
        )
            .then(() => {
                addSuccessNotification(
                    t('Draft started'),
                    t('You successfully created a draft')
                );
                this.props.onRefreshData();
            })
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
    };

    executeChangeDraft = (startDate, endDate) => {
        const {t} = this.props;
        const leagueId = this.props.league.id;
        const leagueSeasonId = this.props.league.currentSeason.id;

        updateDraft(
            leagueId,
            leagueSeasonId,
            {
                draftStartsAt: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
                draftEndsAt: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
            }
        )
            .then(() => {
                addSuccessNotification(
                    t('Draft updated'),
                    t('You successfully updated a draft')
                );
                this.props.onRefreshData();
            })
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        const {showAddDraftModal, showConfirmDraftModal, season, qualificationsMet} = this.state;
        const {league, timezone} = this.props;
        if (!league) {
            return null;
        }

        const currentSeason = league.currentSeason;
        const isPendingSeason = (currentSeason && currentSeason.status === 'pending');
        const isDrafting = (currentSeason && currentSeason.status === 'drafting');
        const showAddDraft = season && canAdminLeague(league.id) && !currentSeason;
        const showQualificationsNotMet = showAddDraft && (qualificationsMet === false);
        const showEditDraft = season && canAdminLeague(league.id) && currentSeason;

        if (!isPendingSeason && !isDrafting && !showAddDraft) {
            return null;
        }

        // const x = (<Moment tz={timezone} format="Do MMMM YYYY, h:mm:ss A Z">{currentSeason.draftStartsAt}</Moment>).render();

        return (
            <div className="draft-section">
                {isPendingSeason && <Fragment>
                    <p>
                        <Trans i18nKey="Your Draft will start at {{date}}" values={{date: moment(currentSeason.draftStartsAt).tz(timezone).format('LLLL')}} />


                        <p className="mt-small">
                            {showEditDraft
                                ? <button onClick={() => this.onStartChangeDraft()} className="btn btn-primary">
                                    <Trans>Change dates</Trans>
                                </button>
                                : <button disabled className="btn btn-primary"><Trans>Enter Draft</Trans></button>
                            }
                        </p>
                    </p>
                </Fragment>}

                {isDrafting && <Fragment>
                    <div>
                        <p><Trans>Your Draft is live now</Trans></p>
                        <p className="mt-small">
                            <Link to={"/leagues/"+league.id+"/draft"} className="btn btn-primary"><Trans>Enter Draft</Trans></Link>
                        </p>
                    </div>
                </Fragment>}

                {showAddDraft && <button disabled={showQualificationsNotMet} className="btn btn-primary" onClick={() => this.onStartAddDraft()}>
                    <Trans>Add Draft</Trans></button>}
                {showAddDraft && showQualificationsNotMet && <div className="mt-default">

                    <Trans values={{nbLeagueTeams: league.nbTeams, nbSeasonTeams: season.nbTeams}}>league_not_qualified</Trans>

                    {/*Your league does not qualify for the draft yet, it should have <strong>even</strong> quantity of teams <br/>*/}
                    {/*which should be less than quantity of real life teams <strong>({season.nbTeams})</strong>.*/}
                </div>}


                {this.state.season && <AddDraftModal
                    timezone={timezone}
                    isOpen={showAddDraftModal}
                    draftWindow={this.state.season.draftWindow}
                    startDate={this.state.draftStartDate}
                    endDate={this.state.draftEndDate}
                    handleCloseModal={() => this.hideAddDraftModal()}
                    onSubmit={this.onDraftDatesSelected}
                />}

                {this.state.season && <ConfirmDraftModal
                    timezone={timezone}
                    leagueId={this.props.league.id}
                    draftStartDate={this.state.draftStartDate}
                    draftEndDate={this.state.draftEndDate}
                    isOpen={showConfirmDraftModal}
                    handleCloseModal={() => this.hideConfirmDraftModal()}
                    onConfirm={this.onDraftConfirmed}
                    onCancel={() =>{
                        this.showAddDraftModal()
                    }}
                />}
            </div>
        );
    }
}

export default withTranslation()(DraftSection);
