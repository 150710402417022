import React, {Component} from 'react';
import {confirmAccount} from "services/AuthService";
import {normalizeGeneralError} from "services/ValidationService";
import {Redirect} from "react-router-dom";
import {addErrorNotification, addSuccessNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";

class ConfirmAccountPage extends Component {
    state = {
        redirectToLoginPage: false
    };

    componentDidMount() {
        const {t} = this.props;
        confirmAccount(this.props.match.params.confirmationToken)
            .then(() => {
                addSuccessNotification(
                    t('Your account was successfully confirmed'),
                    t('Please login to continue')
                );
                this.setState({redirectToLoginPage: true});
            })
            .catch(({response}) => addErrorNotification(this.props.t('Error'), normalizeGeneralError(response)))
    }

    render() {
        if (this.state.redirectToLoginPage) {
            return <Redirect to="/login" />
        }

        return null;
    }
}

export default withTranslation()(ConfirmAccountPage);
