import React from 'react';
import {Link} from "react-router-dom";
import moment from "moment";

const FeaturedBlogPostListItem = ({blogPost, type}) => {
    return (
        <Link to={`blog/${blogPost.id}`} key={blogPost.id} className={`featured-blog-post featured-blog-post-${type}`}>
            <div className={`featured-blog-post-header-image` + (!blogPost.headerImage ? ' featured-blog-post-header-image-none' : '')}>
                {blogPost.headerImage
                    ? <img className="img" src={blogPost.headerImage}/>
                    : <div className="img" />
                }
            </div>

            <div className="featured-blog-post-heading">
                <div className="featured-blog-post-date">
                    {moment(blogPost.createdAt).format('LLLL')}
                </div>

                <h3 className="featured-blog-post-title">{blogPost.category} - {blogPost.title}</h3>
            </div>

            <div dangerouslySetInnerHTML={{__html: blogPost.intro}} />
        </Link>
    );
};

export default FeaturedBlogPostListItem;