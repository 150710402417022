import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {addErrorAsNotification, normalizeErrors} from "services/ValidationService";
import {Trans, withTranslation} from "react-i18next";

const ChangeEmailForm = function({handleSubmit}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form center-form" noValidate>
            <h1><Trans>Change e-mail</Trans></h1>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>New e-mail</Trans></label>
                <Field type="email" name="newEmail" className="form-control" />
                <ErrorMessage name="newEmail" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Confirm e-mail</Trans></label>
                <Field type="email" name="newEmailConfirm" className="form-control" />
                <ErrorMessage name="newEmailConfirm" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Change e-mail</Trans></button>
                <Link to="/profile" className="btn btn-default"><Trans>Cancel</Trans></Link>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: () => ({
            'newEmail': '',
            'newEmailConfirm': '',
        }),
        validationSchema: ({ t }) => yup.object().shape({
            newEmail: yup.string()
                .required(t('Please input your new e-mail'))
                .email(t('E-mail format is incorrect')),
            newEmailConfirm: yup.string()
                .required(t('Please confirm your new e-mail'))
                .email(t('E-mail format is incorrect'))
                .oneOf([yup.ref('newEmail'), null], t('E-mails must match'))
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(ChangeEmailForm);
