import React from 'react';
import {NavLink} from "react-router-dom";
import {Trans} from "react-i18next";
import LangLinks from "modules/Common/LangLinks";

function SecureNavActions({nbNotifications}) {
    let notificationOptions = {};
    if (nbNotifications > 0) {
        notificationOptions['data-badge'] = nbNotifications;
    }

    return (
        <nav className="nav-actions">
            <LangLinks />
            <ul className="nav-links extras">
                <li><NavLink to="/notifications">
                    <span style={{position: 'relative', display: 'inline-block', paddingRight: 5}} {...notificationOptions}>
                        <span className="lg-down"><Trans>Notifications</Trans></span>
                        <img className="lg-up" src="/img/icons/notification.png" style={{width: 30}} />
                    </span>
                </NavLink></li>
                <li><NavLink to="/settings">
                    <span className="lg-down"><Trans>Settings</Trans></span>
                    <img className="lg-up" src="/img/icons/settings.png" style={{width: 30}} />
                </NavLink>
                </li>
                <li><NavLink to="/profile">
                    <span className="lg-down"><Trans>Profile</Trans></span>
                    <img className="lg-up" src="/img/icons/user.png" style={{height: 25, marginLeft: 5}} />
                </NavLink>
                </li>
                <li><NavLink to="/logout"><Trans>Logout</Trans></NavLink></li>
            </ul>
        </nav>
    );
}

export default SecureNavActions;
