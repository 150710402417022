import React from 'react';
import {Field, FieldArray } from "formik";
import SlideWidget from "modules/Common/Form/SlideWidget";

export const SliderWidget = ({field, disabled }) => {
    const {name, value} = field;

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className="slider-form-widget">
                    <div className="slider-form-widget-slides">
                        {value && value.length > 0 && (
                            value.map((_, index) => (
                                <div key={`${index}`} className="slider-form-row">
                                    <Field disabled={disabled} name={`${name}[${index}]`} component={SlideWidget} />
                                    {!disabled && <button type="button" className="btn btn-sm btn-default" onClick={() => arrayHelpers.remove(index)}>✕</button>}
                                </div>
                            ))
                        )}
                    </div>

                    {!disabled && <button className="btn btn-default" type="button" onClick={() => arrayHelpers.push({})}>
                        Add
                    </button>}
                </div>
            )}
        />
    );
}
