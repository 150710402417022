import React from 'react';
import Select from "react-select";
import {withTranslation} from "react-i18next";

const TeamSelect = ({ t, teams, selectedTeamId, selectedTeam, onChange, ...rest }) => {
    function formatTeamName(team) {
        return team.name;
    }

    const prepareTeamsList = () => {
        let options = [];
        if (!teams) {
            return [];
        }

        teams.forEach((team) => {
            options.push({
                value: team.id,
                label: formatTeamName(team)
            });
        });

        return options;
    };

    if (selectedTeam) {
        selectedTeamId = selectedTeam.id;
    }

    const teamsOptions = prepareTeamsList();
    let selectedTeamOption = null;
    for (const teamOption of teamsOptions) {
        if (teamOption.value == selectedTeamId) {
            selectedTeamOption = teamOption;
        }
    }

    return (
        <Select
            placeholder={t('Select')}
            className="language-switcher-select"
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            options={teamsOptions}
            value={selectedTeamOption}
            onChange={(option) => onChange(option ? option.value : null)}
            {...rest}
        />
    );
};

export default withTranslation()(TeamSelect);
