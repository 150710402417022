import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {getCurrentSeason, getSeasons} from "services/SeasonService";
import Loader from "modules/Common/Loader";
import SeasonDetails from "modules/Owner/Season/SeasonDetails";
import {addErrorAsNotification} from "services/ValidationService";
import SeasonsTable from "modules/Owner/Dashboard/SeasonsTable";
import {setSelectedSeasonId} from "services/UserStateService";
import {getSettings} from "services/SettingService";
import {withTranslation} from "react-i18next";

class SeasonsPage extends Component {
    state = {
        season: undefined,
        seasons: undefined,
        timezone: undefined,
        isLoading: true,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        this.setState({isLoading: true});
        getCurrentSeason()
            .then((season) => this.setState({season}))
            .catch(({response}) => {
                if (response.status === 404) {
                    //no active season
                    this.setState({season: null});
                    return;
                }
                addErrorAsNotification(response, this.props.t);
            })
            .finally(() => this.setState({isLoading: false}))
        ;

        const requests = Promise.all([
            getSettings(),
            getSeasons()
        ]);

        requests
            .then(([settings, seasons]) => {
                seasons = seasons.filter((season) => season.status!=='ongoing');
                this.setState({timezone: settings.timezone, seasons, isLoading: false})
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    }

    onSelectSeason = (season) => {
        setSelectedSeasonId(season.id);
        this.props.history.push('/owner/stats');
    };

    render() {
        const {season, timezone, isLoading} = this.state;

        if (season === undefined || isLoading) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <h1>Season</h1>
                {season
                    ? <SeasonDetails timezone={timezone} season={season}/>
                    : <div>
                        <h4 className="mb-small">No Active Season</h4>
                        <Link className="btn btn-success" to="/owner/seasons/add">Start New Season</Link>
                    </div>
                }

                {
                    this.state.seasons && this.state.seasons.length>0 &&
                    <div className="mt-default">
                        <h3 className="mb-small">Previous seasons</h3>
                        <SeasonsTable
                            seasons={this.state.seasons}
                            timezone={timezone}
                            onSelect={this.onSelectSeason}
                        />
                    </div>
                }
            </main>
        );
    }
}

export default withTranslation()(withRouter(SeasonsPage));
