import React from 'react';
import {Field, withFormik} from "formik";
import * as yup from "yup";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import DatePickerWidget from "modules/Common/DatePickerWidget";
import moment from "moment";

function GameDateForm({ errors, submitButtonRef, handleSubmit }) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form game-form" noValidate>
            <div>
                <div className="mb-small">
                    <Field placeholder="Date" className="mr-small" name="date" component={DatePickerWidget} appendToBody={true} />
                </div>
                <ErrorMessage name="date" />
            </div>

            <div className="form-group mt-default" style={{display: 'none'}}>
                <button ref={submitButtonRef} className="submit btn btn-primary mr-small" type="submit">Save Changes</button>
            </div>
        </form>
    );
}

export default
    withFormik({
        mapPropsToValues: ({ game }) => {
            return {
                'date': moment(game.date)
            }
        },
        validationSchema: () => {
            return yup.object().shape({
                date: yup.date()
                    .typeError('This date is invalid, use DD/MM/YYYY format')
                    .transform(function(value, originalValue) {
                        value = moment(originalValue, 'DD/MM/YYYY', true);
                        return value.isValid() ? value.toDate() : new Date('');
                    })
                    .required('Date is required'),
            })
        },
        handleSubmit: (values, { props }) => {
            const data = {...values};
            props.processData(data);
        }
    })(GameDateForm)
;
