import React from 'react';
import {Trans, withTranslation} from "react-i18next";
import {Cell, Column, Table} from "fixed-data-table-2";
import Dimensions from "react-dimensions";
import * as PropTypes from "prop-types";
import PlayerInfoPopup from "modules/Players/PlayerInfoPopup";

class PlayersTableRawFixed extends React.Component {
    state = {
        isPlayerPopupOpen: false,
        selectedPlayer: null,
        selectedPlayerScores: null,
    };

    showPlayerPopup = (player, scores) => {
        this.setState({
            isPlayerPopupOpen: true,
            selectedPlayer: player,
            selectedPlayerScores: scores
        })
    };

    hidePlayerPopup = () => {
        this.setState({
            isPlayerPopupOpen: false
        });
    };

    render() {
        let {
            pickEnabled, players, onPickPlayer, scores,
            containerWidth, containerHeight,
            t, sort, sortDirection, onSort
        } = this.props;
        if (!players) {
            return null;
        }

        const currentSortKey = sort;

        const SortButton = ({label, sort, sortDirection, sortKey}) => {
            const isActiveSort = (sort === sortKey);
            const className = "sort-button " + (isActiveSort ? 'active' : '');
            const value = (isActiveSort ? (sortDirection === 'asc' ? "▲" : "▼") : "") + " " + t(label);
            const newSortDirection = (currentSortKey === sortKey) ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';

            return <Cell><input type="button" value={value} className={className}
                                onClick={() => onSort(sortKey, newSortDirection)}/></Cell>;
        };

        const PickCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            return (<Cell>
                <button className="btn btn-default btn-sm" onClick={() => onPickPlayer(player)}>
                    <Trans>Pick</Trans>
                </button>
            </Cell>);
        };

        const AvatarCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }
            return (
                <Cell>
                    <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                        {player.photo && <img className="mb-small mr-small" width={40} src={player.photo}/>}
                    </div>
                </Cell>
            );
        };

        const NameCell = ({rowIndex, ...rest}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            const playerScores = scores[player.id];
            return (
                <Cell>
                    {player.name}
                    {/*<PlayerToolTip player={player} scores={playerScores} />*/}
                </Cell>
            );
        };

        const PlayerInfoCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            return (
                <Cell>
                    <button className="btn btn-info btn-danger"
                            onClick={() => this.showPlayerPopup(player, scores[player.id])}>
                        <span style={{fontSize: '1.4em'}}>ⓘ</span>
                    </button>
                </Cell>
            )
        };

        const PositionCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            return <Cell><Trans>{player.position}</Trans></Cell>;
        };

        const TeamCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            return <Cell>{player.team.name}</Cell>;
        };

        const StatusCell = ({rowIndex}) => {
            const player = players[rowIndex];
            if (!player) {
                return null;
            }

            return <Cell><Trans>{player.status}</Trans></Cell>;
        };

        const ScoreCell = ({rowIndex, columnKey}) => (<Cell>
            {players[rowIndex] ? players[rowIndex].scores[columnKey] : null}
        </Cell>);


        return (
            <div style={{fontSize: 14}}>
                <PlayerInfoPopup
                    isOpen={this.state.isPlayerPopupOpen}
                    handleCloseModal={this.hidePlayerPopup}
                    player={this.state.selectedPlayer}
                    scores={this.state.selectedPlayerScores}
                />

                <Table
                    rowHeight={50}
                    headerHeight={30}
                    rowsCount={players.length}
                    width={containerWidth}
                    height={containerHeight}
                >
                    {pickEnabled && <Column
                        columnKey="pick"
                        width={80}
                        flexGrow={1}
                        cell={PickCell}
                        fixed={true}
                    />}

                    <Column
                        columnKey="avatar"
                        width={50}
                        cell={AvatarCell}
                        fixed={true}
                    />

                    <Column
                        columnKey="name"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="name" label="Name"/>}
                        width={200}
                        flexGrow={2}
                        cell={NameCell}
                        fixed={true}
                    />


                    <Column
                        columnKey="popup"
                        header={<Cell/>}
                        width={35}
                        flexGrow={1}
                        cell={PlayerInfoCell}
                        fixed={true}
                    />

                    <Column
                        columnKey="position"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="position"
                                            label="Position"/>}
                        width={70}
                        flexGrow={1}
                        cell={PositionCell}
                        fixed={true}
                    />

                    <Column
                        columnKey="team"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="teamName" label="Team"/>}
                        width={100}
                        flexGrow={1}
                        cell={TeamCell}
                        fixed={true}
                    />

                    <Column
                        columnKey="status"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="status" label="Status"/>}
                        width={100}
                        flexGrow={1}
                        cell={StatusCell}
                    />

                    <Column
                        columnKey="pass_yds"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="pass_yds"
                                            label="Pass yds"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="rush_yds"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="rush_yds"
                                            label="Rush yds"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="catch_yds"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="catch_yds"
                                            label="Catch yds"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />
                    <Column
                        columnKey="td"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="td" label="TD"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="_2pt"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="_2pt" label="2pt"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fumble"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="fumble" label="Fumble"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    {/*<td>{playerScores['int'] ? playerScores['int'] : playerScores['defense_int']}</td>*/}
                    <Column
                        columnKey="int"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="int" label="INT"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fg"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="fg" label="FG"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="xp"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="xp" label="XP"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fg_miss"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="fg_miss"
                                            label="FG miss"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="xp_miss"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="xp_miss"
                                            label="XP miss"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fum_forced"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="fum_forced"
                                            label="Fum forced"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="sack"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="sack" label="Sack"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="pts_allowed"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="pts_allowed"
                                            label="Pts allowed"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="win_loss"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="win_loss"
                                            label="Win Loss"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="pts_gained"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="pts_gained"
                                            label="Pts gained"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="score_difference"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="score_difference"
                                            label="Score difference"/>}
                        width={100}
                        flexGrow={1}
                        cell={ScoreCell}
                    />

                    <Column
                        columnKey="fantasy_points"
                        header={<SortButton sort={sort} sortDirection={sortDirection} sortKey="fantasy_points"
                                            label="Fantasy Points"/>}
                        width={120}
                        flexGrow={1}
                        cell={ScoreCell}
                        fixedRight={true}
                    />
                </Table>
            </div>
        );
    }
}

PlayersTableRawFixed.propTypes = {
    pickEnabled: PropTypes.any,
    players: PropTypes.any,
    onPickPlayer: PropTypes.any,
    scores: PropTypes.any,
    containerWidth: PropTypes.any,
    containerHeight: PropTypes.any,
    t: PropTypes.any,
    sort: PropTypes.any,
    sortDirection: PropTypes.any,
    onSort: PropTypes.any
}

// See react-dimensions for the best way to configure
// https://github.com/digidem/react-dimensions
export default withTranslation()(
    Dimensions({
        getHeight: function(element) {
            return window.innerHeight - 200;
        },
        getWidth: function(element) {
            var widthOffset = window.innerWidth < 680 ? 0 : 60;
            return window.innerWidth - widthOffset;
        }
    })(PlayersTableRawFixed)
);