import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {normalizeGeneralError} from "services/ValidationService";
import {addErrorNotification} from "services/NotificationService";
import {Trans, withTranslation} from "react-i18next";

const InviteForm = function({handleSubmit, onCancel}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form invite-form" noValidate>
            <h1><Trans>Invite member</Trans></h1>

            <p className="mb-default"><Trans>Please add the e-mail address of the member you wish to invite</Trans>:</p>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>E-mail</Trans></label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage name="email" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Invite</Trans></button>
                <button onClick={onCancel} className="btn btn-default" type="button"><Trans>Cancel</Trans></button>
            </div>

        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: () => ({
            'email': '',
        }),
        validationSchema: ({ t }) => yup.object().shape({
            email: yup.string()
                .required(t('You didn\'t fill in your e-mail'))
                .email(t('E-mail format is incorrect')),
        }),
        handleSubmit: (values, { props }) => {
            props.onInvite(values)
                .catch(({response}) => {
                    addErrorNotification(props.t('Error'), normalizeGeneralError(response));
                })
            ;
        }
    })
)(InviteForm);
