import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function RemovePendingMemberConfirmationModal({pendingMember, onConfirm, t, ...rest}) {
    if (!pendingMember) {
        return null;
    }

    const name = pendingMember.displayName;
    return (
        <ConfirmationModal
            title={t("Remove invitation")}
            content={t("Are you sure you want to remove {{name}} from the league members?", {name})}
            buttons={{
                yes: t('Remove'),
                no: t('Cancel')
            }}
            type="destructive"
            onConfirm={() => onConfirm(pendingMember)}
            {...rest}
        />
    );
}

export default withTranslation()(RemovePendingMemberConfirmationModal);
