import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import React from "react";

export default function DeleteMessageTemplateConfirmationModal({onConfirm, templateTitle, ...rest}) {
    return (
        <ConfirmationModal
            title={"Delete Message Template"}
            content={`Are you sure you want to delete message template "${templateTitle}"?`}
            buttons={{
                yes: 'Yes, delete',
                no: 'Cancel'
            }}
            type="destructive"
            onConfirm={onConfirm}
            {...rest}
        />
    );
}
