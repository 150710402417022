import React from 'react';
import Select from "react-select";

function getPossibleStatusesAsOptions() {
    return [
        {value: 'active', label: 'Active'},
        {value: 'inactive', label: 'Inactive'},
        {value: 'bye', label: 'Bye'},
    ];
}

const TeamStatusSelect = ({form, field, ...rest}) => {
    const options = getPossibleStatusesAsOptions();
    const selectedOption = options.find((option) => option.value === field.value);

    return (
        <Select
            defaultValue={selectedOption}
            menuPortalTarget={document.body}
            options={options}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default TeamStatusSelect;
