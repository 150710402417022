import React, {Component} from 'react';
import MembersTable from "./MembersTable";
import {getMembers} from "services/LeagueService";
import Loader from "modules/Common/Loader";

export default class DashboardWidgets extends Component {
    state = {
        isLoading: false,
        members: null,
    };

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.league !== this.props.league) {
            this.refreshData();
        }
    }

    refreshData() {
        const league = this.props.league;
        if (!league) {
            return;
        }

        const leagueId = league.id;

        this.setState({isLoading: true});
        getMembers(leagueId)
            .then((members) => {
                this.setState({members});
            }).finally(() => {
                this.setState({isLoading: false});
            })
        ;
    }


    render() {
        const {league} = this.props;
        const {members, isLoading} = this.state;

        if (!league) {
            return null;
        }

        if (isLoading) {
            return <Loader/>
        }

        return (
            <div>
                {members && <MembersTable members={members}/>}
            </div>
        );
    }
}
