import API from "services/API";

export function updateSeason(seasonId, values) {
    return API.post(`/seasons/${seasonId}`, values)
        .then((response) => response.data)
        ;
}

export function startNewSeason(values) {
    return API.post('/seasons', values)
        .then((response) => response.data)
    ;
}

export function updateSchedule(seasonId, values) {
    return API.post(`/seasons/${seasonId}/schedule`, values)
        .then((response) => response.data)
    ;
}

export function getLatestSeason() {
    return API.get('/seasons/latest')
        .then((response) => response.data)
        ;
}

export function getCurrentSeason() {
    return API.get('/seasons/current')
        .then((response) => response.data)
    ;
}

export function getSeason(seasonId) {
    return API.get(`/seasons/${seasonId}`)
        .then((response) => response.data)
    ;
}

export function getSeasons() {
    return API.get('/seasons')
        .then((response) => response.data)
    ;
}

export function startNextWeek() {
    return API.post('/seasons/current/start-week')
        .then((response) => response.data)
    ;
}

export function freezeCurrentWeek() {
    return API.post(`/seasons/current/weeks/current/freeze`)
        .then((response) => response.data)
    ;
}

export function recalculatePoints() {
    return API.post(`/seasons/current/recalculate`)
        .then((response) => response.data)
    ;
}

export function finishWeek(week) {
    return API.post(`/seasons/current/weeks/${week}/finish`)
        .then((response) => response.data)
    ;
}

export function finishSeason() {
    return API.post(`/seasons/current/finish`)
        .then((response) => response.data)
    ;
}

export function groupScoresByWeekAndPlayer(games) {
    let grouppedScores = [];

    if (!games) {
        return [];
    }

    for (const game of games) {
        const teams = [game.team1, game.team2];

        let teamNumber = 1;
        for (const team of teams) {
            const teamRound = (teamNumber === 1) ? game.team1Round : game.team2Round;
            ++teamNumber;

            for (const playersScore of team.playersScores) {
                if (grouppedScores[teamRound] === undefined) {
                    grouppedScores[teamRound] = [];
                }

                if (grouppedScores[teamRound][playersScore.playerId] === undefined) {
                    grouppedScores[teamRound][playersScore.playerId] = {...playersScore.scores};
                    continue;
                }

                const currentScores = grouppedScores[teamRound][playersScore.playerId];
                for (const attr of Object.keys(currentScores)) {
                    grouppedScores[teamRound][playersScore.playerId][attr] = grouppedScores[teamRound][playersScore.playerId][attr] + playersScore.scores[attr];
                }
            }
        }
    }

    return grouppedScores;
}

function sumScores(scores1, scores2) {
    if (scores1 === undefined) {
        return scores2;
    }

    if (scores2 === undefined) {
        return scores1;
    }

    let scores = {};
    for (const attr in scores1) {
        const score1 = scores1.hasOwnProperty(attr) ? scores1[attr] : 0;
        const score2 = scores2.hasOwnProperty(attr) ? scores2[attr] : 0;
        scores[attr] = (score1 + score2);
    }

    return scores;
}

export function groupScoresByPlayerAndRound(games) {
    let grouppedScores = [];

    for (const game of games) {
        const rounds = [game.team1Round, game.team2Round];
        const teams = [game.team1, game.team2];

        for (let i=0; i<=1; ++i) {
            const team = teams[i];
            const round = rounds[i];
            for (const playersScore of team.playersScores) {
                if (grouppedScores[playersScore.playerId] === undefined) {
                    grouppedScores[playersScore.playerId] = [];
                }

                grouppedScores[playersScore.playerId][round] = sumScores(playersScore.scores, grouppedScores[playersScore.playerId][round]);
                grouppedScores[playersScore.playerId][round]['game_score'] = team.score;
            }
        }
    }

    return grouppedScores;
}
