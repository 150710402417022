import React from 'react';
import {Trans} from "react-i18next";
import LeagueActions from "modules/Leagues/LeagueActions";

export default function LeaguesList({ user, leagues, onShowLeagueDetails, onJoinLeague, onCancelPendingMembership }) {
    return (
        <div className="table-responsive">
            {leagues.length>0 ? <table className="table leagues-table">
                <thead>
                <tr>
                    <th><Trans>ID</Trans></th>
                    <th><Trans>League name</Trans></th>
                    <th><Trans>Status</Trans></th>
                    <th><Trans>Max Number of Teams</Trans></th>
                    <th><Trans>Available Slots</Trans></th>
                    <th className="text-center"><Trans>Action</Trans></th>
                </tr>
                </thead>
                <tbody>
                {leagues.map((league) => (
                    <tr key={league.id}>
                        <td>{league.id}</td>
                        <td><a onClick={(e) => {e.preventDefault(); onShowLeagueDetails(league)}} href="#">{league.name}</a></td>
                        <td><Trans>{(league.nbAvailableSlots > 0) ? 'Available' : 'Full'}</Trans></td>
                        <td className="text-right">{league.nbMaxTeams}</td>
                        <td className="text-right">{league.nbAvailableSlots}</td>
                        <td className="text-center">
                            <LeagueActions
                                league={league}
                                user={user}
                                onJoinLeague={onJoinLeague}
                                onCancelPendingMembership={onCancelPendingMembership}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table> : <h3><Trans>No records found</Trans></h3>}

        </div>
    );
}
