import React, {Component} from 'react';
import {addErrorAsNotification} from "services/ValidationService";
import {deleteUser, getUsers, getUsersCsvUrl, getUsersFilters} from "services/UserService";
import UsersTable from "modules/Owner/Users/UsersTable";
import Loader from "modules/Common/Loader";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import DeleteUserConfirmationModal from "modules/Owner/Users/DeleteUserConfirmationModal";
import {addSuccessNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";
import FilterForm from "modules/Owner/Users/FilterForm";
import SendEmailModal from "modules/Owner/Users/SendEmailModal";
import {deleteTemplate, loadMessagePreview, saveTemplate, sendMessages} from "services/MessageService";
import PreviewMessageModal from "modules/Owner/Users/PreviewMessageModal";
import DeleteMessageTemplateConfirmationModal from "modules/Owner/Users/DeleteMessageTemplateConfirmationModal";
import SendMessagesConfirmationModal from "modules/Owner/Users/SendMessagesConfirmationModal";

class UsersPage extends Component {
    state = {
        isLoading: true,
        filters: {},
        filtersOptions: undefined,
        users: undefined,
        selectedUser: null,
        selectedUsers: [],
        showDeleteUserConfirmationModal: false,
        showSendEmailModal: false,
        showPreviewModal: false,
        showMessageTemplateDeleteConfirmationModal: false,
        previewContent: null,

        selectedMessageTemplateId: null,
        selectedMessageTemplateTitle: null,
        refreshIndex: 0,

        showSendMessagesConfirmationModal: false,
        sendMessagesData: null,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        const requests = Promise.all([
            getUsersFilters(),
            getUsers(this.state.filters)
        ]);

        requests
            .then(([filtersOptions, users]) => {
                this.setState({filtersOptions, users});
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
            })
            .finally(() =>{
                this.setState({isLoading: false, refreshIndex: this.state.refreshIndex+1});
            })
        ;
    };

    onFilter = (filters) => {
        this.setState({filters, selectedUsers: []});
        this.refreshData();
    };

    //Message Template Delete confirmation
    showMessageTemplateDeleteConfirmationModal = (selectedMessageTemplateId, selectedMessageTemplateTitle) => {
        this.setState({
            selectedMessageTemplateId,
            selectedMessageTemplateTitle,
            showMessageTemplateDeleteConfirmationModal: true
        });
    };

    hideMessageTemplateDeleteConfirmationModal = () => {
        this.setState({showMessageTemplateDeleteConfirmationModal: false});
    };

    //DELETE
    showDeleteUserConfirmationModal = (selectedUser) => {
        this.setState({selectedUser, showDeleteUserConfirmationModal: true});
    };

    hideDeleteUserConfirmationModal = () => {
        this.setState({showDeleteUserConfirmationModal: false});
    };

    onDelete = (user) => {
        this.showDeleteUserConfirmationModal(user);
    };

    onExecuteDeleteUser = (user) => {
        this.setState({isLoading: true});
        deleteUser(user.id)
            .then(() => {
                addSuccessNotification('Success', `User "${user.name}" was successfully deleted`);
                this.refreshData();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
        ;
    };
    
    //SELECTION
    onToggleSelection = (user, checked) => {
        let selectedUsers;

        if (user === null) {
            //"check/uncheck all" mode
            selectedUsers = checked ? true : [];
        } else {
            //individual selection mode
            selectedUsers = this.state.selectedUsers;
            if (selectedUsers === true) {
                //Expand selected users from all loaded users
                selectedUsers = this.state.users.map((user) => user.id);
            }

            const id = user.id;
            if (checked) {
                selectedUsers.push(id);
            } else {
                selectedUsers = selectedUsers.filter(userId => userId !== id);
            }
        }

        this.setState({selectedUsers});
    };

    isSelected = (user) => {
        const { selectedUsers } = this.state;
        if (selectedUsers === true) {
            return true;
        }

        if (user === null) {
            return false;
        }

        return selectedUsers.includes(user.id);
    }

    getNbSelectedUsers = () => {
        const { selectedUsers } = this.state;
        if (selectedUsers === true) {
            return this.state.users.length;
        }

        return selectedUsers.length;
    }

    //Gets all selected users ids (and expands "select all" to individual ids)
    getExpandedSelectedUsersIds = () => {
        const {selectedUsers} = this.state;
        if (selectedUsers === true) {
            return this.state.users.map((user) => user.id);
        }

        return selectedUsers;
    };

    //Send Emails
    showSendEmailModal = (selectedUser) => {
        this.setState({selectedUser, showSendEmailModal: true});
    };

    hideSendEmailModal = () => {
        this.setState({showSendEmailModal: false});
    };

    showPreviewModal = (previewContent) => {
        this.setState({showPreviewModal: true, previewContent});
    };

    hidePreviewModal = () => {
        this.setState({showPreviewModal: false});
    };

    onSendMessages = (values) => {
        this.showSendMessagesConfirmationModal(values);
    };

    onExecuteSendMessages = (values) => {
        this.setState({isLoading: true});
        const recipients = this.getExpandedSelectedUsersIds();
        return sendMessages(values, recipients)
            .then(({nbSentMessages}) => {
                addSuccessNotification(
                    'Success',
                    `Successfully sent ${nbSentMessages} message` + (nbSentMessages > 1 ? 's' : '')
                );
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    onLoadMessagePreview = (values) => {
        this.setState({isLoading: true});
        return loadMessagePreview(values)
            .then((data) => this.showPreviewModal(data))
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    onSaveTemplate = (values) => {
        this.setState({isLoading: true});
        return saveTemplate(values)
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    onDeleteTemplate = (templateId, templateTitle) => {
        this.showMessageTemplateDeleteConfirmationModal(templateId, templateTitle);
    };

    onExecuteDeleteTemplate = (templateId) => {
        this.setState({isLoading: true});
        return deleteTemplate(templateId)
            .then(() => {
                this.refreshData();
                addSuccessNotification(
                    'Success',
                    `Template was successfully deleted`
                );
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };


    //Send messages confirmation modal
    showSendMessagesConfirmationModal = (sendMessagesData) => {
        this.setState({
            sendMessagesData,
            showSendMessagesConfirmationModal: true
        });
    };

    hideSendMessagesConfirmationModal = () => {
        this.setState({showSendMessagesConfirmationModal: false});
    };


    render() {
        const {users, selectedUser, showDeleteUserConfirmationModal, isLoading} = this.state;
        const nbSelectedUsers = this.getNbSelectedUsers();


        if (isLoading && users===undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <FilterForm filtersOptions={this.state.filtersOptions} onFilter={this.onFilter} />

                <LoadingOverlay active={isLoading}>
                    <div>
                        {nbSelectedUsers > 0 && <p style={{marginBottom: 15}}>
                            Selected <strong>{nbSelectedUsers}</strong> {nbSelectedUsers > 1 ? 'users' : 'user'}

                            <input
                                style={{marginLeft: 10}}
                                type="button"
                                className="btn btn-sm btn-default"
                                value="Send Emails"
                                onClick={this.showSendEmailModal}
                            />
                        </p>
                        }

                        <UsersTable
                            users={users}
                            isSelected={this.isSelected}
                            onDelete={this.onDelete}
                            onToggleSelection={this.onToggleSelection}
                        />
                    </div>
                </LoadingOverlay>

                <div className="mb-default">
                    <a className="btn btn-success mr-small"  target="_blank" href={getUsersCsvUrl()}>Export To Excel</a>
                    <a className="btn btn-success"  target="_blank" href={getUsersCsvUrl()+"?newsletter=1"}>Export for Newsletter</a>
                </div>

                <DeleteUserConfirmationModal
                    isOpen={showDeleteUserConfirmationModal}
                    user={selectedUser}
                    handleCloseModal={this.hideDeleteUserConfirmationModal}
                    onConfirm={() => this.onExecuteDeleteUser(selectedUser)}
                />

                <SendEmailModal
                    key={'mail_'+this.state.refreshIndex}
                    isOpen={this.state.showSendEmailModal}
                    isLoading={this.state.isLoading}
                    handleCloseModal={this.hideSendEmailModal}
                    onSendMessages={this.onSendMessages}
                    onLoadMessagePreview={this.onLoadMessagePreview}
                    onSaveTemplate={this.onSaveTemplate}
                    onDeleteTemplate={this.onDeleteTemplate}
                />

                {this.state.previewContent && <PreviewMessageModal
                    isOpen={this.state.showPreviewModal}
                    isLoading={this.state.isLoading}
                    previewContent={this.state.previewContent}
                    handleCloseModal={this.hidePreviewModal}
                />}

                <DeleteMessageTemplateConfirmationModal
                    key={'confirm_'+this.state.refreshIndex}
                    isOpen={this.state.showMessageTemplateDeleteConfirmationModal}
                    templateTitle={this.state.selectedMessageTemplateTitle}
                    onConfirm={() => this.onExecuteDeleteTemplate(this.state.selectedMessageTemplateId)}
                    handleCloseModal={() => this.hideMessageTemplateDeleteConfirmationModal()}
                />

                <SendMessagesConfirmationModal
                    key={'send_confirm_'+this.state.refreshIndex}
                    isOpen={this.state.showSendMessagesConfirmationModal}
                    nbRecipients={this.getExpandedSelectedUsersIds().length}
                    onConfirm={() => this.onExecuteSendMessages(this.state.sendMessagesData)}
                    handleCloseModal={() => this.hideSendMessagesConfirmationModal()}
                />
            </main>
        );
    }
}

export default withTranslation()(UsersPage);