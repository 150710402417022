import React from 'react';

import { Field, getIn } from 'formik';
const ErrorMessage = ({ name }) => (
    <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touched = getIn(form.touched, name) || form.submitCount > 0;
            return touched && error && (typeof error === 'string')
                ? <div className="error-message">{error}</div>
                : null;
        }}
    />
);

export default ErrorMessage;
