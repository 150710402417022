import React, {Component} from 'react';
import {getAvailablePlayers, getPlayers} from "services/PlayerService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import {getCurrentSeason, groupScoresByPlayerAndRound, groupScoresByWeekAndPlayer} from "services/SeasonService";
import {PlayerToolTip} from "modules/Players/PlayerTooltip";
import {Trans, withTranslation} from "react-i18next";
import PlayersTableRaw from "modules/Players/PlayersTableRaw";
import PlayersTableRawFixed from "modules/Players/PlayersTableRawFixed";

class PlayersTable extends Component {
    state = {
        isLoading: true,
        players: null,
        season: null,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        const sort = this.props.sort;
        const sortDirection = this.props.sortDirection;
        const leagueId = this.props.leagueId;
        this.setState({isLoading: true});
        getAvailablePlayers(leagueId, this.props.search, {sort, sortDirection})
            .then((players) => this.setState({players}))
            .catch(({response}) => {
                if (response.status !== 422) {
                    return addErrorAsNotification(response, this.props.t)
                }
                getPlayers(this.props.search, {sort, sortDirection})
                    .then((players) => this.setState({players}));
            })
            .finally(() => this.setState({isLoading: false}));

        getCurrentSeason()
            .then((season) => this.setState({season}))
            .catch((error) => {
                if (error.response.status === 404) {
                    return null;
                }

                throw error;
            })
        ;
    };

    render() {
        const {pickEnabled, onPickPlayer} = this.props;
        let {players, season, isLoading} = this.state;

        if (isLoading || !players) {
            return <Loader/>
        }

        if (!players.length) {
            return <h3><Trans>No records found</Trans></h3>
        }

        const scores = season ? groupScoresByPlayerAndRound(season.games) : [];


        return <div>
            <div className="text-right" style={{fontSize: 14, marginBottom: '1rem', color: 'gray'}}>
                ▲▼ <Trans>you can sort by clicking table headers</Trans>
            </div>

            <div className="lg-up">
                <PlayersTableRawFixed
                    pickEnabled={pickEnabled}
                    players={players}
                    onPickPlayer={onPickPlayer}
                    scores={scores}
                    sort={this.props.sort}
                    sortDirection={this.props.sortDirection}
                    onSort={this.props.onSort}
                />
            </div>

            <div className="lg-down">
                <PlayersTableRaw
                    pickEnabled={pickEnabled}
                    players={players}
                    onPickPlayer={onPickPlayer}
                    scores={scores}
                    sort={this.props.sort}
                    sortDirection={this.props.sortDirection}
                    onSort={this.props.onSort}
                />
            </div>
        </div>
    }
}

export default PlayersTable;