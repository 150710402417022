import React from "react";
import RosterSection from "modules/Roster/RosterSection";
import * as moment from "moment";
import {Trans, withTranslation} from "react-i18next";

const GameSection = ({t, season, scores, game, teamsRanks}) => {
    const team1 = game.roster1.team;
    const team1Rank = teamsRanks[team1.id] ? teamsRanks[team1.id] : null;

    const team2 = game.roster2.team;
    const team2Rank = teamsRanks[team2.id] ? teamsRanks[team2.id] : null;

    const gameResult = game.gameResult;

    return (
        <div>
            {gameResult !== null && <h3 className="mt-default mb-small">
                {
                    (gameResult === 0) ? t('Game Tied') :
                        (gameResult === 1 ? t('Winner')+': '+team1.name : t('Winner')+': '+team2.name)
                }
            </h3>}

            <div className="game-section">
                <div className="team-column">
                    <div className="team-basics">
                        <div className="team-logo"><img style={{width: 96, height: 96, objectFit: 'cover'}} src={team1.logoUrl} /></div>
                        <div className="team-name">{team1.name}</div>
                        <div className="team-owner-name"><Trans>User</Trans>: {team1.owner.name}</div>
                        {team1Rank && <>
                            <div className="team-owner-rank"><Trans>Rank</Trans>:
                                {moment.localeData().ordinal(team1Rank)} [{team1.wins}-{team1.losses}-{team1.ties}]
                            </div>
                        </>}
                    </div>

                    <RosterSection
                        season={season}
                        roster={game.roster1}
                        scores={scores}
                        editable={false}
                        withEmptySlots={true}
                        gamePoints={game.team1Points}
                        benchPoints={game.bench1Points}
                    />

                </div>
                <div className="team-column">
                    <div className="team-basics">
                        <div className="team-logo"><img style={{width: 96, height: 96, objectFit: 'cover'}} src={team2.logoUrl} /></div>
                        <div className="team-name">{team2.name}</div>
                        <div className="team-owner-name"><Trans>User</Trans>: {team2.owner.name}</div>
                        {team2Rank && <div className="team-owner-rank">
                            <Trans>Rank</Trans>: {moment.localeData().ordinal(team2Rank)} [{team2.wins}-{team2.losses}-{team2.ties}]
                        </div>}
                    </div>

                    <RosterSection
                        season={season}
                        roster={game.roster2}
                        scores={scores}
                        editable={false}
                        withEmptySlots={true}
                        gamePoints={game.team2Points}
                        benchPoints={game.bench2Points}
                    />
                </div>
            </div>

        </div>
    );
};

export default withTranslation()(GameSection);