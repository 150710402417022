import React, {Component} from 'react';
import LoadingOverlay from "modules/Common/LoadingOverlay";
import SettingsForm from "modules/Owner/Settings/SettingsForm";
import {getSettings, saveSettings} from "services/SettingService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import {addSuccessNotification} from "services/NotificationService";
import {Trans, withTranslation} from "react-i18next";

class SettingsPage extends Component {
    state = {
        isLoading: true,
        settings: undefined,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        getSettings()
            .then((settings) => {
                this.setState({settings, isLoading: false});
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onSaveSettings = (settings) => {
        this.setState({isLoading: true});
        return saveSettings(settings)
            .then(() => {
                addSuccessNotification(
                    'Success',
                    'Settings were updated'
                );
                this.refreshData();
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {settings, isLoading} = this.state;

        if (isLoading && settings === undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <h1><Trans>Settings</Trans></h1>

                    <SettingsForm settings={settings} processData={this.onSaveSettings} />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(SettingsPage);
