import React from 'react';

const TeamLogoSelector = ({teamLogos, form, field, ...rest}) => {
    const logosContent = teamLogos.map((teamLogo) => {
        const isSelected = (field.value === teamLogo.id);
        const styles = {
            width: '8rem',
            cursor: 'pointer',
            border: isSelected ? '3px solid #02AC1E' : '3px solid transparent'
        };

        return (
            <div className="team-logo-item" key={teamLogo.id}>
                <img alt="" onClick={() =>{
                    form.setFieldValue(field.name, teamLogo.id);
                }} style={styles} src={teamLogo.image} />
            </div>
        )
    });

    return (
        <div className="team-logo-selector">
            {logosContent}
        </div>
    );
};

export default TeamLogoSelector;
