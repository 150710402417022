import React from 'react';
import {Trans} from "react-i18next";

export default function DraftTeamsSlider({ draftTeams, direction, current, round, nbRounds }) {
    return (
        <div className="draft-teams-slider mb-default">
            <h3 className="mb-default"><Trans>Draft order</Trans></h3>

            <div className="heading mb-small"><Trans i18nKey="Round {{round}}" values={{round}} /> / {nbRounds}</div>

            {/*<div className="draftDirection"><span>{ direction === 'forward' ? '⟹' : '⟸'}</span></div>*/}
            <div className="list-wrapper">
                <ul>
                    {draftTeams.map((team, idx) => {
                        let className = "";
                        if (team.id === current.id) {
                            className += " selected ";
                        }

                        if (direction === 'forward') {
                            if (team.draftOrder < current.draftOrder) {
                                className += " past";
                            }
                        } else {
                            if (team.draftOrder > current.draftOrder) {
                                className += " past";
                            }
                        }

                        return <li className={className}>
                            <img style={{height: 64}} src={team.logoUrl} />
                            <span className="teamName">{team.name}</span>
                            <span className="draftOrder">{idx+1}</span>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    );
}
