import React, {Component} from 'react';
import {getSeasons} from "services/SeasonService";
import SeasonSelect from "modules/Common/Form/SeasonSelect";
import {getSelectedSeason, setSelectedSeasonId} from "services/UserStateService";

export default class SeasonSwitcher extends Component {
    state = {
        seasons: null,
        selectedSeason: undefined,
    };

    componentDidMount() {
        getSeasons()
            .then((seasons) => this.setState({seasons}))
        ;

        getSelectedSeason()
            .then((selectedSeason) => this.setState({selectedSeason}))
            .catch(() => this.setState({selectedSeason: null}))
        ;
    }

    onChange = (seasonId) => {
        setSelectedSeasonId(seasonId);
        window.location.reload();
    };

    render() {
        const {seasons, selectedSeason} = this.state;
        if (!seasons || selectedSeason === undefined) {
            return null;
        }

        return (
            <div className="language-switcher">
                <SeasonSelect
                    seasons={seasons}
                    selectedSeason={selectedSeason}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}
