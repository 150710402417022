import React from 'react';

const CookiePolicy = () => {
    return (
        <div>
            <h1>HUNGARIAN FANTASY FOOTBALL SÜTI SZABÁLYZAT</h1><p><strong>Hatálybalépés
            napja:</strong> 2019. október
            21. </p><p>Sütiket (cookies) használunk a http://fantasyfootball.hu ("Hungarian Fantasy Football").
            Ebben a
            süti szabályzatban (a "Süti Szabályzat") információkat találhat az általunk használt sütik típusairól,
            valamint
            azokról a célokról, amelyekre azokat használjuk. Ha nem ért egyet a sütik használatával, kérjük, tiltsa le a
            sütiket
            a "Hogyan tiltsuk le a sütiket?” részben leírtak szerint. Kérjük, vigyázzon, a Hungarian Fantasy Football
            teljes
            funkcionalitása lehet, hogy sütik nélkül nem érhető el.</p>

            <p><h3>1. Felelős szerv (adatkezelő)</h3></p><p>A
            személyes
            adatainak Hungarian Fantasy Football-on keresztül történő feldolgozásáért felelős adatkezelő Kiss Gábor,
            akinek
            címe: <a id="_Hlk31180059"></a>Farman-strasse 47, 8152 Glattpark (Opfikon), Svájc ("mi"). </p>

            <p><h3>2. Mi az a süti (cookie)?</h3></p><p>A süti (cookie) egy kis adat, amely jellemzően betűkből és számokból áll. Egy weboldal
            meglátogatásakor a webhely sütiket küldhet a böngészőjének. Ezt követően a böngésző bizonyos ideig
            tárolhatja a
            sütiket számítógépén vagy mobileszközén. A sütiket úgy tervezték, hogy lehetővé tegye a készülék
            felismerését,
            valamint bizonyos információk gyűjtését a webhely használatával kapcsolatban. Így idővel a sütik lehetővé
            teszik a
            webhelyek számára, hogy "emlékezzenek" az Ön tevékenységeire és preferenciáira. Számos süti típus létezik,
            nevezetesen (i) állandó sütik, amelyek mindaddig érvényesek, amíg Ön által törlésre nem kerülnek, (ii) olyan
            sütik,
            amelyek a lejáratuk napjáig érvényesek maradnak, és (iii) időszakos sütik, amelyeket egy webböngésző tárol,
            és
            amelyek a böngésző bezárásáig érvényesek maradnak. A sütik lehetnek (i) első féltől származó sütik
            (amelyeket a
            weboldal maga határoz meg) és (ii) harmadik féltől származó sütik (amelyeket harmadik fél webhelyei
            helyeznek
            el).</p>

            <p><h3>3. Milyen sütiket (cookies) használunk?</h3></p><p>Háromféle sütit használunk a Hungarian Fantasy
            Football-on, nevezetesen:</p>
            <ul>
                <li><strong>Technikai sütik</strong>, amelyek elengedhetetlenek a Hungarian Fantasy Football helyes
                    működéséhez,
                    valamint az Ön által kért szolgáltatások biztosításához;
                </li>
                <li><strong>Statisztikai sütik</strong>, amelyek lehetővé teszik számunkra, hogy statisztikai
                    jelentéseket
                    készítsünk arról, hogyan használja a Hungarian Fantasy Football-t; és
                </li>
                <li><strong>Marketing sütik</strong>, amelyek lehetővé teszik számunkra, hogy létrehozzunk,
                    végrehajtsuk, és
                    megvizsgáljuk digitális marketing kampányainkat. Az ilyen sütik lehetővé teszik számunkra, hogy
                    elérjük a
                    megfelelő ügyfeleket, elemezzük marketingkampányaink produktivitását, és személyre szabott
                    hirdetéseket
                    nyújtsunk Önnek.
                </li>
            </ul>
            <p>Az alábbi táblázat áttekintést nyújt a sütikről, beleértve azok célját és lejárati idejét. </p>
            <table className="table">
                <tr>
                    <td colSpan="5"><p><strong>Feltétlenül szükséges sütik</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Név</strong></p></td>
                    <td><p><strong>Típus</strong></p></td>
                    <td><p><strong>Szolgáltató</strong></p></td>
                    <td><p><strong>Lejárati idő</strong></p></td>
                    <td><p><strong>Cél</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>HOZZÁJÁRULÁS</strong></p></td>
                    <td><p>Harmadik fél HTTP süti</p></td>
                    <td><p><a href="http://google.com/">google.com</a></p></td>
                    <td><p>6650 nap</p></td>
                    <td><p>A süti arra szolgál, hogy felismerje, ha elfogadta a marketing kategóriát a süti
                        bannerben.</p></td>
                </tr>
                <tr>
                    <td colSpan="5"><p><strong>Statisztikai sütik</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Név</strong></p></td>
                    <td><p><strong>Típus</strong></p></td>
                    <td><p><strong>Szolgáltató</strong></p></td>
                    <td><p><strong>Lejárati idő</strong></p></td>
                    <td><p><strong>Cél</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>_ga </strong></p></td>
                    <td><p>Első fél HTTP süti</p></td>
                    <td><p><a href="http://fantasyfootball.hu/">fantasyfootball.hu</a></p></td>
                    <td><p>2 év</p></td>
                    <td><p>A süti egy olyan egyedi azonosító beállítására szolgál, amely statisztikai adatokat generál
                        arról, Ön
                        hogyan használja a Hungarian Fantasy Football-t.</p></td>
                </tr>
                <tr>
                    <td><p><strong>_gat </strong></p></td>
                    <td><p>Első fél HTTP süti</p></td>
                    <td><p><a href="http://fantasyfootball.hu/">fantasyfootball.hu</a></p></td>
                    <td><p>1 nap</p></td>
                    <td><p>A sütit a Google Analytics használja a kérések benyújtásának gyakoriságához.</p></td>
                </tr>
                <tr>
                    <td><p><strong>_gid </strong></p></td>
                    <td><p>Első fél HTTP süti</p></td>
                    <td><p><a href="http://fantasyfootball.hu/">fantasyfootball.hu</a></p></td>
                    <td><p>1 nap</p></td>
                    <td><p>A süti egy olyan egyedi azonosító beállítására szolgál, amely statisztikai adatokat generál
                        arról, Ön
                        hogyan használja a Hungarian Fantasy Football-t.</p></td>
                </tr>
                <tr>
                    <td colSpan="5"><p><strong>Marketing sütik</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Név</strong></p></td>
                    <td><p><strong>Típus</strong></p></td>
                    <td><p><strong>Szolgáltató</strong></p></td>
                    <td><p><strong>Lejárati idő</strong></p></td>
                    <td><p><strong>Cél</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>1P_JAR</strong></p></td>
                    <td><p>Harmadik fél HTTP süti</p></td>
                    <td><p><a href="http://google.com/">google.com</a></p></td>
                    <td><p>29 nap</p></td>
                    <td><p>A sütit arra használják, hogy információkat gyűjtsön az Ön viselkedéséről több webhelyen
                        annak érdekében,
                        hogy optimalizálja a hirdetések relevanciáját.</p></td>
                </tr>
                <tr>
                    <td><p><strong>domainads/tracking/caf.gif </strong></p></td>
                    <td><p>Harmadik fél Pixel süti</p></td>
                    <td><p><a href="http://gstatic.com/">gstatic.com</a></p></td>
                    <td><p>Az időszak vége</p></td>
                    <td><p>A sütit arra használják, hogy adatokat gyűjtsenek az Ön viselkedéséről több webhelyről annak
                        érdekében,
                        hogy relevánsabb hirdetéseket prezentáljanak. Ez azt is lehetővé teszi, hogy a weboldal
                        korlátozza annak a
                        számát, hogy ugyanaz a reklám hányszor jelenik meg Önnek. </p></td>
                </tr>
                <tr>
                    <td><p><strong>NID</strong></p></td>
                    <td><p>Harmadik fél HTTP süti</p></td>
                    <td><p><a href="http://google.com/">google.com</a></p></td>
                    <td><p>6 hónap</p></td>
                    <td><p>A süti egy olyan egyedi azonosító regisztrálására szolgál, amely azonosítja a visszatérő
                        felhasználó
                        eszközét. Az azonosítót célzott reklámokhoz használják. </p></td>
                </tr>
            </table>

            <p><h3>4. Süti hozzájárulás</h3></p>
            <p>Amikor először látogatja meg a Hungarian Fantasy Football-t, megkérhetjük Önt,
            hogy engedélyezze a sütik használatát a sütik engedélyezésére szolgáló banner segítségével. Ha nem adja
            hozzájárulását az összes süti használatához, csak olyan technikai sütiket szolgáltathatunk, amelyek
            feltétlenül
            szükségesek a Hungarian Fantasy Football megfelelő működésének biztosításához. Az ilyen sütik használata nem
            igényli
            az Ön beleegyezését. Kérjük, vegye figyelembe, hogy előfordulhat, hogy nem tudjuk biztosítani Önnek a lehető
            legjobb
            felhasználói élményt, ha nincs minden süti engedélyezve.</p>

            <p><h3>5. Hogyan lehet letiltani a sütiket?</h3></p>
            <p>Amikor
                arra kérjük Önt, hogy járuljon hozzá a statisztikai és marketing sütik használatához, jogában áll, hogy
                ne adjon meg
                ilyen hozzájárulást. Ha vissza szeretné utasítani a sütik használatát a Hungarian Fantasy Football-on,
                akkor ezt
                bármikor megteheti, a sütik letiltásával a böngészőjében vagy az eszközén. Kérjük, vegye figyelembe,
                hogy a
                Hungarian Fantasy Football egyes részei lehet, hogy nem fognak megfelelően működni sütik nélkül, ezért
                nem
                utasíthatja el a feltétlenül szükséges technikai sütiket. Hasznos lehet megtekintenie a böngésző
                süti-kezelési
                utasításait:</p>
            <ul>
                <li><strong>Apple Safari: </strong><a
                    href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
                </li>
                <li><strong>Google Chrome: </strong><a
                    href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a><strong> </strong>
                </li>
                <li><strong>Firefox: </strong><a
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a><strong> </strong>
                </li>
                <li><strong>Internet Explorer: </strong><a
                    href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a><strong> </strong>
                </li>
                <li><strong>Microsoft Edge: </strong><a
                    href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a><strong> </strong>
                </li>
            </ul>

            <p><h3>6. Google Analytics</h3></p>
            <p>A Google Analytics-t, a Google, Inc ("Google") által nyújtott szolgáltatást
            használjuk, a Hungarian Fantasy Football használatának elemzéséhez. A Google Analytics sütik segítségével
            statisztikai vagy egyéb adatokat generál, szolgáltatásaival pedig jelentéseket készítünk a Hungarian Fantasy
            Football használatáról. A Google Analytics sütik névtelen, első féltől származó sütik. Az ilyen sütik közé
            tartozik
            a "__gat", a "__gid", valamint a "__ga". Kérjük, vegye figyelembe, hogy a Google Analytics sütik használata
            névtelen, így semmilyen módon nem azonosíthatjuk Önt. A sütik által generált információkat a Hungarian
            Fantasy
            Football használatáról (beleértve az Ön IP-címét is) elküldésre és tárolásra kerülnek a Google szerverein,
            az
            Egyesült Államokban. Kérjük, vegye figyelembe, hogy az Ön IP-címe anonimizálásra kerül, a Google pedig nem
            kombinálja az Ön IP-címét más, a Google által Önről tárolt adatokkal. Így a Google nem lesz képes
            azonosítani Önt.
            Bizonyos esetekben (pl. ha a törvény előírja, vagy ha harmadik fél a Google nevében nyújt szolgáltatásokat)
            a Google
            továbbíthatja az információkat harmadik feleknek. A Google Analytics adatvédelmi gyakorlatáról további
            információkért kérjük látogassa meg a következő oldalt <a
                href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>
            .</p><p>Ha szeretne leiratkozni a Google Analytics-ről, megteheti a Google Analytics leiratkozási böngésző
            add-on
            telepítésével, mely az alábbi oldalon érhető el <a
                href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>
            . További információkért a hirdetési funkciókról való leiratkozáshoz a készülékén, kérjük, látogasson el az
            alábbi
            oldalra <a href="https://www.networkadvertising.org/">https://www.networkadvertising.org</a> .</p>

            <p><h3>7. Célzott reklám és GoogleAdSense</h3></p>
            <p>A Hungarian Fantasy Football-on célzott, érdeklődésen alapuló hirdetéseket
            helyezhetünk el a GoogleAdSense reklámhálózat részeként. Az ilyen hirdetések a Hungarian Fantasy Football,
            más
            internetes honlapok, valamint a sütik által generált adatok alapján jönnek létre. Beállítható, hogy milyen
            reklámok
            jelenjenek meg, vagy leiratkozhat a célzott reklámozásról, követve a Digitális Reklám Szövetség
            támogatásával
            üzemeltetett útmutatót, mely elérhető a <a
                href="https://youradchoices.com/">https://youradchoices.com</a> oldalon
            és a Hálózati Hirdetési Kezdeményezést, mely elérhető a <a
                href="https://www.networkadvertising.org/">https://www.networkadvertising.org</a>
            oldalon.</p>
            
            <p><h3>8. Lépjen velünk kapcsolatba</h3></p><p>Ha bármilyen kérdése van ezzel a Süti Irányelvvel
            kapcsolatban,
            kérjük, lépjen kapcsolatba velünk:</p><p><strong>E-mail:</strong> info@fantasyfootball.hu</p><p><strong>Postai
            cím a
            kommunikációhoz: </strong>Farman-strasse 47, 8152 Glattpark (Opfikon), Svájc</p><p><strong>***</strong></p>
        </div>
    );
};

export default CookiePolicy;