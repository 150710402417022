import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function RemoveMemberConfirmationModal({member, onConfirm, t, ...rest}) {
    const name = member ? member.name : '';

    return (
        <ConfirmationModal
            title={t("Remove member", {'name': name})}
            content={t("Are you sure you want to remove {{name}} from the league members?", {'name': name})}
            buttons={{
                yes: t('Remove'),
                no: t('Cancel')
            }}
            type="destructive"
            onConfirm={() => onConfirm(member)}
            {...rest}
        />
    );
}

export default withTranslation()(RemoveMemberConfirmationModal);
