import React from 'react';
import Select from "react-select";

function SelectControl({selectedValue, templates, ...rest}) {
    let options = templates ? templates.map(({id, title}) => {
        return {value: id, label: title};
    }) : [];

    options.unshift({value: -1, label: '-- Create New --'});

    const selectedOption = options.find(({value}) => {
        return (value === selectedValue)
    });

    return (
        <Select
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            value={selectedOption}
            options={options}
            {...rest}
        />
    );
}

function MessageTemplateWidget({field, form, templates, ...rest}) {
    return (
        <SelectControl
            key={JSON.stringify(templates)}
            templates={templates}
            defaultValue={{
                label: field.value ? field.value : '-- Not selected --',
                value: field.value
            }}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            selectedValue={field.value}
            {...rest}
        />
    );
}

export default MessageTemplateWidget;
