import React from 'react';
import {withTranslation} from "react-i18next";
import TeamsNbSelect from "modules/Common/TeamsNbSelect";

function TeamsNbSelectWidget({field, form, t, ...rest}) {
    return (
        <TeamsNbSelect
            defaultValue={{
                label: field.value ? field.value : t('Any'),
                value: field.value
            }}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem',
                    overflow: 'scroll'
                }),
            }}
            {...rest}
        />
    );
}

export default withTranslation()(TeamsNbSelectWidget);
