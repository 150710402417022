import React from 'react';
import {withTranslation} from "react-i18next";

function WeekSwitcher({ nbWeeks, nbVisibleWeeks, currentWeek, nbPlayoffWeeks, onSelectWeek, t }) {
    const classNames = 'btn btn-sm btn-default';
    let children = [];

    const playoffStartWeek = (nbWeeks - nbPlayoffWeeks + 1);

    for (let week=1; week<=nbWeeks; ++week) {
        if (week > nbVisibleWeeks) {
            continue;
        }

        let currentClassNames = classNames;
        if (week === currentWeek) {
            currentClassNames += ' selected';
        }

        const playoffWeek = week >= playoffStartWeek ? (week - playoffStartWeek + 1) : null;

        const weekTitle = playoffWeek
            ? (nbPlayoffWeeks > 1 ? t('Playoff Week {{week}}', {week: playoffWeek}) : t('Playoff'))
            : t('Week {{week}}', {week})
        ;

        children.push(<li key={week}>
            <button onClick={() => onSelectWeek(week)} className={currentClassNames}>{weekTitle}</button>
        </li>);
    }

    return (
        <ul className="week-switcher">
            {children}
        </ul>
    );
}

export default withTranslation()(WeekSwitcher);
