import React, {Component} from 'react';
import EditTeamForm from "./EditTeamForm";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import Loader from "modules/Common/Loader";
import {getFantasyTeam, updateFantasyTeam} from "services/FantasyTeamService";
import {addErrorAsNotification} from "services/ValidationService";
import {withRouter} from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";
import {getTeamLogos} from "services/TeamLogoService";
import {withTranslation} from "react-i18next";

class EditTeamPage extends Component {
    state = {
        team: undefined,
        teamLogos: undefined,
        isLoading: true,
    };

    componentDidMount() {
        const teamId = this.props.match.params.id;
        this.setState({isLoading: true});

        const requests = Promise.all([
            getFantasyTeam(teamId),
            getTeamLogos()
        ]);

        //fetch all at once
        requests
            .then(([team, teamLogos]) => this.setState({team, teamLogos, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false})
            })
        ;
    }

    processData = (values) => {
        const {t} = this.props;
        const teamId = this.props.match.params.id;
        this.setState({isLoading: true});
        return updateFantasyTeam(teamId, values)
            .then(() => {
                addSuccessNotification(t('Success'), t('Team details were updated'));
                this.props.history.push('/settings');
            })
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        const {team, teamLogos, isLoading} = this.state;

        if (isLoading && team === undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <EditTeamForm
                        team={team}
                        teamLogos={teamLogos}
                        processData={this.processData}
                        onCancel={() => this.props.history.push('/settings')}
                    />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(EditTeamPage));
