import React from 'react';
import Select from "react-select";

function getPossiblePositionsAsOptions() {
    const positions = [
        'QB', 'RB', 'WR', 'TE', 'K', 'DEF', 'HC'
    ];
    let options = [];

    for (const position of positions) {
        options.push({
            value: position,
            label: position
        });
    }

    return options;
}

const PositionWidget = ({form, field, ...rest}) => {
    const options = getPossiblePositionsAsOptions();
    const selectedOption = options.find((option) => option.value === field.value);

    return (
        <Select
            defaultValue={selectedOption}
            menuPortalTarget={document.body}
            options={options}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default PositionWidget;
