import React from 'react';
import {formatPendingMemberType} from "services/LeagueService";
import {Trans} from "react-i18next";

export default function PendingMembershipsList({onCancelPendingMembership, pendingLeagueMemberships}) {
    if (!pendingLeagueMemberships.length) {
        return null;
    }

    return (
        <div className="mb-default">
            <p className="mb-small"><strong><Trans>Pending memberships</Trans>:</strong></p>
            <ul className="list-normal">
                {pendingLeagueMemberships.map((pendingLeagueMembership) =>
                    <li>
                        {pendingLeagueMembership.league.name} (<Trans>{formatPendingMemberType(pendingLeagueMembership.type)}</Trans>)

                        {pendingLeagueMembership.type === 'REQUESTED' ? <button onClick={() => onCancelPendingMembership(pendingLeagueMembership)} className="ml-small btn btn-default btn-sm"><Trans>Withdraw</Trans></button> : ''}
                    </li>
                )}
            </ul>
        </div>
    );
}
