import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function CutPlayerConfirmationModal({player, onConfirm, t, ...rest}) {
    if (!player) {
        return null;
    }

    const positionTitle = t(player.position);

    return (
        <ConfirmationModal
            title={t("Cut player")}
            content={t('Are you sure you want to cut {{namePosition}}?', {namePosition: `${player.name} (${positionTitle})`})}
            buttons={{
                yes: t('Yes, cut player'),
                no: t('Cancel')
            }}
            type="destructive"
            onConfirm={() => onConfirm(player)}
            {...rest}
        />
    );
}

export default withTranslation()(CutPlayerConfirmationModal);
