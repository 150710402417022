import React from 'react';
import PrivacyPolicy_en from "./html/en/PrivacyPolicy";
import PrivacyPolicy_hu from "./html/hu/PrivacyPolicy";
import {withTranslation} from "react-i18next";

function PrivacyPage({ i18n }) {
    return (
        <main className="content-page">
            {i18n.language === 'en' ? <PrivacyPolicy_en/> : <PrivacyPolicy_hu/>}
        </main>
    );
}

export default withTranslation()(PrivacyPage);
