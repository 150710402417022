import React from 'react';
import Select from "react-select";
import {withTranslation} from "react-i18next";

function getPossibleStatusesAsOptions() {
    return [
        {value: 'active', label: 'Active'},
        {value: 'inactive', label: 'Inactive'}
    ];
}

const PlayerStatusSelect = ({t, selectedValue, onChange, ...rest}) => {
    const options = getPossibleStatusesAsOptions();
    const selectedOption = options.find((option) => option.value === selectedValue);

    return (
        <Select
            value={selectedOption ? selectedOption : null}
            placeholder={t('select_placeholder')}
            menuPortalTarget={document.body}
            options={options}
            onChange={onChange}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default withTranslation()(PlayerStatusSelect);
