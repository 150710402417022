import React from 'react';
import {NavLink} from "react-router-dom";
import {Trans} from "react-i18next";

const FooterLinks = () => {
    return (
        <ul className="links">
            {/*<li><NavLink to="/press"><Trans>Press</Trans></NavLink></li>*/}
            {/*<li><NavLink to="/media"><Trans>Media</Trans></NavLink></li>*/}
            <li><NavLink to="/contact"><Trans>Contact</Trans></NavLink></li>
            <li><NavLink to="/terms"><Trans>Terms of Use</Trans></NavLink></li>
            <li><NavLink to="/privacy"><Trans>Privacy Policy</Trans></NavLink></li>
            <li><NavLink to="/cookie-policy"><Trans>Cookie Policy</Trans></NavLink></li>
            <li><NavLink to="/copyright-notice"><Trans>Copyright notice</Trans></NavLink></li>
        </ul>
    );
};

export default FooterLinks;