import React from 'react';

import {FacebookProvider} from "react-facebook";

const AppFacebookProvider = ({ ...rest }) => {
    return (
        <FacebookProvider appId="60417561460" {...rest} />
    );
};

export default AppFacebookProvider;
