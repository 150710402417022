import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import React from "react";

export default function FinishSeasonConfirmationModal({onConfirm, ...rest}) {
    return (
        <ConfirmationModal
            title={"Finish Season"}
            content={`Are you sure you want to finish season?`}
            buttons={{
                yes: 'Confirm',
                no: 'Cancel'
            }}
            type="creative"
            onConfirm={onConfirm}
            {...rest}
        />
    );
}
