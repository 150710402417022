import React from 'react';
import {Trans} from "react-i18next";
import SocialIcons from "modules/Common/SocialIcons";

const FooterInfo = () => {
    return (
        <div className="footer-info">
            <div className="social">
                <div className="footer-heading"><Trans>Follow us on</Trans>:</div>
                <SocialIcons/>
            </div>

            <div className="creator">
                <div className="footer-heading"><Trans>Created by</Trans>:</div>
                <div>Pavel Dubinin</div>
            </div>
        </div>
    );
};

export default FooterInfo;