import React from 'react';
import {Field} from "formik";
import PointsWidget from "modules/Owner/ScoreConfig/PointsWidget";

export default function ScoreConfigBlock({ form, attribute, withPoints = true }) {

    return (
        <table>
            <thead>
                <tr>
                    <th className="text-center">Action</th>
                    <th className="text-center">+/-</th>
                    {withPoints ? <th className="text-center">Point</th> : null}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <Field name={`${attribute}.0.operation`} component={({field}) =>  (
                            <select {...field}>
                                <option value="*">×</option>
                                <option value="/">÷</option>
                            </select>
                        )} />

                    </td>
                    <td>
                        <Field name={`${attribute}.0.sign`} component={({field}) =>  (
                            <select {...field}>
                                <option value="+">+</option>
                                <option value="-">-</option>
                            </select>
                        )} />
                    </td>
                    {withPoints ?
                        <td>
                            <Field component={PointsWidget} name={`${attribute}.0.points`}/>
                        </td>
                        : null
                    }
                </tr>
            </tbody>
        </table>
    );
}
