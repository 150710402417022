import React from 'react';
import {Trans} from "react-i18next";
import moment from "moment";

const FooterCopy = () => {
    return (
        <div className="copy"><Trans i18nKey="copy_line" values={{currentYear: moment().format('YYYY')}} /></div>
    );
};

export default FooterCopy;