import React, {Component} from 'react';
import Select from 'react-select';
import {getUser} from "services/AuthService";
import {formatLeagueRole} from "services/LeagueService";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

class LeagueSwitcher extends Component {
    state = {
        leagues: null,
        redirectRoute: null,
    };

    prepareLeaguesList = () => {
        let options = [];

        const user = getUser();
        if (!user) {
            return [];
        }

        user.leagueMemberships.forEach((membership) => {
            options.push({
                value: membership.league.id,
                label: membership.league.name + ' (' + this.props.t(formatLeagueRole(membership.role)) + ')'
            });
        });

        return options;
    };

    componentDidMount() {
        const leagueOptions = this.prepareLeaguesList();
        let selectedOption = null;

        const league = this.props.league;
        if (league) {
            selectedOption = {
                value: league.id,
                label: league.name,
            };
        }

        this.setState({
            leagues: leagueOptions,
            selectedLeague: selectedOption,
        });
    }

    onChangeLeague(leagueId) {
        const user = getUser();
        if (!user) {
            return;
        }

        const league = this.props.league;
        if (league && league.id === leagueId) {
            return;
        }

        user.leagueMemberships.forEach((membership) => {
            if (leagueId === membership.league.id) {
                this.props.onChangeLeague(membership.league, membership);
            }
        });
    }

    render() {
        const {leagues, selectedLeague} = this.state;

        if (!leagues) {
            return null;
        }

        // if (leagues.length <= 1) {
        //     return (
        //         <div className="language-switcher">
        //             <Link className="add-btn btn-primary btn-sm" to="/create-league">Create a League</Link>
        //         </div>
        //     );
        // }

        return (
            <div className="language-switcher">
                <Select
                    className="language-switcher-select"
                    options={leagues}
                    defaultValue={selectedLeague}
                    onChange={(option) => this.onChangeLeague(option.value)}
                />
                <Link style={{paddingLeft: "1rem"}} className="add-btn btn-primary btn-sm" to="/create-league">➕</Link>
            </div>
        );
    }
}

export default withTranslation()(LeagueSwitcher);
