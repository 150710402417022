import React from 'react';
import i18n from "i18next";

const LangLinks = () => {
    return (
        <ul className="nav-links langs">
            <li><img className="lang" src="/img/flag-hu.png" alt="HU" width={32} onClick={() => i18n.changeLanguage('hu')}></img></li>
            <li><img className="lang" src="/img/flag-uk.png" alt="UK" width={32} onClick={() => i18n.changeLanguage('en')}></img></li>
        </ul>
    );
};

export default LangLinks;