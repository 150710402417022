import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1>HUNGARIAN FANTASY FOOTBALL PRIVACY POLICY</h1>
            <p><strong>Effective date: </strong>21 October 2019</p>
            <p><em>Your privacy is important to us. Please read this privacy policy (the “Privacy Policy”) carefully
                because it
                explains in detail how we collect, use and disclose your personal data, and what choices you have with
                respect to
                your personal data. </em></p>

            <ol>
                <li><a href="#_Toc">GENERAL INFORMATION</a></li>
                <li><a href="#_Toc1">WHAT PERSONAL DATA DO WE COLLECT AND FOR WHAT PURPOSES DO WE USE IT?</a></li>
                <li><a href="#_Toc2">NON-PERSONAL DATA</a></li>
                <li><a href="#_Toc3">MARKETING COMMUNICATION</a></li>
                <li><a href="#_Toc4">HOW LONG DO WE KEEP YOUR DATA?</a></li>
                <li><a href="#_Toc5">HOW DO WE SHARE AND DISCLOSE DATA?</a></li>
                <li><a href="#_Toc6">INTERNATIONAL TRANSFERS OF PERSONA DATA</a></li>
                <li><a href="#_Toc7">PROTECTION OF PERSONAL DATA</a></li>
                <li><a href="#_Toc8">AGE RESTRICTIONS</a></li>
                <li><a href="#_Toc9">YOUR RIGHTS REGARDING PERSONAL DATA</a></li>
                <li><a href="#_Toc10">TERM, TERMINATION, AND AMENDMENTS</a></li>
                <li><a href="#_Toc11">CONTACT US</a></li>
            </ol>

            <p><a id="_Toc"></a> <h3>1. GENERAL INFORMATION</h3></p><p>1.1 <strong>About the Privacy Policy. </strong>This Privacy Policy governs the processing of personal
            data
            collected from individual users (“you” and “your”) through the website <a
                href="http://fantasyfootball.hu">http://fantasyfootball.hu</a>
            (“Hungarian Fantasy Football”). This Privacy Policy does not cover any third-party applications or software
            that
            integrate with Hungarian Fantasy Football or any other third-party products, services or businesses. </p>
            <p>1.2
                <strong>Data controller. </strong>The data controller that is responsible for the processing of your
                personal data
                through Hungarian Fantasy Football is Gábor Kiss having an address at Farman-strasse 47, 8152 Glattpark
                (Opfikon),
                Switzerland (“we”, “us”, and “our”). </p><p>1.3 <strong>What is Hungarian Fantasy Football? </strong>Hungarian
            Fantasy Football is a platform that provides access to information about the national league of American
            football in
            Hungary and facilitates your communication with other users of Hungarian Fantasy Football, including message
            boards,
            gaming, and team formation.</p><p>1.4 <strong>Important terms. </strong>In this Privacy Policy, you will
            encounter
            recurrent terms. For your convenience, we would like to explain what such terms mean:</p>
            <ul>
                <li>
                    <ul>
                        <li><strong>“Consent”</strong> means a freely given, specific, informed and unambiguous
                            agreement to the
                            processing of personal data;
                        </li>
                        <li><strong>“Data controller”</strong> means the entity that determines the purposes and means
                            of the
                            processing of personal data;
                        </li>
                        <li><strong>“Data processor”</strong> means a natural or legal person, public authority, agency
                            or other
                            body which processes personal data on behalf of the data controller;
                        </li>
                        <li><strong>“Personal data”</strong> means any information relating to a natural person who can
                            be
                            identified, directly or indirectly, by using such information (e.g., name, address, email,
                            phone number,
                            and IP address); and
                        </li>
                        <li><strong>“Processing”</strong> means the use of personal data in any manner, including, but
                            not limited
                            to, collection, storage, erasure, transfer, and disclosure of personal data.
                        </li>
                    </ul>
                </li>
            </ul>
            <p>1.5 <strong>Applicable laws. </strong>We process personal data in accordance with the applicable data
                protection
                laws, including the Swiss Federal Data Protection Act and the EU General Data Protection Regulation
                (GDPR).</p><p>
            1.6 <strong>Your consent to the Privacy Policy. </strong>Your use of Hungarian Fantasy Football is subject
            to this
            Privacy Policy. Before you submit any personal data through Hungarian Fantasy Football, you are encouraged
            to read
            this Privacy Policy. In some cases (where required by the applicable law), we may seek to obtain your
            consent for
            the processing of your personal data. For example, we may seek your prior consent for the following
            purposes:</p>
            <ul>
                <li>
                    <ul>
                        <li>If we intend to collect other types of personal data that are not mentioned in this Privacy
                            Policy;
                        </li>
                        <li>If we intend to use your personal data for purposes that are not indicated in this Privacy
                            Policy;
                        </li>
                        <li>If we would like to disclose or transfer your personal data to third parties that are not
                            specified in
                            this Privacy Policy; or
                        </li>
                        <li>If we significantly amend this Privacy Policy.</li>
                    </ul>
                </li>
            </ul>
            <p>1.7 <strong>Cookies. </strong>We use cookies on Hungarian Fantasy Football. For detailed information
                about our
                cookies, please refer to our cookie policy available at <a href="//fantasyfootball.hu/cookie-policy">fantasyfootball.hu/cookie-policy</a>.</p><p><a
            id="_Toc1"></a><h3>2. WHAT PERSONAL DATA DO WE COLLECT AND FOR WHAT PURPOSES DO WE USE IT?</h3></p>

            <p>2.1<strong> Types of personal
            data. </strong>We
            comply with data minimisation principles and we collect only a minimal amount of personal data that is
            necessary for
            ensuring your proper use of Hungarian Fantasy Football. The list of the types of personal data that we
            collect from
            you is provided in Section 2.3 below.</p><p>2.2 <strong>Purposes of personal data.</strong> We process your
            personal
            data only for specified and legitimate purposes explicitly mentioned in this Privacy Policy. In short, we
            will use
            personal data only for the purposes of enabling you to use Hungarian Fantasy Football, maintaining and
            improving
            Hungarian Fantasy Football, conducting research about our business activities, and replying to your
            enquiries. We
            will not use your personal data for any purposes that are different from the purposes for which your
            personal data
            was provided. </p><p>2.3 <strong>Overview of types and purposes of your personal data. </strong>The table
            below
            provides a detailed description of the types of personal data that we collect, the purposes for which we use
            it, and
            legal bases on which we rely when processing personal data.</p>
            <table className="table">
                <tr>
                    <td><p><strong>Personal data</strong></p></td>
                    <td><p><strong>Purpose</strong></p></td>
                    <td><p><strong>Legal basis</strong></p></td>
                </tr>
                <tr>
                    <td><p>When you register your user account, we collect your:</p>
                        <ul>
                            <li>Full name;</li>
                            <li>Email address;</li>
                            <li>Password;</li>
                            <li>Date of birth;</li>
                            <li>Location;</li>
                            <li>Gender;</li>
                            <li>Phone number; and</li>
                            <li>Country.</li>
                        </ul>
                        <p>If you decide to sign up with your Facebook account, we will have access to your:</p>
                        <ul>
                            <li>First name;</li>
                            <li>Last name;</li>
                            <li>Email address; and</li>
                            <li>Profile picture.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>To register and maintain your user account;</li>
                            <li>To enable your access to the full functionality of Hungarian Fantasy Football;</li>
                            <li>To deliver the requested services;</li>
                            <li>To contact you, if necessary; and</li>
                            <li>To analyse and improve our business.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Performing a contract with you; and</li>
                            <li>Pursuing our legitimate business interests (to administer and improve our business).
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>When you submit a comment on a blog post or a message board, we collect your:</p>
                        <ul>
                            <li>Name; and</li>
                            <li>Email address.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>To feature your comment or message;</li>
                            <li>To ensure the security of FantasyFootbal;</li>
                            <li>To enforce our legal documents; and</li>
                            <li>To contact you, if necessary.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Performing a contract with you; and</li>
                            <li>Pursuing our legitimate business interests (to ensure security and compliance).</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>When you contact us by email or through the contact form, we collect your:</p>
                        <ul>
                            <li>Full Name;</li>
                            <li>Email address; and</li>
                            <li>Any information or personal data that you decide to provide us in your message.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>To respond to your enquiries</li>
                            <li>To provide you with the requested information</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Pursuing our legitimate business interests (to grow and promote our business)</li>
                            <li>Your consent (for optional personal data).</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>When you sign up for a newsletter or updates, we collect your:</p>
                        <ul>
                            <li>Email address.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>To deliver you the newsletter</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Your consent</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><p>When you use Hungarian Fantasy Football, we collect your:</p>
                        <ul>
                            <li>IP address.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>To analyse, improve, and evaluate our business activities;</li>
                            <li>To customise Hungarian Fantasy Football for your location; and</li>
                            <li>To ensure security of Hungarian Fantasy Football.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Pursuing our legitimate business interests (to analyse and improve our business
                                activities and
                                ensure security)
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>2.4 <strong>Additional data. </strong>From time to time, we may receive certain additional data if you
                participate in a focus group, contest, activity or event, request support, interact with our social
                media accounts,
                submit your feedback and reviews or otherwise communicate with us. Please note that the provision of
                such data is
                optional and you may choose what personal data you would like to share with us. We kindly request you to
                exercise
                your due diligence when making your personal data publicly available. We will use such personal data to
                reply to
                you, provide you with the requested services, or for pursuing our legitimate business interests (i.e.,
                to analyse
                and improve our business).</p><p>2.5<strong> Sensitive data. </strong>We do not collect, under any
            circumstances,
            special categories of personal data (“sensitive data”) from you, such as your health information, opinion
            about your
            religious and political beliefs, racial origins, membership of a professional or trade association, or
            information
            about your sexual orientation, unless you decide to provide such sensitive data, at your own sole
            discretion. </p>
            <p>2.6 <strong>Personal data obtained from third parties.</strong> When using Hungarian Fantasy Football,
                you can choose
                to permit or restrict services, functionalities, and integrations provided by third parties, such as
                social media
                providers (collectively, the “Third-Party Services”). Once enabled, the providers of the Third-Party
                Services will
                share information that you make available through your account settings. We will use such information to
                (i) provide
                you with the requested services only and (ii) analyse and improve our business activities.</p>
            <p>2.7 <strong>Failure
                to provide personal data.</strong> If you fail to provide us with the personal data when requested, we
                may not be
                able to perform the requested operation and you may not be able to use the full functionality of
                Hungarian Fantasy
                Football, receive the services provided through Hungarian Fantasy Football, or get our response.</p>
            <p>2.8 <strong>Personal
                data in public messages. </strong>If you decide to publish a blog comment or a message on a message
                board, you may
                decide to reveal certain personal data about you or other persons. Please keep in mind that such data
                will become
                available to other users of Hungarian Fantasy Football. Therefore, we request you to exercise your due
                diligence and
                not to disclose your personal data that is not necessary, extensive, or sensitive. For example, we
                strongly
                discourage you from publishing your address or financial information as such data can be used by third
                parties for
                unlawful purposes. Also, please note that you ARE NOT ALLOWED to publish personal data pertaining to
                other persons
                if they have not provided you with their prior consent to disclose such data. We will take immediate
                steps to remove
                your messages or comments from Hungarian Fantasy Football if we become aware that they contain personal
                data
                disclosed unlawfully. </p>

            <p><a id="_Toc2"></a><h3>3. NON-PERSONAL DATA</h3></p>

            <p>3.1 <strong>What non-personal
            data do we
            collect? </strong>When you use Hungarian Fantasy Football, we may automatically collect certain non-personal
            data
            about your use of Hungarian Fantasy Football for analytics purposes. Such non-personal data does not allow
            us to
            identify you in any manner. The non-personal data collected by us includes information about: (i) the type
            of device
            that you use; (ii) operating system that you use; and (iii) URL address clicked from Hungarian Fantasy
            Football.
            Please note that de-identified personal data is also considered to be non-personal data.</p><p>3.2 <strong>Your
            feedback</strong>. If you contact us, we may keep records of any questions, complaints or compliments made
            by you
            and the response, if any. Where possible, we will de-identify your personal data. Please note that
            de-identified
            personal data is also considered to be non-personal data. </p><p>3.3 <strong>How do we use non-personal
            data? </strong>We will use non-personal data in furtherance of our legitimate interests in operating
            Hungarian
            Fantasy Football, conducting our business activities, and developing new products and services. More
            specifically,
            we collect the non-personal data for the following purposes:</p>
            <ul>
                <li>
                    <ul>
                        <li>To analyse what kind of users visit and use Hungarian Fantasy Football;</li>
                        <li>To examine the relevance, popularity, and engagement rate of the content available on
                            Hungarian Fantasy
                            Football;
                        </li>
                        <li>To investigate and help prevent security issues and abuse;</li>
                        <li>To develop and provide additional features to Hungarian Fantasy Football; and</li>
                        <li>To personalise Hungarian Fantasy Football for your specific needs.</li>
                    </ul>
                </li>
            </ul>
            <p>3.4<strong> Aggregated data. </strong>In case your non-personal data is combined with certain elements of
                your
                personal data in a way that allows us to identify you, we will handle such aggregated data as personal
                data. If your
                personal data is aggregated or de-identified in a way that it can no longer be associated with an
                identified or
                identifiable natural person, it will not be considered personal data and we may use it for any business
                purpose.</p>
            <p><a id="_Toc3"></a><h3>4. MARKETING COMMUNICATION</h3></p>
            <p>4.1<strong> Marketing messages. </strong>To keep you
            up-to-date
            with Hungarian Fantasy Football, we may send you marketing messages, such as newsletters, brochures,
            promotions and
            advertisements, informing you about our new services or new features of Hungarian Fantasy Football. You will
            receive
            such marketing messages or be contacted by us for marketing purposes only if:</p>
            <ul>
                <li>
                    <ul>
                        <li>We receive your express (“opt-in”) consent to receive marketing messages (please note that
                            your
                            voluntary subscription to our newsletters and updates constitutes such consent); or
                        </li>
                        <li>We decide to send you marketing messages about our new services that are closely related to
                            the services
                            already used by you.
                        </li>
                    </ul>
                </li>
            </ul>
            <p>4.2<strong> Opting-out.</strong> You can opt-out from receiving marketing messages at any time free of
                charge by
                clicking on the “unsubscribe” link contained in any of the messages sent to you or by contacting us
                directly.</p><p>
            4.3<strong> Informational notices and service updates.</strong> From time to time (if we have your email
            address),
            we may send you informational notices, such as service-related, technical or administrative emails,
            information
            about Hungarian Fantasy Football, your user account, privacy and security, and other important matters.
            Please note
            that we will send such notices on an “if-needed” basis and they do not fall within the scope of direct
            marketing
            communication that requires your prior consent. </p>
            <p><a id="_Toc4"></a><h3>5. HOW LONG DO WE KEEP YOUR DATA?</h3></p><p>5.1
            <strong>Retention of personal data. </strong>We will store your personal data in our systems only for as
            long as
            such personal data is required for the purposes described in this Privacy Policy or you request us to delete
            your
            personal data. After your personal data is no longer necessary for its purposes and there is no other legal
            basis
            for storing it (e.g., we are not obliged by law to store your personal data), we will immediately delete
            your
            personal data from our systems.</p><p>5.2 <strong>Retention of non-personal data.</strong> We may retain
            non-personal data pertaining to you for as long as necessary for the purposes described in this Privacy
            Policy. This
            may include keeping non-personal data after you have deactivated your user account for the period of time
            needed for
            us to pursue legitimate business interests, conduct audits, comply with (and demonstrate compliance with)
            legal
            obligations, resolve disputes and enforce our agreements.</p><p>5.3 <strong>Retention as required by
            law. </strong>Please
            note that, in some cases, we may be obliged by law to store your personal data for certain period of time.
            In such
            cases, we will store your personal data for the time period stipulated by the applicable law and delete the
            personal
            data as soon as the required retention period expires.</p>

            <p><a id="_Toc5"></a><h3>6. HOW DO WE SHARE AND DISCLOSE DATA?</h3></p>
            <p>6.1 <strong>Do we disclose your personal data? </strong>If necessary, we will disclose your personal data
            to the
            service providers with whom we cooperate (our data processors). For example, we may share your personal and
            non-personal data with entities that provide certain technical support services to us, such as web
            analytics,
            advertising, and email distribution services, or if you explicitly request us to disclose the personal data.
        </p><p>
            6.2 <strong>When do we disclose your personal data? </strong>The disclosure of your personal data is limited
            to the
            situations when such data is required for the following purposes:</p>
            <ul>
                <li>
                    <ul>
                        <li>Ensuring the proper operation of Hungarian Fantasy Football;</li>
                        <li>Ensuring the delivery of the services requested by you;</li>
                        <li>Providing you with the requested information;</li>
                        <li>Pursuing our legitimate business interests;</li>
                        <li>Enforcing our rights, preventing fraud, and security purposes;</li>
                        <li>Carrying out our contractual obligations;</li>
                        <li>Law enforcement purposes; or</li>
                        <li>If you provide your prior consent to such a disclosure.</li>
                    </ul>
                </li>
            </ul>
            <p>6.3 <strong>With what data processors do we share your personal data?</strong> We will share your
                personal data
                only with the data processors that agree to ensure an adequate level of protection of personal data that
                is
                consistent with this Privacy Policy and the applicable data protection laws. The data processors that
                will have
                access to your personal data are listed below.</p>
            <table className="table">
                <tr>
                    <td><p><strong>Service</strong></p></td>
                    <td><p><strong>Name</strong></p></td>
                    <td><p><strong>Location</strong></p></td>
                    <td><p><strong>More information</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Hosting service provider</strong></p></td>
                    <td><p>Amazon Web Services</p></td>
                    <td><p>The United States</p></td>
                    <td><p><a href="https://aws.amazon.com">https://aws.amazon.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Database service provider</strong></p></td>
                    <td><p>MySQL</p></td>
                    <td><p>The United States</p></td>
                    <td><p><a href="https://www.mysql.com">https://www.mysql.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Analytics service provider</strong></p></td>
                    <td><p>GoogleAnalytics</p></td>
                    <td><p>The United States</p></td>
                    <td><p><a href="https://analytics.google.com">https://analytics.google.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Newsletter service provider</strong></p></td>
                    <td><p>MailChimp</p></td>
                    <td><p>The United States</p></td>
                    <td><p><a href="https://www.mailchimp.com">https://www.mailchimp.com</a></p></td>
                </tr>
                <tr>
                    <td><p><strong>Advertising service provider</strong></p></td>
                    <td><p>Google AdSense</p></td>
                    <td><p>The United States</p></td>
                    <td><p><a href="https://www.google.com/adsense">https://www.google.com/adsense</a></p></td>
                </tr>
            </table>
            <p>6.4 <strong>Sharing of non-personal data. </strong>We may disclose or use non-personal data and
                de-identified
                data for any purpose. For example, we may share it with prospects or partners for business or research
                purposes, for
                improving Hungarian Fantasy Football, responding to lawful requests from public authorities or
                developing new
                products and services. </p><p>6.5 <strong>Legal requests.</strong> If necessary, we will to disclose
            information
            about the users of Hungarian Fantasy Football to the extent necessary for pursuing a public interest
            objective, such
            as national security or law enforcement.</p><p>6.6 <strong>Successors.</strong> In case our business is sold
            partly
            or fully, we will provide your personal data to a purchaser or successor entity and request the successor to
            handle
            your personal data in line with this Privacy Policy.</p>

            <p><a id="_Toc6"></a><h3>7. INTERNATIONAL TRANSFERS OF PERSONA DATA</h3></p>
            <p>Some of our data processors listed in Section 6 of this Privacy Policy are located outside the
            country in
            in which you reside. If you reside in the European Economic Area (EEA), we may need to transfer your
            personal data
            to jurisdictions outside the EEA. In case it is necessary to make such a transfer, we will make sure that
            the
            jurisdiction in which the recipient third party is located guarantees an adequate level of protection for
            your
            personal data (e.g., the recipient is a Privacy-Shield certified entity) or we conclude an agreement with
            the
            respective third party that ensures such protection (e.g., a data processing agreement based pre-approved
            standard
            contractual clauses).</p>

            <p><a id="_Toc7"></a><h3>8. PROTECTION OF PERSONAL DATA</h3></p>
            <p>8.1 <strong>Our security
            measures.</strong> We put our best efforts to keep your personal data safe and secure. We implement
            organisational
            and technical information security measures to protect your personal data from loss, misuse, unauthorised
            access,
            and disclosure. The security measures taken by us include secured networks, encryption, SSL, strong
            passwords,
            limited access to your personal data by our staff, and anonymisation of personal data (when possible). In
            order to
            ensure the security of your personal data, we kindly ask you to use Hungarian Fantasy Football through a
            secure
            network only. </p><p>8.2 <strong>Handling security breaches.</strong> Although we put our best efforts to
            protect
            your personal data, given the nature of communications and information processing technology and the
            Internet, we
            cannot be liable for any unlawful destruction, loss, use, copying, modification, leakage, and falsification
            of your
            personal data caused by circumstances that are beyond our reasonable control. In case a serious breach
            occurs, we
            will inform our local data protection authority, namely, The Federal Data Protection and Information
            Commissioner
            (FDPIC), without undue delay and immediately take reasonable measures to mitigate the breach, as required by
            the
            applicable law. Our liability for any security breach will be limited to the highest extent permitted by the
            applicable law. </p>
            <p><a id="_Toc8"></a><h3>9. AGE RESTRICTIONS</h3></p>
            <p>We do not allow anyone younger than 16
            years old
            to use Hungarian Fantasy Football. Thus, we do not knowingly collect personal data of persons below the age
            of 16.
            If you learn that anyone younger than 16 has unlawfully provided us with personal data and you are a parent
            or legal
            guardian of that person, please contact us and we will take immediate steps to delete such personal
            data.</p><p><a
            id="_Toc9"></a><h3>10. YOUR RIGHTS REGARDING PERSONAL DATA</h3></p><p>10.1 <strong>What rights do you have? </strong>Subject
            to any exemptions provided by law, you may ask us to:</p>
            <ul>
                <li>
                    <ul>
                        <li>Get a copy of your personal data that we store;</li>
                        <li>Get a list of purposes for which your personal data is processed;</li>
                        <li>Rectify inaccurate personal data;</li>
                        <li>Move your personal data to another processor;</li>
                        <li>Delete your personal data from our systems;</li>
                        <li>Object and restrict processing of your personal data;</li>
                        <li>Withdraw your consent, if you have provided one; or</li>
                        <li>Process your complaint regarding your personal data.</li>
                    </ul>
                </li>
            </ul>
            <p>10.2 <strong>How to exercise your rights?</strong> If you would like to exercise your rights listed
                above,
                please contact us by email at <a href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a> and
                explain in
                detail your request. In order to verify the legitimacy of your request, we may ask you to provide us
                with an
                identifying piece of information, so that we would be able to identify you in our system. We will answer
                your
                request within a reasonable timeframe but no later than 2 weeks.</p><p>10.3<strong> How to launch a
            complaint?</strong> If you would like to launch a complaint about the way in which we handle your personal
            data, we
            kindly ask you to contact us first and express your concerns. After you contact us, we will investigate your
            complaint and provide you with our response as soon as possible. If you are are not satisfied with the
            outcome of
            your complaint, you have the right to lodge a complaint with your local data protection authority.</p><p><a
            id="_Toc10"></a><h3>11. TERM, TERMINATION, AND AMENDMENTS</h3></p><p>11.1 <strong>Term and termination. </strong>This
            Privacy Policy enters into force on the effective date indicated at the top of the Privacy Policy and
            remains valid
            until terminated or updated by us.</p><p>11.2 <strong>Amendments.</strong> The Privacy Policy may be changed
            from
            time to time to address the changes in laws, regulations, and industry standards. The amended version of the
            Privacy
            Policy will be posted on this page and, if we have your email address, we will send you a notice about all
            the
            changes implemented by us. We encourage you to review our Privacy Policy to stay informed. For significant
            material
            changes in the Privacy Policy or, where required by the applicable law, we may seek your consent. If you
            disagree
            with the changes to the Privacy Policy, you should cease using Hungarian Fantasy Football. </p><p><a
            id="_Toc11"></a><h3>12. CONTACT US</h3></p><p>Please feel free to contact us if you have any questions about the
            Privacy
            Policy, our privacy and security practices, or would like to exercise your rights listed in Section 10 of
            the
            Privacy Policy. You may contact us by using the following contact details:</p><p><strong>Email:</strong> <a
            href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a></p><p><strong>Postal address for
            communication: </strong>Farman-strasse 47, 8152 Glattpark (Opfikon), Switzerland</p><p><strong>***</strong>
        </p>
        </div>
    );
};

export default PrivacyPolicy;