import React, { Component } from 'react';
import {Trans, withTranslation} from "react-i18next";
import PlayerInfoPopup from "modules/Players/PlayerInfoPopup";

class PlayersTableRaw extends Component {
    state = {
        isPlayerPopupOpen: false,
        selectedPlayer: null,
        selectedPlayerScores: null,
    };

    showPlayerPopup = (player, scores) => {
        this.setState({
            isPlayerPopupOpen: true,
            selectedPlayer: player,
            selectedPlayerScores: scores
        })
    };

    hidePlayerPopup = () => {
        this.setState({
            isPlayerPopupOpen: false
        });
    };

    render() {
        let {t, sort, sortDirection, onSort, pickEnabled, players, onPickPlayer, scores} = this.props;
        const currentSortKey = sort;

        const SortButton = ({label, sort, sortDirection, sortKey}) => {
            const isActiveSort = (sort === sortKey);
            const className = "sort-button " + (isActiveSort ? 'active' : '');
            const value = (isActiveSort ? (sortDirection === 'asc' ? "▲" : "▼") : "") + " " + t(label);
            const newSortDirection = (currentSortKey === sortKey) ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';

            return <input type="button" value={value} className={className}
                          onClick={() => onSort(sortKey, newSortDirection)}/>;
        };

        return (
            <div>
                <div className="table-responsive">
                <PlayerInfoPopup
                    isOpen={this.state.isPlayerPopupOpen}
                    handleCloseModal={this.hidePlayerPopup}
                    player={this.state.selectedPlayer}
                    scores={this.state.selectedPlayerScores}
                />

                <table className="table table-sortable table-condensed table-bordered mb-default">
                    <thead>
                    <tr>
                        {pickEnabled && <th className="min-width"></th>}
                        <th></th>
                        <th colSpan={2}><SortButton sort={sort} sortDirection={sortDirection} sortKey="name" label="Name"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="position" label="Position"/>
                        </th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="teamName" label="Team"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="status" label="Status"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="pass_yds" label="Pass yds"/>
                        </th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="rush_yds" label="Rush yds"/>
                        </th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="catch_yds"
                                        label="Catch yds"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="td" label="TD"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="_2pt" label="2pt"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="fumble" label="Fumble"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="int" label="INT"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="fg" label="FG"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="xp" label="XP"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="fg_miss" label="FG miss"/>
                        </th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="xp_miss" label="XP miss"/>
                        </th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="fum_forced"
                                        label="Fum forced"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="sack" label="Sack"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="pts_allowed"
                                        label="Pts allowed"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="win_loss" label="Win Loss"/>
                        </th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="pts_gained"
                                        label="Pts gained"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="score_difference"
                                        label="Score difference"/></th>
                        <th><SortButton sort={sort} sortDirection={sortDirection} sortKey="fantasy_points"
                                        label="Fantasy Points"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {players.map((player) => {
                        const playerScores = player.scores;

                        return (
                            <tr key={player.id}>
                                {pickEnabled && <td>
                                    <button className="btn btn-default btn-sm" onClick={() => onPickPlayer(player)}>
                                        <Trans>Pick</Trans>
                                    </button>
                                </td>}
                                <td>
                                    <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                                        {player.photo &&
                                        <img className="mb-small mr-small" width={40} src={player.photo}/>}
                                    </div>
                                </td>
                                <td>{player.name}</td>
                                <td>
                                    <button className="btn btn-info btn-danger"
                                            onClick={() => this.showPlayerPopup(player, scores[player.id])}>
                                        <span style={{fontSize: '1.4em'}}>ⓘ</span>
                                    </button>
                                </td>
                                <td><Trans>{player.position}</Trans></td>
                                <td>{player.team.name}</td>
                                <td>{player.status}</td>
                                <td>{playerScores['pass_yds']}</td>
                                <td>{playerScores['rush_yds']}</td>
                                <td>{playerScores['catch_yds']}</td>
                                <td>{playerScores['td']}</td>
                                <td>{playerScores['_2pt']}</td>
                                <td>{playerScores['fumble']}</td>
                                <td>{playerScores['int'] ? playerScores['int'] : playerScores['defense_int']}</td>
                                <td>{playerScores['fg']}</td>
                                <td>{playerScores['xp']}</td>
                                <td>{playerScores['fg_miss']}</td>
                                <td>{playerScores['xp_miss']}</td>
                                <td>{playerScores['fum_forced']}</td>
                                <td>{playerScores['sack']}</td>
                                <td>{playerScores['pts_allowed']}</td>
                                <td>{playerScores['win_loss']}</td>
                                <td>{playerScores['pts_gained']}</td>
                                <td>{playerScores['score_difference']}</td>
                                <td>{playerScores['fantasy_points']}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            </div>
        );
    }
}

export default withTranslation()(PlayersTableRaw);