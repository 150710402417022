import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {addErrorAsNotification, normalizeErrors} from "services/ValidationService";
import {Trans, withTranslation} from "react-i18next";

const RequestResetPasswordForm = function({handleSubmit, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form center-form" noValidate>
            <h1><Trans>Forgot password</Trans></h1>

            <p className="mb-default"><Trans>We will send you a link to your e-mail to change your password</Trans></p>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>E-mail</Trans></label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage name="email" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Send</Trans></button>
                <Link to="/login" className="btn btn-default"><Trans>Cancel</Trans></Link>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: () => ({
            'email': '',
        }),
        validationSchema: ({ t }) => yup.object().shape({
            email: yup.string()
                .required(t('You didn\'t fill in your e-mail'))
                .email(t('E-mail format is incorrect')),
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(RequestResetPasswordForm);
