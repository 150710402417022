import API from "services/API";

const positionsOrder = ['QB', 'RB', 'TE_WR', 'FLEX', 'K', 'DEF', 'HC'];

const sectionPositions = {
    'offense': ['QB', 'RB', 'TE', 'WR', 'FLEX'],
    'special': ['K'],
    'defense': ['DEF'],
    'hc': ['HC'],
};

const fantasySectionPositions = {
    'offense': ['QB', 'RB', 'TE_WR', 'TE_WR', 'FLEX'],
    'special': ['K'],
    'defense': ['DEF'],
    'hc': ['HC'],
};

export const formatPositionTitle = (position) => {
    if (position === 'TE_WR') {
        return 'TE/WR';
    }

    if (position === 'FLEX') {
        return 'FLEX (RB/WR/TE)';
    }

    return position;
}

export function getFantasySections()
{
    return Object.keys(fantasySectionPositions);
}

export function getFantasySectionPositionsList()
{
    let output = [];

    const sections = getFantasySections();
    for(const section of sections) {
        output = [...output, ...fantasySectionPositions[section]]
    }

    return output;
}

export function getPlayerSectionByPosition(position) {
    for (let [section, positions] of Object.entries(sectionPositions)) {
        if (positions.includes(position)) {
            return section;
        }
    }

    return null;
}

export function getFantasyPlayerSectionByPosition(fantasyPosition) {
    for (let [section, positions] of Object.entries(fantasySectionPositions)) {
        if (positions.includes(fantasyPosition)) {
            return section;
        }
    }

    return null;
}

function prepareGroupFantasyPlayersBySection(players)
{
    let grouped = {
        'offense': [],
        'special': [],
        'defense': [],
        'hc': [],
    };

    //group
    for (const player of players) {
        const section = getFantasyPlayerSectionByPosition(player.fantasyPosition);
        grouped[section].push(player);
    }

    return grouped;
}

export function sortGroupedFantasyPlayers(groupedFantasyPlayers)
{
    const sections = Object.keys(groupedFantasyPlayers);
    for (const section of sections) {
        sortFantasyPlayers(groupedFantasyPlayers[section]);
    }
}

export function getRemainingPositions(fantasyPlayers)
{
    let remainingPositions = getFantasySectionPositionsList();

    for (const {fantasyPosition} of fantasyPlayers) {
        const index = remainingPositions.indexOf(fantasyPosition);
        if (index > -1) {
            remainingPositions.splice(index, 1);
        }
    }

    return remainingPositions;
}

export function getRemainingPositionsGroupedBySection(groupedFantasyPlayers)
{
    const sections = Object.keys(groupedFantasyPlayers);
    const output = Object.fromEntries(
        sections.map((section) => [section, []])
    );

    for (const section of sections) {
        let remainingPositions = [...fantasySectionPositions[section]];
        const sectionPlayers = groupedFantasyPlayers[section];
        for (const {fantasyPosition} of sectionPlayers) {
            const index = remainingPositions.indexOf(fantasyPosition);
            if (index > -1) {
                remainingPositions.splice(index, 1);
            }
        }

        for (const fantasyPosition of remainingPositions) {
            output[section].push(fantasyPosition);
        }
    }

    return output;
}

export function groupFantasyPlayersBySection(players, includeEmpty = false)
{
    const grouped = prepareGroupFantasyPlayersBySection(players)
    const sections = Object.keys(grouped);

    //include empty slots (if needed)
    if (includeEmpty) {
        const remainingPositionsPerSection = getRemainingPositionsGroupedBySection(grouped);
        for (const section of sections) {
            for (const fantasyPosition of remainingPositionsPerSection[section]) {
                grouped[section].push({fantasyPosition});
            }
        }
    }

    //sort
    sortGroupedFantasyPlayers(grouped);

    return grouped;
}

function sortFantasyPlayers(players) {
    return players.sort((player1, player2) => {
        const pos1 = positionsOrder.indexOf(player1.fantasyPosition);
        const pos2 = positionsOrder.indexOf(player2.fantasyPosition);
        if (pos1 === pos2) {
            return 0;
        }
        return (pos1 < pos2 ? -1 : 1);
    });
}

export function groupPlayersBySection(players)
{
    let groupped = {
        'offense': [],
        'special': [],
        'defense': [],
        'hc': [],
    };

    //group
    for (const player of players) {
        const group = getPlayerSectionByPosition(player.position);
        groupped[group].push(player);
    }

    //sort
    for (const group in groupped) {
        groupped[group] = sortByPosition(groupped[group]);
    }

    return groupped;
}

export function getPositions()
{
    return positionsOrder;
}

function sortByPosition(players) {
    //Sort offense by position
    return players.sort((player1, player2) => {
        const pos1 = player1.fantasyPosition !== undefined ? player1.fantasyPosition : player1.position;
        const pos2 = player2.fantasyPosition !== undefined ? player2.fantasyPosition : player2.position;

        const pos1index = positionsOrder.indexOf(pos1);
        const pos2index = positionsOrder.indexOf(pos2);

        if (pos1index === pos2index) {
            return 0;
        }

        return (pos1index < pos2index ? -1 : 1);
    });
}

export function getSections() {
     return ['offense', 'special', 'defense'];//, 'hc'];
}

export function getSectionsAttributes() {
     let sectionsAttibutes = {};
     const sections = getSections();
     for (const section of sections) {
         sectionsAttibutes[section] = getAttributesBySection(section);
     }

     return sectionsAttibutes;
}

export function getAttributesBySection(section) {
    if (section === 'offense') {
        return ['pass_yds', 'rush_yds', 'catch_yds', 'td', '_2pt', 'fumble', 'int'];
    }

    if (section === 'special') {
        return ['fg', 'xp', 'fg_miss', 'xp_miss'];
    }

    if (section === 'defense') {
        return ['fum_forced', 'defense_int', 'sack', 'pts_allowed'];
    }

    if (section === 'hc') {
        return ['win_loss', 'pts_gained', 'score_difference'];
    }

    return [];
}

export default function groupPlayersByTeamAndPosition(rosters) {
    let output = [];
    for (let roster of rosters) {
        const team = roster.team;

        let openPositions = {};
        for (const position of positionsOrder) {
            openPositions[position] = [];
        }

        let teamData = {
            ...team,
            players: {
                active: {...openPositions},
                bench: [],
            },
            lastAddedPlayerId: null
        };

        let lastAddedDate = null;
        for (let player of roster.players) {
            if (player.activityStatus === 'active') {
                teamData.players.active[player.fantasyPosition].push(player);
            } else {
                teamData.players.bench.push(player);
            }

            if (!lastAddedDate || player.addedAt > lastAddedDate) {
                lastAddedDate = player.addedAt;
                teamData.lastAddedPlayerId = player.id;
            }
        }

        output.push(teamData);
    }

    return output;
}

export function groupPlayersByActivityStatus(players)
{
    let groupped = {
        active: [],
        bench: [],
    };

    for (const player of players) {
        groupped[player.activityStatus].push(player);
    }

    return groupped;
}

export function getPlayers(search = {}, sortOptions = {}) {
    const {sort, sortDirection} = sortOptions;

    return API.get('players', {params: {
            ...search, sort, sortDirection
        }})
        .then((response) => response.data)
    ;
}

export function addPlayer(playerData) {
    let formData = new FormData();
    for (let key in playerData) {
        formData.append(key, playerData[key] !== null ? playerData[key] : '' );
    }

    return API.post(`players`, formData)
        .then((response) => response.data)
    ;
}


export function transferPlayer(playerId, newTeamId) {
    return API.post(`players/${playerId}/transfer/${newTeamId}`)
        .then((response) => response.data)
    ;
}

export function updatePlayer(playerId, playerData) {
    let formData = new FormData();
    for (let key in playerData) {
        formData.append(key, playerData[key] !== null ? playerData[key] : '' );
    }

    return API.post(`players/${playerId}`, formData)
        .then((response) => response.data)
    ;
}

export function deletePlayer(playerId) {
    return API.delete(`players/${playerId}`)
        .then((response) => response.data)
    ;
}

export function getAvailablePlayers(leagueId, search = {}, sortOptions = {}) {
     const {sort, sortDirection} = sortOptions;

    return API.get(`leagues/${leagueId}/available-players`, {params: {
            ...search, sort, sortDirection
        }})
        .then((response) => response.data)
    ;
}

export function getPlayersPoints(seasonId) {
    return API.get(`seasons/${seasonId}/points`)
        .then((response) => response.data)
    ;
}

export function createTradePlayersProposal(leagueId, fromPlayerId, toPlayerId) {
    return API.get(`leagues/${leagueId}/trade-proposal/${fromPlayerId}/${toPlayerId}`)
        .then((response) => response.data)
    ;
}

export function getPendingTradePlayersProposals(seasonId, teamId) {
    return API.get(`seasons/${seasonId}/fantasy-teams/${teamId}/trade-proposals`)
        .then((response) => response.data)
    ;
}

export function acceptTradePlayersProposal(tradePlayersProposalId) {
    return API.post(`trade-proposals/${tradePlayersProposalId}/accept`)
        .then((response) => response.data)
    ;
}

export function withdrawTradePlayersProposal(tradePlayersProposalId) {
    return API.post(`trade-proposals/${tradePlayersProposalId}/withdraw`)
        .then((response) => response.data)
    ;
}

export function rejectTradePlayersProposal(tradePlayersProposalId) {
    return API.post(`trade-proposals/${tradePlayersProposalId}/reject`)
        .then((response) => response.data)
    ;
}
