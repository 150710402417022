import React from 'react';
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

export default function LeagueSection({league, membership, onExitLeague, onRemoveLeague}) {
    const isLeagueAdmin = (membership.role === 'LEAGUE_ADMIN');
    const canExitLeague = membership.id !== null; //superadmin cannot exit league

    return (
        <div className="mb-default">
            <h2><Trans>League settings</Trans></h2>
            <dl className="dl-list mb-small">
                <dt><Trans>League name</Trans></dt><dd>{league.name}</dd>
                <dt><Trans>Number of teams</Trans></dt><dd>{league.nbMaxTeams}</dd>
            </dl>

            {isLeagueAdmin && <Link to={'/leagues/'+league.id+'/edit'} className="btn-sm btn-default mr-small"><Trans>Edit</Trans></Link>}
            {isLeagueAdmin && <button onClick={() => onRemoveLeague()} className="btn-sm btn-default mr-small"><Trans>Delete League</Trans></button>}
            {canExitLeague && <button onClick={() => onExitLeague()} className="btn-sm btn-default"><Trans>Exit League</Trans></button>}
        </div>
    );
}
