import React from 'react';
import Select from "react-select";
import {getAllTimezones} from "services/TimezoneService";
import {withTranslation} from "react-i18next";

const timezones = getAllTimezones();

function getTimezonesOptions() {
    return Object.entries(timezones).map(([value, label]) => {
        return {value, label};
    });
}

function TimezonePicker({field, form, options, t, ...rest}) {
    if (options === undefined) {
        options = getTimezonesOptions();
    }

    const selectedTimezone = options.find(({value}) => (value === field.value));
    return (
        <Select
            placeholder={t('select_placeholder')}
            options={options}
            defaultValue={selectedTimezone}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            {...rest}
        />
    );
}

export default withTranslation()(TimezonePicker);
