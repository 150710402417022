import API from "services/API";

export function getTeams() {
    return API.get('teams')
        .then((response) => response.data)
    ;
}

export function editTeam(teamId, teamData) {
    return API.post(`teams/${teamId}`, teamData)
        .then((response) => response.data)
    ;
}

export function addTeam(teamData) {
    return API.post(`teams`, teamData)
        .then((response) => response.data)
    ;
}

export function deleteTeam(teamId) {
    return API.delete(`teams/${teamId}`)
        .then((response) => response.data)
    ;
}

export function getPlayers(teamId) {
    return API.get(`teams/${teamId}/players`)
        .then((response) => response.data)
    ;
}

export function getSeasonPlayers(seasonId, teamId) {
    return API.get(`seasons/${seasonId}/teams/${teamId}/players`)
        .then((response) => response.data)
    ;
}

export function getSeasonTeams(seasonId) {
    return API.get(`seasons/${seasonId}/teams`)
        .then((response) => response.data)
    ;
}

export function getCurrentRosterPlayers(teamId) {
    return API.get(`teams/${teamId}/rosters/current/players`)
        .then((response) => response.data)
    ;
}
