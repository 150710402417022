import React from 'react';
import Terms_en from "./html/en/Terms";
import Terms_hu from "./html/hu/Terms";
import {withTranslation} from "react-i18next";

function TermsPage({ i18n }) {
    return (
        <main className="content-page">
            {i18n.language === 'en' ? <Terms_en/> : <Terms_hu/>}
        </main>
    );
}

export default withTranslation()(TermsPage);
