import React from 'react';
import LoadingOverlay from "./LoadingOverlay";

export default function Loader(props) {
    return (
        <LoadingOverlay
            spinner
            active={true}
            styles={{
                overlay: (base) => ({
                    ...base,
                    position: 'initial',
                    background: 'rgba(255, 255, 255, .7)'
                }),
                spinner: (base) => ({
                    ...base,
                    width: '70px',
                    '& svg circle': {
                        stroke: 'rgba(255, 0, 0, 0.5)'
                    }
                })
            }}
            {...props}
        />
    );
}
