import React from 'react';
import Select from "react-select";

function NewsletterSelect({form, field, t, ...rest}) {
    const options = [
        {value: '', label: 'Any'},
        {value: '1', label: 'Yes'},
        {value: '0', label: 'No'}
    ];

    const selectedOption = options.find((option) => option.value === field.value);
    return (
        <Select
            placeholder={'Any'}
            value={selectedOption ? selectedOption : null}
            options={options}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            {...rest}
        />
    );
}

export default NewsletterSelect;
