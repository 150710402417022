import React from 'react';
import {NavLink} from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";

function SecureNavBar({ t }) {
    return (
        <nav className="main-nav-bar">
            <ul className="nav-links">
                <li><NavLink to="/">{t('Home')}</NavLink></li>
                <li><NavLink to="/secure">{t('Leagues')}</NavLink></li>
                <li><NavLink to="/team">{t('My Team')}</NavLink></li>
                <li><NavLink to="/players">{t('Players')}</NavLink></li>
                <li><NavLink to="/game-center">{t('Game Center')}</NavLink></li>
                <li><NavLink to="/trophies">{t('Trophy room')}</NavLink></li>
                <li><NavLink to="/leaderboard">{t('Leaderboards')}</NavLink></li>
                <li><NavLink to="/blog">{t('Blog')}</NavLink></li>
                <li><NavLink to="/faq"><Trans>FAQ</Trans></NavLink></li>
            </ul>
        </nav>
    );
}

export default withTranslation()(SecureNavBar);
