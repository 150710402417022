import React from 'react';
import ReactModal from 'react-modal';
import {Trans} from "react-i18next";

export default function GameResultModal({
      handleCloseModal,
      content,
      gameResult,
      onConfirm,
      onCancel,
      ...rest
    }) {

    const onRequestCancel = () => {
        if (onCancel===undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };

    function getTitle(result) {
        switch (result) {
            case 1:
                return 'Congratulations';
            case 0:
                return 'Keep your head up!';
            case null:
            default:
                return 'Almost got it!';
        }
    }

    function getMessage(result) {
        switch (result) {
            case 1:
                return 'Congratulations, you won your matchup against {{teamName}}';
            case 0:
                return 'Sorry, but you lost your matchup against {{teamName}}';
            case null:
            default:
                return 'Your matchup against {{teamName}} has tied';
        }
    }

    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">
                <Trans values={{teamName: gameResult.oppositeTeam.name}}>{getTitle(gameResult.result)}</Trans>
            </p>
            <div className="modal-content">
                <Trans values={{teamName: gameResult.oppositeTeam.name}}>{getMessage(gameResult.result)}</Trans>
            </div>

            <div className="modal-actions">
                <button className="btn btn-default" onClick={() => {handleCloseModal()}}><Trans>OK</Trans></button>
            </div>
        </ReactModal>
    );
}
