import React from 'react';
import Select from "react-select";
import {getPossiblePlacements} from "services/AdsService";

function getPossiblePlacementsAsOptions() {
    const placements = getPossiblePlacements();
    let options = [];

    for (const placementKey in placements) {
        options.push({
            value: placementKey,
            label: placements[placementKey],
        });
    }

    return options;
}

const PlacementSelect = ({form, field, ...rest}) => {
    const options = getPossiblePlacementsAsOptions();
    const selectedOption = options.find((option) => option.value === field.value);

    return (
        <Select
            defaultValue={selectedOption}
            options={options}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            {...rest}
        />
    );
};

export default PlacementSelect;
