import React from 'react';
import {
    acceptTradePlayersProposal,
    getPendingTradePlayersProposals,
    rejectTradePlayersProposal, withdrawTradePlayersProposal
} from "services/PlayerService";
import {addErrorAsNotification} from "services/ValidationService";
import {Trans, withTranslation} from "react-i18next";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import TradeProposalsTable from "modules/Team/TradeProposalsTable";
import {addSuccessNotification} from "services/NotificationService";

class TradeProposalsSection extends React.Component {
    state = {
        isLoading: true,
        tradePlayersProposals: null,
    };

    componentDidMount() {
        this.refreshData();
    };

    refreshData = () => {
        const {season, team} = this.props;

        this.setState({isLoading: true});
        getPendingTradePlayersProposals(season.id, team.id)
            .then((tradePlayersProposals) => {
                this.setState({tradePlayersProposals, isLoading: false});
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onAccept = (tradePlayersProposalsId) => {
        this.setState({isLoading: true});
        acceptTradePlayersProposal(tradePlayersProposalsId)
            .then(() => {
                this.setState({isLoading: false});
                const {onChange, t} = this.props;
                addSuccessNotification(
                    t('Success'),
                    t('Trade proposal was successfully accepted')
                );
                onChange();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onReject = (tradePlayersProposalsId) => {
        this.setState({isLoading: true});
        rejectTradePlayersProposal(tradePlayersProposalsId)
            .then(() => {
                const {onChange, t} = this.props;
                addSuccessNotification(
                    t('Success'),
                    t('Trade proposal was successfully rejected')
                );
                onChange();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onWithdraw = (tradePlayersProposalsId) => {
        this.setState({isLoading: true});
        withdrawTradePlayersProposal(tradePlayersProposalsId)
            .then(() => {
                const {onChange, t} = this.props;
                addSuccessNotification(
                    t('Success'),
                    t('Trade proposal was successfully withdrawn')
                );
                onChange();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };
    
    render() {
        const {tradePlayersProposals, isLoading} = this.state;
        if (!tradePlayersProposals || !tradePlayersProposals.length) {
            return null;
        }

        return (
            <div className="mb-default mt-default">
                <LoadingOverlay active={isLoading}>
                    <h3 className="mb-default"><Trans>Trade proposals</Trans></h3>
                    <TradeProposalsTable
                        team={this.props.team}
                        tradePlayersProposals={tradePlayersProposals}
                        onAccept={this.onAccept}
                        onReject={this.onReject}
                        onWithdraw={this.onWithdraw}
                    />
                </LoadingOverlay>
            </div>
        );
    }
}

export default withTranslation()(TradeProposalsSection);