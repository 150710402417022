import { store } from 'react-notifications-component';

export function addSuccessNotification(title, message) {
    store.addNotification({
        title: title,
        message: message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 10000,
            showIcon: true
        }
    });
}

export function addWarningNotification(title, message) {
    store.addNotification({
        title: title,
        message: message,
        type: "warning",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 10000,
            showIcon: true
        }
    });
}

export function addNotification(title, message) {
    store.addNotification({
        title: title,
        message: message,
        type: "default",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 10000,
            showIcon: true
        }
    });
}

export function addErrorNotification(title, message) {
    store.addNotification({
        title: title,
        message: message,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 10000,
            showIcon: true
        }
    });
}
