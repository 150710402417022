import React, {Component} from 'react';
import {addErrorAsNotification} from "services/ValidationService";
import {deleteBlogPost, getBlogPosts} from "services/BlogService";
import BlogPostsTable from "./BlogPostsTable";
import Loader from "modules/Common/Loader";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import DeleteBlogPostConfirmationModal from "./DeleteBlogPostConfirmationModal";
import {addSuccessNotification} from "services/NotificationService";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

class BlogPostsPage extends Component {
    state = {
        isLoading: true,
        blogPosts: undefined,
        selectedBlogPost: null,
        showDeleteBlogPostConfirmationModal: false,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        getBlogPosts()
            .then((blogPosts) => this.setState({blogPosts, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    showDeleteBlogPostConfirmationModal = (selectedBlogPost) => {
        this.setState({selectedBlogPost, showDeleteBlogPostConfirmationModal: true});
    };

    hideDeleteBlogPostConfirmationModal = () => {
        this.setState({showDeleteBlogPostConfirmationModal: false});
    };

    onDelete = (blogPost) => {
        this.showDeleteBlogPostConfirmationModal(blogPost);
    };

    onExecuteDeleteBlogPost = (blogPost) => {
        this.setState({isLoading: true});
        deleteBlogPost(blogPost.id)
            .then(() => {
                addSuccessNotification('Succcess', `Blog post "${blogPost.title}" was successfully deleted`);
                this.refreshData();
            })
            .catch((response) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    addNewBlogPost = () => {
        this.props.history.push('/owner/blog/create-post');
    };

    onEdit = (blogPost) => {
        this.props.history.push(`/owner/blog/${blogPost.id}`);
    };

    render() {
        const {blogPosts, selectedBlogPost, showDeleteBlogPostConfirmationModal, isLoading} = this.state;

        if (isLoading && blogPosts===undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <div className="mb-small clearfix">
                    <input
                        type="button"
                        className="btn btn-primary float-right"
                        style={{marginBottom: 4}}
                        value="New Post"
                        onClick={() => this.addNewBlogPost()}
                    />
                    <h1>Blog</h1>
                </div>

                <LoadingOverlay active={isLoading}>
                    <BlogPostsTable
                        blogPosts={blogPosts}
                        onDelete={this.onDelete}
                        onEdit={this.onEdit}
                    />
                </LoadingOverlay>

                <DeleteBlogPostConfirmationModal
                    isOpen={showDeleteBlogPostConfirmationModal}
                    blogPost={selectedBlogPost}
                    handleCloseModal={this.hideDeleteBlogPostConfirmationModal}
                    onConfirm={() => this.onExecuteDeleteBlogPost(selectedBlogPost)}
                />
            </main>
        );
    }
}

export default withTranslation()(withRouter(BlogPostsPage));
