import {Trans} from "react-i18next";
import React from "react";

export function prepareActionsCell({ type, selectable, editable, deletable, tradeable, onTradePlayer, onSelectPlayer, onCutPlayer, onMoveToBench, onMoveToActive }) {
    const hasActions = editable || selectable;
    const movable = deletable;

    if (!hasActions) {
        return null;
    }

    return (player) => {
        let actions = [];

        if (player.id) {
            if (editable) {
                if (movable) {
                    if (type === 'active') {
                        actions.push(<button key={`${player.id}_to_bench`} className="btn btn-ico btn-sm btn-default"
                                             onClick={() => onMoveToBench(player)}>
                            <img  style={{width: 16}} src="/img/icons/down.svg" />
                        </button>);
                    } else {
                        actions.push(<button key={`${player.id}_to_active`} className="btn btn-ico btn-sm btn-default"
                                             onClick={() => onMoveToActive(player)}><img style={{width: 16}} src="/img/icons/up.svg" /></button>);
                    }

                    if (tradeable) {
                        actions.push(<button key={`${player.id}_trade`} className="btn btn-ico btn-sm btn-default"
                                             onClick={() => onTradePlayer(player)}><span
                            style={{fontSize: '1.4em'}}><img style={{width: 16}} src="/img/icons/transfer.svg" /></span></button>);
                    }

                    if (deletable) {
                        actions.push(<button key={`${player.id}_cut`} className="btn btn-ico btn-sm btn-danger"
                                             onClick={() => onCutPlayer(player)}>✕</button>);
                    }
                }
            }

            if (selectable) {
                actions.push(<button key={`${player.id}_select`}
                                     className="btn btn-default btn-sm ml-tiny mr-tiny mb-tiny"
                                     onClick={() => onSelectPlayer(player)}><Trans>Select</Trans></button>);
            }

            if (!actions.length) {
                return [];
            }
        } else {
            if (!editable && !selectable) {
                return [];
            }
        }

        return (<span className="roster-actions">{actions}</span>);
    };
}