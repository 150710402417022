import React from 'react';

const CopyrightNotice = () => {
    return (
        <div>
            <h1>HUNGARIAN FANTASY FOOTBALL SZERZŐI JOGI ÉRTESÍTÉS</h1>
            <p><strong>Hatálybalépés napja:</strong> 2019.
            október 21. </p><p>Ez a honlap, valamint annak tartalma a Hungarian Fantasy Football szerzői joga alá esik - ©
            Hungarian Fantasy Football 2019. Minden jog fenntartva.</p><p>A weboldalon elérhető tartalom az alkalmazandó szerzői
            jogi törvény és nemzetközi szerződések hatálya alá tartozik. A szerzői jog tulajdonosának kifejezett írásos
            engedélye nélkül tilos reprodukálni, újraosztani, kereskedelmi szinten hasznosítani, kommunikálni, eladni, bérbe
            adni, alengedélyezni vagy nyilvánosan megjeleníteni az ezen a weboldalon elérhető tartalmakat. Továbbá tilos
            továbbítani az elektronikus visszakeresési rendszer tartalmát, vagy tárolni bármely más honlapon, vagy más
            formában.</p><p>A tiltás vonatkozik az ezen a honlapon elérhető anyagok minden típusára, beleértve korlátozás nélkül
            az írott anyagokat, képeket, forráskódot, a felhasználó által generált tartalmat, adatbázisokat és a letölthető
            fájlokat.</p><p>Kizárólag a következő tevékenységek végzését engedélyezzük:</p>
            <ul>
                <li>Weblapokat tekinthet meg egy webböngésző segítségével személyes, nem kereskedelmi célú használatra;</li>
                <li>Oldalakat tölthet le a Hungarian Fantasy Footballról, a böngészőben történő megtekintéshez;</li>
                <li>Személyes és nem kereskedelmi célú felhasználásra nyomtathat vagy letölthet helyi merevlemezre kivonatokat;
                    valamint
                </li>
                <li>A tartalmat másolhatja az egyes harmadik feleknek személyes használatra, de csak akkor, ha elismeri, hogy a
                    Hungarian Fantasy Football ezeknek az anyagoknak a forrása.
                </li>
            </ul><p>További információkért az egyéb vonatkozó szabályainkról a Hungarian Fantasy Football és a harmadik fél szellemi
            tulajdonára vonatkozóan, kérjük, olvassa el az Általános Szerződési Feltételeket, mely elérhető a <a href="//fantasyfootball.hu">fantasyfootball.hu/terms</a> linken.</p><p>A Hungarian FantasyFootball tartalmával kapcsolatos engedélyekért kérjük,
            vegye fel velünk a kapcsolatot a(z) info@fantasyfootball.hu e-mail címen.</p>
        </div>
    );
};

export default CopyrightNotice;