import React, {useState} from 'react';
import Moment from "react-moment";
import {withTranslation} from "react-i18next";

const PlayersTable = ({players, selectedPlayer: origSelectedPlayer, editable, onEdit, onTransfer, onDelete, t}) => {
    const [selectedPlayer] = useState(origSelectedPlayer);

    if (!players) {
        return null;
    }

    return (
        <table className="table table-bordered table-condensed mb-default">
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Jersey #</th>
                        <th>Weight</th>
                        <th>Height</th>
                        <th>DOB</th>
                        <th>Status</th>
                        {editable && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {players.map((player) =>
                        <>
                            <tr key={player.id}
                                className={selectedPlayer && selectedPlayer.id === player.id ? 'selected' : null}
                            >
                                <td>
                                    {player.photo && <img style={{width: 80}} src={player.photo} />}
                                </td>
                                <td>{player.name}</td>
                                <td>{t(player.position)}</td>
                                <td>{player.jerseyNumber}</td>
                                <td>{player.weight ? `${player.weight} kg` : '-'}</td>
                                <td>{player.height ? `${player.height} cm` : '-'}</td>
                                <td>{player.birthDate ? <Moment format="DD/MM/YYYY">{player.birthDate}</Moment> : '-'}</td>
                                <td>{player.rosterStatus ? player.rosterStatus : player.status}</td>
                                {editable && <td className="min-content">
                                    <input
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        value="✎"
                                        onClick={() => onEdit(player)}
                                    />

                                    &nbsp;

                                    <button key={`${player.id}_trade`} className="btn btn-ico btn-sm btn-default"
                                            onClick={() => onTransfer(player)}><span><img style={{width: 12}} src="/img/icons/transfer.svg" /></span></button>

                                    &nbsp;

                                    <input
                                        type="button"
                                        className="btn btn-sm btn-default"
                                        value="✕"
                                        onClick={() => onDelete(player)}
                                    />
                                </td>}
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
    );
};

export default withTranslation()(PlayersTable);
