import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import Header from "modules/Common/Header";
import Footer from "modules/Common/Footer";
import {getUser} from "services/AuthService";
import OwnerHeader from "modules/Owner/Common/Header";
import {getAds, getPageNameByLocation} from "services/AdsService";

class SimpleLayout extends Component {
    state = {
        ads: null,
        pageAds: null,
    };

    componentDidMount() {
        getAds()
            .then((ads) => {
                this.setState({ads}, () => {
                    this.loadPageAds();
                })
            })
            .catch(() => null)
        ;
    }

    componentDidUpdate(prevProps) {
        this.loadPageAds(prevProps);
    }

    loadPageAds(prevProps) {
        if (!this.state.ads) {
            return;
        }

        if (!prevProps || this.props.location !== prevProps.location) {
            const page = getPageNameByLocation(this.props.location.pathname);
            this.setState({
                pageAds : (page && this.state.ads[page] !== undefined) ? this.state.ads[page] : null,
            });
        }
    }

    render() {
        const {component: Component, ...rest} = this.props;

        const user = getUser();
        const header = (user && user.roles && user.roles.includes("SUPER_ADMIN")) ? <OwnerHeader /> : <Header />;

        return (
            <Route {...rest} render={matchProps => (
                <div>
                    {header}
                    <Component {...matchProps} />
                    <Footer />
                </div>
            )}/>
        )
    }
}

export default withRouter(SimpleLayout);
