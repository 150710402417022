import API from "services/API";

export function getTeamLogos() {
    return API.get('team-logos')
        .then((response) => response.data)
    ;
}

export function createTeamLogo(teamLogoData) {
    let formData = new FormData();
    for (let key in teamLogoData) {
        formData.append(key, teamLogoData[key]);
    }

    return API.post('team-logos', formData, {
        headers: {'Content-Type': 'multipart/form-data'}}
    ).then((response) => response.data);
}

export function deleteTeamLogo(teamLogoId) {
    return API.delete(`team-logos/${teamLogoId}`)
        .then((response) => response.data)
    ;
}
