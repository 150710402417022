import React, {Component} from 'react';
import ReactModal from "react-modal";
import TeamSelect from "modules/Leagues/TeamSelect";
import {Trans, withTranslation} from "react-i18next";
import {addErrorAsNotification} from "services/ValidationService";
import {getFantasyTeamRoster} from "services/LeagueService";
import RosterSection from "modules/Roster/RosterSection";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import TradePlayerConfirmationModal from "modules/Draft/TradePlayerConfirmationModal";

class TradePlayerModal extends Component {
    state = {
        selectedPlayer: null,
        isLoading: false,
        selectedRoster: null,
        showConfirmationModal: false,
    };

    onChangeTeam = (teamId) => {
        this.setState({isLoading: true});
        getFantasyTeamRoster(this.props.leagueId, teamId)
            .then((selectedRoster) => {
                this.setState({
                    selectedRoster,
                    team: this.getTeam(teamId),
                    isLoading: false
                });
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    getTeam = (teamId) => {
        const { teams } = this.props;
        if (!teams) {
            return null;
        }

        for (const team of teams) {
            if (team.id === teamId) {
                return team;
            }
        }

        return null;
    };

    selectPlayer = (player) => {
        this.setState({showConfirmationModal: true, selectedPlayer: player});
    };

    render() {
        const {
            player,
            teams,
            handleCloseModal,
            onConfirm,
            onCancel,
            t,
            ...rest
        } = this.props;

        const { selectedTeam, selectedRoster } = this.state;

        if (!player) {
            return null;
        }

        const onRequestCancel = () => {
            if (onCancel === undefined || onCancel() !== false) {
                handleCloseModal();
            }
        };
        const positionTitle = t(player.position);
        const namePosition = `${player.name} (${positionTitle})`;

        return (
            <ReactModal
                onRequestClose={onRequestCancel}
                className="default-modal wide"
                overlayClassName="tall-modal-overlay"
                closeTimeoutMS={200}
                {...rest}
            >
                <span onClick={onRequestCancel} className="modal-close">&times;</span>

                <p className="modal-title"><Trans>Trade players</Trans></p>
                <div className="modal-content">

                    <p className="mb-default">
                        <Trans i18nKey="Select a player you want to trade {{namePosition}} with" values={{namePosition}} />
                    </p>

                    <p className="mb-small">
                        <Trans>Choose player</Trans>
                    </p>
                    <TeamSelect
                        teams={teams}
                        selectedTeam={selectedTeam}
                        onChange={(teamId) => this.onChangeTeam(teamId)}
                    />

                    <LoadingOverlay active={this.state.isLoading}>
                        {selectedRoster && <div className="roster-wrapper">
                        <RosterSection
                            roster={selectedRoster}
                            editable={false}
                            selectable={true}
                            onSelectPlayer={this.selectPlayer}
                        /></div>}
                    </LoadingOverlay>
                </div>

                <div className="modal-actions">
                    <button className="btn btn-default" onClick={onRequestCancel}><Trans>Cancel</Trans></button>
                </div>

                {player && <TradePlayerConfirmationModal
                    isOpen={this.state.showConfirmationModal}
                    fromPlayer={player}
                    toPlayer={this.state.selectedPlayer}
                    handleCloseModal={() => this.setState({showConfirmationModal: false})}
                    onConfirm={onConfirm}
                />}

            </ReactModal>
        )
    }
}

export default withTranslation()(TradePlayerModal);
