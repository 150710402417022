import React from 'react';
import {Trans} from "react-i18next";
import facebookIcon from "img/social/colored/facebook.svg";
import instagramIcon from "img/social/colored/instagram.svg";

export default class ContactPage extends React.Component {
    render() {
        return (
            <main>
                <h1>Contact</h1>

                <div>
                    <h4>
                        <Trans>Owner</Trans>: Gábor Kiss - <a
                        href="mailto:info@fantasyfootball.hu">info@fantasyfootball.hu</a>
                    </h4>

                    <div className="social-icons">
                        <a target="_blank" href="https://www.facebook.com/FantasyFootball.hu/"><img src={facebookIcon}
                                                                                                    alt="Facebook"
                                                                                                    style={{width: 40}}/></a>
                        <a target="_blank" href="https://www.instagram.com/fantasyfootball.hu/"><img src={instagramIcon}
                                                                                                     alt="Instagram" style={{width: 40}}/></a>
                    </div>

                    <h4 className="mb-small">
                        <p><Trans>Web development</Trans>: Pavel Dubinin / <a target="_blank" href="https://github.com/geekdevs">@geekdevs</a></p>
                    </h4>

                    <h4 className="mb-small">
                        <p><Trans>Photography</Trans>: Krisztina Kiss</p>
                    </h4>

                    <div className="mb-small">
                        <h4><Trans>Landing page banner photos</Trans>: Norbert Radó</h4>
                        <p>facebook: Norbert Radó / <a target="_blank" href="https://facebook.com/photoinvisible">@photoinvisible</a></p>
                        <p>instagram: <a target="_blank" href="https://instagram.com/radonorbi">@radonorbi</a> <a target="_blank" href="https://instagram.com/photoinvisible">@photoinvisible</a></p>
                        <p>web: Norbert Radó <a target="_blank" href="https://photoinvisible.com/">#photoinvisible</a></p>
                        <p>print: Norbert Radó</p>
                    </div>

                </div>
            </main>
        );
    }
}
