import React from 'react';
import Select from "react-select";
import {getPossiblePages} from "services/AdsService";

function getPossiblePagesAsOptions() {
    const pages = getPossiblePages();
    let options = [];

    for (const pageKey in pages) {
        options.push({
            value: pageKey,
            label: pages[pageKey],
        });
    }

    return options;
}

const PageSelect = ({form, field, ...rest}) => {
    const options = getPossiblePagesAsOptions();
    const selectedOption = options.find((option) => option.value === field.value);

    return (
        <Select
            defaultValue={selectedOption}
            options={options}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            {...rest}
        />
    );
};

export default PageSelect;
