import React, {useRef} from 'react';
import ReactModal from "react-modal";
import TeamForm from "./TeamForm";

export default function TeamModal(props) {
    const {
        team,
        switchTeams,
        handleCloseModal,
        onSubmit,
        onCancel,
        ...rest
    } = props;

    const onRequestCancel = () => {
        if (onCancel === undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };

    const submitButtonRef = useRef(null);
    const isNew = (team === null);

    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">{isNew ? 'Add Team' : 'Edit Team'}</p>
            <div className="modal-content">
                <TeamForm
                    team={team}
                    submitButtonRef={submitButtonRef}
                    processData={(teamData) => {
                        //selectedTeam
                        onSubmit(teamData);
                        handleCloseModal();
                    }}
                />
            </div>

            <div className="modal-actions">
                <button className="btn btn-default" onClick={onRequestCancel}>Cancel</button>
                <button className="btn btn-success" onClick={() => {
                    submitButtonRef.current.click();
                }}>Save
                </button>
            </div>
        </ReactModal>
    )
}
