import {getIn} from "formik";
import React from "react";

const PointsWidget = function({ field, form }) {
    const {name} = field;
    let className = 'form-control-min';
    const error = getIn(form.errors, name);
    const touched = getIn(form.touched, name);
    if (touched && error && (typeof error === 'string')) {
        className += ' has-error';
    }

    return <input name={name} type="text" className={className} {...field} />
};

export default PointsWidget;
