import React from 'react';
import {NavLink} from "react-router-dom";
import {isLoggedIn} from "services/AuthService";
import {Trans} from "react-i18next";
import LangLinks from "modules/Common/LangLinks";

export default function NavActions() {
    const loggedIn = isLoggedIn();

    return (
        <nav className="nav-actions">
            <LangLinks />
            <ul className="nav-links">
                {loggedIn || <li><NavLink to="/register"><Trans>Register</Trans></NavLink></li>}
                {loggedIn || <li><NavLink to="/login"><Trans>Login</Trans></NavLink></li>}
                {!loggedIn || <li><NavLink to="/logout"><Trans>Logout</Trans></NavLink></li>}
            </ul>
        </nav>
    );
}
