import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {addErrorAsNotification, normalizeErrors} from "services/ValidationService";
import {Link} from "react-router-dom";
import GenderSelect from "modules/Common/Form/GenderSelect";
import moment from 'moment/min/moment-with-locales';
import {Trans, withTranslation} from "react-i18next";
import Checkbox from "modules/Common/Form/Checkbox";
import DropdownDatePicker from "modules/Common/Form/DropdownDatePicker";

const RegisterForm = function({handleSubmit, t, ...rest}) {
    const termsLink = t('By creating an account you agree with our {{link}}Terms of Use{{/link}}', {
        'link': '<a target="_blank" href="/terms">',
        '/link': '</a>',
    });

    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form registration-form" noValidate>
            <h1><Trans>Register</Trans></h1>

            <div className="form-group">
                <label className="form-label" htmlFor="firstName"><Trans>First name</Trans></label>
                <Field type="text" name="firstName" className="form-control" />
                <ErrorMessage name="firstName" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="lastName"><Trans>Last name</Trans></label>
                <Field type="text" name="lastName" className="form-control" />
                <ErrorMessage name="lastName" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="gender"><Trans>Gender</Trans></label>
                <Field component={GenderSelect} name="gender" className="form-control" />
                <ErrorMessage name="gender" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="location"><Trans>Location</Trans></label>
                <Field type="text" name="location" className="form-control" />
                <ErrorMessage name="location" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="birthDate"><Trans>DOB</Trans></label>
                <Field component={DropdownDatePicker} name="birthDate" />
                <ErrorMessage name="birthDate" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="email"><Trans>E-mail</Trans></label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage name="email" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="emailConfirm"><Trans>Confirm e-mail</Trans></label>
                <Field type="email" name="emailConfirm" className="form-control" />
                <ErrorMessage name="emailConfirm" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="password"><Trans>Password</Trans></label>
                <Field type="password" name="password" className="form-control" />
                <ErrorMessage name="password" />
            </div>
            <div style={{marginBottom: '3rem', 'color': 'gray'}}>
                <Trans i18nKey="Please input at least {{length}} characters" values={{length: 8}} />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="passwordConfirm"><Trans>Confirm password</Trans></label>
                <Field type="password" name="passwordConfirm" className="form-control" />
                <ErrorMessage name="passwordConfirm" />
            </div>

            <div className="form-group mt-default">
                <label className="form-label" htmlFor="newsletter" />
                <label className="form-label">
                    <Field component={Checkbox} className="mr-tiny" id="newsletter" name="newsletter"  />

                    <Trans>I want to receive a newsletter</Trans>
                </label>

                <ErrorMessage name="newsletter" />
            </div>

            <div className="mt-default">
                <input className="submit btn btn-primary" type="submit" value={t('Create Account')} />
            </div>

            <div className="form-group mt-large">
                <label className="form-label"/>
                <p className="form-control text-center-mobile">
                    <p dangerouslySetInnerHTML={{__html: termsLink}} className="mb-small" />
                    <Trans>Already have an account?</Trans> <Link to="/login"><Trans>Click here to Log in</Trans></Link>
                </p>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: () => ({
            'firstName': '',
            'lastName': '',
            'gender': '',
            'location': '',
            'birthDate': null,
            'email': '',
            'emailConfirm': '',
            'password': '',
            'passwordConfirm': '',
            'newsletter': false,
        }),
        validationSchema: ({ t }) => yup.object().shape({
            firstName: yup.string().required(t('Please input first name')),
            lastName: yup.string().required(t('Please input last name')),
            location: yup.string()
                .required(t('Please input your location'))
                .max(180, t('Must be shorter than {{max}} characters', {max: 180})),
            birthDate: yup.date()
                .required(t('Please input your date of birth'))
                .nullable()
                .typeError(t('Invalid date'))
            ,
            gender: yup.string()
                .required(t('Please choose your gender')).oneOf(['m', 'f']),
            email: yup.string()
                .required(t('You didn\'t fill in your e-mail'))
                .email(t('E-mail format is incorrect')),
            emailConfirm: yup.string()
                .required(t('Please confirm email'))
                .oneOf([yup.ref('email'), null], t('E-mails must match')),
            password: yup.string()
                .required(t('Please input your password'))
                .min(8, t('Please input at least {{length}} characters', {length: 8})),
            passwordConfirm: yup.string()
                .required(t('Please confirm password'))
                .oneOf([yup.ref('password'), null], t('Passwords don\'t match')),

            newsletter: yup.bool()
        }),
        handleSubmit: (values, { props, setErrors }) => {
            const data = {...values};
            data.birthDate =  moment(values.birthDate).format('YYYY-MM-DD');
            props.processData(data)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(RegisterForm);
