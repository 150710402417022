import React, {useState} from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";
import MemberSelect from "modules/LeagueAdmin/MemberSelect";
import * as PropTypes from "prop-types";

class TransferRightsModal extends React.Component {
    state = {
        selectedMembers: {}
    };

    setSelectedMember = (leagueId, member) => {
        let selectedMembers = this.state.selectedMembers;
        selectedMembers[leagueId] = member;
        this.setState({selectedMembers});
    };

    render() {
        let {league, members, onConfirm, t, ...rest} = this.props;

        if (!members) {
            return null;
        }

        let content = [];
        for (const leagueMembers of members) {
            const league = leagueMembers.league;
            if (!league) {
                continue;
            }
            const selectedMember = this.state.selectedMembers[league.id] !== undefined ? this.state.selectedMembers[league.id] : null;

            content.push(
                <div key={league.id} className="mb-default">
                    <strong>{league.name}:</strong>
                    <MemberSelect selectedMember={selectedMember} members={leagueMembers.members}
                                  onChange={(member) => this.setSelectedMember(league.id, member)}/>
                </div>
            );
        }

        return (
            <ConfirmationModal
                title={t("Transfer rights")}
                content={content}
                buttons={{
                    yes: t('OK'),
                    no: t('Cancel')
                }}
                type="creative"
                onConfirm={() => onConfirm(this.state.selectedMembers)}
                {...rest}
            />
        );
    }
}

export default withTranslation()(TransferRightsModal);
