import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ render, ...rest }) => (
    <Route {...rest} render={props => {
        if (localStorage.getItem('user')) {
            return render(props);
        }

        return <Redirect to={{ pathname: '/login', state: { from: props.location, notification: true } }} />;
    }} />
);

export default PrivateRoute;
