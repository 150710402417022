import React from 'react';
import {compose} from "recompose";
import {Field, withFormik} from "formik";
import * as yup from "yup";
import {Trans} from "react-i18next";
import MemberTypeSelect from "modules/Common/Form/MemberTypeSelect";
import LeagueFillStatusSelect from "modules/Common/Form/LeagueFillStatusSelect";
import DraftStatusSelect from "modules/Common/Form/DraftStatusSelect";
import LeaguesQtySelect from "modules/Owner/Users/LeaguesQtySelect";
import DraftLengthSelect from "modules/Owner/Users/DraftLengthSelect";
import LeagueSizeGradeSelect from "modules/Owner/Users/LeagueSizeGradeSelect";
import NewsletterSelect from "modules/Owner/Users/NewsletterSelect";

const FilterForm = function({filtersOptions, values, handleReset, handleSubmit, onFilter, errors, touched}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} noValidate style={{marginBottom: 30}}>
            <div className="form search-form">
                <div className="form-group">
                    <label style={errors.memberType && touched.memberType ? {color: 'red'} : {}} className="form-label" htmlFor="memberType"><Trans>Member Type</Trans></label>
                    <div style={{width: '100%'}}>
                        <Field component={MemberTypeSelect} name="memberType" className="form-control" />
                    </div>
                </div>

                <div className="form-group">
                    <label style={errors.nbLeagues && touched.nbLeagues ? {color: 'red'} : {}} className="form-label" htmlFor="nbLeagues"><Trans>Qty of Leagues</Trans></label>
                    <Field component={LeaguesQtySelect} nbMaxLeagues={filtersOptions.nbMaxLeagues} name="nbLeagues" className="form-control" />
                </div>

                <div className="form-group">
                    <label style={errors.leagueFillStatus && touched.leagueFillStatus ? {color: 'red'} : {}} className="form-label" htmlFor="leagueFillStatus"><Trans>League Status</Trans></label>
                    <div style={{width: '100%'}}>
                        <Field component={LeagueFillStatusSelect} name="leagueFillStatus" className="form-control" />
                    </div>
                </div>

                <div className="form-group">
                    <label style={errors.leagueSizeGrade && touched.leagueSizeGrade ? {color: 'red'} : {}} className="form-label" htmlFor="leagueSizeGrade"><Trans>League Size</Trans></label>
                    <div style={{width: '100%'}}>
                        <Field component={LeagueSizeGradeSelect} anyOption name="leagueSizeGrade" className="form-control" />
                    </div>
                </div>

                <div className="form-group">
                    <label className="form-label" htmlFor="draftStatus"><Trans>Draft Status</Trans></label>
                    <Field component={DraftStatusSelect} name="draftStatus" className="form-control" />
                </div>


                <div className="form-group">
                    <label className="form-label" htmlFor="draftLength"><Trans>Draft Length</Trans></label>
                    <Field component={DraftLengthSelect} name="draftLength" className="form-control" />
                </div>

                <div className="form-group">
                    <label className="form-label" htmlFor="newsletter"><Trans>Newsletter</Trans></label>
                    <Field component={NewsletterSelect} name="newsletter" className="form-control" />
                </div>
            </div>

            <input className="btn-sm btn-primary" type="submit" value="Search" style={{marginRight: 10}} />
            <input className="btn-sm btn-primary" type="reset" onClick={handleReset} value="Reset" />
        </form>
    );
};

export default compose(
    withFormik({
        mapPropsToValues: ({ t }) => ({
            memberType: null,
            nbLeagues: null,
            leagueFillStatus: null,
            leagueSizeGrade: null,
            draftStatus: null,
            draftLength: null,
            newsletter: null,
        }),
        validationSchema: () => {
            return yup.object().shape({
            })
        },
        handleSubmit: (values, { props }) => {
            props.onFilter(values);
        },
        onReset: (values, {submitForm}) => {
            submitForm();
        }
    })
)(FilterForm);
