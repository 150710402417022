import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {addErrorAsNotification, normalizeErrors} from "services/ValidationService";
import TimezonePicker from "modules/Common/TimezonePicker";
import UploadThumbnail from "modules/Common/UploadThumbnail";
import TeamLogoSelector from "modules/LeagueAdmin/EditTeam/TeamLogoSelector";
import {Trans, withTranslation} from "react-i18next";

const EditTeamForm = function({team, teamLogos, handleSubmit, errors, values, setFieldValue, onCancel}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form center-form" noValidate>
            <h1><Trans>{team ? 'Edit team' : 'Create team'}</Trans></h1>

            {errors.general && <div className="error-message">{errors.general}</div>}

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Team name</Trans></label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Timezone</Trans></label>
                <Field component={TimezonePicker} name="timezone" className="form-control" />
                <ErrorMessage name="timezone" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="ownerLogo"><Trans>Select logo</Trans></label>
                <Field component={TeamLogoSelector} teamLogos={teamLogos} name="ownerLogo" />
                <ErrorMessage name="ownerLogo" />

                <label className="form-label" htmlFor="logo"><Trans>Browse on your pc and upload a picture</Trans>:</label>

                <input name="logo" type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                    setFieldValue("logo", event.currentTarget.files[0]);
                }} className="form-control"/>
                <ErrorMessage name="logo" />

                <div style={{marginLeft: '25rem'}} className='mt-default'>
                    <UploadThumbnail file={values.logo}/>
                </div>
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>{team ? 'Save Changes' : 'Create team'}</Trans></button>
                <button onClick={() => onCancel()} className="btn btn-default"><Trans>Cancel</Trans></button>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: ({ team }) => {
            return {
                'name': team ? team.name : '',
                'timezone': team ? team.timezone : '',
                'ownerLogo': '',
                'logo': '',
                'newsletter': null
            }
        },
        validationSchema: ({ t }) => yup.object().shape({
            name: yup.string().required(t('Team name is required')),
            timezone: yup.string().required(t('Please select your timezone')),
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(EditTeamForm);
