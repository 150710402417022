import React, {Component} from 'react';
import MembersTable from "modules/LeagueAdmin/Dashboard/MembersTable";
import {
    acceptPendingMember,
    deleteLeagueMember,
    deletePendingLeagueMember,
    getMembers,
    getPendingMembers
} from "services/LeagueService";
import {addErrorNotification, addSuccessNotification} from "services/NotificationService";
import {normalizeGeneralError} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import {withRouter} from "react-router-dom";
import RemoveMemberConfirmationModal from "modules/LeagueAdmin/Dashboard/RemoveMemberConfirmationModal";
import RemovePendingMemberConfirmationModal from "modules/LeagueAdmin/Dashboard/RemovePendingMemberConfirmationModal";
import AcceptPendingMemberConfirmationModal from "modules/LeagueAdmin/Dashboard/AcceptPendingMemberConfirmationModal";
import {withTranslation} from "react-i18next";

class MembersWidget extends Component {
    state = {
        isLoading: false,
        members: null,
        pendingMembers: null,

        selectedMember: null,
        selectedPendingMember: null,

        //modals
        showRemoveMemberModal: false,
        showRemovePendingMemberModal: false,
        showAcceptPendingMemberModal: false,
    };

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.league !== this.props.league) {
            this.refreshData();
        }
    }

    refreshData() {
        const league = this.props.league;
        if (!league) {
            return;
        }

        const leagueId = league.id;
        let data = {league: null, members: null};
        this.setState({isLoading: true});

        Promise.all([
            getMembers(leagueId).then((members) => {
                data.members = members;
            }),

            getPendingMembers(leagueId).then((pendingMembers) => {
                data.pendingMembers = pendingMembers;
            }),
        ]).then(() => {
            this.setState(data);
        }).finally(() => {
            this.setState({isLoading: false});
        });
    }



    showRemoveMemberModal = (member) => {
        this.setState({showRemoveMemberModal: true, selectedMember: member});
    };

    hideRemoveMemberModal = () => {
        this.setState({showRemoveMemberModal: false});
    };
    onRequestDeleteMember = (member) => {
        this.showRemoveMemberModal(member);
    };

    onDeleteMember = (member) => {
        const {t} = this.props;
        this.setState({isLoading: true});
        deleteLeagueMember(member.league.id, member.id)
            .then(() => {
                addSuccessNotification(
                    t('Success'),
                    t('The following member has been removed from the league: {{name}}', {name: member.name})
                );
                this.refreshData();
            })
            .catch(({response}) => {
                 addErrorNotification(this.props.t('Error'), normalizeGeneralError(response));
            }).finally(() => {
            this.setState({isLoading: false});
        })
        ;
    };


    showRemovePendingMemberModal = (pendingMember) => {
        this.setState({showRemovePendingMemberModal: true, selectedPendingMember: pendingMember});
    };

    hideRemovePendingMemberModal = () => {
        this.setState({showRemovePendingMemberModal: false});
    };

    onRequestDeletePendingMember = (pendingMember) => {
        this.showRemovePendingMemberModal(pendingMember);
    };

    onDeletePendingMember = (pendingMember) => {
        const { t } = this.props;
        this.setState({isLoading: true});
        deletePendingLeagueMember(this.props.league.id, pendingMember.id)
            .then(() => {
                addSuccessNotification(
                    t('Success'),
                    t("The following member has been removed from the league {{name}}", {name: pendingMember.displayName})
                );
                this.refreshData();
            })
            .catch(({response}) => {
                 addErrorNotification(this.props.t('Error'), normalizeGeneralError(response));
            }).finally(() => {
                this.setState({isLoading: false});
            })
        ;
    };


    //Accept pending member
    showAcceptPendingMemberModal = (pendingMember) => {
        this.setState({showAcceptPendingMemberModal: true, selectedPendingMember: pendingMember});
    };

    hideAcceptPendingMemberModal = () => {
        this.setState({showAcceptPendingMemberModal: false});
    };

    onRequestAcceptPendingMember = (pendingMember) => {
        this.showAcceptPendingMemberModal(pendingMember);
    };

    onAcceptPendingMember = (pendingMember) => {
        this.setState({isLoading: true});
        acceptPendingMember(pendingMember.id)
            .then(() => {
                addSuccessNotification(
                    'Thank you!',
                    'Invitation is accepted, user is now a league member.'
                );
                this.refreshData();
            })
            .catch(({response}) => {
                 addErrorNotification(this.props.t('Error'), normalizeGeneralError(response));
            }).finally(() => {
                this.setState({isLoading: false});
            })
        ;
    };

    onInviteMember = () => {
        this.props.history.push('/invite/' + this.props.league.id);
    };

    render() {
        const {league} = this.props;
        const {
            members,
            pendingMembers,
            isLoading,

            selectedMember,
            selectedPendingMember,

            //modals
            showRemoveMemberModal,
            showRemovePendingMemberModal,
            showAcceptPendingMemberModal
        } = this.state;

        if (isLoading) {
            return <Loader/>
        }

        return (
            <div>
                <RemoveMemberConfirmationModal
                    isOpen={showRemoveMemberModal}
                    handleCloseModal={() => this.hideRemoveMemberModal()}
                    member={selectedMember}
                    onConfirm={this.onDeleteMember}
                />

                <RemovePendingMemberConfirmationModal
                    isOpen={showRemovePendingMemberModal}
                    handleCloseModal={() => this.hideRemovePendingMemberModal()}
                    pendingMember={selectedPendingMember}
                    onConfirm={this.onDeletePendingMember}
                />

                <AcceptPendingMemberConfirmationModal
                    isOpen={showAcceptPendingMemberModal}
                    handleCloseModal={() => this.hideAcceptPendingMemberModal()}
                    pendingMember={selectedPendingMember}
                    onConfirm={this.onAcceptPendingMember}
                />

                {members && <MembersTable
                    members={members}
                    pendingMembers={pendingMembers}
                    nbSlots={league.nbMaxTeams}
                    onAcceptPendingMember={this.onRequestAcceptPendingMember}
                    onInviteMember={this.onInviteMember}
                    onDeleteMember={this.onRequestDeleteMember}
                    onDeletePendingMember={this.onRequestDeletePendingMember}
                />}
            </div>
        );
    }
}

export default withTranslation()(withRouter(MembersWidget));
