import React from 'react';
import {Trans} from "react-i18next";

export default function Partners() {
    return (
        <div className="partners">
            <div className="footer-heading"><Trans>Partners</Trans></div>
            <ul>
                <li>
                    <img src="/img/partners/mafsz-logo.png" style={{width: 60, background: '#fff', padding: 5}}/>
                </li>
            </ul>
        </div>
    );
}
