import API from "services/API";
import {toFormData} from "services/FormService";

export function getMessageTemplates() {
    return API.get('email-templates')
        .then((response) => response.data)
    ;
}

export function saveTemplate({template, ...templateData}) {
    const url = (template > 0) ? `email-templates/${template}` : 'email-templates';
    return API.post(url, toFormData(templateData), {
        headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => response.data);
}

export function deleteTemplate(templateId) {
    return API.delete(`email-templates/${templateId}`)
        .then((response) => response.data);
}

export function sendMessages(messageData, usersIds) {
    const formData = toFormData({...messageData, users: usersIds});

    return API.post('owner/messages/send', formData, {
        headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => response.data);
}

export function loadMessagePreview(messageData) {
    const formData = toFormData(messageData);

    return API.post('owner/messages/send?preview=1', formData, {
        headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => response.data);
}
