import React from 'react';
import Moment from "react-moment";
import {Link} from "react-router-dom";

function StatsSection({season, timezone, weeklyGames, onEditDate, onLockWeek, onFreezeWeek, onFinishSeason}) {
    const weeks = Object.keys(weeklyGames).map(Number);
    const {currentWeek, nbWeeks, isWeekActive, frozenWeeks} = season;
    const isOngoingSeason = (season.status === 'ongoing');
    const canFinishSeason = isOngoingSeason && !isWeekActive && (currentWeek === nbWeeks) && !frozenWeeks.length;

    return (
        <div>
            {
                weeks.map((week) => {
                    const isCurrentActiveWeek = isWeekActive && (week === currentWeek);
                    const isFrozenWeek = (season.frozenWeeks.indexOf(week) !== -1);

                    const isFreezable = isOngoingSeason && isCurrentActiveWeek && week < nbWeeks;
                    const isEditable = isOngoingSeason && (isCurrentActiveWeek || isFrozenWeek);

                    //Allow editing dates if week is in future or is frozen
                    const isDateEditable = isOngoingSeason && (week > currentWeek || isFrozenWeek || (week === currentWeek && isCurrentActiveWeek));

                    return (
                        <div className="mb-default">
                            <RoundStatsSection timezone={timezone} round={week} roundGames={weeklyGames[week]} editable={isDateEditable} onEditDate={onEditDate} />

                            <div className="mt-small mb-default text-right">
                                {isFreezable && <button className="btn btn-sm btn-default" onClick={() => onFreezeWeek()}>
                                    Freeze Round {week}
                                </button>}

                                {isEditable && <button className="btn btn-sm btn-primary ml-small" onClick={() => onLockWeek(week)}>
                                    Lock Round {week}
                                </button>}
                            </div>
                        </div>
                    );
                })
            }

            {canFinishSeason && <button className="btn btn-success" onClick={() => onFinishSeason()}>
                Finish Season
            </button>}
        </div>
    );
}

function RoundStatsSection({round, timezone, roundGames, editable, onEditDate}) {
    return (
        <div>
            <table className="table table-condensed table-bordered">
                <caption>Round {round}</caption>
                <thead>
                    <tr>
                        <th>Date/Time</th>
                        <th>Team1</th>
                        <th>Team2</th>
                        <th>Score</th>
                        <th>Diff</th>
                        <th>Winner</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {roundGames.map((game) =>
                        <tr>
                            <td className="min-content">
                                <Moment tz={timezone} format="DD/MM/YYYY hh:mma">{game.date}</Moment>
                                {/*{editable && <button onClick={() => onEditDate(game)} className="btn btn-default btn-sm" style={{padding: 3, marginLeft: '.5rem'}}>✎</button>}*/}
                            </td>
                            <td className={game.team1Round !== round ? "off-round min-content" : "min-content"}>{game.team1.name}</td>
                            <td className={game.team2Round !== round ? "off-round min-content" : "min-content"}>{game.team2.name}</td>
                            <td className="min-content text-center">
                                {game.team1.score !== null ? `${game.team1.score} : ${game.team2.score}` : '—'}
                            </td>
                            <td className="min-content">
                                {game.team1.score !== null ? Math.abs(game.team1.score - game.team2.score) : '—'}
                            </td>
                            <td className="min-content">
                                {game.team1.score !== null
                                    ? (game.team1.score > game.team2.score) ? game.team1.name : game.team2.name
                                    : '—'
                                }
                            </td>
                            <td className="min-content">
                                <Link to={`/owner/stats/games/${game.id}`} className="btn btn-sm btn-default">Details</Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}


export default StatsSection;
