import React, {Component} from 'react';
import Header from "modules/Owner/Common/Header";
import PrivateRoute from "modules/Common/PrivateRoute";

export default class SecureLayout extends Component {
    render() {
        const {component: Component, ...rest} = this.props;

        return (
            <PrivateRoute {...rest} render={matchProps => (
                <div className="container">
                    <Header/>
                    <div className="wrapper">
                        <section className="content wide">
                            <Component {...matchProps} />
                        </section>
                    </div>
                </div>
            )}/>
        )
    }
}
