import React, { Component} from 'react';
import Logo from "modules/Common/Logo";
import {NavLink, withRouter} from "react-router-dom";

class Header extends Component {
    state = {
        isMenuOpen: false,
        path: this.props.location.pathname,
    };

    toggleMenu = () => {
        if (this.state.isMenuOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    };

    openMenu = () => {
        document.body.classList.add('noscroll');
        this.setState({isMenuOpen: true});
    };

    closeMenu = () => {
        document.body.classList.remove('noscroll');
        this.setState({isMenuOpen: false});
    };

    componentDidUpdate() {
        if (this.state.path !== this.props.location.pathname) {
            this.setState({path: this.props.location.pathname});
            this.closeMenu();
        }
    }

    render() {
        const {isMenuOpen} = this.state;

        return (
            <header className={"header" + (isMenuOpen ? ' menu-open' : '')}>
                <Logo linkTo="/owner"/>

                <button className="mobile-nav-toggle" onClick={() => {
                    this.toggleMenu()
                }}>
                    <img src="/img/icons/bars.svg" style={{width: 30, marginTop: 10}}/>
                </button>

                <div className="topnav">
                    <nav className="main-nav-bar">
                        <ul className="nav-links">
                            <li><NavLink to="/owner/seasons">Season</NavLink></li>
                            <li><NavLink to="/owner/teams">Teams</NavLink></li>
                            <li><NavLink to="/owner/rosters">Rosters</NavLink></li>
                            <li><NavLink to="/owner/stats">Games Stats</NavLink></li>
                            <li><NavLink to="/owner/players">Players Stats</NavLink></li>
                            <li><NavLink to="/owner/leagues">Leagues</NavLink></li>
                            <li><NavLink to="/owner/users">Members</NavLink></li>
                            <li><NavLink to="/owner/score-config">Configure</NavLink></li>
                            <li><NavLink to="/owner/files">Files</NavLink></li>
                            <li><NavLink to="/owner/blog">Blog</NavLink></li>
                            <li><NavLink to="/owner/settings">Settings</NavLink></li>
                        </ul>
                    </nav>

                    <nav className="nav-actions">
                        <ul className="nav-links">
                            <li><NavLink to="/logout">Logout</NavLink></li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);