import {Component} from 'react';
import {withRouter} from "react-router-dom";
import {setCurrentLeagueId} from "services/UserStateService";
import {getUser} from "services/AuthService";
import {addErrorNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";

class LeaguePage extends Component {
    componentWillMount() {
        const leagueId = parseInt(this.props.match.params.id);

        const user = getUser();
        if (!user) {
            return;
        }

        const leagueMembership = user.leagueMemberships.find((membership) => (leagueId === membership.league.id));
        if (leagueMembership && leagueId === leagueMembership.league.id) {
            setCurrentLeagueId(leagueId);
            this.props.history.push('/secure');
            return;
        }

        const { t } = this.props;
        addErrorNotification(
            t('Cannot select league'),
            t('League no longer exists or you don\'t have access here')
        );

        setCurrentLeagueId(null);
        this.props.history.push('/secure');
    }

    render() {
        return null;
    }
}
export default withTranslation()(withRouter(LeaguePage));

