import React from 'react';

const Terms = () => {
    return (
        <div>
            <h1>FANTASY FOOTBALL TERMS AND CONDITIONS</h1>
            <p><strong>Effective date: </strong>21 October 2019</p><p>
            <em>Please read tGeneral informationhis document carefully because it contains important information about your use of Hungarian
                Fantasy Football, legal rights and obligations, liabilities, and limitations. If you do not agree with one or
                more provisions of these Terms, please do not use Hungarian Fantasy Football.</em></p><p>These Hungarian Fantasy
            Football terms and conditions (the “Terms”) is a legally binding agreement between Gábor Kiss having an address at
            Farman-Strasse 47, 8152 Glattpark (Opfikon), Switzerland (“we”, “us”, or “our”) and an individual user (the “User”,
            “you” or “your”) accessing and using the website <a href="http://fantasyfootball.hu">http://fantasyfootball.hu</a>
            (“Hungarian Fantasy Football”).</p>
            <ol>
                <li><a href="#_Toc">General information</a></li>
                <li><a href="#_Toc1">User account</a></li>
                <li><a href="#_Toc2">User-Generated Content</a></li>
                <li><a href="#_Toc3">Notifications</a></li>
                <li><a href="#_Toc4">Third-party links</a></li>
                <li><a href="#_Toc5">Prohibited use</a></li>
                <li><a href="#_Toc6">Intellectual property</a></li>
                <li><a href="#_Toc7">Disclaimer of warranties</a></li>
                <li><a href="#_Toc8">Limitation of liability</a></li>
                <li><a href="#_Toc9">Availability</a></li>
                <li><a href="#_Toc10">Indemnification</a></li>
                <li><a href="#_Toc11">Severability</a></li>
                <li><a href="#_Toc12">Governing law and dispute resolution</a></li>
                <li><a href="#_Toc13">Assignment</a></li>
                <li><a href="#_Toc14">Term and termination</a></li>
                <li><a href="#_Toc15">Changes and amendments</a></li>
                <li><a href="#_Toc16">Contact us</a></li>
            </ol>

            <ul>
                <li><a id="_Toc"></a><h3>General information</h3>
                    <ol>
                        <li><strong>About Hungarian Fantasy Football. </strong>Hungarian Fantasy Football is a platform that
                            provides access to information about the national league of American football in Hungary and facilitates
                            Users’ communication, including message boards, gaming, and team formation.
                        </li>
                        <li><strong>Third parties.</strong> These Terms apply to your use of Hungarian Fantasy Football only. Unless
                            otherwise provided by the applicable law, we are not responsible or liable in any manner for the acts
                            and omissions of third parties, the quality of services provided by third parties, including
                            advertisers, the content uploaded and published by the Users or third parties on Hungarian Fantasy
                            Football, and security and privacy practices deployed by the operators of third-party websites,
                            applications, and services linked to and from Hungarian Fantasy Football.
                        </li>
                        <li><strong>Minors.</strong> Hungarian Fantasy Football should not be accessed and used by persons under the
                            age of 16.
                        </li>
                        <li><strong>License to use Hungarian Fantasy Football.</strong> We grant you a personal, revocable,
                            non-exclusive, non-transferable, and limited license to use Hungarian Fantasy Football pursuant to these
                            Terms.
                        </li>
                        <li><strong>Disclaimer.</strong> Although we regularly monitor the information available on Hungarian
                            Fantasy Football, we do not guarantee the truthfulness, reliability, currency, relevance, and
                            completeness of all information available on Hungarian Fantasy Football. Further, we are not responsible
                            for the correctness, legality, and suitability of any information and content provided by the Users
                            through Hungarian Fantasy Football.
                        </li>
                        <li><strong>Support.</strong> Any requests for customer or technical support should be addressed to us by
                            email at info@fantasyfootball.hu. We will respond to support enquiries without undue delay and no later
                            than 5 business days.
                        </li>
                        <li><strong>Privacy</strong>. We are committed to protecting your right to privacy and complying with
                            applicable data protection laws, including the The Swiss Federal Data Protection Act and the EU General
                            Data Protection Regulation (GDPR). Therefore, before using Hungarian Fantasy Football, you are strongly
                            encouraged to review our privacy policy available at <a href="//fantasyfootball.hu/privacy">fantasyfootball.hu/privacy</a> (the “Privacy
                            Policy”) and our cookie policy available at <a href="//fantasyfootball.hu/cookie-policy">fantasyfootball.hu/cookie-policy</a>, which explain in detail
                            our practices and procedures pertaining to the processing of your personal data.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc1"></a><h3>User account</h3>
                    <ol>
                        <li><strong>Registration of the User Account.</strong> In order to access and use the full functionality of
                            Hungarian Fantasy Football, the User is required to sign up by creating a User’s account (the “User
                            Account”), submitting the requested personal data, accepting these Terms, and reviewing the Privacy
                            Policy. If the User does not agree with one or more provisions of the said documents, the User is not
                            allowed to register the User Account; by registering the User Account, the User acknowledges and agrees
                            that the User has read the said documents. We may monitor and review new User Accounts and we reserve
                            the right to refuse the registration of any User Account at our sole discretion. The User is not allowed
                            to assign User’s rights under these Terms and the User Account is not transferable. When completing the
                            registration of the User Account, the User acknowledges, agrees and warrants that:
                            <ul>
                                <li>The User shall comply with these Terms and all applicable laws;</li>
                                <li>The User shall provide only true, accurate, complete, and up-to-date personal data;</li>
                                <li>The User is a human and not a machine (User Accounts that are registered by machines, bots, and
                                    other automated methods are not permitted);
                                </li>
                                <li>The User shall register no more than one User Account; and</li>
                                <li>The User shall be solely responsible for all information and activities that occur under the
                                    User Account.
                                </li>
                            </ul>
                        </li>
                        <li><strong>Age limitation.</strong> We do not accept any registrations of the User Accounts if the User is
                            below the age of 16, even if the User has obtained permission from parents or legal guardians for such
                            registration. We reserve right to delete the User Account at any time if we become aware that the User
                            has manipulated the process of registration and we reserve the right to prevent that User from further
                            registrations until the User becomes at least 16 years old. We strongly encourage parents and legal
                            guardians to monitor their children’s Internet usage and assist us in enforcing these Terms by
                            instructing their minor children not to: (i) access and use Hungarian Fantasy Football, (ii) register
                            the User Account, and (iii) provide personal data through Hungarian Fantasy Football.
                        </li>
                        <li><strong>Processing of personal data.</strong> The processing of personal data collected during the
                            registration of the User Account and through Hungarian Fantasy Football is conducted in accordance with
                            our Privacy Policy. Before registering the User Account, you will be asked to review the Privacy Policy.
                            Please note that we will use your personal data for the purposes explicitly listed in the Privacy Policy
                            only.
                        </li>
                        <li><strong>Security of User Account.</strong> In order to complete the registration of the User Account and
                            ensure its security, you are required to create a strong password. If you choose otherwise, you will not
                            be able to complete the registration of your User Account. You are also solely responsible for (i)
                            maintaining the security of your login details, (ii) all activities that occur under your User Account,
                            and (iii) any other actions taken in connection with your User Account. You must immediately notify us
                            of any unauthorised uses of your User Account or any other breaches of security. You are responsible for
                            using secure Internet connection and protected networks when accessing Hungarian Fantasy Football. We
                            cannot and will not be liable for any loss or damage resulting from your failure to take precautions, as
                            necessary to protect yourself and your devices on which you use Hungarian Fantasy Football from viruses,
                            malware, worms, Trojan horses, and other harmful or destructive content.
                        </li>
                        <li><strong>Deletion of User Account. </strong>At any time, you may delete the User Account through the
                            “Settings” functionality of the User Account. Upon deletion of the User Account, these Terms shall
                            terminate.
                        </li>
                        <li><strong>Suspension and termination of User Account.</strong> We may suspend, disable, or delete your
                            User Account (or any part thereof) if we have a reason to believe, at our sole discretion, that you have
                            violated any provision of these Terms or that your conduct tends to damage our reputation and goodwill.
                            If we delete your User Account for the foregoing reasons, you may not re-register. We may also suspend
                            or terminate the User Account upon a lawful request of a public authority.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc2"></a><h3>User-Generated Content</h3>
                    <ol>
                        <li>The User may submit certain types of content through Hungarian Fantasy Football, including public and
                            private messages, comments, links, text, and images (the “User-Generated Content”). The User-Generated
                            Content may become available to other users of Hungarian Fantasy Football. The User is not allowed to
                            make publicly available personal data of persons who have not provided the User with their prior
                            authorisation or consent to share that personal data. The User agrees not to submit any User-Generated
                            content that violates these Terms or any applicable laws.
                        </li>
                        <li>We do not own any User-Generated Content. The User is responsible for the accuracy, quality, integrity,
                            legality, reliability, appropriateness, and intellectual property ownership or the right to use all
                            User-Generated Content submitted by the User. We reserve the right, but are under no obligation, to, at
                            our own sole discretion, refuse or remove any User-Generated Content that, in our reasonable opinion,
                            violates the Terms, any of our other policies, or is in any way harmful or objectionable.
                        </li>
                        <li>By submitting the User-Generated Content through Hungarian Fantasy Football, the User grants us
                            unrestricted, sub-licensable, royalty-free, perpetual, and irrevocable rights to use, distribute,
                            advertise, adapt, remix, modify, publicly display, publicly perform, excerpt, prepare derivative works
                            of, and reproduce the User-Generated Content for the purposes of providing services through Hungarian
                            Fantasy Football.
                        </li>
                        <li>We do not intentionally or regularly review the User-Generated Content and their subsequent editions and
                            revisions. We reserve the right, but are under no obligation, to, at our own sole discretion, refuse or
                            remove any User-Generated Content that, in our reasonable opinion, violates the Terms, any of our other
                            policies, or is in any way harmful or objectionable.
                        </li>
                        <li>We do not control or have any knowledge of the content of any communication(s) transmitted directly
                            between the Users through Hungarian Fantasy Football and such content is the responsibility of the Users
                            from whom such content originates. No content violating any provision of these Terms, especially section
                            6 of the Terms, may be submitted through Hungarian Fantasy Football.
                        </li>
                        <li>The User is solely responsible to creating, keeping and maintaining any backup copies of any
                            User-Generated Content or other information submitted to, through, or in relation to Hungarian Fantasy
                            Football.
                        </li>
                        <li>The User acknowledges, understands and agrees that, due to technical issues, errors, human error or
                            similar, some or all of the User-Generated Content may remain available to other Users of Hungarian
                            Fantasy Football after the respective User’s User Account is deactivated.
                        </li>
                        <li>We do not represent or imply that we endorse any User-Generated Content and any other material posted by
                            the User or that we believe that such materials are accurate, useful or non-harmful.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc3"></a><h3>Notifications</h3>
                    <ol>
                        <li>After you register your User Account, you may receive from us important technical notifications about
                            your User Account and Hungarian Fantasy Football (the “Notifications”), including service-related,
                            technical or administrative emails, information about your User Account, privacy and security, and other
                            important matters. Please note that we will send the Notices on an “if-needed” basis and they do not
                            fall within the scope of direct marketing communication that requires your prior consent. Please note
                            that, even if you disable marketing communication, you cannot opt-out from the Notifications.
                        </li>
                        <li><strong>Marketing. </strong>To keep you up-to-date with Hungarian Fantasy Football, we may send you
                            marketing messages informing you about our new services or special offers. You can opt-out from
                            receiving marketing messages at any time free of charge by clicking on the “unsubscribe” link contained
                            in any of the messages sent to you or contacting us directly. You will receive marketing messages only
                            if:
                            <ul>
                                <li>We receive your express (“opt-in”) consent to receive marketing messages (please note that your
                                    voluntary subscription to our newsletters and updates constitutes such consent); or
                                </li>
                                <li>We decide to send you marketing messages about our new services that are closely related to the
                                    services already used by you.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc4"></a><h3>Third-party links</h3>
                    <ol>
                        <li>Hungarian Fantasy Football may contain links to websites and services that are owned, operated and
                            controlled by third parties. Please be aware that we are not responsible for any business or security
                            practices employed by such third parties. We encourage you to be aware when you leave Hungarian Fantasy
                            Football and ask you to assess carefully each and every website or service that you access.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc5"></a><h3>Prohibited use</h3>
                    <ol>
                        <li>When using Hungarian Fantasy Football, you are required to follow our guidelines outlined in this
                            Section 6. Please be advised that we work closely with law enforcement and we report any inappropriate
                            content that may infringe applicable laws.
                        </li>
                        <li>You are not permitted to use Hungarian Fantasy Football in any manner that substitutes or contributes to
                            the following activities (the list is representative and not exhaustive):
                            <ul>
                                <li>Any unlawful activity, including violation of any laws, statutes, ordinances, or regulations;
                                </li>
                                <li>Fraud;</li>
                                <li>Provision of false, inaccurate, or misleading information;</li>
                                <li>Dissemination of information about the acts, including pranks and challenges, that may result in
                                    injuries and physical harm;
                                </li>
                                <li>Posting of the User-Generated Content that depicts or incites others to commit acts of
                                    violence;
                                </li>
                                <li>Provision of the User-Generated Content that depicts children or may cause emotional distress to
                                    children;
                                </li>
                                <li>Gambling, including contests, lotteries, games of chance, bidding fee auctions, sports
                                    forecasting or odds making, Internet gaming, fantasy sports leagues with cash prizes, and
                                    sweepstakes;
                                </li>
                                <li>Spreading of malware (e.g., viruses, worms, Trojan horses), spam, and other illegal messaging;
                                </li>
                                <li>Spreading ethnically, racially, or otherwise objectionable information;</li>
                                <li>Sexually explicit, nude, libellous, harassing, violent, defamatory, abusive, profane, vulgar,
                                    threatening, hateful, obscene behaviour and terrorism-related content;
                                </li>
                                <li>Advertising or encouraging the use of tobacco, alcohol, and any illegal substances;</li>
                                <li>Copying, distributing, renting, reselling, modifying, compromising, damaging, disabling,
                                    impairing, and overburdening Hungarian Fantasy Football;
                                </li>
                                <li>Interfering with or abusing other users of Hungarian Fantasy Football;</li>
                                <li>Using bots, scripts, and other automated methods; and</li>
                                <li>Collecting and disclosing any information about other Users of Hungarian Fantasy Football.</li>
                            </ul>
                        </li>
                        <li><strong>Reporting inappropriate content.</strong> If you think that some of the content available on
                            Hungarian Fantasy Football is inappropriate, infringes these Terms, applicable laws, or your right to
                            privacy, please contact us immediately by email at info@fantasyfootball.hu. If any content or user is
                            reported as inappropriate, we will immediately delete such content from Hungarian Fantasy Football and
                            investigate the conduct of the reported User.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc6"></a><h3>Intellectual property</h3>
                    <ol>
                        <li>Most of the content made available on Hungarian Fantasy Football is owned by us, our partners, agents,
                            licensors, vendors, and/or other content providers (“Our Content”). Our Content includes, but is not
                            limited to, text, images, audiovisual content, source code, trademarks, service marks and trade names.
                            Our Content is protected by the applicable intellectual property laws and international treaties.
                        </li>
                        <li>The User is not allowed, without obtaining prior written authorisation from us, to:
                            <ul>
                                <li>Distribute Our Content;</li>
                                <li>Copy Our Content;</li>
                                <li>Disassemble, make alterations, decompile, reverse engineer, translate, and adapt Our Content;
                                    and
                                </li>
                                <li>Rent, loan, use, lease or attempt to grant other rights to Our Content to third parties.</li>
                            </ul>
                        </li>
                        <li><strong>Hungarian Fantasy Football</strong> <strong>brand.</strong> The User many not use the Hungarian
                            Fantasy Football brand, the trademarks associated with Hungarian Fantasy Football or third-party
                            trademarks outside of what is expressed. The User may not use such brands and trademarks in any way that
                            suggests that we sponsor, endorse, or associate with the User without obtaining prior written consent
                            from us.
                        </li>
                        <li><strong>Third-party intellectual property.</strong> The User-Generated Content, some of the trademarks,
                            and other intellectual property featured on Hungarian Fantasy Football may be owned by third parties.
                            Such third-party intellectual property does not belong to us and it remains the sole property of the
                            respective third-party proprietors. You agree not to violate such third-party property. We will not
                            access or use such intellectual property for the purposes other than ensuring the provision of our
                            services through Hungarian Fantasy Football.
                        </li>
                        <li>These Terms do not transfer to you any intellectual property owned by us or third parties, and all
                            rights, titles, and interests in and to such property will remain solely with us or respective
                            third-party proprietors.
                        </li>
                        <li><strong>Copyright infringement claims.</strong> If you have any grounds to believe that any content made
                            available through Hungarian Fantasy Football violates your or third party’s intellectual property
                            rights, please contact us and express your concerns or request to remove the allegedly infringing
                            content. We will reply to the copyright infringement claim as soon as possible but no later than 2
                            weeks. Before sending your claim to us, please make sure that you sign it and include information that
                            would allow us to located the allegedly infringing content.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc7"></a><h3>Disclaimer of warranties</h3>
                    <ol>
                        <li>You agree that your use of Hungarian Fantasy Football is solely at your own risk. You agree that
                            Hungarian Fantasy Football is provided on an “as is” and “as available” basis and we hereby expressly
                            disclaim all warranties of any kind, whether express or implied, including but not limited to, the
                            implied warranties of merchantability, fitness for a particular purpose and non-infringement.
                        </li>
                        <li>We make no warranty that Hungarian Fantasy Football will meet your requirements or that Hungarian
                            Fantasy Football will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to
                            the results that may be obtained from the use of Hungarian Fantasy Football or as to the accuracy or
                            reliability of any information obtained through Hungarian Fantasy Football or that defects in Hungarian
                            Fantasy Football will be corrected.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc8"></a><h3>Limitation of liability</h3>
                    <ol>
                        <li>To the fullest extent permitted by applicable law, in no event will we, our affiliates, founders,
                            officers, directors, employees, agents, suppliers or licensors be liable to any person for any indirect,
                            incidental, special, punitive, cover or consequential damages, including, without limitation, damages
                            for lost profits, revenue, sales, goodwill, use or content, impact on business, business interruption,
                            loss of anticipated savings, loss of business opportunity, however caused, under any theory of
                            liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence
                            or otherwise, even if we have been advised as to the possibility of such damages or could have foreseen
                            such damages.
                        </li>
                        <li>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so
                            the limitation and exclusion of this Section 9 may not apply to you.
                        </li>
                        <li>By using Hungarian Fantasy Football, you acknowledge that we may use third-party suppliers to provide
                            software, hardware, storage, networking, and other technological services. The acts and omissions of
                            such third-party suppliers may be outside of our control. To the maximum extent permitted by law, we
                            exclude any liability for any loss or damage resulting from the acts and omissions of such third-party
                            suppliers.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc9"></a><h3>Availability</h3>
                    <ol>
                        <li>We put reasonable efforts to ensure that Hungarian Fantasy Football is always accessible. However, the
                            availability of Hungarian Fantasy Football may be affected by factors, which we cannot reasonably
                            control, such as bandwidth problems, equipment failure, or <em>force majeure</em> events, including, but
                            not limited to: acts of God; strikes; work stoppages; accidents; acts of war or terrorism; civil or
                            military disturbances; nuclear or natural catastrophes and interruptions; shortage of supply; and
                            breakdowns. We take no responsibility for the unavailability of Hungarian Fantasy Football caused by
                            such factors. If you encounter problems while using Hungarian Fantasy Football, please contact us
                            immediately.
                        </li>
                        <li>We reserve the right, in our sole discretion, to change, modify, suspend, or discontinue any aspect of
                            Hungarian Fantasy Football and any services provided through it at any time, including Hungarian Fantasy
                            Football’s technical features, content, databases, availability, and specifications of the required
                            equipment. We may also impose limits on certain features of Hungarian Fantasy Football or restrict your
                            access to parts or all of Hungarian Fantasy Football without notice or liability.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc10"></a><h3>Indemnification</h3>
                    <ol>
                        <li>You agree to indemnify and hold us and our affiliates, founders, directors, officers, employees, and
                            agents harmless from and against any liabilities, losses, damages or costs, including reasonable
                            attorneys’ fees, incurred in connection with or arising from any third-party allegations, claims,
                            actions, disputes, or demands asserted against any of them as a result of or relating to the
                            User-Generated Content, your use of Hungarian Fantasy Football or any willful misconduct on your part.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc11"></a><h3>Severability</h3>
                    <ol>
                        <li>In the event that any provision of these Terms is determined to be unlawful, void or unenforceable, such
                            a provision shall nonetheless be enforceable to the fullest extent permitted by the applicable law, and
                            the unenforceable portion shall be deemed to be severed from these Terms. The validity and
                            enforceability of the remaining provisions shall not be affected as a result.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc12"></a><h3>Governing law and dispute resolution</h3>
                    <ol>
                        <li>The formation, interpretation, and performance of these Terms and any disputes arising out of the Terms
                            shall be governed by the substantive and procedural laws of Zürich canton, Switzerland, without regard
                            to its rules on conflicts or choice of law and, to the extent applicable, the laws of Switzerland.
                        </li>
                        <li>The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the
                            courts located in Bern, Switzerland.
                        </li>
                        <li>Nothing herein prevents the User or us from seeking any interim injunction it deems necessary in order
                            to preserve the status quo prior to the resolution of any dispute, in any jurisdiction.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc13"></a><h3>Assignment</h3>
                    <ol>
                        <li>You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or
                            obligations under these Terms, in whole or in part, without our prior written consent, which shall be at
                            our own sole discretion and without obligation; any such assignment or transfer shall be null and void.
                        </li>
                        <li>We reserve the right to assign any of our rights or obligations hereunder, in whole or in part, to any
                            third-party as part of the sale of all or substantially all of our assets or stock or as part of a
                            merger.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc14"></a><h3>Term and termination</h3>
                    <ol>
                        <li><strong>Term.</strong> These Terms enter into force on the effective date indicated at the top of the
                            Terms and remain in force until terminated by us. We reserve the right, at our sole discretion, to
                            terminate the Terms and provision of Hungarian Fantasy Football at any time by informing the User.
                            User’s rights under these Terms will be automatically terminated if the User does not comply with any
                            provision of these Terms. Upon termination of the Terms, all legal rights granted to the User pursuant
                            to the Terms will terminate and the User shall stop using Hungarian Fantasy Football.
                        </li>
                        <li><strong>Breach of the Terms.</strong> If we believe, at our sole discretion, that the User violates
                            these Terms and it is appropriate, necessary or desirable to do so, we may:
                            <ul>
                                <li>Send the User a formal warning;</li>
                                <li>Temporary suspend the User Account;</li>
                                <li>Delete the User Account;</li>
                                <li>Temporarily or permanently prohibit User’s access to Hungarian Fantasy Football;</li>
                                <li>Report the User to law enforcement authorities; or</li>
                                <li>Commence a legal action against the User.</li>
                            </ul>
                        </li>
                        <li><strong>Merger or acquisition.</strong> In the event we, during the term of these Terms, are acquired,
                            merged, or we sell all or substantially all of our assets, these Terms shall not automatically be
                            terminated and we agree to use our best efforts to ensure that the transferee or surviving company shall
                            assume and be bound by the provisions of these Terms.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc15"></a><h3>Changes and amendments</h3>
                    <ol>
                        <li>We reserve the right to modify these Terms, Hungarian Fantasy Football policies, any services provided
                            through Hungarian Fantasy Football, and our business practices (including marketing policies) at any
                            time, effective upon posting of an updated version of these Terms through Hungarian Fantasy Football and
                            sending you a notification.
                        </li>
                        <li>Your continued use of Hungarian Fantasy Football after any such changes shall constitute your consent to
                            such changes. If you do not agree with one or more provisions of the updated Terms, you are requested to
                            stop using Hungarian Fantasy Football.
                        </li>
                    </ol>
                </li>
                <li><a id="_Toc16"></a><h3>Contact us</h3></li>
            </ul><p>If you have any questions, suggestions, or concerns about these Terms or your use of Hungarian Fantasy Football,
            please feel free to contact us.</p><p><strong>Email:</strong> info@fantasyfootball.hu</p><p><strong>Postal address
            for communication: </strong>Farman-strasse 47, 8152 Glattpark (Opfikon), Switzerland</p><p>***</p>
        </div>
    );
};

export default Terms;