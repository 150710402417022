import React from 'react';

const TeamsTable = ({teams, editable, deletable, selectedTeam, onSelectTeam, onEditTeam, onDeleteTeam}) => {

    if (!teams) {
        return null;
    }

    return (
        <table className="table selectable table-bordered table-condensed mb-default">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        {(editable || deletable) && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {teams.map((team) =>
                        <tr key={team.id}
                            className={selectedTeam && selectedTeam.id === team.id ? 'selected' : null}
                        >
                            <td onClick={() => onSelectTeam(team)}>{team.name}</td>
                            <td onClick={() => onSelectTeam(team)}>{team.status}</td>
                            {(editable || deletable) && <td className="min-content">
                                {editable && <input
                                    type="button"
                                    className="btn btn-sm btn-default"
                                    value="✎"
                                    onClick={() => {onEditTeam(team)}}
                                />}

                                {deletable && <input
                                    type="button"
                                    className="btn btn-sm btn-default"
                                    value="✕"
                                    onClick={() => onDeleteTeam(team)}
                                />}
                            </td>}
                        </tr>
                    )}
                </tbody>
            </table>
    );
};

export default TeamsTable;
