import React from 'react';
import Moment from "react-moment";
import {formatGender} from "services/UserService";
import {formatLeagueRole, groupStandingsByTeamId} from "services/LeagueService";

const LeagueMembersTable = ({members, onEditTeam, standings: rawStandings = null}) => {
    if (!members) {
        return null;
    }

    const hasStandings = rawStandings && rawStandings.length>0;
    const standings = groupStandingsByTeamId(rawStandings);

    return (
        <table className="table table-bordered table-striped table-condensed">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>E-mail</th>
                    <th>Location</th>
                    <th>Gender</th>
                    <th>DOB</th>
                    <th colSpan={2}>Team</th>
                    {hasStandings && <>
                        <th>Rank</th>
                        <th>Wins</th>
                        <th>Losses</th>
                        <th>Ties</th>
                    </>}
                </tr>
            </thead>
            <tbody>
                {members.map(({team, role, user}) => {
                    const teamStandings = team
                        ? (standings && standings[team.id] !== undefined ? standings[team.id] : null)
                        : null
                    ;
                    
                    return (<tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{formatLeagueRole(role)}</td>
                        <td>{user.email}</td>
                        <td>{user.location}</td>
                        <td>{formatGender(user.gender)}</td>
                        <td><Moment format="DD/MM/YYYY">{user.birthDate}</Moment></td>
                        <td>{team ? team.name : '-'}</td>
                        <td className="min-width">
                            {team && <button onClick={() => onEditTeam(team)} className="btn btn-sm btn-default">✎</button>}
                        </td>
                        {teamStandings && <>
                            <td>{teamStandings.rank}</td>
                            <td>{teamStandings.wins}</td>
                            <td>{teamStandings.losses}</td>
                            <td>{teamStandings.ties}</td>
                        </>}
                    </tr>);
                })}
            </tbody>
        </table>
    );
};

export default LeagueMembersTable;
