import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {getCategories, getTags} from "services/BlogService";

export default function CategorySelect({ field, form, children, ...rest }) {
    const promiseOptions = inputValue =>
        getCategories().then(categories => categories.map((_) => ({
            value: _,
            label: _
        })))
    ;

    return (
        <AsyncCreatableSelect
            className="form-control"
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            defaultOptions
            loadOptions={promiseOptions}
            menuPortalTarget={document.body}
            value={
                !field.value ? null : ({
                    value: field.value,
                    label: field.value
                })
            }
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
        />
    );
}




