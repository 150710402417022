import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function RequestToJoinLeagueConfirmationModal({league, onConfirm, t, ...rest}) {
    if (!league) {
        return null;
    }

    const name = league.name;
    const autoAccept = !league.adminUser;

    if (autoAccept) {
        return (
            <ConfirmationModal
                title={t("Join League")}
                content={t("Are you sure you want to join {{name}} league?", {name})}
                buttons={{
                    yes: t('Join'),
                    no: t('Cancel')
                }}
                type="creative"
                onConfirm={() => onConfirm(league)}
                {...rest}
            />
        );
    }

    return (
        <ConfirmationModal
            title={t("Request to join league")}
            content={t("Are you sure you want to request to join {{name}} league?", {name})}
            buttons={{
                yes: t('Request to join'),
                no: t('Cancel')
            }}
            type="creative"
            onConfirm={() => onConfirm(league)}
            {...rest}
        />
    );
}

export default withTranslation()(RequestToJoinLeagueConfirmationModal);
