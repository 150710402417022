import React from 'react';
import ReactModal from 'react-modal';

export default function ConfirmationModal({
      handleCloseModal,
      title,
      content,
      buttons,
      onConfirm,
      onCancel,
      type,
      ...rest
    }) {

    if (buttons.yes === undefined) {
        buttons.yes = 'Yes';
    }

    if (buttons.no === undefined) {
        buttons.no = 'No';
    }

    const confirmClass =
        (type === 'destructive') ? 'btn btn-default' :
        (type === 'creative') ? 'btn btn-success' :
        'btn btn-primary'
    ;

    const onRequestCancel = () => {
        if (onCancel===undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };

    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">{title}</p>
            <div className="modal-content">{content}</div>

            <div className="modal-actions">
                <button className="btn btn-default" onClick={onRequestCancel}>{buttons.no}</button>
                <button className={confirmClass} onClick={() => {handleCloseModal(); onConfirm()}}>{buttons.yes}</button>
            </div>
        </ReactModal>
    );
}
