import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from "moment";
import {getUser} from "services/AuthService";
import {rememberLanguagePreference} from "services/UserService";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const languageDetector = new LanguageDetector(null, {
});

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(languageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    })
;

function reload(locale)
{
    const pathname = window.location.pathname;
    const match = pathname.match(/^\/trophies\/(?:en|hu)\/(\d+)$/);
    if (match && match[1] !== undefined) {
        window.location.replace(`/trophies/${locale}/`+match[1]);
        return;
    }

    window.location.reload();
}

i18n.on('languageChanged', function(locale) {
    locale = locale ? locale.substr(0, 2) : null;
    locale = (locale === 'hu' ? 'hu' : 'en');

    if (locale !== 'en') {
        require('moment/locale/' + locale);
    }
    moment.locale(locale);

    document.cookie = `locale=${locale}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT`;

    if (i18n.isInitialized) {
        const user = getUser();
        if (user) {
            rememberLanguagePreference(user.id, locale)
                .finally(() => {
                    reload(locale);
                });
        } else {
            reload(locale);
        }
    }
});

export default i18n;
