import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {normalizeErrors} from "services/ValidationService";
import TimezonePicker from "modules/Common/TimezonePicker";
import {Trans} from "react-i18next";

const SettingsForm = function({handleSubmit}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form" noValidate>
            <div className="form-group">
                <label className="form-label" htmlFor="timezone"><Trans>Timezone</Trans></label>
                <Field component={TimezonePicker} name="timezone" className="form-control" />
                <ErrorMessage name="timezone" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Save Settings</Trans></button>
            </div>
        </form>
    );
};

export default compose(
    withFormik({
        mapPropsToValues: ({ settings }) => ({
            'timezone': settings.timezone,
        }),
        validationSchema: yup.object().shape({
            timezone: yup.string().required('Timezone is required'),
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(SettingsForm);
