import React from 'react';
import PlayerInfoPopup from "modules/Players/PlayerInfoPopup";
import {Trans} from "react-i18next";

class BenchRosterTableRaw extends React.Component {
    state = {
        isPlayerPopupOpen: false,
        selectedPlayer: null,
        selectedPlayerScores: null,
    };

    showPlayerPopup = (player, scores) => {
        this.setState({
            isPlayerPopupOpen: true,
            selectedPlayer: player,
            selectedPlayerScores: scores
        })
    };

    hidePlayerPopup = () => {
        this.setState({
            isPlayerPopupOpen: false
        });
    };

    render() {
        const {actionsCell, scores, playersWeeklyScores, players} = this.props;
        const formatPlayerName = (player) => {
            if (!player.id || player.emptySlot===true) {
                return null;
            }

            return <>
                {player.name}
            </>
        };

        const formatPlayerPopupButton = (player) => {
            if (!player.id || player.emptySlot === true) {
                return null;
            }

            return <>
                <button className="btn btn-info btn-danger"
                        onClick={() => this.showPlayerPopup(player, playersWeeklyScores[player.id])}>
                    <span style={{fontSize: '1.4em'}}>ⓘ</span>
                </button>
            </>
        };



        return (
            <div className="table-responsive roster-tables">
                <PlayerInfoPopup
                    isOpen={this.state.isPlayerPopupOpen}
                    handleCloseModal={this.hidePlayerPopup}
                    player={this.state.selectedPlayer}
                    scores={this.state.selectedPlayerScores}
                />

                <table className="table table-condensed table-bordered mb-default">
                    <thead>
                    <tr>
                        {actionsCell !== null && <th className="min-width"></th>}
                        <th className="min-width"></th>
                        <th><Trans>Position</Trans></th>
                        <th><Trans>Name</Trans></th>
                        <th className="min-width"></th>
                        <th><Trans>Team</Trans></th>
                        <th><Trans>Pass yds</Trans></th>
                        <th><Trans>Rush yds</Trans></th>
                        <th><Trans>Catch yds</Trans></th>
                        <th><Trans>TD</Trans></th>
                        <th><Trans>2pt</Trans></th>
                        <th><Trans>Fumble</Trans></th>
                        <th><Trans>INT</Trans></th>
                        <th><Trans>FG</Trans></th>
                        <th><Trans>XP</Trans></th>
                        <th><Trans>FG miss</Trans></th>
                        <th><Trans>XP miss</Trans></th>
                        <th><Trans>Fum forced</Trans></th>
                        <th><Trans>Sack</Trans></th>
                        <th><Trans>Pts allowed</Trans></th>
                        <th><Trans>Win Loss</Trans></th>
                        <th><Trans>Pts gained</Trans></th>
                        <th><Trans>Score difference</Trans></th>
                        <th><Trans>Points</Trans></th>
                    </tr>
                    </thead>
                    <tbody>
                    {players.map((player) => {
                        let playerScores = (scores && scores[player.id] !== undefined) ? scores[player.id] : player.scores;
                        if (!playerScores) {
                            playerScores = {};
                        }

                        return (
                            <tr key={`player_${player.id}`}>
                                {actionsCell !== null && <td className="actions-cell">{player.emptySlot ? '' : actionsCell(player)}</td>}
                                <td>
                                    <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                                        {player.photo &&
                                        <img className="mb-small mr-small" width={40} src={player.photo}/>}
                                    </div>
                                </td>
                                <td>{!player.emptySlot ? <Trans>{player.position}</Trans> :
                                    <Trans>Available slot</Trans>}</td>
                                <td>{formatPlayerName(player)}</td>
                                <td>{formatPlayerPopupButton(player)}</td>
                                <td>{player.team ? player.team.name : ''}</td>
                                <td>{playerScores['pass_yds']}</td>
                                <td>{playerScores['rush_yds']}</td>
                                <td>{playerScores['catch_yds']}</td>
                                <td>{playerScores['td']}</td>
                                <td>{playerScores['_2pt']}</td>
                                <td>{playerScores['fumble']}</td>
                                <td>{playerScores['int'] ? playerScores['int'] : playerScores['defense_int']}</td>
                                <td>{playerScores['fg']}</td>
                                <td>{playerScores['xp']}</td>
                                <td>{playerScores['fg_miss']}</td>
                                <td>{playerScores['xp_miss']}</td>
                                <td>{playerScores['fum_forced']}</td>
                                <td>{playerScores['sack']}</td>
                                <td>{playerScores['pts_allowed']}</td>
                                <td>{playerScores['win_loss']}</td>
                                <td>{playerScores['pts_gained']}</td>
                                <td>{playerScores['score_difference']}</td>
                                <td>{playerScores['points']}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default BenchRosterTableRaw;