import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import Loader from "modules/Common/Loader";
import {Trans, withTranslation} from "react-i18next";
import {getLeaderboard} from "services/LeaderboardService";
import {addErrorAsNotification} from "services/ValidationService";
import TeamsNbSelect from "modules/Common/TeamsNbSelect";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {getUser} from "services/AuthService";
import {getLatestSeason, getSeasons} from "services/SeasonService";
import SeasonSelect from "modules/Common/Form/SeasonSelect";

class LeaderboardPage extends Component {
    state = {
        isLoading: true,
        seasons: null,
        selectedSeasonId: null,
        leaderboard: null,
        search: {
            nbTeams: null,
            term: ''
        }
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});

        getLatestSeason()
            .then((season) => {
                const selectedSeasonId = this.state.selectedSeasonId
                    ? this.state.selectedSeasonId
                    : (season ? season.id : null)
                ;

                let requests = [
                    getSeasons()
                ];

                if (selectedSeasonId) {
                    requests.push(
                        getLeaderboard(selectedSeasonId, this.state.search)
                    );
                }

                Promise.all(requests)
                    .then(([seasons, leaderboard]) => {
                        this.setState({isLoading: false, selectedSeasonId, seasons, leaderboard});
                    })
                    .catch(({response}) => {
                        addErrorAsNotification(response, this.props.t);
                        this.setState({isLoading: false});
                    })
                ;
            })
            .catch(({response}) => {
                this.setState({isLoading: false});

                if (response.status === 404) {
                    return [];
                }

                addErrorAsNotification(response, this.props.t);
            })
        ;

    };

    onChangeTerm = (value) => {
        const search = {
            ...this.state.search,
            term: value
        };
        this.setState({search});
    } ;

    onChangeNbTeams = (nbTeams) => {
        const search = {
            ...this.state.search,
            nbTeams
        };

        this.setState({search});
    };

    search = (search = null) => {
        if (search) {
            this.setState({search}, () => {
                this.refreshData();
            });
        } else {
            this.refreshData();
        }
    };

    resetSearch = () => {
        this.setState({
            search: {
                nbTeams: null,
                term: ''
            }
        }, () => {
            this.refreshData();
        });
    };

    onChangeSeason = (selectedSeasonId) => {
        this.setState({selectedSeasonId}, () => {
            this.refreshData();
        });
    };

    render() {
        const {leaderboard, seasons, selectedSeasonId, isLoading} = this.state;

        if (isLoading && !leaderboard) {
            return <main><Loader /></main>;
        }

        const loggedInUser = getUser();

        return (
            <main>
                <div className="season-switcher">
                    {seasons && <SeasonSelect
                        seasons={seasons}
                        selectedValue={selectedSeasonId}
                        onChange={this.onChangeSeason}
                    />}
                </div>

                <h1><Trans>Leaderboards</Trans></h1>

                <div className="form mb-default">
                    <div className="form-group">
                        <label className="form-label" htmlFor="teams"><Trans>Teams</Trans></label>
                        <TeamsNbSelect selectedValue={this.state.search.nbTeams} id="teams" className="form-control" anyOption={true} onChange={({ value }) => this.onChangeNbTeams(value)} />
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="term"><Trans>Search</Trans></label>
                        <input id="term" onChange={(e) => this.onChangeTerm(e.target.value)} className="form-control" type="text" value={this.state.search.term} />
                    </div>

                    <div className="form-group">
                        <button onClick={() => this.search()} className="mr-small btn btn-sm btn-primary"><Trans>Search</Trans></button>
                        <button onClick={() => this.resetSearch()} className="btn btn-sm btn-primary"><Trans>Reset</Trans></button>
                    </div>
                </div>

                <LoadingOverlay active={isLoading}>
                    {leaderboard && leaderboard.length ?
                        <table className="table table-condensed table-bordered mb-default">
                            <thead>
                            <tr>
                                <th><Trans>Rank</Trans></th>
                                <th><Trans>User</Trans></th>
                                <th><Trans>Team</Trans></th>
                                <th><Trans>League</Trans></th>
                                <th><Trans>Points</Trans></th>
                            </tr>
                            </thead>
                            <tbody>
                            {leaderboard.map(({ id, rank, league, user, team, points}, idx) => {
                                return <tr key={id}>
                                    <td>{idx+1}</td>
                                    <td>{user.name}</td>
                                    <td>
                                        {loggedInUser
                                            ? <Link to={`/leagues/${league.id}/team/${team.id}/roster`}>{team.name}</Link>
                                            : team.name
                                        }
                                    </td>
                                    <td>
                                        {loggedInUser
                                            ? <Link to={`/leagues/${league.id}/standings`}>{league.name}</Link>
                                            : league.name
                                        }
                                    </td>
                                    <td>{points}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                        : <h3><Trans>No records found</Trans></h3>
                    }
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(LeaderboardPage));
