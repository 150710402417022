import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";

export default function DeleteTeamConfirmationModal({team, onConfirm, ...rest}) {
    if (!team) {
        return null;
    }

    const name = team.name;
    return (
        <ConfirmationModal
            title={"Delete Team?"}
            content={"Are you sure you want to delete team \""+name+"\"?"}
            buttons={{
                yes: 'Yes, delete',
                no: 'Cancel'
            }}
            type="destructive"
            onConfirm={() => onConfirm(team)}
            {...rest}
        />
    );
}
