import React from 'react';
import ReactModal from 'react-modal';
import {Trans} from "react-i18next";
import LeagueActions from "modules/Leagues/LeagueActions";
import Moment from "react-moment";
import LeagueSlider from "modules/Leagues/LeagueSlider";

export default function LeagueDetailsModal({
      handleCloseModal,
      league,
      user,
      onJoinLeague,
      onCancelPendingMembership,
      onConfirm,
      onCancel,
      ...rest
    }) {

    if (!league) {
        return null;
    }

    const onRequestCancel = () => {
        if (onCancel===undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };


    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">
                {league.name}
            </p>
            <div className="modal-content">
                <dl className="dl-list">
                    <dt><Trans>Members</Trans></dt>
                    <dd>{league.nbMaxTeams}</dd>

                    <dt><Trans>Available Slots</Trans></dt>
                    <dd>{league.nbAvailableSlots}</dd>

                    <dt><Trans>Draft date</Trans></dt>
                    <dd>
                        {league.currentSeason && league.currentSeason.draftStartsAt
                            ? <Moment format="DD/MM/YYYY">{league.currentSeason.draftStartsAt}</Moment>
                            : '-'
                        }
                    </dd>
                </dl>

                {league.description && <div className="mt-default mb-default">
                    <div dangerouslySetInnerHTML={{__html:league.description.replace(/\n/g, '<br />')}} />
                </div>}

                <LeagueSlider slides={league.slides} />
            </div>

            <div className="modal-actions">
                <LeagueActions
                    size="lg"
                    league={league}
                    user={user}
                    onJoinLeague={onJoinLeague}
                    onCancelPendingMembership={onCancelPendingMembership}
                />
                <button className="btn btn-default" onClick={() => {handleCloseModal()}}><Trans>Cancel</Trans></button>
            </div>
        </ReactModal>
    );
}
