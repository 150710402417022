import React from 'react';
import {formatLeagueRole} from "services/LeagueService";
import {Trans} from "react-i18next";

export default function LeagueSelector({leagueMemberships, onLeagueSelected}) {
    if (!leagueMemberships.length) {
        return null;
    }

    return (
        <div className="mb-default">
            <p className="mb-small"><strong><Trans>Choose League</Trans>:</strong></p>
                {leagueMemberships.map((leagueMembership) => (
                        <button key={leagueMembership.id} className="btn btn-default mr-small mb-small" style={{display: 'inline-block'}} onClick={(e) => {
                           e.preventDefault();
                           onLeagueSelected(leagueMembership.league);
                       }}>
                            {leagueMembership.league.name} (<Trans>{formatLeagueRole(leagueMembership.role)}</Trans>)
                       </button>
                ))}
        </div>
    );
}
