import {addErrorNotification} from "services/NotificationService";
import {useTranslation} from "react-i18next";

export function normalizeGeneralError(response) {
    if (response && response.data) {
        return response.data.error
            ? response.data.error.message
            : response.data.message
        ;
    }

    return 'Invalid data provided';
}

export function addErrorAsNotification(response, t) {
    const error = normalizeGeneralError(response);
    addErrorNotification(t('Error'), error);
}

export function normalizeErrors(response) {
    if (!response || !response.data) {
        return {'general': 'Server error'};
    }

    const data = response.data;
    const errorMessage = (data.error ? data.error.message : data.message);
    const violations = data.violations;
    const errorMessages = {};

    errorMessages['general'] = errorMessage ? errorMessage : 'Invalid data provided';
    if (violations) {
        violations.forEach((violation) => {
            errorMessages[violation.property] = violation.message;
        });
    }

    return errorMessages;
}
