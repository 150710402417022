import React, {Component} from 'react';
import RosterTable from "modules/Roster/RosterTable";
import {
    deletePlayerFromRoster, getMoveToActiveSwitchList,
    getMoveToBenchSwitchList,
    movePlayerToActive,
    movePlayerToBench, switchPlayers
} from "services/RosterService";
import {addWarningNotification, addSuccessNotification} from "services/NotificationService";
import {addErrorAsNotification} from "services/ValidationService";
import CutPlayerConfirmationModal from "modules/Draft/CutPlayerConfirmationModal";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {createTradePlayersProposal, groupPlayersByActivityStatus} from "services/PlayerService";
import MoveToActiveConfirmationModal from "modules/Draft/MoveToActiveConfirmationModal";
import MoveToBenchConfirmationModal from "modules/Draft/MoveToBenchConfirmationModal";
import SwitchPlayersModal from "modules/Draft/SwitchPlayersModal";
import {Trans, withTranslation} from "react-i18next";
import BenchRosterTable from "modules/Roster/BenchRosterTable";
import TradePlayerModal from "modules/Draft/TradePlayerModal";
import {getTeams} from "services/LeagueService";
import {getUser} from "services/AuthService";
import {groupScoresByPlayerAndRound} from "services/SeasonService";

class RosterSection extends Component {
    state = {
        isLoading: false,
        selectedPlayer: null,
        switchPlayers: [],
        teams: [],
        showPickPlayerConfirmationModal: false,
        showCutPlayerConfirmationModal: false,
        showMoveToActiveConfirmationModal: false,
        showMoveToBenchConfirmationModal: false,
        showSwitchPlayersModal: false,
        showTradePlayerModal: false,
    };

    //Cut Player
    showCutPlayerConfirmationModal = (player) => {
        this.setState({selectedPlayer: player, showCutPlayerConfirmationModal: true});
    };

    hideCutPlayerConfirmationModal = () => {
        this.setState({showCutPlayerConfirmationModal: false});
    };

    onCutPlayer = (player) => {
        this.showCutPlayerConfirmationModal(player);
    };

    onExecuteCutPlayer = (player) => {
        this.setState({isLoading: true});
        deletePlayerFromRoster(player.id, this.props.roster.id)
            .then(() => {
                const {t} = this.props;
                const positionTitle = t(player.position);

                addSuccessNotification(
                    t('Cut player'),
                    t('You successfully cut {{namePosition}} from your roster', {namePosition: `${player.name} (${positionTitle})`})
                );

                this.props.onRosterChanged();
            })
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    //Move to active
    showMoveToActiveConfirmationModal = (player) => {
        this.setState({selectedPlayer: player, showMoveToActiveConfirmationModal: true});
    };

    hideMoveToActiveConfirmationModal = (player) => {
        this.setState({showMoveToActiveConfirmationModal: false});
    };

    onMoveToActive = (player) => {
        const playersList = getMoveToActiveSwitchList(this.props.roster, player);

        if (playersList === null) {
            this.showMoveToActiveConfirmationModal(player);
            return;
        }

        this.showSwitchPlayersModal(player, playersList);
    };

    onExecuteMoveToActive = (player) => {
        this.setState({isLoading: true});
        movePlayerToActive(player.rosterPlayerId, this.props.roster.id)
            .then(() => {
                this.setState({isLoading: false});
                this.props.onRosterChanged()
            })
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    //Move to bench
    showMoveToBenchConfirmationModal = (player) => {
        this.setState({selectedPlayer: player, showMoveToBenchConfirmationModal: true});
    };

    hideMoveToBenchConfirmationModal = () => {
        this.setState({showMoveToBenchConfirmationModal: false});
    };

    onMoveToBench = (player) => {
        const playersList = getMoveToBenchSwitchList(this.props.roster, player);

        if (playersList === null) {
            this.showMoveToBenchConfirmationModal(player);
            return;
        }

        if (playersList.length === 0) {
            addWarningNotification(
                'Cannot move to bench',
                'Cannot move to bench - no available spots'
            );

            return;
        }

        this.showSwitchPlayersModal(player, playersList);
    };

    onExecuteMoveToBench = (player) => {
        this.setState({isLoading: true});
        movePlayerToBench(player.rosterPlayerId, this.props.roster.id)
            .then(() => {
                this.setState({isLoading: false});
                this.props.onRosterChanged()
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    //Show switch players modal
    showSwitchPlayersModal = (player, switchPlayers) => {
        this.setState({selectedPlayer: player, switchPlayers: switchPlayers, showSwitchPlayersModal: true});
    };

    hideSwitchPlayersModal = () => {
        this.setState({showSwitchPlayersModal: false});
    };

    onExecuteSwitchPlayers = (player1, player2) => {
        this.setState({isLoading: true});
        switchPlayers(player1.rosterPlayerId, player2.rosterPlayerId, this.props.roster.id)
            .then(() => {
                this.setState({isLoading: false});
                this.props.onRosterChanged()
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    //Trade player
    showTradePlayerModal = (player) => {
        const leagueId = this.props.league.id;

        this.setState({isLoading: true});

        const skipUserId = getUser().id;
        getTeams(leagueId, skipUserId)
            .then((teams) => {
                this.setState({
                    selectedPlayer: player,
                    showTradePlayerModal: true,
                    teams,
                    isLoading: false
                })
            })
            .catch(({response}) => {
                alert("FAIL");
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    hideTradePlayerModal = () => {
        this.setState({showTradePlayerModal: false});
    };

    onExecuteTradePlayer = (fromPlayer, toPlayer) => {
        this.setState({isLoading: true});
        createTradePlayersProposal(this.props.league.id, fromPlayer.id, toPlayer.id)
            .then(() => {
                this.setState({isLoading: false});

                const {onChange, t} = this.props;
                addSuccessNotification(
                    t('Success'),
                    t('Trade proposal was successfully created')
                );
                onChange();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;

        this.hideTradePlayerModal();
    };

    render() {
        const {isLoading, selectedPlayer, switchPlayers, teams, showCutPlayerConfirmationModal, showMoveToActiveConfirmationModal, showMoveToBenchConfirmationModal, showSwitchPlayersModal, showTradePlayerModal} = this.state;
        let {season, roster, league, withEmptySlots, selectable, onSelectPlayer, editable, deletable, tradeable, scores, benchPoints, gamePoints} = this.props;

        const {active: activePlayers, bench: benchPlayers} = roster
            ? groupPlayersByActivityStatus(roster.players)
            : {active: [], bench: []}
        ;

        const playersWeeklyScores = season ? groupScoresByPlayerAndRound(season.games) : [];

        return (
            <div>
                <LoadingOverlay active={isLoading}>
                    <h3 className="mb-small"><Trans>Starting lineup</Trans></h3>
                    <RosterTable
                        type="active"
                        playersWeeklyScores={playersWeeklyScores}
                        withEmptySlots={withEmptySlots}
                        players={activePlayers}
                        selectable={selectable}
                        editable={editable}
                        deletable={deletable}
                        tradeable={tradeable}
                        onSelectPlayer={onSelectPlayer}
                        onCutPlayer={this.onCutPlayer}
                        onMoveToBench={this.onMoveToBench}
                        onTradePlayer={this.showTradePlayerModal}
                        scores={scores}
                    />
                </LoadingOverlay>
                {gamePoints !== null && gamePoints !== undefined ? <div className="mt-default text-right"><strong><Trans>Points</Trans>: {gamePoints}</strong></div> : null}

                <LoadingOverlay active={isLoading}>
                    <h3 className="mt-default mb-small"><Trans>Bench</Trans></h3>
                    <BenchRosterTable
                        playersWeeklyScores={playersWeeklyScores}
                        withEmptySlots={withEmptySlots}
                        players={benchPlayers}
                        selectable={selectable}
                        editable={editable}
                        deletable={deletable}
                        tradeable={tradeable}
                        onSelectPlayer={onSelectPlayer}
                        onCutPlayer={this.onCutPlayer}
                        onMoveToActive={this.onMoveToActive}
                        onTradePlayer={this.showTradePlayerModal}
                        scores={scores}
                    />
                </LoadingOverlay>
                {benchPoints !== null && benchPoints !== undefined ? <div className="mt-default text-right"><strong><Trans>Bench Points</Trans>: {benchPoints}</strong></div> : null}


                <CutPlayerConfirmationModal
                    isOpen={showCutPlayerConfirmationModal}
                    handleCloseModal={() => this.hideCutPlayerConfirmationModal()}
                    player={selectedPlayer}
                    onConfirm={() => this.onExecuteCutPlayer(selectedPlayer)}
                />

                <MoveToActiveConfirmationModal
                    isOpen={showMoveToActiveConfirmationModal}
                    handleCloseModal={() => this.hideMoveToActiveConfirmationModal()}
                    player={selectedPlayer}
                    onConfirm={() => this.onExecuteMoveToActive(selectedPlayer)}
                />

                <MoveToBenchConfirmationModal
                    isOpen={showMoveToBenchConfirmationModal}
                    handleCloseModal={() => this.hideMoveToBenchConfirmationModal()}
                    player={selectedPlayer}
                    onConfirm={() => this.onExecuteMoveToBench(selectedPlayer)}
                />

                <SwitchPlayersModal
                    isOpen={showSwitchPlayersModal}
                    handleCloseModal={() => this.hideSwitchPlayersModal()}
                    player={selectedPlayer}
                    switchPlayers={switchPlayers}
                    onConfirm={this.onExecuteSwitchPlayers}
                />

                {league && <TradePlayerModal
                    isOpen={showTradePlayerModal}
                    handleCloseModal={() => this.hideTradePlayerModal()}
                    player={selectedPlayer}
                    teams={teams}
                    leagueId={league ? league.id : null}
                    onConfirm={this.onExecuteTradePlayer}
                />}
            </div>
        );
    }
}

export default withTranslation()(RosterSection);
