import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {addErrorAsNotification, normalizeErrors} from "services/ValidationService";
import {Link} from "react-router-dom";
import TeamsNbSelect from "modules/Common/TeamsNbSelectWidget";
import {Trans, withTranslation} from "react-i18next";

const EditLeagueForm = function({handleSubmit, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form registration-form" noValidate>
            <h1><Trans>Edit League</Trans></h1>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>League name</Trans></label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="nbMaxTeams"><Trans>Number of teams</Trans></label>
                <Field className="form-control"
                       name="nbMaxTeams"
                       component={TeamsNbSelect}
                />
                <ErrorMessage name="nbMaxTeams" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Save Changes</Trans></button>
                <Link to="/settings" className="btn btn-default"><Trans>Cancel</Trans></Link>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: ({league}) => {return ({
            'name': league.name,
            'nbMaxTeams': league.nbMaxTeams,
        })},
        validationSchema: yup.object().shape({
            name: yup.string().required('Please input league name'),
            nbMaxTeams: yup.number().required('Please select number of teams'),
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(EditLeagueForm);
