import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function TradePlayerConfirmationModal({fromPlayer, toPlayer, onConfirm, t, ...rest}) {
    if (!fromPlayer || !toPlayer) {
        return null;
    }

    const fromPlayerPositionTitle = t(fromPlayer.position);
    const toPlayerPositionTitle = t(toPlayer.position);

    return (
        <ConfirmationModal
            title={t("Trade players")}
            content={t('Are you sure you trade {{fromPlayerPositionTitle}} to {{toPlayerPositionTitle}}?', {
                fromPlayerPositionTitle: `${fromPlayer.name} (${fromPlayerPositionTitle})`,
                toPlayerPositionTitle: `${toPlayer.name} (${toPlayerPositionTitle})`
            })}
            buttons={{
                yes: t('OK'),
                no: t('Cancel')
            }}
            type="creative"
            onConfirm={() => onConfirm(fromPlayer, toPlayer)}
            {...rest}
        />
    );
}

export default withTranslation()(TradePlayerConfirmationModal);
