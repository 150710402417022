import React from 'react';
import {Field} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import UploadThumbnail from "modules/Common/UploadThumbnail";

export default function SlideWidget({ field, form, disabled }) {
    const {name, value} = field;

    return (
        <div className="slide-widget">
            <Field name={`${name}[id]`} type="hidden" />

            <div className="slide-widget-title">
                <Field isDisabled={disabled} placeholder="Title" name={`${name}[title]`}/>
                <ErrorMessage name={`${name}[title]`} />
            </div>

            <div>
                <input name="image" type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                    form.setFieldValue(name+'.image', event.currentTarget.files[0]);
                }} className="form-control"/>
                <ErrorMessage name="image" />

                {value && value.image && <div className="mt-default">
                    <UploadThumbnail file={value.image} width={150} height={150} />
                </div>}
            </div>
        </div>
    );
}
