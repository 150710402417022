import React from 'react';
import Moment from "react-moment";
import {Link} from "react-router-dom";

const LeaguesTable = ({leagues, onDelete}) => {
    if (!leagues) {
        return null;
    }

    return (
        <table className="table table-bordered mb-default">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Admin</th>
                    <th>Members</th>
                    <th>Creation&nbsp;Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {leagues.map((league) =>
                    <tr key={league.id}>
                        <td>{league.name}</td>
                        <td>{league.adminUser ? league.adminUser.name : '-'}</td>
                        <td className="min-content">{league.nbTeams} / {league.nbMaxTeams}</td>
                        <td className="min-content"><Moment format="DD/MM/YYYY">{league.createdAt}</Moment></td>
                        <td className="min-content">
                            <Link to={`/owner/leagues/${league.id}`} className="btn btn-sm btn-default mr-small">View</Link>

                            <input
                                type="button"
                                className="btn btn-sm btn-default"
                                value="Delete"
                                onClick={() => onDelete(league)}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default LeaguesTable;
