import React, {useEffect} from 'react';

import { compose } from "recompose";
import {Field, FieldArray, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import MessageTemplateWidget from "modules/Owner/Users/MessageTemplateWidget";
import {formatLanguageName} from "services/LanguageService";
import {addSuccessNotification} from "services/NotificationService";
import RichEditor from "modules/Common/Form/RichEditor";
import UploadThumbnail from "modules/Common/UploadThumbnail";

const defaultTranslationsValues = [
    {language: 'en', subject: '', body: '', headerImage: null},
    {language: 'hu', subject: '', body: '', headerImage: null},
];

const MessageTemplateForm = function({templates, values, setFieldValue, onDeleteTemplate, submitForm, resetForm}) {
    const selectedTemplateId = values.template;
    const selectedTemplate = selectedTemplateId > 0 ? templates.find(({id}) => id === selectedTemplateId) : null;
    const isNewTemplateMode = selectedTemplateId === -1;
    const showMessageFields = isNewTemplateMode || selectedTemplate !== null;

    useEffect(() => {
        if (selectedTemplate) {
            setFieldValue('title', selectedTemplate.title);
            setFieldValue('translations', selectedTemplate.translations);
        } else {
            setFieldValue('title', '');
            setFieldValue('translations', defaultTranslationsValues);
        }
    }, [values.template]);

    const handleSaveTemplate = () => {
        setFieldValue('action', 'save', false)
        setTimeout(() => submitForm(), 100);
    };

    const handleCopyTemplate = () => {
        setFieldValue('action', 'copy', false)
        setTimeout(() => submitForm(), 100);
    };

    const handleDeleteTemplate = () => {
        const templateId = values.template;

        if (templateId) {
            onDeleteTemplate(templateId, values.title);
        }
    };

    const handleSendMessages = () => {
        setFieldValue('action', 'send', false)
        setTimeout(() => submitForm(), 100);
    };

    const handlePreview = () => {
        setFieldValue('action', 'preview', false)
        setTimeout(() => submitForm(), 100);
    };


    return (
        <form className="form form-vertical" noValidate>
            <div>
                <div className="form-group" style={{width: '40%', minWidth: 300}}>
                    <label className="form-label" htmlFor="template">Template</label>
                    <Field
                        templates={templates}
                        component={MessageTemplateWidget}
                        name="template"
                        className="form-control"
                    />
                    <ErrorMessage name="template" />
                </div>

                {showMessageFields && <>
                    <div className="form-group" style={{width: '40%', minWidth: 300}}>
                        <label className="form-label" htmlFor="title">Title</label>
                        <Field name="title" className="form-control full-width" />
                        <ErrorMessage name="title" />
                    </div>
                    <div style={{alignSelf: 'center'}}>
                        <input type="button" className="btn btn-sm btn-success" value={isNewTemplateMode ? 'Save New Template' : 'Update Template'} onClick={handleSaveTemplate} />
                        {!isNewTemplateMode && <input style={{marginLeft: 10}} type="button" className="btn btn-sm btn-success" value="Save as copy" onClick={handleCopyTemplate} />}

                        {values.template>0 && <input style={{marginLeft: 10}} type="button" className="btn btn-sm btn-success" value="Delete" onClick={handleDeleteTemplate} />}
                    </div>
                </>}
            </div>

            {showMessageFields && <>
                <div style={{display: 'flex', width: '70vw', marginTop: 30, paddingTop: 30, borderTop: '1px solid #ccc'}}>
                    <FieldArray
                        name="translations"
                        render={arrayHelpers => {
                            return values.translations.map((translation, index) => (
                                <>
                                    <div style={{flex: 1, width: 300, marginRight: (index<1 ? 60 : 0)}}>
                                        <h4>{formatLanguageName(translation.language)}</h4>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor={`translations.${index}.subject`}>Subject</label>
                                            <Field name={`translations.${index}.subject`} className="form-control full-width" />
                                            <ErrorMessage name={`translations.${index}.subject`} />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label" htmlFor={`translations.${index}.body`}>Body</label>
                                            <Field name={`translations.${index}.body`} editorOptions={{height: 250, content_style: "body { font-family: sans-serif; }"}} component={RichEditor} className="form-control full-width" />
                                            <ErrorMessage name={`translations.${index}.body`} />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label" htmlFor={`translations.${index}.headerImage`}>Header image</label>
                                            <input name={`translations.${index}.headerImage`}  type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                                                setFieldValue(`translations.${index}.headerImage`, event.currentTarget.files[0]);
                                            }} className="form-control"/>
                                            <ErrorMessage name={`translations.${index}.headerImage`} />

                                            <div className='mt-default mb-small'>
                                                <UploadThumbnail file={values.translations[index].headerImage} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ));
                        }}
                    />
                </div>

                <div className="form-group">
                    <input style={{marginRight: 15}} type="button" className="btn btn-success" value="Preview" onClick={handlePreview} />
                    <input type="button" className="btn btn-success" value="Send Messages" onClick={handleSendMessages} />
                </div>
            </>}
        </form>
    );
};

export default compose(
    withFormik({
        mapPropsToValues: () => ({
            template: null,
            action: null, //"send" or "save"
            title: '',
            translations: defaultTranslationsValues
        }),
        validationSchema: yup.object().shape({
            action: yup.string().required(),
            title: yup.string().required('Title is required'),
            translations: yup.array().required().of(
                yup.object().shape({
                    language: yup.string().required(),
                    subject: yup.string().required('Subject is required'),
                    body: yup.string().required('Body is required'),
                })
            )
        }),
        handleSubmit: ({action, ...values}, { props, setFieldValue }) => {
            document.getElementById('close-button').focus();
            let data = {...values};

            if (action === 'save' || action === 'copy') {
                if (action === 'copy') {
                    delete data.template;
                }

                const isNew = !data.template || data.template <= 0;
                props.onSaveTemplate(data)
                    .then(({ id }) => {
                        setFieldValue('template', id);
                        addSuccessNotification(
                            'Success',
                            `Template "${values.title}" was successfully ` + (isNew ? 'created' : 'updated')
                        );
                    })
            }

            if (action === 'send') {
                props.onSendMessages(data);
            }

            if (action === 'preview') {
                props.onLoadMessagePreview(data);
            }
        }
    })
)(MessageTemplateForm);
