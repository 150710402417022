import React from 'react';
import Select from "react-select";

const DraftLengthSelect = ({form, field, ...rest}) => {
    const hoursInDay = 24;

    let options = [
        {value: '*-3', label: '3 hrs'},
        {value: '3-24', label: '3-24 hrs'},
        {value: '24-48', label: '24-48 hrs'},
        {value: '48-72', label: '48-72 hrs'},
        {value: `${3*hoursInDay}-${7*hoursInDay}`, label: '3-7 days'},
        {value: `${7*hoursInDay}-${14*hoursInDay}`, label: '7-14 days'},
        {value: `${14*hoursInDay}-${21*hoursInDay}`, label: '14-21 days'},
        {value: `${21*hoursInDay}-${28*hoursInDay}`, label: '21-28 days'},
        {value: `${28*hoursInDay}-*`, label: '28+ days'},
    ];

    const selectedOption = field.value ?
        options.filter((option) => field.value.includes(option.value))
        : []
    ;

    return (
        <Select
            isMulti={true}
            closeMenuOnSelect={false}
            placeholder='Any'
            value={selectedOption}
            menuPortalTarget={document.body}
            options={options}
            onChange={(options) => {
                if (!options) {
                    options = [];
                }

                form.setFieldValue(field.name, options.map((option) => option.value));
            }}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default DraftLengthSelect;
