import React from 'react';
import DateRangeSelector from "modules/LeagueAdmin/Dashboard/DateRangeSelector";

export default function DateRangePickerWidget({ form, field, ...rest }) {
    const {startDate, endDate} = field.value;

    return (
        <div>
            <DateRangeSelector
                {...rest}
                startDate={startDate}
                endDate={endDate}
                onDateChange={(value) => {
                    form.setFieldValue(field.name, value);
                }}
            />
        </div>
    );
}
