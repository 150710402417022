import React, {Component} from "react";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import RegisterForm from "./RegisterForm";
import {registerUser} from "services/AuthService";
import {Link, Redirect, withRouter} from "react-router-dom";

class RegisterPage extends Component
{
    state = {
        isLoading: false,
        redirectToThankYouPage: false,
    };


    processData = (values) => {
        const params = new URLSearchParams(this.props.location.search);
        const hash = params.get('hash');
        const data = {hash, ...values};

        this.setState({isLoading: true});
        return registerUser(data)
            .then(() => this.setState({redirectToThankYouPage: true}))
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        const {isLoading, redirectToThankYouPage} = this.state;

        if (redirectToThankYouPage) {
            return <Redirect to="/thanks" />
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <RegisterForm processData={this.processData} />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withRouter(RegisterPage);
