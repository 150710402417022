import API from "services/API";

export function getUserNotifications() {
    return API.get('users/me/notifications')
        .then((response) => response.data)
    ;
}

export function countUnreadNotifications() {
    return API.get('users/me/unread-notifications/count')
        .then((response) => response.data)
        ;
}

export function markUserNotificationsAsViewed() {
    return API.post('users/me/notifications/mark-viewed')
        .then((response) => response.data)
    ;
}

export function formatNotificationType(type) {
    switch (type) {
        case 'LEAGUE':
            return 'notification_league';
        case 'PROFILE':
            return 'notification_profile';
        case 'DRAFT':
            return 'notification_draft';
        default:
            return type;
    }
}
