import React, {useRef} from 'react';
import ReactModal from "react-modal";
import LeagueForm from "modules/Owner/Leagues/LeagueForm";

export default function LeagueModal(props) {
    const {
        handleCloseModal,
        onSubmit,
        onCancel,
        ...rest
    } = props;

    const onRequestCancel = () => {
        if (onCancel === undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };

    const submitButtonRef = useRef(null);

    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal autofit"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">Create League</p>
            <div className="modal-content">
                <LeagueForm
                    submitButtonRef={submitButtonRef}
                    processData={(leagueData) => {
                        onSubmit(leagueData)
                            .then(() => handleCloseModal())
                            .catch(() => null)
                    }}
                />
            </div>

            <div className="modal-actions">
                <button className="btn btn-default" onClick={onRequestCancel}>Cancel</button>
                <button className="btn btn-success" onClick={() => {
                    submitButtonRef.current.click();
                }}>Create
                </button>
            </div>
        </ReactModal>
    )
}
