import React from 'react';

const CopyrightNotice = () => {
    return (
        <div>
            <h1>HUNGARIAN FANTASY FOOTBALL COPYRIGHT NOTICE</h1>
            <p><strong>Effective date:</strong> 21
            October 2019</p><p>This website and its content is copyright of Hungarian Fantasy Football - © Hungarian
            Fantasy Football 2019. All rights reserved.</p><p>The content available on this website is subject to the
            applicable copyright law and international treaties. You are not allowed to reproduce, redistribute,
            commercially exploit, communicate, sell, rent, sub-license, or publicly display any part or all of the
            content available on this website in any form, without express written permission of the copyright owner.
            Nor may you transmit the content or store it in any other website or other form of electronic retrieval
            system.</p><p>The prohibition applies to all types of materials available on this website, including,
            without limitation, written material, images, source code, user-generated content, databases, and
            downloadable files.</p><p>We permit you to conduct the following activities only:</p>
            <ul>
                <li>You may view webpages in a web browser for your personal non-commercial use;</li>
                <li>You may download pages from Hungarian Fantasy Football for catching in a web browser;</li>
                <li>You may print or download to a local hard disk extracts for your personal and non-commercial use;
                    and
                </li>
                <li>You may copy the content to individual third parties for their personal use, but only if you
                    acknowledge and specify Hungarian Fantasy Football as the source of those materials.
                </li>
            </ul>
            <p>For more information about our other rules regarding Hungarian Fantasy Football’s and third-party
                intellectual property, please refer to our Terms and Conditions available at <a href="//fantasyfootball.hu">fantasyfootball.hu/terms</a>.</p><p>For permissions regarding Hungarian FantasyFootball’s content, please contact us at
            info@fantasyfootball.hu.</p>
        </div>
    );
};

export default CopyrightNotice;