import API from "services/API";

export function getGames(leagueId, seasonId = null) {
    let route = `/leagues/${leagueId}/games`;
    if (seasonId) {
        route += '/season/' + seasonId;
    }

    return API.get(route)
        .then((response) => response.data)
    ;
}

export function markGameResultViewied(leagueId, gameId, teamId) {
    return API.get(`leagues/${leagueId}/games/${gameId}/mark-viewed/${teamId}`)
        .then((response) => response.data)
    ;
}