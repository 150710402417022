import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

const LeagueSlide = ({slide}) => {
    const {title, image} = slide;
    return (<div className="team-slide">
        <img alt={title} src={image} />
        {title}
    </div>);
};

export default function LeagueSlider({ slides }) {
    var settings = {
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    return (
        <div className="team-slider">
            <Slider {...settings}>
                {slides.map((slide) => <LeagueSlide key={slide.id} slide={slide} />)}
            </Slider>
        </div>
    );
}