import React from 'react';
import Moment from "react-moment";
import {formatGender} from "services/UserService";

const UsersTable = ({users, isSelected, onToggleSelection, onDelete}) => {
    if (!users) {
        return null;
    }

    return (
        <table className="table table-bordered mb-default">
            <thead>
                <tr>
                    <th><input type="checkbox" checked={isSelected(null)} onChange={(e) => onToggleSelection(null, e.target.checked)} /></th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>DOB</th>
                    <th>Gender</th>
                    <th>E-mail</th>
                    <th>Leagues</th>
                    <th>Teams</th>
                    <th>Registration&nbsp;Date</th>
                    <th>Newsletter</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {users.map((user) =>
                    <tr key={user.id}>
                        <td>
                            <input type="checkbox" checked={isSelected(user)} onChange={(e) => onToggleSelection(user, e.target.checked)} />
                        </td>
                        <td>{user.name}</td>
                        <td>{user.location}</td>
                        <td><Moment format="DD/MM/YYYY">{user.birthDate}</Moment></td>
                        <td>{formatGender(user.gender)}</td>
                        <td>{user.email}</td>
                        <td>
                            {user.memberships.map(({ league }) => <p key={league.id}>
                                {league.name} <small style={{color: 'gray'}}>{league.nbTeams}/{league.nbMaxTeams}</small>
                            </p>)}
                        </td>
                        <td>
                            {user.teams.map(({ id, name }) => <p key={id}>
                                {name}
                            </p>)}
                        </td>
                        <td><Moment format="DD/MM/YYYY">{user.createdAt}</Moment></td>
                        <td>{user.newsletter ? 'Yes' : 'No'}</td>
                        <td className="min-content">
                            <input
                                type="button"
                                className="btn btn-sm btn-default"
                                value="Delete"
                                onClick={() => onDelete(user)}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default UsersTable;
