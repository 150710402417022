import React, {Component} from 'react';

import Loader from "modules/Common/Loader";
import {getUserNotifications, markUserNotificationsAsViewed} from "services/UserNotificationService";
import NotificationsTable from "modules/Notification/NotificationsTable";
import {Trans} from "react-i18next";

export default class NotificationsPage extends Component {
    state = {
        isLoading: false,
        notifications: null,
    };

    componentDidMount() {
        this.setState({isLoading: true});
        getUserNotifications()
            .then((notifications) => {
                this.setState({notifications: notifications});
            })
            .finally(() => {
                this.setState({isLoading: false});
                markUserNotificationsAsViewed();
                this.props.onUpdateNotificationsCount(0);
            })
        ;
    }

    render() {
        const {isLoading, notifications} = this.state;

        return (
            <main>
                <h1><Trans>Notifications</Trans></h1>

                {isLoading && <Loader />}
                <NotificationsTable notifications={notifications} />
            </main>
        );
    }
}
