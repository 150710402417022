import React from 'react';
import RoundGamesList from "modules/Owner/Season/RoundGamesList";

const ScheduleSection = ({ values }) => {
    return (
        <>
            <h3 className="mb-small">Games Schedule</h3>


            {/*<div className="form-row">*/}
            {/*    <div className="form-group">*/}
            {/*        <label className="form-label" htmlFor="name">Schedule Type</label>*/}
            {/*        <div className="form-control">*/}
            {/*            <label style={{marginRight: 15}}><Field name="scheduleType" type="radio" value="rounds" /> Rounds</label>*/}
            {/*            <label><Field name="scheduleType" type="radio" value="weeks" /> Weeks</label>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <ErrorMessage name="scheduleType" />*/}
            {/*</div>*/}

            {/*@todo*/}
            {/*<WeekGamesList values={values.roundGames} />*/}
            {/**/}
            {/*<hr/>*/}

            <RoundGamesList values={values.roundGames} />

            {/*<hr />*/}
            {/*<RoundGamesOverview games={values.roundGames} teams={values.teams} />*/}
        </>
    );
};

export default ScheduleSection;