import React, {Component} from 'react';
import {getBlogPosts} from "services/BlogService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {Trans, withTranslation} from "react-i18next";
import BlogPostListItem from "modules/Blog/BlogPostListItem";

class BlogPage extends Component {
    state = {
        isLoading: true,
        blogPosts: undefined,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        this.setState({isLoading: true});
        getBlogPosts()
            .then((blogPosts) => this.setState({blogPosts, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    render() {
        const {blogPosts, isLoading} = this.state;
        if (blogPosts===undefined) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <h1><Trans>Blog</Trans></h1>

                <LoadingOverlay active={isLoading}>
                    <div className="blog-posts">
                        {blogPosts.map((blogPost) => <BlogPostListItem blogPost={blogPost} />)}
                    </div>
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(BlogPage);
