import API from "services/API";

export function getFantasyTeam(teamId) {
    return API.get('fantasy-teams/'+teamId)
        .then((response) => response.data)
    ;
}

export function updateFantasyTeam(teamId, teamData) {
    let formData = new FormData();
    for (let key in teamData) {
        formData.append(key, teamData[key]);
    }

    return API.post('fantasy-teams/'+teamId+'/update', formData)
        .then((response) => response.data)
    ;
}

export function ownerUpdateFantasyTeam(teamId, data) {
    return API.post(`/owner/teams/${teamId}/update`, data)
        .then((response) => response.data)
    ;
}
