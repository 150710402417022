import React, {Component} from 'react';

export default class PlayersPointsTable extends Component {
    render() {
        const {playersPoints, nbRounds} = this.props;

        //Populate rounds array
        let rounds = [];
        for (let round=1; round<=nbRounds; ++round) {
            rounds.push(round);
        }

        if (!playersPoints) {
            return null;
        }

        return (
            <div>
                <table className="table table-condensed table-bordered mb-default">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Position</th>
                            {rounds.map((round) => <th key={`${round}`}>
                                {`Round ${round}`}
                            </th>)}
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {playersPoints.map((playersPoint) => <tr key={playersPoint.id}>
                            <td>{playersPoint.name}</td>
                            <td>{playersPoint.position}</td>
                            {rounds.map((round) => <td key={`${playersPoint.id}_${round}`}>
                                {playersPoint.points[round] !== undefined ? playersPoint.points[round] : '-'}
                            </td>)}
                            <td>{playersPoint.points['total'] !== undefined ? playersPoint.points['total'] : '-'}</td>
                        </tr>)}
                    </tbody>
                </table>

            </div>
        );
    }
}
