import React from 'react';

export default function Checkbox({ field, form, children, ...rest }) {
    return (
        <input
            type="checkbox"
            checked={field.value}
            {...field}
            {...rest}
        />
    );
}
