import API from "services/API";

export function getGame(gameId) {
    return API.get(`games/${gameId}`)
        .then((response) => response.data)
    ;
}

export function updateGameScores(gameId, values) {
    return API.post(`games/${gameId}/scores`, values)
        .then((response) => response.data)
    ;
}

export function changeGameDate(gameId, date) {
    return API.post(`games/${gameId}/date`, {date: date})
        .then((response) => response.data)
    ;
}

export function groupGamesByRound(games) {
    let groupedGames = {};

    const addGame = (round, game) => {
        if (!groupedGames.hasOwnProperty(round)) {
            groupedGames[round] = [];
        }
        groupedGames[round].push(game);
    }

    for (const game of games) {
        addGame(game.team1Round, game);
        if (game.team2Round !== game.team1Round) {
            addGame(game.team2Round, game);
        }
    }

    return groupedGames;
}
