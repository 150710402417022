import React, {Component} from 'react';
import {getTeams} from "services/TeamService";
import {addErrorAsNotification} from "services/ValidationService";
import Loader from "modules/Common/Loader";
import {getCurrentSeason, updateSchedule} from "services/SeasonService";
import {addSuccessNotification} from "services/NotificationService";
import { withRouter } from "react-router-dom"
import {getSettings} from "services/SettingService";
import EditScheduleForm from "modules/Owner/Season/EditScheduleForm";
import {withTranslation} from "react-i18next";

class EditSchedulePage extends Component {
    state = {
        isLoading: true,
        season: null,
        teams: null,
        timezone: null,
    };

    componentDidMount() {
        this.setState({isLoading: true});

        const requests = Promise.all([
            getCurrentSeason(),
            getSettings(),
            getTeams()
        ]);

        requests
            .then(([season, settings, teams]) => this.setState({season, timezone: settings.timezone, teams, isLoading: false}))
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    }

    handleSubmit = (values) => {
        this.setState({isLoading: true});
        updateSchedule(this.state.season.id, values)
            .then(({ id }) => {
                addSuccessNotification('Success', 'Successfully updated a season');
                this.props.history.push('/owner/stats');
            })
            .catch(({response}) => addErrorAsNotification(response, this.props.t))
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    render() {
        const {season, teams, timezone, isLoading} = this.state;

        if (isLoading) {
            return <Loader/>;
        }

        return (
            <main>
                <EditScheduleForm season={season} timezone={timezone} teams={teams} handleSubmit={this.handleSubmit} />
            </main>
        );
    }
}

export default withTranslation()(withRouter(EditSchedulePage));
