import React, {Component} from 'react';
import {getLeague} from "services/LeagueService";
import {getMyRoster} from "services/RosterService";
import {addErrorAsNotification} from "services/ValidationService";
import {getCurrentLeagueId, setCurrentLeagueId} from "services/UserStateService";
import {withRouter} from "react-router-dom";
import {addWarningNotification} from "services/NotificationService";
import LeagueSwitcher from "modules/Dashboard/LeagueSwitcher";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import RosterSection from "modules/Roster/RosterSection";
import {getCurrentSeason} from "services/SeasonService";
import {Trans, withTranslation} from "react-i18next";
import TradeProposalsSection from "modules/Team/TradeProposalsSection";
import Loader from "modules/Common/Loader";

class MyTeamPage extends Component {
    state = {
        isLoading: true,
        season: null,
        league: null,
        roster: null,
        refreshAt: null,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        const leagueId = getCurrentLeagueId();
        if (!leagueId) {
            addWarningNotification(
                'No league selected',
                'Please choose a league first'
            );
            this.props.history.push('/secure');
            return;
        }

        this.setState({isLoading: true});


        //league
        getLeague(leagueId)
            .then((league) => {
                this.setState({league});

                const requests = Promise.all([
                    //season
                    getCurrentSeason()
                        .catch((error) => {
                            if (error.response.status === 404) {
                                return null;
                            }

                            throw error;
                        })
                    ,

                    //roster
                    getMyRoster(league.id)
                        .then((roster) => roster)
                        .catch((error) => {
                            if (error.response.status === 422) {
                                return null;
                            }

                            throw error;
                        })
                ]);

                //fetch all at once
                requests
                    .then(([season, roster]) => {
                        this.setState({
                            season,
                            roster,
                            refreshAt: Date.now()
                        })
                    })
                    .catch(({response}) => addErrorAsNotification(response, this.props.t))
                    .finally(() => this.setState({isLoading: false}))
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    onChangeLeague = ({id: leagueId}) => {
        setCurrentLeagueId(leagueId);
        this.refreshData();
    };

    render() {
        const {league, roster, season, isLoading} = this.state;

        if (!league) {
            return <main><Loader /></main>;
        }

        if (!league.currentSeason) {
            return (
                <main>
                    <div className="top-side-actions">
                        <LeagueSwitcher league={league} onChangeLeague={this.onChangeLeague} />
                    </div>

                    <h1><Trans>My Team</Trans></h1>
                    <h2><Trans values={{name: league.name}}>There's no active season for league</Trans></h2>
                </main>
            );
        }

        const team = roster ? roster.team : null;
        const isEditable = (roster && roster.status === 'open');
        const isDeletable = (league.currentSeason && league.currentSeason.status !== 'drafting');

        return (
            <main key={this.state.refreshAt}>
                <div className="top-side-actions">
                    <LeagueSwitcher league={league} onChangeLeague={this.onChangeLeague} />
                </div>

                {team && <h1><Trans>My Team - {team.name}</Trans></h1>}

                {season && team && <TradeProposalsSection
                    season={season}
                    team={team}
                    onChange={this.refreshData}
                />}

                <LoadingOverlay active={isLoading}>
                    {roster && <RosterSection
                        season={season}
                        roster={roster}
                        editable={isEditable}
                        deletable={isDeletable}
                        tradeable={isEditable}
                        withEmptySlots={true}
                        onRosterChanged={this.refreshData}
                        league={league}
                        onChange={this.refreshData}
                    />}
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(MyTeamPage));
