import React from 'react';
import Select from "react-select";

export default function PlayoffWeeksSelect({field, form, ...rest}) {
    const options = [
        {value: 1, label: '1 Week'},
        {value: 2, label: '2 Weeks'},
    ];
    const selectedOption = options.find(({value}) => (value === field.value));

    return (
        <Select
            options={options}
            defaultValue={selectedOption}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            {...rest}
        />
    );
}
