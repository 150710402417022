import React from 'react';
import Select from "react-select";
import {withTranslation} from "react-i18next";

const MemberSelect = ({ t, members, selectedMember, onChange }) => {

    if (!onChange) {
        onChange = () => {};
    }

    function formatMemberName(member) {
        return member.name;
    }

    const prepareMembersList = () => {
        let options = [];

        members.forEach((member) => {
            options.push({
                value: member.id,
                label: formatMemberName(member),
                member: member,
            });
        });

        return options;
    };

    const selectedOption = selectedMember ? {
        value: selectedMember.id,
        label: formatMemberName(selectedMember)
    } : null;

    return (
        <Select
            placeholder={t('Select')}
            className="language-switcher-select"
            menuPortalTarget={document.body}
            options={prepareMembersList()}
            defaultValue={selectedOption}
            onChange={(option) => onChange(option.member)}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
        />
    );
};

export default withTranslation()(MemberSelect);
