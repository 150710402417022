export function humanizeDuration(timeInSeconds, t) {
    const hrs = ~~(timeInSeconds / 3600);
    const mins = ~~((timeInSeconds % 3600) / 60);

    let result = '';
    if (hrs > 0) {
        result += t('durationHour', {count: hrs});
    }

    if (mins > 0) {
        result += ' ' + t('durationMinute', {count: mins});
    }

    return result;
}
