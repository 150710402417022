import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function WithdrawRequestToJoinModal({pendingLeagueMembership, onConfirm, t, ...rest}) {
    if (!pendingLeagueMembership) {
        return null;
    }

    const {league} = pendingLeagueMembership;
    const name = league.name;

    return (
        <ConfirmationModal
            title={t("Are you sure you withdraw your request to join {{name}} league?", {name})}
            content={t("Please confirm that you want ot withdraw your request to join {{name}} league", {name})}
            buttons={{
                yes: t('Yes, withdraw'),
                no: t('Cancel')
            }}
            type="destructive"
            onConfirm={() => onConfirm()}
            {...rest}
        />
    );
}
export default withTranslation()(WithdrawRequestToJoinModal);
