import React, {Component} from 'react';
import ThumbnailImg from "modules/Common/ThumbnailImg";
import Loader from "modules/Common/Loader";

export default class UploadThumbnail extends Component {
    state = {
        isLoading: false,
        thumb: undefined,
        file: null,
    };

    componentDidMount() {
        this.refreshData(this.props);
    }

    refreshData(nextProps) {
        if (!nextProps.file) {
            return;
        }

        if (nextProps.file === this.state.file) {
            return;
        }

        const file = nextProps.file;

        if (typeof nextProps.file === 'string') {
            this.setState({ thumb: nextProps.file, file: nextProps.file });
            return;
        }

        this.setState({ isLoading: true }, () => {
            let reader = new FileReader();

            reader.onloadend = () => {
                this.setState({ isLoading: false, thumb: reader.result, file: file });
            };

            reader.readAsDataURL(nextProps.file);
        });
    }

    componentWillReceiveProps(nextProps) {
        this.refreshData(nextProps);
    }

    render() {
        const { file } = this.props;
        const { isLoading, thumb } = this.state;
        const {width = 350, height = 350} = this.props;

        if (!file) { return null; }

        if (isLoading) {
            return <Loader active={true} />
        }

        return <ThumbnailImg width={width} height={height} src={thumb} />
    }
}
