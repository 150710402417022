import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {generateHash, isLoggedIn, setPostLoginRoute} from "services/AuthService";
import {addWarningNotification} from "services/NotificationService";
import AcceptInvitationConfirmationModal from "modules/LeagueUser/AcceptInvitationConfirmationModal";
import {getLeague} from "services/LeagueService";
import Loader from "modules/Common/Loader";
import {Trans, withTranslation} from "react-i18next";

class AcceptInvitationPage extends Component {
    state = {
        isLoading: true,
        league: null,
        showAcceptInvitationConfirmationModal: false,
    };

    componentDidMount() {
        const {t} = this.props;

        if (!isLoggedIn()) {
            const hash = generateHash();
            setPostLoginRoute(this.props.history.location.pathname, hash); //current route
            addWarningNotification(
                t('Account is required in order to accept invitations'),
                t('Please register or login first')
            );
            this.props.history.push('/register?hash='+hash);
            return;
        }

        const leagueId = this.props.match.params.id;
        if (leagueId !== undefined) {
            this.setState({isLoading: true});
            getLeague(leagueId)
                .then((league) => {
                    this.setState({league: league});
                    this.showAcceptInvitationConfirmationModal(league);
                })
                .finally(() => {
                    this.setState({isLoading: false});
                })
            ;
        }
    }

    showAcceptInvitationConfirmationModal = (league) => {
        this.setState({selectedLeague: league, showAcceptInvitationConfirmationModal: true});
    };

    hideAcceptInvitationConfirmationModal = () => {
        this.setState({showAcceptInvitationConfirmationModal: false});
    };

    onExecuteAcceptInvitation = ({ id }) => {
        const {confirmationToken} = this.props.match.params;

        //Logged in
        this.props.history.push({
            pathname: '/leagues/'+id+'/create-team',
            state: { intent: 'ACCEPT_INVITATION', confirmationToken: confirmationToken }
        });
    };

    onCancel = () => {
        this.setState({isLoading: true});
        this.props.history.push('/leagues');
    };

    render() {
        const {showAcceptInvitationConfirmationModal, league, isLoading} = this.state;

        if (isLoading) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <AcceptInvitationConfirmationModal
                    isOpen={showAcceptInvitationConfirmationModal}
                    handleCloseModal={() => this.hideAcceptInvitationConfirmationModal()}
                    league={league}
                    onConfirm={() => this.onExecuteAcceptInvitation(league)}
                    onCancel={this.onCancel}
                />

                <h1><Trans i18nKey="Your invitation to join {{leagueName}}" values={{leagueName: league.name}} /></h1>
            </main>
        );
    }
}

export default withTranslation()(withRouter(AcceptInvitationPage));
