import React from 'react';
import Partners from "./Partners";
import FooterLinks from "modules/Common/FooterLinks";
import FooterInfo from "modules/Common/FooterInfo";
import FooterCopy from "modules/Common/FooterCopy";
import CookieConsent from "react-cookie-consent";
import {Trans} from "react-i18next";

import {withTranslation} from "react-i18next";

function Footer({ t })
{
    return (
        <footer className="footer">
            <CookieConsent
                location="bottom"
                buttonText={t('cookies_accept')}
            >
                <Trans i18nKey="cookies_consent_text" />
            </CookieConsent>



            <div className="lg-down">
                <div className="mb-small">
                    <FooterLinks/>
                </div>

                <div className="mb-small">
                    <Partners/>
                </div>

                <div className="mb-small">
                    <FooterInfo/>
                </div>
                <div className="mb-small">
                    <FooterCopy/>
                </div>
            </div>

            <div className="lg-up">
                <FooterCopy/>
                <div className="footer-content">
                    <Partners />
                    <FooterInfo/>
                    <FooterLinks/>
                </div>
            </div>

        </footer>
    );
}

export default withTranslation()(Footer);