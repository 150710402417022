import React from 'react';

import { compose } from "recompose";
import {Field, withFormik} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {addErrorAsNotification, normalizeErrors} from "services/ValidationService";
import {Trans, withTranslation} from "react-i18next";

const ChangePasswordForm = function({handleSubmit, errors}) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form center-form" noValidate>
            <h1><Trans>Change password</Trans></h1>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Old password</Trans></label>
                <Field type="password" name="oldPassword" className="form-control" />
                <ErrorMessage name="oldPassword" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>New password</Trans></label>
                <Field type="password" name="newPassword" className="form-control" />
                <ErrorMessage name="newPassword" />
            </div>
            <div style={{marginBottom: '3rem', 'color': 'gray'}}>
                <Trans i18nKey="Please input at least {{length}} characters" values={{length: 8}} />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="name"><Trans>Confirm password</Trans></label>
                <Field type="password" name="newPasswordConfirm" className="form-control" />
                <ErrorMessage name="newPasswordConfirm" />
            </div>

            <div className="form-group mt-default">
                <button className="submit btn btn-primary mr-small" type="submit"><Trans>Change password</Trans></button>
                <Link to="/profile" className="btn btn-default"><Trans>Cancel</Trans></Link>
            </div>
        </form>
    );
};

export default compose(
    withTranslation(),
    withFormik({
        mapPropsToValues: () => ({
            'oldPassword': '',
            'newPassword': '',
            'newPasswordConfirm': '',
        }),
        validationSchema: ({ t }) => yup.object().shape({
            oldPassword: yup.string().required(t('Please input your current password')),
            newPassword: yup.string().required(t('Please input new password')),
            newPasswordConfirm: yup.string()
                .required(t('Please confirm new password'))
                .oneOf([yup.ref('newPassword'), null], t('Passwords don\'t match'))
        }),
        handleSubmit: (values, { props, setErrors }) => {
            props.processData(values)
                .catch(({response}) => {
                    addErrorAsNotification(response, props.t);
                    setErrors(normalizeErrors(response));
                })
            ;
        }
    })
)(ChangePasswordForm);
