import React from 'react';
import Select from "react-select";

export default function TeamSelect({field, form, options, ...rest}) {
    const selectedTeam = options.find(({value}) => (value === field.value));
    return (
        <Select
            options={options}
            defaultValue={selectedTeam}
            onChange={({value}) => {
                form.setFieldValue(field.name, value);
            }}
            {...rest}
        />
    );
}
