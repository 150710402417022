import React, {Component} from 'react';
import TrophyModal from "modules/Trophies/TrophyModal";

class TrophiesNotification extends Component {
    state = {
        notViewedTrophies: [],
        trophy: null,
        showTrophyModal: false
    };

    componentDidMount() {
        this.setState({notViewedTrophies: this.props.trophies}, () => {
            this.showNextNotViewedTrophy();
        });
    }

    showTrophyModal = (trophy) => {
        this.setState({trophy, showTrophyModal: true});
    };

    hideTrophyModal = () => {
        this.setState({showTrophyModal: false});
        this.showNextNotViewedTrophy();
    };

    showNextNotViewedTrophy = () => {
        const { notViewedTrophies } = this.state;
        if (notViewedTrophies.length > 0) {
            const trophy = notViewedTrophies.splice(0, 1)[0];
            this.showTrophyModal(trophy);
            this.setState({notViewedTrophies: notViewedTrophies});
        }
    };


    render() {
        const {trophy, showTrophyModal} = this.state;

        return (
            <>
                {trophy && <TrophyModal
                    isOpen={showTrophyModal}
                    trophy={trophy}
                    handleCloseModal={this.hideTrophyModal}
                />}
            </>
        );
    }
}

export default TrophiesNotification;
