import React from 'react';
import FAQ_en from "./html/en/FAQ";
import FAQ_hu from "./html/hu/FAQ";
import {withTranslation} from "react-i18next";

function FAQPage({ i18n }) {
    return (
        <main className="content-page">
            {i18n.language === 'en' ? <FAQ_en/> : <FAQ_hu/>}
        </main>
    );
}

export default withTranslation()(FAQPage);
