import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import React from "react";

export default function LockWeekConfirmationModal({onConfirm, week, ...rest}) {
    return (
        <ConfirmationModal
            title={"Lock Week"}
            content={`Are you sure you want to week ${week}?`}
            buttons={{
                yes: 'Confirm',
                no: 'Cancel'
            }}
            type="creative"
            onConfirm={() => onConfirm(week)}
            {...rest}
        />
    );
}
