import React, {Component} from 'react';
import {deletePendingLeagueMember, getLeague, getLeagues} from "services/LeagueService";
import LeaguesList from "modules/Leagues/LeaguesList";
import {withRouter} from "react-router-dom";
import Loader from "modules/Common/Loader";
import RequestToJoinLeagueConfirmationModal from "modules/Leagues/RequestToJoinLeagueConfirmationModal";
import {addSuccessNotification, addWarningNotification} from "services/NotificationService";
import {generateHash, getUser, isLoggedIn, setPostLoginRoute} from "services/AuthService";
import {Trans, withTranslation} from "react-i18next";
import WithdrawRequestToJoinModal from "modules/Dashboard/WithdrawRequestToJoinModal";
import {addErrorAsNotification} from "services/ValidationService";
import LeagueDetailsModal from "modules/Leagues/LeagueDetailsModal";

class LeaguesPage extends Component {
    state = {
        isLoading: false,
        leagues: null,
        selectedLeague: null,
        showRequestToJoinLeagueConfirmationModal: false,
        showWithdrawRequestToJoinModal: false,
        showLeagueDetailsModal: false,
        selectedPendingLeagueMembership: null,
        search: {
            term: ''
        }
    };

    componentDidMount() {
        this.refreshData();
    }


    onCancelPendingMembership = (pendingLeagueMembership) => {
        this.setState({
            showWithdrawRequestToJoinModal: true,
            selectedPendingLeagueMembership: pendingLeagueMembership
        });
    };

    onExecuteCancelPendingMembership = ({ league, id}) => {
        this.setState({isLoading: true});
        deletePendingLeagueMember(league.id, id)
            .then(() => {
                addSuccessNotification(
                    this.props.t('Success'),
                    this.props.t('Your request to join league has been withdrawn')
                );
                this.refreshData();
            })
            .catch(({response}) => {
                addErrorAsNotification(response, this.props.t);
                this.setState({isLoading: false});
            })
        ;
    };

    refreshData = () => {
        this.setState({isLoading: true});
        getLeagues(this.state.search)
            .then((leagues) => {
                this.setState({leagues: leagues});
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
        ;

        const leagueId = this.props.match.params.id;
        if (leagueId !== undefined) {
            this.setState({isLoading: true});
            getLeague(leagueId)
                .then((league) => {
                    this.setState({selectedLeague: league});
                    this.showRequestToJoinLeagueConfirmationModal(league);
                })
                .finally(() => {
                    this.setState({isLoading: false});
                })
            ;
        }
    };

    showRequestToJoinLeagueConfirmationModal = (league) => {
        this.setState({selectedLeague: league, showRequestToJoinLeagueConfirmationModal: true});
    };

    hideRequestToJoinLeagueConfirmationModal = () => {
        this.setState({showRequestToJoinLeagueConfirmationModal: false});
    };

    onShowLeagueDetails = (league) => {
        this.setState({
            selectedLeague: league,
            showLeagueDetailsModal: true,
        });
    };

    onRequestJoinLeague = (league) => {
        this.showRequestToJoinLeagueConfirmationModal(league);
    };

    onExecuteRequestToJoinLeague = (league) => {
        const {t} = this.props;

        //Not logged in
        if (!isLoggedIn()) {
            addWarningNotification(
                t('Join a League'),
                t('Please create an account or login first to join a league')
            );

            const hash = generateHash();
            setPostLoginRoute('/leagues/'+league.id+'/join', hash);
            this.props.history.push('/register?hash='+hash);
            return;
        }

        //Logged in
        this.props.history.push({
            pathname: '/leagues/'+league.id+'/create-team',
            state: { intent: 'JOIN_LEAGUE' }
        });
    };

    onChangeTerm = (value) => {
        const search = {
            ...this.state.search,
            term: value
        };
        this.setState({search});
    };

    search = (search = null) => {
        if (search) {
            this.setState({search}, () => {
                this.refreshData();
            });
        } else {
            this.refreshData();
        }
    };

    resetSearch = () => {
        this.setState({search: {term: ''}}, () => {
            this.refreshData();
        });
    };

    render() {
        const {
            leagues,
            selectedLeague,
            showRequestToJoinLeagueConfirmationModal,
            isLoading
        } = this.state;

        return (
            <main>
                <LeagueDetailsModal
                    isOpen={this.state.showLeagueDetailsModal}
                    league={this.state.selectedLeague}
                    user={getUser()}
                    onJoinLeague={this.onRequestJoinLeague}
                    onCancelPendingMembership={this.onCancelPendingMembership}
                    handleCloseModal={() => this.setState({showLeagueDetailsModal: false})}
                />

                <RequestToJoinLeagueConfirmationModal
                    isOpen={showRequestToJoinLeagueConfirmationModal}
                    handleCloseModal={() => this.hideRequestToJoinLeagueConfirmationModal()}
                    league={selectedLeague}
                    onConfirm={() => this.onExecuteRequestToJoinLeague(selectedLeague)}
                />

                <WithdrawRequestToJoinModal
                    isOpen={this.state.showWithdrawRequestToJoinModal}
                    pendingLeagueMembership={this.state.selectedPendingLeagueMembership}
                    handleCloseModal={() => this.setState({showWithdrawRequestToJoinModal: false})}
                    onConfirm={() => this.onExecuteCancelPendingMembership(this.state.selectedPendingLeagueMembership)}
                />

                <h1><Trans>Leagues</Trans></h1>

                <div className="form mb-default">
                    <div className="form-group">
                        <input id="term" onChange={(e) => this.onChangeTerm(e.target.value)} className="form-control mb-small mr-small" type="text" value={this.state.search.term} />
                        <button onClick={() => this.search()} className="mr-small btn btn-sm btn-primary"><Trans>Search</Trans></button>
                        <button onClick={() => this.resetSearch()} className="btn btn-sm btn-primary"><Trans>Reset</Trans></button>
                    </div>
                </div>

                {isLoading && <Loader />}
                {leagues && <LeaguesList
                    user={getUser()}
                    leagues={leagues}
                    onShowLeagueDetails={this.onShowLeagueDetails}
                    onJoinLeague={this.onRequestJoinLeague}
                    onCancelPendingMembership={this.onCancelPendingMembership}
                />}
            </main>
        );
    }
}

export default withTranslation()(withRouter(LeaguesPage));
