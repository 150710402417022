import React, {Component} from 'react';
import {getFantasyTeamRoster, getLeague} from "services/LeagueService";
import {setCurrentLeagueId} from "services/UserStateService";
import {withRouter} from "react-router-dom";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import RosterSection from "modules/Roster/RosterSection";
import {withTranslation} from "react-i18next";

class TeamPage extends Component {
    state = {
        isLoading: false,
        league: null,
        roster: null,
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {

        const teamId = this.props.match.params.teamId;
        const leagueId = this.props.match.params.leagueId;

        getLeague(leagueId)
            .then((league) => {
                this.setState({league});
            })
        ;

        getFantasyTeamRoster(leagueId, teamId)
            .then((roster) => {
                this.setState({roster})
            })
        ;




        // const leagueId = getCurrentLeagueId();
        // if (!leagueId) {
        //     addWarningNotification(
        //         'No league selected',
        //         'Please choose a league first'
        //     );
        //     this.props.history.push('/secure');
        //     return;
        // }
        //
        // this.setState({isLoading: true});
        //
        //
        // //league
        // getLeague(leagueId)
        //     .then((league) => {
        //         this.setState({league});
        //
        //         const requests = Promise.all([
        //             //season
        //             getCurrentSeason()
        //                 .catch((error) => {
        //                     if (error.response.status === 404) {
        //                         return null;
        //                     }
        //
        //                     throw error;
        //                 })
        //             ,
        //
        //             //roster
        //             getMyRoster(league.id)
        //                 .then((roster) => roster)
        //                 .catch((error) => {
        //                     if (error.response.status === 422) {
        //                         return null;
        //                     }
        //
        //                     throw error;
        //                 })
        //         ]);
        //
        //         //fetch all at once
        //         requests
        //             .then(([season, roster]) => {
        //                 this.setState({
        //                     season,
        //                     roster,
        //                     selectedWeek: season ? season.currentWeek : null,
        //                     refreshAt: Date.now()
        //                 })
        //             })
        //             .catch(({response}) => addErrorAsNotification(response, this.props.t))
        //             .finally(() => this.setState({isLoading: false}))
        //     })
        //     .catch(({response}) => {
        //         addErrorAsNotification(response, this.props.t);
        //         this.setState({isLoading: false});
        //     })
        // ;
    };

    onChangeLeague = ({id: leagueId}) => {
        setCurrentLeagueId(leagueId);
        this.refreshData();
    };

    onSelectWeek = (selectedWeek) => {
        this.setState({
            selectedWeek: selectedWeek,
        });
    };

    render() {
        const {league, roster, isLoading} = this.state;

        if (!league || !roster) {
            return null;
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <RosterSection
                        roster={roster}
                        league={league}
                    />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(TeamPage));
