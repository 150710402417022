import React, {Component} from 'react';
import ReactModal from "react-modal";
import Moment from "react-moment";
import {prepareSeason} from "services/LeagueService";
import {humanizeDuration} from "services/TimeHelper";
import moment from "moment";
import {Trans, withTranslation} from "react-i18next";

class ConfirmDraftModal extends Component {
    state = {
        draftSettings: null
    };

    componentDidUpdate(prevProps) {
        if (!this.props.draftStartDate || !this.props.draftEndDate) {
            return null;
        }

        if (prevProps.draftStartDate === this.props.draftStartDate && prevProps.draftEndDate === this.props.draftEndDate) {
            return null;
        }

        prepareSeason(
            this.props.leagueId,
            this.props.draftStartDate.utc().format('YYYY-MM-DD HH:mm:ss'),
            this.props.draftEndDate.utc().format('YYYY-MM-DD HH:mm:ss')
        )
        .then((data) => (this.setState({draftSettings: data})))
        ;
    }

    render() {
        const {
            timezone,
            handleCloseModal,
            onConfirm,
            onCancel,
            ...rest
        } = this.props;

        const draftStartDate = this.props.draftStartDate ? moment.tz(this.props.draftStartDate, timezone) : null;
        const draftEndDate = this.props.draftEndDate ? moment.tz(this.props.draftEndDate, timezone) : null;

        if (!this.state.draftSettings) {
            return null;
        }
        const {nbTeams, nbRounds, nbTotalPicks, draftTurnDurationSeconds} = this.state.draftSettings;

        const onRequestCancel = () => {
            if (onCancel === undefined || onCancel() !== false) {
                handleCloseModal();
            }
        };


        return (
            <ReactModal
                onRequestClose={onRequestCancel}
                className="default-modal"
                overlayClassName="modal-overlay"
                closeTimeoutMS={200}
                {...rest}
            >
                <span onClick={onRequestCancel} className="modal-close">&times;</span>

                <p className="modal-title"><Trans>Draft dates selected</Trans></p>
                <div className="modal-content">
                    <div className="text-center mb-default">
                        <p><strong><Trans>Draft dates successfully selected</Trans></strong></p>
                        <p>
                            <strong><Trans>Start date</Trans>:</strong> <Moment format="LLLL">{draftStartDate}</Moment>
                        </p>
                        <p>
                            <strong><Trans>End date</Trans>:</strong> <Moment format="LLLL">{draftEndDate}</Moment>
                        </p>
                    </div>

                    <div>
                        <p><strong><Trans>Teams participating</Trans>:</strong> {nbTeams}</p>
                        <p><strong><Trans>Number of rounds</Trans>:</strong> {nbRounds}</p>
                        <p><strong><Trans>Total picks</Trans>:</strong> {nbTotalPicks}</p>
                        <p>
                            <strong><Trans>Time-frame for picks</Trans>:</strong> {humanizeDuration(draftTurnDurationSeconds, this.props.t)}
                        </p>
                    </div>

                    <div className="mt-default">
                        <Trans>Please note that failure to pick during the given time-frame will result in an automated pick</Trans>
                    </div>
                </div>

                <div className="modal-actions">
                    <button className="btn btn-default" onClick={onRequestCancel}><Trans>Change dates</Trans></button>
                    <button className="btn btn-success" onClick={() => {
                        handleCloseModal();
                        onConfirm(draftStartDate, draftEndDate)
                    }}><Trans>Confirm</Trans>
                    </button>
                </div>
            </ReactModal>
        )
    }
}

export default withTranslation()(ConfirmDraftModal);
