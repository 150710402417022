import React from 'react';
import {SingleDatePicker} from 'react-dates';

export default class DatePickerWidget extends React.Component {
    state = {
        calendarFocused: false
    };

    onDateChange = (value) => {
        this.props.form.setFieldValue(this.props.field.name, value);
    };

    onFocusChange = ({focused}) => {
        this.setState(() => ({calendarFocused: focused}));
    };

    render() {
      const {field, form, options, ...rest} = this.props;

        return (
            <SingleDatePicker
                date={field.value}
                displayFormat="DD/MM/YYYY"
                onDateChange={this.onDateChange}
                focused={this.state.calendarFocused}
                onFocusChange={this.onFocusChange}
                hideKeyboardShortcutsPanel={true}
                noBorder={true}
                numberOfMonths={1}
                isOutsideRange={() => false}
                {...rest}
            />
        )
    }
}
