import React from 'react';

const CookiePolicy = () => {
    return (
        <div>
            <h1>HUNGARIAN FANTASY FOOTBALL COOKIE POLICY</h1>
            <p><strong>Effective date:</strong> 21
            October 2019</p>
            <p>We use cookies on http://fantasyfootball.hu (“Hungarian Fantasy Football”). In this cookie policy
                (the “Cookie
                Policy”), you can find information about the types of cookies that we use and the purposes for which we
                use them. If
                you do not agree with our use of cookies, please disable your cookies as described in the section “How
                to disable
                cookies?”<strong> </strong>Please beware that the full functionality of Hungarian Fantasy Football may
                not be
                available without cookies.</p>

            <p><h3>1. Responsible entity (data controller)</h3></p>
            <p>The data controller that
            is
            responsible for the processing of your personal data through Hungarian Fantasy Football is Gábor Kiss having
            an
            address at Farman-strasse 47, 8152 Glattpark (Opfikon), Switzerland (“we”, “us”, and “our”). </p>

            <p><h3>2. What is a cookie?</h3></p>
            <p>A cookie is a small piece of data typically consisting of letters and numbers. When you visit
            a
            website, the website may send a cookie to your browser. Subsequently, the browser may store the cookie on
            your
            computer or mobile device for certain period of time. Cookies are designed to allow the recognition of your
            device
            and collection of certain information about your use of a website. Thus, over time, cookies allow websites
            to
            “remember” your actions and preferences. There are several types of cookies, namely, (i) persistent cookies,
            which
            remain valid until deleted by you, (ii) cookies that remain valid until their expiration date, and (iii)
            session
            cookies that are stored on a web browser and remain valid until the moment the browser is closed. Cookies
            may also
            be (i) first-party cookies (set by the website itself) and (ii) third-party cookies (placed by third-party
            websites).</p>

            <p><h3>3. What cookies do we use?</h3></p><p>We use three types of cookies on Hungarian Fantasy
            Football,
            namely:</p>
            <ul>
                <li><strong>Technical cookies</strong>, which are essential to ensure the correct functioning of
                    Hungarian Fantasy
                    Football and to provide the services requested by you;
                </li>
                <li><strong>Statistics cookies</strong> that allow us to generate statistical reports about how you use
                    Hungarian
                    Fantasy Football; and
                </li>
                <li><strong>Marketing cookies</strong> that allow us to create, implement, and examine our digital
                    marketing
                    campaigns. Such cookies allow us to reach the right customers, analyse the productivity of our
                    marketing
                    campaigns, and offer you personalised advertisement.
                </li>
            </ul>
            <p>The table below provides an overview of our cookies, including their purpose and expiration time. </p>
            <table className="table">
                <tr>
                    <td colSpan="5"><p><strong>Strictly necessary cookies</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Name</strong></p></td>
                    <td><p><strong>Type</strong></p></td>
                    <td><p><strong>Provider</strong></p></td>
                    <td><p><strong>Expiration</strong></p></td>
                    <td><p><strong>Purpose</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>CONSENT</strong></p></td>
                    <td><p>Third-party HTTP cookie</p></td>
                    <td><p><a href="http://google.com">google.com</a></p></td>
                    <td><p>6650 days</p></td>
                    <td><p>The cookie is used to detect if you have accepted the marketing category in the cookie
                        banner.</p></td>
                </tr>
                <tr>
                    <td colSpan="5"><p><strong>Statistics cookies</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Name</strong></p></td>
                    <td><p><strong>Type</strong></p></td>
                    <td><p><strong>Provider</strong></p></td>
                    <td><p><strong>Expiration</strong></p></td>
                    <td><p><strong>Purpose</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>_ga </strong></p></td>
                    <td><p>First-party HTTP cookie</p></td>
                    <td><p><a href="http://fantasyfootball.hu">fantasyfootball.hu</a></p></td>
                    <td><p>2 years</p></td>
                    <td><p>The cookie is used to set a unique ID that is used to generate statistical data on how you
                        use Hungarian
                        Fantasy Football.</p></td>
                </tr>
                <tr>
                    <td><p><strong>_gat </strong></p></td>
                    <td><p>First-party HTTP cookie</p></td>
                    <td><p><a href="http://fantasyfootball.hu">fantasyfootball.hu</a></p></td>
                    <td><p>1 day</p></td>
                    <td><p>The cookie is used by Google Analytics to throttle request rate.</p></td>
                </tr>
                <tr>
                    <td><p><strong>_gid </strong></p></td>
                    <td><p>First-party HTTP cookie</p></td>
                    <td><p><a href="http://fantasyfootball.hu">fantasyfootball.hu</a></p></td>
                    <td><p>1 day</p></td>
                    <td><p>The cookie is used to set a unique ID that is used to generate statistical data on how you
                        use Hungarian
                        Fantasy Football.</p></td>
                </tr>
                <tr>
                    <td colSpan="5"><p><strong>Marketing cookies</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>Name</strong></p></td>
                    <td><p><strong>Type</strong></p></td>
                    <td><p><strong>Provider</strong></p></td>
                    <td><p><strong>Expiration</strong></p></td>
                    <td><p><strong>Purpose</strong></p></td>
                </tr>
                <tr>
                    <td><p><strong>1P_JAR</strong></p></td>
                    <td><p>Third-party HTTP cookie</p></td>
                    <td><p><a href="http://google.com">google.com</a></p></td>
                    <td><p>29 days</p></td>
                    <td><p>The cookie is used to collect information on your behaviour on multiple websites in order to
                        optimise the
                        relevance of advertisement.</p></td>
                </tr>
                <tr>
                    <td><p><strong>domainads/tracking/caf.gif </strong></p></td>
                    <td><p>Third-party Pixel cookie</p></td>
                    <td><p><a href="http://gstatic.com">gstatic.com</a></p></td>
                    <td><p>End of session</p></td>
                    <td><p>The cookie is used to collect data about your behaviour from multiple websites, in order to
                        present more
                        relevant advertisement. This also allows the website to limit the number of times that you are
                        shown the
                        same advertisement. </p></td>
                </tr>
                <tr>
                    <td><p><strong>NID</strong></p></td>
                    <td><p>Third-party HTTP cookie</p></td>
                    <td><p><a href="http://google.com">google.com</a></p></td>
                    <td><p>6 months</p></td>
                    <td><p>The cookie is used to register a unique ID that identifies a returning user's device. The ID
                        is used for
                        targeted advertising. </p></td>
                </tr>
            </table>

            <p><h3>4. Cookie consent</h3></p>
            <p>When you visit Hungarian Fantasy Football for the first time, we may ask you to
            provide us with your consent to our use of cookies via a cookie consent banner. If you do not provide your
            consent
            to our use of all cookies, we may serve you technical cookies only that are strictly necessary to ensure the
            proper
            functioning of Hungarian Fantasy Football. The use of such cookies does not require your consent. Please
            note that
            we may not be able to provide you with the best possible user experience if not all cookies are enabled.</p>
            <p><h3>5. How to disable cookies?</h3></p><p>When we ask you to provide your consent to our use of statistics and
            marketing
            cookies, you have a freedom not to provide such consent. If you would like to refuse our use of cookies on
            Hungarian
            Fantasy Football, you can do it at any time by declining cookies in your browser or device. Please note that
            some
            parts of Hungarian Fantasy Football may not function properly without cookies and you cannot decline
            strictly
            necessary technical cookies. It may be helpful to consult the cookie management instructions of your
            browser:</p>
            <ul>
                <li><strong>Apple Safari:</strong> <a
                    href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
                </li>
                <li><strong>Google Chrome: </strong><a
                    href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a>
                </li>
                <li><strong>Firefox:</strong> <a
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>
                </li>
                <li><strong>Internet Explorer:</strong> <a
                    href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                </li>
                <li><strong>Microsoft Edge:</strong> <a
                    href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a>
                </li>
            </ul>

            <p><h3>6. Google Analytics</h3></p>
            <p>We use Google Analytics, the service provided by Google, Inc. (“Google”) to
            analyse
            your use of Hungarian Fantasy Football. Google Analytics generates statistical and other information by
            means of
            cookies and we use its services to create reports about your use of Hungarian Fantasy Football. Google
            Analytics
            cookies are anonymous first-party cookies. Such cookies include cookies entitled “__gat”, “__gid”, and
            “__ga”.
            Please note that the use of Google Analytics cookies is anonymous and does not allow us to identify you in
            any
            manner. The information generated by cookies about your use of Hungarian Fantasy Football (including your IP
            address) will be transmitted to and stored by Google on servers in the United States. Please note that your
            IP
            address will be anonymised and Google will not combine your IP address with other information Google holds
            about
            you. Thus, Google will not be able to identify you. In certain cases (e.g., when required by law or when
            third
            parties conduct services on behalf of Google), Google may transfer the information to third parties. For
            more
            information about Google Analytics’ privacy practices, please visit <a
                href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>
            .</p><p>If you would like to opt out from Google Analytics, you can do so by installing a Google Analytics
            opt-out
            browser add-on available at <a
                href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>
            . For more information on opting-out from advertising features on your device, please visit <a
                href="https://www.networkadvertising.org">https://www.networkadvertising.org</a> .</p>

            <p><h3>7. Targeted advertising and GoogleAdSense</h3></p>
            <p>We may place targeted interest-based advertisements on Hungarian Fantasy
            Football as a part of the advertising network GoogleAdSense. Such advertisements are generated on the basis
            of your
            use of Hungarian Fantasy Football, other websites on the Internet, and data generated by your cookies. You
            can
            control how such advertisements are shown to you or opt-out from targeted advertising by consulting the
            guide
            powered by the Digital Advertising Alliance available at <a
                href="https://youradchoices.com">https://youradchoices.com</a>
            and the Network Advertising Initiative available at <a
                href="https://www.networkadvertising.org">https://www.networkadvertising.org</a>
            .</p>

            <p><h3>8. Contact us</h3></p><p>If you have any questions about this Cookie Policy, please contact us:</p><p>
            <strong>Email:</strong>
            info@fantasyfootball.hu</p><p><strong>Postal address for communication: Farman-strasse 47, 8152 Glattpark
            (Opfikon),
            Switzerland</strong></p><p><strong>***</strong></p>
        </div>
    );
};

export default CookiePolicy;