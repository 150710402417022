import React, { useEffect } from 'react';
import { groupPlayersBySection } from "services/PlayerService";
import { formatPlayerPosition } from "services/RosterService";
import { PlayerToolTip } from "modules/Players/PlayerTooltip";
import { Field } from "formik";
import ScoreWidget from "modules/Owner/Stats/ScoreWidget";
import Select from "react-select";

export default function EditRosterScoresTable({ editable, team, onSetNewPlayers }) {
    let { offense, special, defense, hc } = groupPlayersBySection(team.players);

    // let [newPlayers, setNewPlayers] = useState([]);
    let getNewPlayers = () => {
        return team.newPlayers.map(pl => {
            return {
                value: pl.id,
                label: pl.name
            }
        });
    }

    useEffect(() => {

    }, []);

    const colourOptions = [
        { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
        { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
        { value: 'purple', label: 'Purple', color: '#5243AA' },
        { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
        { value: 'orange', label: 'Orange', color: '#FF8B00' },
        { value: 'yellow', label: 'Yellow', color: '#FFC400' },
        { value: 'green', label: 'Green', color: '#36B37E' },
        { value: 'forest', label: 'Forest', color: '#00875A' },
        { value: 'slate', label: 'Slate', color: '#253858' },
        { value: 'silver', label: 'Silver', color: '#666666' },
    ];

    return (
        <div className="roster-tables">
            <div className="roster-header">
                <div className="table-caption">
                    Offense
                </div>
                {
                    editable ?
                        <Select
                            isMulti
                            name="New players"
                            options={getNewPlayers()}
                            onChange={(options) => {
                                onSetNewPlayers(options);
                            }}
                            placeholder="Add new players"
                            className="basic-multi-select"
                            classNamePrefix="select"
                        /> : <></>
                }
            </div>
            <table className="table table-condensed table-bordered">
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Name</th>
                        <th>Pass yds</th>
                        <th>Rush yds</th>
                        <th>Catch yds</th>
                        <th>TD</th>
                        <th>2pt</th>
                        <th>Fumble</th>
                        <th>INT</th>
                    </tr>
                </thead>
                <tbody>
                    {offense.map((player) => {
                        return <tr>
                            <td>{formatPlayerPosition(player)}</td>
                            <td className="nowrap"><PlayerToolTip player={player} team={team} /></td>
                            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.pass_yds`} className="form-control-min" /></td>
                            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.rush_yds`} className="form-control-min" /></td>
                            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.catch_yds`} className="form-control-min" /></td>
                            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.td`} className="form-control-min" /></td>
                            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}._2pt`} className="form-control-min" /></td>
                            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.fumble`} className="form-control-min" /></td>
                            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.int`} className="form-control-min" /></td>
                        </tr>
                    })}
                </tbody>
            </table>

            <table className="table table-condensed table-bordered">
                <caption>Special teams</caption>
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Name</th>
                        <th>FG</th>
                        <th>XP</th>
                        <th>FG miss</th>
                        <th>XP miss</th>
                    </tr>
                </thead>
                <tbody>
                    {special.map((player) => {
                        return (
                            <tr>
                                <td>{formatPlayerPosition(player)}</td>
                                <td className="nowrap"><PlayerToolTip player={player} team={team} /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.fg`} className="form-control-min" /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.xp`} className="form-control-min" /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.fg_miss`} className="form-control-min" /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.xp_miss`} className="form-control-min" /></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <table className="table table-condensed table-bordered">
                <caption>Defense</caption>
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Name</th>
                        <th>Fum forced</th>
                        <th>INT</th>
                        <th>Sack</th>
                        <th>Pts allowed</th>
                    </tr>
                </thead>
                <tbody>
                    {defense.map((player) => {
                        return (
                            <tr>
                                <td>{formatPlayerPosition(player)}</td>
                                <td className="nowrap"><PlayerToolTip player={player} team={team} /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.fum_forced`} className="form-control-min" /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.defense_int`} className="form-control-min" /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.sack`} className="form-control-min" /></td>
                                <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.pts_allowed`} className="form-control-min" /></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/*{<table className="table table-condensed table-bordered">*/}
            {/*    <caption>HC</caption>*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th>Position</th>*/}
            {/*        <th>Name</th>*/}
            {/*        <th>Win Loss</th>*/}
            {/*        <th>Pts gained</th>*/}
            {/*        <th>Score difference</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {hc.map((player) => {*/}
            {/*        return (<tr>*/}
            {/*            <td>{formatPlayerPosition(player)}</td>*/}
            {/*            <td className="nowrap"><PlayerToolTip player={player} team={team} /></td>*/}
            {/*            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.win_loss`} className="form-control-min" /></td>*/}
            {/*            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.pts_gained`} className="form-control-min" /></td>*/}
            {/*            <td><Field component={ScoreWidget} disabled={!editable} name={`players.${player.id}.score_difference`} className="form-control-min" /></td>*/}
            {/*        </tr>)*/}
            {/*    })}*/}
            {/*    </tbody>*/}
            {/*</table>}*/}
        </div>
    );
}

