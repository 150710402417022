import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function RemoveLeagueConfirmationModal({league, onConfirm, t, ...rest}) {
    const name = league.name;

    return (
        <ConfirmationModal
            title={t("remove_league_title", {name})}
            content={t("remove_league_body", {name})}
            buttons={{
                yes: t('Remove'),
                no: t('Cancel')
            }}
            type="destructive"
            onConfirm={() => onConfirm()}
            {...rest}
        />
    );
}

export default withTranslation()(RemoveLeagueConfirmationModal);
