import React from 'react';
import {Field, FieldArray} from "formik";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import RoundGameRow from "modules/Owner/Season/RoundGameRow";

const RoundGamesList = ({ values }) => {
    return (
        <div className="round-games">
            <FieldArray
                name="roundGames"
                render={arrayHelpers => (
                    <div>
                        <ErrorMessage name={`roundGames`}/>

                        {values && values.length > 0 && (
                            values.map((game, index) => {
                                const disabled = game.id && !game.editable;

                                return (
                                    <div key={`${index}_${values.length}`} className="game-row">
                                        <div className="game-heading mr-default"><strong>Game {index + 1}</strong></div>
                                        <Field disabled={disabled} name={`roundGames[${index}]`} component={RoundGameRow}/>
                                        <button disabled={disabled} type="button" className="btn btn-sm btn-default" onClick={() => arrayHelpers.remove(index)}>✕</button>
                                    </div>
                                )
                            })
                        )}

                        <button className="btn btn-sm btn-primary" type="button" onClick={() => arrayHelpers.push({})}>
                            Add a game
                        </button>
                    </div>
                )}
            />
        </div>
    )
};

export default RoundGamesList;