import React from 'react';
import {compose} from "recompose";
import {Field, withFormik} from "formik";
import * as yup from "yup";
import ScoreConfigBlock from "modules/Owner/ScoreConfig/ScoreConfigBlock";
import PointsWidget from "modules/Owner/ScoreConfig/PointsWidget";

function ScoreConfigForm(props) {
    const {handleSubmit} = props;

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit()
        }} className="form form-season mb-default" noValidate>
            <div className="mb-default">
                <h3 className="mb-small">Offense</h3>

                <div style={{display: 'flex'}}>
                    <div className="score-attribute">
                        <div className="score-attribute-heading">Pass yards</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="pass_yds" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Rush yards</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="rush_yds" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Catch yards</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="catch_yds" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">TD</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="td" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">2pt</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="_2pt" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Fumble</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="fumble" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">INT</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="int" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-default">
                <h3 className="mb-small">Defense</h3>

                <div style={{display: 'flex'}}>
                    <div className="score-attribute">
                        <div className="score-attribute-heading">Fumble forced</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="fum_forced" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">INT</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="defense_int" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Sack</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="sack" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Points allowed</div>
                        <div className="score-attribute-body">
                            <table>
                                <thead>
                                <tr>
                                    <th>Min</th>
                                    <th>Max</th>
                                    <th>Point</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <Field component={PointsWidget} name={`pts_allowed.0.min`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`pts_allowed.0.max`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`pts_allowed.0.points`} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Field component={PointsWidget} name={`pts_allowed.1.min`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`pts_allowed.1.max`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`pts_allowed.1.points`} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-default">
                <h3 className="mb-small">Special teams</h3>

                <div style={{display: 'flex'}}>
                    <div className="score-attribute">
                        <div className="score-attribute-heading">Field goal</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="fg" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Extra point</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="xp" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">FG miss</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="fg_miss" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">XP miss</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="xp_miss" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-default">
                <h3 className="mb-small">Head Coach</h3>

                <div style={{display: 'flex'}}>
                    <div className="score-attribute">
                        <div className="score-attribute-heading">Win / Loss</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="win_loss"  />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Points gained</div>
                        <div className="score-attribute-body">
                            <ScoreConfigBlock attribute="pts_gained" />
                        </div>
                    </div>

                    <div className="score-attribute">
                        <div className="score-attribute-heading">Score Difference</div>
                        <div className="score-attribute-body">
                            <table>
                                <thead>
                                <tr>
                                    <th>Min</th>
                                    <th>Max</th>
                                    <th>Point</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.0.min`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.0.max`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.0.points`} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.1.min`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.1.max`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.1.points`} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.2.min`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.2.max`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.2.points`} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.3.min`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.3.max`} />
                                    </td>
                                    <td>
                                        <Field component={PointsWidget} name={`score_difference.3.points`} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>

            <div style={{marginTop: '7rem'}}>
                <input type="submit" className="btn btn-success" value="Save Changes"/>
            </div>

        </form>
    );
}


export default compose(
    withFormik({
        mapPropsToValues: ({ values }) => {
            let data = {...values};

            //do not show negative score_difference on ui (it's automatic on form save)
            data.score_difference = data.score_difference.filter(attr => attr.sign === '+');

            return data;
        },

        validationSchema: () => {
            const validators = {};

            //Default blocks
            const defaultConfigBlocks = [
                'pass_yds', 'rush_yds', 'catch_yds', 'td', '_2pt', 'fumble', 'int', 'fum_forced', 'defense_int', 'sack', 'fg', 'xp', 'fg_miss', 'xp_miss', 'pts_gained', 'win_loss'
            ];
            for (const block of defaultConfigBlocks) {
                validators[block] = yup.array().required().of(
                    yup.object().shape({
                        operation: yup.string().oneOf(['*', '/']),
                        sign: yup.string().oneOf(['+', '-']),
                        points: yup.number()
                    })
                );
            }

            //Custom blocks
            validators.pts_allowed = yup.array().required().of(
                yup.object().shape({
                    min: yup.number(),
                    max: yup.number(),
                    points: yup.number()
                })
            );

            validators.score_difference = yup.array().required().of(
                yup.object().shape({
                    min: yup.number(),
                    max: yup.number(),
                    points: yup.number()
                })
            );

            return yup.object().shape(validators);
        },

        handleSubmit: (values, { props, setSubmitting }) => {
            //deep copy
            let data = JSON.parse(JSON.stringify(values));

            //score difference - automatically save inversed version
            for (const score_difference of values.score_difference) {
                data.score_difference.push({
                    operation: '=',
                    sign: '-',
                    min: -score_difference.max,
                    max: -score_difference.min,
                    points: score_difference.points
                });
            }

            props.handleSubmit(data).finally(() => {
                setSubmitting(false);
            });
        }
    })
)(ScoreConfigForm);
