import React, {Component} from 'react';
import ConfirmResetPasswordForm from "./ConfirmResetPasswordForm";
import {resetPassword} from "services/AuthService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import { withRouter } from "react-router-dom";
import {addSuccessNotification} from "services/NotificationService";
import {withTranslation} from "react-i18next";

class ConfirmResetPasswordPage extends Component {
    state = {
        isLoading: false
    };

    handlePasswordReset = ({newPassword}) => {
        const resetPasswordToken = this.props.match.params.resetPasswordToken;
        this.setState({isLoading: true});
        return resetPassword(resetPasswordToken, newPassword)
            .then(() => {
                const { t } = this.props;
                addSuccessNotification(
                    t('Success'),
                    t('Your password was successfully reset')
                );
                this.props.history.push('/login');
            })
            .finally(() => this.setState({isLoading: false}))
        ;
    };

    render() {
        const {isLoading} = this.state;

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <ConfirmResetPasswordForm processData={this.handlePasswordReset}/>
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(ConfirmResetPasswordPage));
