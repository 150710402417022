import React, {Component} from 'react';
import ReactModal from "react-modal";
import DateRangeSelector from "./DateRangeSelector";
import {addErrorNotification} from "services/NotificationService";
import moment from "moment";
import {Trans, withTranslation} from "react-i18next";

class AddDraftModal extends Component {
    state = {
        defaultStartDate: null,
        defaultEndDate: null,

        startDate: null,
        endDate: null,
    };

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        if (state.defaultStartDate !== props.startDate) {
            newState['startDate'] = props.startDate;
            newState['defaultStartDate'] = props.startDate;
        }

        if (state.defaultEndDate !== props.endDate) {
            newState['endDate'] = props.endDate;
            newState['defaultEndDate'] = props.endDate;
        }

        return newState;
    }

    onDateChange = ({startDate, endDate}) => {
        this.setState({startDate, endDate});
    };

    onSubmit = () => {
        const { t, timezone } = this.props;

        if (!this.state.startDate || !this.state.endDate) {
            addErrorNotification(t('Draft date / time not selected'), t('You did not select date / time for the draft'));
            return;
        }

        const now = moment().tz(timezone);
        let startDate = this.state.startDate.tz(timezone);
        let endDate = this.state.endDate.tz(timezone);

        if (startDate.isBefore(now)) {
            addErrorNotification(t('Invalid dates'), t('Start date should be after current time'));
            return;
        }

        if (endDate.isBefore(startDate)) {
            addErrorNotification(t('Invalid dates'), t('End date should be later than start date!'));
            return;
        }

        const draftWindowStart = moment(this.props.draftWindow.startDate).tz(timezone);
        if (startDate.isBefore(draftWindowStart)) {
            addErrorNotification(t('Invalid dates'), t('Draft start date/time should be after {{date}}', {date: draftWindowStart.format("LLLL")}));
            return;
        }

        const draftWindowEnd = moment(this.props.draftWindow.endDate).tz(timezone);
        if (endDate.isAfter(draftWindowEnd)) {
            addErrorNotification(t('Invalid dates'), t('Draft end date/time should be before {{date}}', {date: draftWindowEnd.format("LLLL")}));
            return;
        }

        if (endDate.diff(startDate, 'hours') < 3) {
            addErrorNotification(t('Invalid dates'), t('Draft period should at least be 3 hours long!'));
            return;
        }

        this.props.onSubmit({startDate, endDate});
        this.props.handleCloseModal();
    };

    render() {
        let {onCancel, handleCloseModal, draftWindow, timezone, ...rest} = this.props;
        const onRequestCancel = () => {
            if (onCancel === undefined || onCancel() !== false) {
                handleCloseModal();
            }
        };
        const today = moment().startOf('day');

        const draftStart = moment.tz(draftWindow.startDate, timezone).startOf('day');
        const draftEnd = moment.tz(draftWindow.endDate, timezone).endOf('day');

        return (
            <ReactModal
                contentLabel="onRequestClose Example"
                onRequestClose={onRequestCancel}
                className="draft-modal autofit"
                overlayClassName="draft-modal-overlay"
                closeTimeoutMS={200}
                {...rest}
            >
                <span onClick={onRequestCancel} className="modal-close">&times;</span>

                <p className="modal-title"><Trans>Select draft date</Trans></p>
                <div className="modal-content">
                    <p className="mb-default" style={{width: '75%'}}><Trans i18nKey="Please select draft dates" values={{date: moment.tz(draftWindow.endDate, timezone).format('LLLL')}} /></p>
                    <DateRangeSelector
                        timezone={timezone}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onDateChange={this.onDateChange}
                        isOutsideRange={(day) => {
                            if (day.isBefore(today)) {
                                return true;
                            }

                            if (day.isBefore(draftStart)) {
                                return true;
                            }

                            if (day.isAfter(draftEnd)) {
                                return true;
                            }

                            return false;
                        }}
                    />
                </div>

                <div className="modal-actions">
                    <button className="btn btn-default" onClick={onRequestCancel}><Trans>Cancel</Trans></button>
                    <button className="btn btn-success" onClick={() => {
                        this.onSubmit()
                    }}><Trans>Continue</Trans>
                    </button>
                </div>
            </ReactModal>
        );
    }
}

export default withTranslation()(AddDraftModal);
