import React, {Component} from "react";
import LoginForm from "modules/Auth/Login/LoginForm";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import {extractPostLoginRoute, login} from "services/AuthService";
import {Redirect} from "react-router-dom";
import {addWarningNotification} from "services/NotificationService";

export default class LoginPage extends Component
{
    state = {
        isLoading: false,
        redirectRoute: null,
    };

    componentDidMount() {
        const routeState = this.props.location.state;
        if (!routeState) {
            return;
        }

        if (routeState.notification) {
            addWarningNotification('Access Denied', 'You need to login in order to access this page');
        }
    }

    processData = (values) => {
        this.setState({isLoading: true});
        return login(values.email, values.password)
            .then(() => this.redirectToNextPage())
            .finally(() => this.setState({isLoading: false}))
    };

    redirectToNextPage = () => {
        let redirectRoute = extractPostLoginRoute();
        if (!redirectRoute) {
            redirectRoute = '/secure';
        }
        this.setState({redirectRoute: redirectRoute});
    };

    render() {
        const {isLoading, redirectRoute} = this.state;

        if (redirectRoute) {
            return <Redirect to={redirectRoute} />
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <LoginForm processData={this.processData} />
                </LoadingOverlay>
            </main>
        );
    }
}
