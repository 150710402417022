import React from 'react';
import {groupFantasyPlayersBySection} from "services/PlayerService";
import {formatPlayerPosition} from "services/RosterService";
import {Trans} from "react-i18next";
import {prepareActionsCell} from "modules/Roster/RosterTableHelper";
import PlayerInfoPopup from "modules/Players/PlayerInfoPopup";

export default class RosterTable extends React.Component {
    state = {
        isPlayerPopupOpen: false,
        selectedPlayer: null,
        selectedPlayerScores: null,
    };

    showPlayerPopup = (player, scores) => {
        this.setState({
            isPlayerPopupOpen: true,
            selectedPlayer: player,
            selectedPlayerScores: scores
        })
    };

    hidePlayerPopup = () => {
        this.setState({
            isPlayerPopupOpen: false
        });
    };

    render() {
        let {playersWeeklyScores, scores, withEmptySlots} = this.props;

        //Sort players by position
        const players = this.props.players.sort(function (player1, player2) {
            const fantasyPosition1 = player1.fantasyPosition;
            const fantasyPosition2 = player2.fantasyPosition;

            if (fantasyPosition1 === fantasyPosition2) {
                return 0;
            }

            return fantasyPosition1 < fantasyPosition2 ? -1 : 1;
        });
        let {offense, special, defense, hc} = groupFantasyPlayersBySection(players, withEmptySlots);

        //Configure actions cell
        let actionsCell = prepareActionsCell(this.props);


        const formatPlayerName = (player) => {
            if (!player.id) {
                return null;
            }

            return player.name
        };

        const formatPlayerPopupButton = (player) => {
            if (!player.id) {
                return null;
            }

            return <>
                <button className="btn btn-info btn-danger"
                        onClick={() => this.showPlayerPopup(player, playersWeeklyScores[player.id])}>
                    <span style={{fontSize: '1.4em'}}>ⓘ</span>
                </button>
            </>
        };

        return (
            <div className="table-responsive roster-tables">
                <PlayerInfoPopup
                    isOpen={this.state.isPlayerPopupOpen}
                    handleCloseModal={this.hidePlayerPopup}
                    player={this.state.selectedPlayer}
                    scores={this.state.selectedPlayerScores}
                />

                <table className="table table-condensed table-bordered">
                    <caption><Trans>Offense</Trans></caption>
                    <thead>
                    <tr>
                        {actionsCell !== null && <th className="min-width"></th>}
                        <th className="min-width"></th>
                        <th><Trans>Position</Trans></th>
                        <th><Trans>Name</Trans></th>
                        <th className="min-width"></th>
                        <th><Trans>Pass yds</Trans></th>
                        <th><Trans>Rush yds</Trans></th>
                        <th><Trans>Catch yds</Trans></th>
                        <th><Trans>TD</Trans></th>
                        <th><Trans>2pt</Trans></th>
                        <th><Trans>Fumble</Trans></th>
                        <th><Trans>INT</Trans></th>
                        <th><Trans>Points</Trans></th>
                    </tr>
                    </thead>
                    <tbody>
                    {offense.map((player) => {
                        const playerScores = (scores && scores[player.id] !== undefined) ? scores[player.id] : player.scores;
                        return <tr key={player.id}>
                            {actionsCell !== null &&  <td className="actions-cell">{actionsCell(player)}</td>}
                            <td>
                                <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                                    {player.photo && <img className="mb-small mr-small" width={40} src={player.photo}/>}
                                </div>
                            </td>
                            <td>{formatPlayerPosition(player)}</td>
                            <td>{formatPlayerName(player)}</td>
                            <td>{formatPlayerPopupButton(player)}</td>
                            <td>{playerScores ? playerScores['pass_yds'] : ''}</td>
                            <td>{playerScores ? playerScores['rush_yds'] : ''}</td>
                            <td>{playerScores ? playerScores['catch_yds'] : ''}</td>
                            <td>{playerScores ? playerScores['td'] : ''}</td>
                            <td>{playerScores ? playerScores['_2pt'] : ''}</td>
                            <td>{playerScores ? playerScores['fumble'] : ''}</td>
                            <td>{playerScores ? playerScores['int'] !== null ? playerScores['int'] : playerScores['defense_int'] : ''}</td>
                            <td>{playerScores ? playerScores['points'] : ''}</td>
                        </tr>
                    })}
                    </tbody>
                </table>

                <table className="table table-condensed table-bordered">
                    <caption><Trans>Special teams</Trans></caption>
                    <thead>
                    <tr>
                        {actionsCell !== null && <th className="min-width"></th>}
                        <th className="min-width"></th>
                        <th><Trans>Position</Trans></th>
                        <th><Trans>Name</Trans></th>
                        <th className="min-width"></th>
                        <th><Trans>FG</Trans></th>
                        <th><Trans>XP</Trans></th>
                        <th><Trans>FG miss</Trans></th>
                        <th><Trans>XP miss</Trans></th>
                        <th><Trans>Points</Trans></th>
                    </tr>
                    </thead>
                    <tbody>
                    {special.map((player) => {
                        const playerScores = (scores && scores[player.id] !== undefined) ? scores[player.id] : player.scores;
                        return (
                            <tr key={player.id}>
                                {actionsCell !== null && <td className="actions-cell">{actionsCell(player)}</td>}
                                <td>
                                    <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                                        {player.photo &&
                                        <img className="mb-small mr-small" width={40} src={player.photo}/>}
                                    </div>
                                </td>
                                <td>{formatPlayerPosition(player)}</td>
                                <td>{formatPlayerName(player)}</td>
                                <td>{formatPlayerPopupButton(player)}</td>
                                <td>{playerScores ? playerScores['fg'] : ''}</td>
                                <td>{playerScores ? playerScores['xp'] : ''}</td>
                                <td>{playerScores ? playerScores['fg_miss'] : ''}</td>
                                <td>{playerScores ? playerScores['xp_miss'] : ''}</td>
                                <td>{playerScores ? playerScores['points'] : ''}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                <table className="table table-condensed table-bordered">
                    <caption><Trans>Defense</Trans></caption>
                    <thead>
                    <tr>
                        {actionsCell !== null && <th className="min-width"></th>}
                        <th className="min-width"></th>
                        <th><Trans>Position</Trans></th>
                        <th><Trans>Name</Trans></th>
                        <th className="min-width"></th>
                        <th><Trans>Fum forced</Trans></th>
                        <th><Trans>INT</Trans></th>
                        <th><Trans>Sack</Trans></th>
                        <th><Trans>Pts allowed</Trans></th>
                        <th><Trans>Points</Trans></th>
                    </tr>
                    </thead>
                    <tbody>
                    {defense.map((player) => {
                        const playerScores = (scores && scores[player.id] !== undefined) ? scores[player.id] : player.scores;
                        return (
                            <tr key={player.id}>
                                {actionsCell !== null &&  <td className="actions-cell">{actionsCell(player)}</td>}
                                <td>
                                    <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                                        {player.photo &&
                                        <img className="mb-small mr-small" width={40} src={player.photo}/>}
                                    </div>
                                </td>
                                <td>{formatPlayerPosition(player)}</td>
                                <td>{formatPlayerName(player)}</td>
                                <td>{formatPlayerPopupButton(player)}</td>
                                <td>{playerScores ? playerScores['fum_forced'] : ''}</td>
                                <td>{playerScores ? playerScores['defense_int'] : ''}</td>
                                <td>{playerScores ? playerScores['sack'] : ''}</td>
                                <td>{playerScores ? playerScores['pts_allowed'] : ''}</td>
                                <td>{playerScores ? playerScores['points'] : ''}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                <table className="table table-condensed table-bordered">
                    <caption><Trans>Head Coach</Trans></caption>
                    <thead>
                    <tr>
                        {actionsCell !== null && <th className="min-width"></th>}
                        <th className="min-width"></th>
                        <th><Trans>Position</Trans></th>
                        <th><Trans>Name</Trans></th>
                        <th className="min-width"></th>
                        <th><Trans>Win Loss</Trans></th>
                        <th><Trans>Pts gained</Trans></th>
                        <th><Trans>Score difference</Trans></th>
                        <th><Trans>Points</Trans></th>
                    </tr>
                    </thead>
                    <tbody>
                    {hc.map((player) => {
                        const playerScores = (scores && scores[player.id] !== undefined) ? scores[player.id] : player.scores;
                        return (<tr key={player.id}>
                            {actionsCell !== null &&  <td className="actions-cell">{actionsCell(player)}</td>}
                            <td>
                                <div style={{width: 40, height: 40, overflow: 'hidden'}}>
                                    {player.photo && <img className="mb-small mr-small" width={40} src={player.photo}/>}
                                </div>
                            </td>
                            <td>{formatPlayerPosition(player)}</td>
                            <td>{formatPlayerName(player)}</td>
                            <td>{formatPlayerPopupButton(player)}</td>
                            <td>{playerScores ? playerScores['win_loss'] : ''}</td>
                            <td>{playerScores ? playerScores['pts_gained'] : ''}</td>
                            <td>{playerScores ? playerScores['score_difference'] : ''}</td>
                            <td>{playerScores ? playerScores['points'] : ''}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}
