import moment from "moment";

function prepareTeams(teams) {
    if (!teams) {
        return [];
    }

    return teams.map(({id, name}) => ({value: id, label: name}))
}

export const prepareScheduleForm = (season, teams, timezone) => {
    if (!season) {
        return {
            teams: prepareTeams(teams),
            weeks: [
                {games: [{}, {}]}
            ],
            scheduleType: 'rounds', //weeks or rounds
            roundGames: [],
            // {team1: 15, team1Round: 1, team2: 16, team2Round: 1},
            // {team1: 15, team1Round: 2, team2: 17, team2Round: 1},
            // {team1: 17, team1Round: 2, team2: 16, team2Round: 2},
            // ]
        };
    }

    //@todo
    // let weeksGames = {};
    // for (const game of season.games) {
    //     const week = game.week;
    //
    //     if (weeksGames[week] === undefined) {
    //         weeksGames[week] = {
    //             games: [],
    //         };
    //     }
    //
    //     const date = moment.tz(game.date, timezone);
    //     weeksGames[week]['games'].push({
    //         id: game.id,
    //         team1: game.team1.id,
    //         team2: game.team2.id,
    //         date: date,
    //         time: date.format('HH:mm:ss'),
    //     });
    // }
    // weeksGames = Object.values(weeksGames);

    const roundGames = season.games.map((game) => ({
        id: game.id,
        team1: game.team1.id,
        team1Round: game.team1Round,
        team2: game.team2.id,
        team2Round: game.team2Round,
        date: moment.tz(game.date, timezone),
        time: moment.tz(game.date, timezone).format('HH:mm:ss'),
        editable : game.startedAt === null,
    }));

    return {
        teams: prepareTeams(teams),
        scheduleType: 'rounds', //weeks or rounds @todo
        roundGames: roundGames,
        //weeks: weeksGames //@todo
    }
}
