import React from 'react';
import {Trans} from "react-i18next";

export default function NotFoundPage() {
    return (
        <main>
            <h1><Trans>Page Not Found</Trans></h1>
        </main>
    );
}
