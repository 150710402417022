import React from 'react';
import {Field, withFormik} from "formik";
import * as yup from "yup";
import ErrorMessage from "modules/Common/Form/ErrorMessage";
import moment from "moment";
import PlayerStatusSelect from "./PlayerStatusSelect";
import UploadThumbnail from "modules/Common/UploadThumbnail";
import PositionWidget from "modules/Owner/Teams/PositionWidget";

function PlayerForm({ submitButtonRef, values,setFieldValue, handleSubmit }) {
    return (
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}} className="form form-player" noValidate>

            <div className="form-group">
                <label className="form-label" htmlFor="name">Name</label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="position">Position</label>
                <Field component={PositionWidget} name="position" className="form-control" />
                <ErrorMessage name="position" />
            </div>

            {values.position !== 'DEF' && <div className="form-group">
                <label className="form-label" htmlFor="birthDate">D.O.B.</label>
                <Field type="text" name="birthDate" className="form-control" />
                <ErrorMessage name="birthDate" />
            </div>}

            {values.position !== 'HC' && values.position !== 'DEF' &&
                <>
                    <div className="form-group">
                        <label className="form-label" htmlFor="height">Height (cm)</label>
                        <Field type="text" name="height" className="form-control" />
                        <ErrorMessage name="height" />
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="weight">Weight (kg)</label>
                        <Field type="text" name="weight" className="form-control" />
                        <ErrorMessage name="weight" />
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="jerseyNumber">Jersey number</label>
                        <Field type="text" name="jerseyNumber" className="form-control" />
                        <ErrorMessage name="jerseyNumber" />
                    </div>
                </>
            }

            <div className="form-group">
                <label className="form-label" htmlFor="status">Status</label>
                <Field component={PlayerStatusSelect} name="status" className="form-control" />
                <ErrorMessage name="status" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="analysis">Analysis</label>
                <Field component="textarea" name="analysis" className="form-control" />
                <ErrorMessage name="analysis" />
            </div>

            <div className="form-group">
                <label className="form-label" htmlFor="file">Picture</label>
                <input key={values.photo} name="file" type="file" accept=".gif,.jpg,.jpeg,.png" onChange={(event) => {
                    setFieldValue("photo", event.currentTarget.files[0]);
                }} className="form-control"/>

                <ErrorMessage name="photo" />

                <div style={{marginLeft: '25rem'}} className='mt-default'>
                    <UploadThumbnail file={values.photo}/>
                </div>
            </div>

            <div className="form-group mt-default" style={{display: 'none'}}>
                <button ref={submitButtonRef} className="submit btn btn-primary mr-small" type="submit">Save Changes</button>
                {/*<Link to="/secure" className="btn btn-default">Cancel</Link>*/}
            </div>
        </form>
    );
}

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

export default
    withFormik({
        mapPropsToValues: ({ player }) => {
            if (player) {
                return {
                    'name': player.name,
                    'position': player.position ? player.position : null,
                    'birthDate': player.birthDate ? moment(player.birthDate).format('DD/MM/YYYY') : null,
                    'height': player.height ? player.height : null,
                    'weight': player.weight ? player.weight : null,
                    'jerseyNumber': player.jerseyNumber ? player.jerseyNumber : null,
                    'status': player.status ? player.status : null,
                    'analysis': player.analysis,
                }
            }

            return {
                'name': '',
                'position': '',
                'birthDate': '',
                'jerseyNumber': null,
                'status': '',
                'height': null,
                'weight': null,
                'photo': null,
                'analysis': '',
            }
        },
        validationSchema: yup.object().shape({
            name: yup.string().required('Name is required'),
            position: yup.string().required('Position is required'),
            birthDate: yup.mixed().nullable().when('position', {
                is: (position) => (position !== 'DEF'),
                then: yup.date().nullable()
                    .typeError('This date is invalid, use DD/MM/YYYY format')
                    .transform(function(value, originalValue) {
                        if (!value) {
                            return null;
                        }

                        value = moment(originalValue, 'DD/MM/YYYY', true);
                        return value.isValid() ? value.toDate() : new Date('');
                    })
            }),

            height: yup.mixed().when('position', {
                is: (position) => (position !== 'HC' && position !== 'DEF'),
                then: yup.number().nullable().integer('Height must be an integer').typeError('Height must be an integer').min(150).max(250),
            }),

            weight: yup.mixed().when('position', {
                is: (position) => (position !== 'HC' && position !== 'DEF'),
                then: yup.number().nullable().integer('Weight must be an integer').typeError('Weight must be an integer').min(40).max(200),
            }),

            jerseyNumber: yup.mixed().when('position', {
                is: (position) => (position !== 'HC' && position !== 'DEF'),
                then: yup.number().nullable().integer('Jersey number must be an integer').typeError('Jersey number must be an integer').min(1).max(99),
            }),

            status: yup.string().nullable(),
            analysis: yup.string(),
            photo: yup
                .mixed()
                .test(
                    "fileSize",
                    "File too large",
                    value => !value || value.size <= FILE_SIZE
                )
                .test(
                    "fileFormat",
                    "Unsupported Format",
                    value => !value || SUPPORTED_FORMATS.includes(value.type)
                )
        }),
        handleSubmit: (values, { props }) => {
            const data = {...values};
            const position = values.position;

            if (position !== 'HC' && position !== 'DEF') {
                data.height = values.height ? parseInt(values.height) : null;
                data.weight = values.weight ? parseInt(values.weight) : null;
                data.jerseyNumber = values.jerseyNumber ? parseInt(values.jerseyNumber) : null;
            } else {
                data.height = null;
                data.weight = null;
                data.jerseyNumber = null;
            }

            data.birthDate = position !== 'DEF' && values.birthDate
                ? moment(values.birthDate, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
                : null
            ;

            props.processData(data);
        }
    })(PlayerForm)
;
