import React from 'react';
import {compose} from "recompose";
import groupPlayersByTeamAndPosition, {
    formatPositionTitle,
    getFantasySectionPositionsList
} from "services/PlayerService";
import {NB_BENCH_PLAYERS} from "services/RosterService";
import {withTranslation} from "react-i18next";
const positions = getFantasySectionPositionsList();

const RostersSummaryTable = ({ rosters, withHighlight = true, t }) => {
    const teams = groupPlayersByTeamAndPosition(rosters);
    const benchPositions = Array.from({length: NB_BENCH_PLAYERS}, () => t('Bench'));

    const PlayerCell = ({ player, team }) => {
        const isHighlighted = withHighlight
            ? player ? team.lastAddedPlayerId === player.id : null
            : false
        ;

        return (
            <td className={isHighlighted ? 'player-cell-highlighted' : 'player-cell'}>
                {player ? player.name : ''}
            </td>
        );
    }

    return (
        <table className="table table-bordered table-striped draft-picks-table">
            <thead>
            <tr>
                <th></th>
                {teams.map((team) => <th>
                    <div style={{display: 'flex', alignItems: 'center', fontSize: 16}}>
                        <img style={{height: 32, marginRight: 10}} src={team.logoUrl} />
                        <span>{team.name}</span>
                    </div>
                </th>)}
            </tr>
            </thead>
            <tbody>
            {positions.map((position) => {

                return <tr>
                    <td>{formatPositionTitle(position)}</td>
                    {teams.map((team) => {
                        const currentPositionPlayers = team.players.active[position] ?? [];
                        const player = currentPositionPlayers.length>0 ? currentPositionPlayers.shift() : null;

                        return <PlayerCell player={player} team={team} />;
                    })}
                </tr>
            })}

            {benchPositions.map((caption, benchIndex) => <tr>
                <td>{caption}</td>
                {teams.map((team) => {
                    const player = team.players.bench[benchIndex] ?? null;
                    return <PlayerCell player={player} team={team} />;
                })}
            </tr>)}
            </tbody>
        </table>
    );
};

export default compose(
    withTranslation()
)(RostersSummaryTable);