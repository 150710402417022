import React, {Component} from "react";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import EditLeagueForm from "./EditLeagueForm";
import {updateLeague, getLeague} from "services/LeagueService";
import {addSuccessNotification} from "services/NotificationService";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";
import Loader from "modules/Common/Loader";

class EditLeaguePage extends Component
{
    state = {
        league: null,
        isLoading: false,
    };

    componentDidMount() {
        const leagueId = this.props.match.params.id;
        this.setState({isLoading: true});
        getLeague(leagueId)
            .then((league) => {
                this.setState({league});
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
        ;
    }

    processData = (values) => {
        const {t} = this.props;

        this.setState({isLoading: true});
        return updateLeague(this.state.league.id, values)
            .then(() => {
                addSuccessNotification(t('Success'), t('League details were updated'));
                this.props.history.push('/settings');
            })
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        const {isLoading, league} = this.state;

        if (!league) {
            return <main><Loader /></main>;
        }

        return (
            <main>
                <LoadingOverlay active={isLoading}>
                    <EditLeagueForm league={league} processData={this.processData} />
                </LoadingOverlay>
            </main>
        );
    }
}

export default withTranslation()(withRouter(EditLeaguePage));
