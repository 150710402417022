import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {checkDraftOverdue, getLeague} from "services/LeagueService";
import Loader from "modules/Common/Loader";
import {addErrorAsNotification} from "services/ValidationService";
import DraftTeamsSlider from "modules/Draft/DraftTeamsSlider";
import {getDraftTeam, getDraftTeams} from "services/DraftService";
import PlayersTable from "modules/Players/PlayersTable";
import RosterSection from "modules/Roster/RosterSection";
import {getLeagueRosters, getMyRoster} from "services/RosterService";
import LoadingOverlay from "modules/Common/LoadingOverlay";
import PickablePlayersTable from "modules/Players/PickablePlayersTable";
import {getCurrentSeason} from "services/SeasonService";
import {addErrorNotification, addSuccessNotification} from "services/NotificationService";
import {Trans, withTranslation} from "react-i18next";
import moment from "moment";
import {setPostLoginRoute} from "services/AuthService";
import Countdown from "react-countdown";
import RostersSummaryTable from "modules/Draft/RostersSummaryTable";
import {getCurrentUser} from "services/UserService";
import LeagueSwitcher from "modules/Dashboard/LeagueSwitcher";

class DraftPage extends Component {
    state = {
        isLoading: true,
        listenFormDraftStatusChangeIntervalId: null,
        league: null,
        season: null,
        leagueRosters: null,
        roster: null,
        draftTeams: null,
        players: null,
        user: null,
        sort: 'fantasy_points',
        sortDirection: 'desc',
    };

    componentDidMount() {
        this.refreshData();
    }

    componentWillUnmount() {
        if (this.state.listenFormDraftStatusChangeIntervalId) {
            clearInterval(this.state.listenFormDraftStatusChangeIntervalId);
        }
    }

    listenFormDraftStatusChange() {
        const league = this.state.league;
        if (!league) {
            return;
        }

        const draft = league.currentSeason ? league.currentSeason.draft : null;
        if (!draft || !draft.team) {
            return;
        }

        if (this.state.listenFormDraftStatusChangeIntervalId) {
            clearInterval(this.state.listenFormDraftStatusChangeIntervalId);
        }

        //Listen for draft status change (every 2s)
        const intervalId = setInterval(() => {
            getDraftTeam(league.id)
                .then(({draftTeam}) => {
                    if (draftTeam === null || draftTeam.id !== draft.team.id) {
                        window.location.reload();
                    }
                })
                .catch(() => {
                    //noop
                })
            ;
        }, 4000);

        this.setState({listenFormDraftStatusChangeIntervalId: intervalId});
    }

    isSuperAdmin = () => {
        return this.state.user.roles.includes("SUPER_ADMIN");
    }

    refreshData = () => {
        const leagueId = this.props.match.params.id;
        const {t} = this.props;

        this.setState({isLoading: true});

        const requests = Promise.all([
            //league
            getLeague(leagueId)
                .then((league) => {
                    const currentSeason = league.currentSeason;
                    if (currentSeason) {
                        if (currentSeason.status !== 'drafting') {
                            addSuccessNotification(
                                t('Draft ended'),
                                t('The draft has ended, good luck this season')
                            );

                            this.props.history.push('/leagues/'+leagueId);
                        }
                    } else {
                        addErrorNotification(
                            t('Cannot access draft'),
                            t('There is no active season')
                        );

                        this.props.history.push('/leagues/'+leagueId);
                    }

                    return league;
                }),

            //season
            getCurrentSeason(),

            //roster
            getMyRoster(leagueId),

            //rosters of all league teams
            getLeagueRosters(leagueId),

            //draft teams
            getDraftTeams(leagueId),

            //user
            getCurrentUser(),
        ]);

        //fetch all at once
        requests
            .then(([league, season, roster, leagueRosters, draftTeams, user]) => {
                this.setState({league, season, roster, leagueRosters, draftTeams, user, isLoading: false});
                this.listenFormDraftStatusChange();
            })
            .catch((response) => {
                this.setState({isLoading: false});
                addErrorAsNotification(response, this.props.t);
                if (response.status === 403) {
                    setPostLoginRoute(this.props.location.pathname);
                    this.props.history.push('/login');
                }
            })
    };

    onSort = (sort, sortDirection) => {
        this.setState({sort, sortDirection}, () => this.refreshData());
    };

    checkDraftOverdue = () => {
        if (!this.state.league) {
            return;
        }

        setTimeout(() => {
            checkDraftOverdue(this.state.league.id)
                .then((result) => {
                    if (result.player_picked) {
                        window.location.reload();
                    }
                })
                .catch(() => {})
            ;
        }, 2000); //2s delay to compensate for any server time difference

    };

    render() {
        const {isLoading, league, roster, draftTeams} = this.state;

        if (isLoading) {
            return <main><Loader /></main>;
        }

        if (!league) {
            return null;
        }

        const currentSeason = league.currentSeason;
        const draft = currentSeason.draft;
        if (!draft) {
            return null;
        }

        const isSuperAdmin = this.isSuperAdmin();
        const isMyTurn = (draft && roster && roster.team.id === draft.team.id);
        const playersTable = isMyTurn
            ? <PickablePlayersTable key={draft.round+"_"+this.state.sort+"_"+this.state.sortDirection} sort={this.state.sort} sortDirection={this.state.sortDirection} onSort={this.onSort} leagueId={league.id} rosterId={roster.id} onPlayerPicked={this.refreshData} />
            : <PlayersTable key={this.state.sort+"_"+this.state.sortDirection} sort={this.state.sort} sortDirection={this.state.sortDirection} onSort={this.onSort} leagueId={league.id} />
        ;

        const turnDeadline = moment.tz(currentSeason.draft.turnDeadlineAt, currentSeason.draft.team.timezone);

        return (
            <main>

                {isSuperAdmin &&
                    <div className="clearfix">
                        <div className="mb-default">
                            <Link to={`/owner/leagues/${league.id}`} className="btn btn-sm btn-default">❮ &nbsp; League
                                Details</Link>
                        </div>
                    </div>
                }

                <LoadingOverlay active={isLoading}>
                    <div className="league-draft-overview-section">
                        <h1><Trans i18nKey="League draft center" values={{leagueName: league.name}} /></h1>

                        <DraftTeamsSlider
                            draftTeams={draftTeams}
                            current={currentSeason.draft.team}
                            round={currentSeason.draft.round}
                            nbRounds={currentSeason.draft.nbRounds}
                            direction={currentSeason.draft.direction}
                        />

                        {isMyTurn && <div className="draft-section text-center mb-default">
                            <h3 className="mb-small"><Trans>Your turn</Trans>!</h3>
                            <div className="countdown">
                                <Countdown
                                    date={turnDeadline}
                                    daysInHours={true}
                                    onComplete={() => this.checkDraftOverdue()}
                                />
                            </div>
                            <p>
                                <Trans i18nKey="You have time until {{date}}" values={{date: turnDeadline.format('LLLL')}}/>
                            </p>
                        </div>}

                        {!isMyTurn && <div className="draft-section text-center mb-default">
                            <h3 className="mb-small"><Trans i18nKey="{{teamName}}'s turn" values={{teamName: draft.team.name}} /></h3>
                            <div className="countdown">
                                <Countdown
                                    date={turnDeadline}
                                    daysInHours={true}
                                    onComplete={() => this.checkDraftOverdue()}
                                />
                            </div>
                            <p>
                                <Trans i18nKey="{{teamName}} has time until {{date}}" values={{teamName: draft.team.name, date: turnDeadline.format('LLLL')}}/>
                            </p>
                        </div>}

                        {this.state.leagueRosters && <div className="draft-picks-section">
                            <h3 className="mb-small"><Trans>Draft picks</Trans></h3>
                            <RostersSummaryTable rosters={this.state.leagueRosters} />
                        </div>}

                        <div className="mb-default">
                            <h3 className="mb-small"><Trans>Players</Trans></h3>
                            {playersTable}
                        </div>
                    </div>
                </LoadingOverlay>

                {!isSuperAdmin && <LoadingOverlay active={isLoading}>
                    <div className="my-team-section">
                        <h3 className="mb-small"><Trans>My Team</Trans></h3>
                        <RosterSection
                            roster={roster}
                            editable={false}
                            withEmptySlots={true}
                        />
                    </div>
                </LoadingOverlay>}
            </main>
        );
    }
}

export default withTranslation()(withRouter(DraftPage));
