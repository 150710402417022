import React from 'react';
import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import {withTranslation} from "react-i18next";

function AcceptInvitationConfirmationModal({league, onConfirm, onCancel, t, ...rest}) {
    if (!league) {
        return null;
    }

    const name = league.name;
    return (
        <ConfirmationModal
            title={t("Invitation to join league")}
            content={t("Are you sure you want to accept the invitation to join {{name}} league?", {name})}
            buttons={{
                yes: t('Accept invitation'),
                no: t('Cancel')
            }}
            type="creative"
            onConfirm={() => onConfirm(league)}
            onCancel={onCancel}
            {...rest}
        />
    );
}

export default withTranslation()(AcceptInvitationConfirmationModal);
