import React from 'react';
import Select from "react-select";

const LeaguesQtySelect = ({form, field, nbMaxLeagues, ...rest}) => {
    nbMaxLeagues = Math.max(nbMaxLeagues, 1)

    let options = [];
    for (let i=0; i<=nbMaxLeagues; ++i) {
        options.push({value: i, label: i});
    }

    const selectedOption = field.value ?
        options.filter((option) => field.value.includes(option.value))
        : []
    ;

    return (
        <Select
            isMulti={true}
            value={selectedOption}
            closeMenuOnSelect={false}
            placeholder='Any'
            menuPortalTarget={document.body}
            options={options}
            onChange={(options) => {
                if (!options) {
                    options = [];
                }

                form.setFieldValue(field.name, options.map((option) => option.value));
            }}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                menuList: () => ({
                    fontSize: '1.6rem'
                })
            }}
            {...rest}
        />
    );
};

export default LeaguesQtySelect;
