import React, {Component} from 'react';
import GameResultModal from "./GameResultModal";
import {markGameResultViewied} from "services/FantasyGameService";

class GameResultsNotification extends Component {
    state = {
        notViewedGameResults: [],
        gameResult: null,
        showGameResultModal: false
    };

    componentDidMount() {
        this.setState({notViewedGameResults: this.props.gameResults}, () => {
            this.showNextNotViewedGameResult();
        });
    }

    showGameResultModal = (gameResult) => {
        this.setState({gameResult, showGameResultModal: true}, function() {
            const {team, league} = this.props;
            markGameResultViewied(league.id, gameResult.game.id, team.id);
        });
    };

    hideGameResultModal = () => {
        this.setState({showGameResultModal: false});
        this.showNextNotViewedGameResult();
    };

    showNextNotViewedGameResult = () => {
        const { notViewedGameResults } = this.state;
        if (notViewedGameResults.length > 0) {
            const gameResult = notViewedGameResults.splice(0, 1)[0];
            this.showGameResultModal(gameResult);
            this.setState({notViewedGameResults: notViewedGameResults});
        } else {
            this.props.onFinished();
        }
    };


    render() {
        const {gameResult, showGameResultModal} = this.state;

        return (
            <>
                {gameResult && <GameResultModal
                    isOpen={showGameResultModal}
                    gameResult={gameResult}
                    handleCloseModal={this.hideGameResultModal}
                />}
            </>
        );
    }
}

export default GameResultsNotification;
