import ConfirmationModal from "modules/Common/Modal/ConfirmationModal";
import React from "react";

export default function FreezeWeekConfirmationModal({onConfirm, ...rest}) {
    return (
        <ConfirmationModal
            title={"Freeze Week"}
            content={`Are you sure you want to freeze current week?`}
            buttons={{
                yes: 'Confirm',
                no: 'Cancel'
            }}
            type="creative"
            onConfirm={onConfirm}
            {...rest}
        />
    );
}
