import React from 'react';
import Moment from "react-moment";
import {Link} from "react-router-dom";

function SeasonDetails({season, timezone}) {
    return (
        <div className="mb-default">
            <h4 className="mb-small">Active Season:</h4>
            <dl className="dl-list mb-small">
                <dt>ID:</dt> <dd>{season.id}</dd>
                <dt>Starts:</dt> <dd><Moment tz={timezone}>{season.startsAt}</Moment></dd>
                <dt>Ends:</dt> <dd><Moment tz={timezone}>{season.endsAt}</Moment></dd>
                <dt>Weeks:</dt> <dd>{season.nbWeeks}</dd>
                <dt>Playoff Weeks:</dt> <dd>{season.nbPlayoffWeeks}</dd>
                <dt>Teams:</dt> <dd>{season.nbTeams}</dd>
                <dt>Max Fantasy Teams:</dt> <dd>{season.nbMaxFantasyTeams}</dd>
                <dt>Draft Window Start:</dt> <dd><Moment tz={timezone}>{season.draftWindow.startDate}</Moment></dd>
                <dt>Draft Window End:</dt> <dd><Moment tz={timezone}>{season.draftWindow.endDate}</Moment></dd>
            </dl>

            <Link className="btn btn-primary" to={`/owner/seasons/${season.id}`}>
                Edit Season
            </Link>
        </div>
    );
}

export default SeasonDetails;
