import React, {useRef} from 'react';
import ReactModal from "react-modal";
import PlayerForm from "./PlayerForm";

export default function PlayerModal(props) {
    const {
        team,
        player,
        switchPlayers,
        handleCloseModal,
        onSubmit,
        onCancel,
        ...rest
    } = props;

    const onRequestCancel = () => {
        if (onCancel === undefined || onCancel() !== false) {
            handleCloseModal();
        }
    };

    const submitButtonRef = useRef(null);
    const isNew = (player === null);

    if (!team && !player) {
        return null;
    }

    return (
        <ReactModal
            onRequestClose={onRequestCancel}
            className="default-modal autofit"
            overlayClassName="modal-overlay"
            closeTimeoutMS={200}
            {...rest}
        >
            <span onClick={onRequestCancel} className="modal-close">&times;</span>

            <p className="modal-title">{isNew ? 'Add Player' : 'Edit Player'}</p>
            <div className="modal-content">
                <PlayerForm
                    submitButtonRef={submitButtonRef}
                    player={player}
                    processData={(playerData) => {
                        onSubmit(team, playerData)
                            .then(() => handleCloseModal())
                            .catch(() => null)
                    }}
                />
            </div>

            <div className="modal-actions">
                <button className="btn btn-default" onClick={onRequestCancel}>Cancel</button>
                <button className="btn btn-success" onClick={() => {
                    submitButtonRef.current.click();
                }}>{isNew ? 'Add Player' : 'Edit Player'}
                </button>
            </div>
        </ReactModal>
    )
}
